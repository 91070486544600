import React from "react";
import styled, { keyframes } from "styled-components";

const rolodexAnimation = keyframes`
  0%{
    transform: translateY(60rem);
     opacity: 0;
    }
  15% {
    transform: translateY(55rem);
    opacity: 0;
    }
  33% {
    opacity: 0;
      }
  37.3% {
    transform: translateY(-4.2rem);
    opacity: 1;
      }
  41% {
    transform: translateY(-4.2rem);
    opacity: 1;
      }
  47% {
    transform: translateY(-4.13rem);
    opacity: 1;
      }
  49% {
    transform: translateY(-4.0rem);
    opacity: 1;
      }
  51.5% {
    opacity: 0;
      }
  54% {
    transform: translateY(-18rem);
    opacity: 0;
        }
  75% {
    transform: translateY(-40rem);
    opacity: 0;
  }
    100% {
    transform: translateY(-50rem);
    opacity: 0;
  }
`;

const SubWelcomeContainer = styled.div`
  display: flex;
  justify-content: left;
  position: relative;
  height: 5.5rem;
  overflow: hidden;
  @media (max-width: 992px) {
    height: 4.5rem;
  }
`;

const SubWelcomeItem = styled.div`
  position: absolute;
  top: 3.9rem;
  animation: ${rolodexAnimation} 14s linear infinite;
  opacity: 0;
  color: #e75139;
  font-size: 56px;
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
  @media (max-width: 992px) {
    font-size: 2.15rem;
    top: 4.9rem;
    line-height: 1;
  }
  @media (max-width: 600px) {
    font-size: 2.15rem;
    top: 4.9rem;
    line-height: 1;
  }

  @media (max-width: 400px) {
    font-size: 1.9rem;
    top: 4.9rem;
    line-height: 1;
  }

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 1.75s;
  }

  &:nth-child(3) {
    animation-delay: 3.5s;
  }

  &:nth-child(4) {
    animation-delay: 5.25s;
  }

  &:nth-child(5) {
    animation-delay: 7s;
  }

  &:nth-child(6) {
    animation-delay: 8.75s;
  }

  &:nth-child(7) {
    animation-delay: 10.5s;
  }
  &:nth-child(8) {
    animation-delay: 12.25s;
  }
`;

const SubWelcomeAnimation = ({ texts }) => {
  return (
    <SubWelcomeContainer>
      {texts.map((text, index) => (
        <SubWelcomeItem key={index}>{text}</SubWelcomeItem>
      ))}
    </SubWelcomeContainer>
  );
};

export default SubWelcomeAnimation;