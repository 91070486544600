import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { Line } from "react-chartjs-2";
import numeral from "numeral";
import { GlobalStyle } from "../StyledComponents";
import { Element } from "react-scroll";
import { Chart, Filler, registerables } from "chart.js/auto";
import Select from "react-select";
Chart.register(...registerables, Filler);

const calculateArea = (data) => {
  let area = 0;
  const lastIndex = data.length - 1;

  for (let i = 1; i <= lastIndex; i++) {
    const width = data[i].x - data[i - 1].x;
    const height1 = data[i].y;
    const height2 = data[i - 1].y;

    if (!isNaN(height1) && !isNaN(height2)) {
      const height = (height1 + height2) / 2;
      area += width * height;
    } else {
      console.warn(
        `Invalid height values at indices ${i} and ${
          i - 1
        }. Skipping calculation.`
      );
    }
  }

  return area;
};

const calculateAreaBetweenCurves = (data1, data2) => {
  let areaAbove1 = 0;
  let areaAbove2 = 0;

  for (let i = 1; i < data1.length; i++) {
    const width = data1[i].x - data1[i - 1].x;
    const heightDifference = data1[i].y - data2[i].y;

    if (!isNaN(heightDifference)) {
      if (heightDifference > 0) {
        areaAbove1 += width * heightDifference;
      } else if (heightDifference < 0) {
        areaAbove2 += width * Math.abs(heightDifference);
      }
    } else {
      console.warn(
        `Invalid height difference at index ${i}. Skipping calculation.`
      );
    }
  }

  return { areaAbove1, areaAbove2 };
};

const PowerGraphs = ({ isHPBannerVisible }) => {
  const [datasets, setDatasets] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleFileLoad = (data) => {
    console.log("Loaded data:", data);

    const headers = data[0];
    const rows = data.slice(1);

    const processedData = headers.slice(1).map((header, index) => ({
      label: header,
      data: rows.map((row) => ({
        x: parseInt(row[0], 10),
        y: parseFloat(row[index + 1]),
      })),
    }));

    console.log("Processed data:", processedData);
    setDatasets(processedData);
  };

  const handleSelectChange = (selectedOptions) => {
    console.log("Selected options:", selectedOptions);
    setSelectedGraphs(selectedOptions);
  };

  const colorOptions = [
    { label: "Pink", value: "rgba(255,99,132,1)" },
    { label: "Blue", value: "rgba(54,162,235,1)" },
    { label: "Orange", value: "rgba(255,159,64,1)" },
    { label: "Green", value: "rgba(0, 225, 66, 1)" },
    { label: "Purple", value: "rgba(153,102,255,1)" },
    { label: "Yellow", value: "rgba(255,205,86,1)" },
    { label: "Teal", value: "rgba(64,224,208,1)" },
    { label: "MidnightBlue", value: "rgba(25,25,112,1)" },
    { label: "Crimson", value: "rgba(220,20,60,1)" },
    { label: "SlateGray", value: "rgba(112,128,144,1)" },
    { label: "DarkOrange", value: "rgba(255,140,0,1)" },
    { label: "Maroon", value: "rgba(128,0,0,1)" },
  ];

  const graphOptions = datasets.map((dataset, index) => ({
    label: capitalizeFirstLetter(dataset.label),
    value: dataset.label,
    color: colorOptions[index % colorOptions.length].value,
  }));

  const selectedData = selectedGraphs.map((graph, index) => {
    const dataset = datasets.find((dataset) => dataset.label === graph.value);
    const colorIndex = graphOptions.findIndex(
      (option) => option.value === graph.value
    );
    const rgbaColor = colorOptions[
      colorIndex % colorOptions.length
    ].value.replace("1)", "0.2)");
    return {
      label: capitalizeFirstLetter(dataset.label),
      data: dataset.data,
      borderColor: graph.color,
      backgroundColor: rgbaColor,
      fill: "-1",
      pointBackgroundColor: graph.color,
      tension: 0.4,
    };
  });

  const areas = selectedGraphs.map((graph) => {
    const data = datasets.find((dataset) => dataset.label === graph.value).data;
    const area = calculateArea(data);
    return {
      label: graph.label,
      area,
    };
  });

  const { areaAbove1, areaAbove2 } =
    selectedGraphs.length === 2
      ? calculateAreaBetweenCurves(
          datasets.find((dataset) => dataset.label === selectedGraphs[0].value)
            .data,
          datasets.find((dataset) => dataset.label === selectedGraphs[1].value)
            .data
        )
      : { areaAbove1: 0, areaAbove2: 0 };

  const chartOptions = {
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        ticks: {
          stepSize: 1,
        },
        title: {
          display: true,
          text: "Time of day (hours)",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Energy (kWh)",
        },
      },
    },
    plugins: {
      filler: {
        propagate: true,
      },
    },
    elements: {
      line: {
        fill: "start",
      },
    },
  };

  return (
    <>
      <GlobalStyle />
      <Element
        name="insights-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div
          style={{
            padding: "50px",
            paddingBottom: "100px",
            minHeight: "700px",
          }}
        >
          <h2>Upload Data (CSV)</h2>
          <h5>
            Time in 1 hr increments in column 1, energy data in kilowatt hours,
            one column per energy dataset
          </h5>
          <CSVReader onFileLoaded={handleFileLoad} />

          {datasets.length > 0 && (
            <>
              <h3>Select Data to Plot</h3>
              <Select
                isMulti
                options={graphOptions}
                onChange={handleSelectChange}
              />

              {selectedGraphs.length > 0 && (
                <>
                  <Line
                    data={{ datasets: selectedData }}
                    options={chartOptions}
                  />

                  <div>
                    <h3>Area Calculations</h3>
                    {areas.map((area) => (
                      <p key={area.label}>
                        Area under {area.label} curve:{" "}
                        {numeral(area.area).format("0,0.00")} kWh
                      </p>
                    ))}

                    {selectedGraphs.length === 2 && (
                      <div style={{ marginTop: "20px" }}>
                        <p>
                          Area where {selectedGraphs[0].label} is above{" "}
                          {selectedGraphs[1].label}:{" "}
                          {numeral(areaAbove1).format("0,0.00")} kWh
                        </p>
                        <p>
                          Area where {selectedGraphs[1].label} is above{" "}
                          {selectedGraphs[0].label}:{" "}
                          {numeral(areaAbove2).format("0,0.00")} kWh
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Element>
    </>
  );
};

export default PowerGraphs;
