import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../utils/fireBaseConfig";
import styled from "styled-components";
import logo from "../../assets/logo/kepler_logo.svg";
import image21 from "../../assets/images/background5.svg";
import {
  Button4,
  ButtonContainer2,
  GlobalStyle,
  Copyright1,
} from "../StyledComponents";
import {
  EyeOpenIcon,
  EyeOffIcon,
  LoginIcon,
  CapsLockIcon,
  InfoIcon,
} from "../Icons";
import africa from "../../assets/images/africa1.svg";
import { Grid } from "react-loader-spinner";

const VerifyCodePopup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-image: url(${image21}); // Replace with your image URL
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: 3vh;
  margin-bottom: 3vh;
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 35%;
  border: 1px solid #b6c6c5;

  @media (max-width: 992px) {
    width: 87%;
    border-radius: 5px;
  }
`;

const PopupTitle = styled.h2`
  width: 100%;
  margin-top: 3vh;
  margin-bottom: 5vh;
  color: #245e41;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
`;

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  width: 100%;
`;

const PopupLabel = styled.label`
  width: 98%;
  position: absolute;
  padding-bottom: 20px;
  left: 12px;
  top: ${(props) =>
    props.focused || props.value || !props.valid ? "-15px" : "50%"};
  transform: translateY(-30%);
  font-size: ${(props) =>
    props.focused || props.value || !props.valid ? "14px" : "16px"};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.value && props.valid ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;

  ${FormGroup}:focus-within & {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }
`;

const PopupLabel1 = styled.label`
  width: 98%;
  position: absolute;
  padding-bottom: 20px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-15px" : "50%")};
  transform: translateY(-30%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.value ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;

  ${FormGroup}:focus-within & {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }
`;

const PopupInput = styled.input`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid
    ${(props) => (props.value && !props.valid ? "red" : "#ccc")};
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  background: transparent;

  &:focus {
    outline: none;
    border-bottom: 1px solid #245e41;
  }

  /* Style for autofill input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const PopupInput1 = styled.input`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  background: transparent;

  &:focus {
    outline: none;
    border-bottom: 1px solid
      border-bottom: 1px solid #245e41;
  }

  /* Style for autofill input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const RequiredIndicator = styled.span`
  color: ${(props) =>
    !props.focused && props.transparent ? "transparent" : "red"};
  margin-left: 1px;
  font-size: 14px;
`;

const ToggleButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;

  svg {
    color: #7a9795;
  }

  &:hover {
    svg {
      color: #245e41;
    }
  }
`;

const CapsLockIndicator = styled.div`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 2%;

  svg {
    color: #ff4545;
  }

  &:hover {
    svg {
      color: #e90000;
    }
  }
`;

const PopupError = styled.p`
  color: red;
  font-size: 14px;
`;

const VerifyCode = ({
  successNotification,
  warningNotification,
  errorNotification,
  infoNotification,
}) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isCapsLockActive, setIsCapsLockActive] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const allowedAttempts = 5;
  const [remainingAttempts, setRemainingAttempts] = useState(allowedAttempts);
  const [isAccountLocked, setIsAccountLocked] = useState(false);
  const [isAccountLocked1, setIsAccountLocked1] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
    const [userIpAddress, setUserIpAddress] = useState("");

  const navigate = useNavigate();
  const emailInputRef = useRef(null);

  const currentYear = new Date().getFullYear();
  const copyrightText =
    currentYear === 2023 ? `${currentYear}` : `${2023} - ${currentYear}`;

  const CountryFlagIcon = ({ country, width, height }) => {
    const flagMap = {
      Africa: africa,
    };

    return (
      <div>
        <img
          src={flagMap[country]}
          alt={`${country} Flag`}
          width={width}
          height={height}
        />
      </div>
    );
  };

    useEffect(() => {
      async function fetchUserIPAdress() {
        try {
          const response = await fetch("https://freeipapi.com/api/json");
          const data = await response.json();
          setUserIpAddress(data.ipAddress);
        } catch (error) {
          setUserIpAddress("");
        }
      }

      fetchUserIPAdress();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const getFirebaseErrorMessage = (errorCode) => {
    const errorMessages = {
      // Add error messages as needed
      default: errorCode,
    };

    return errorMessages[errorCode] || errorMessages.default;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    if (email.trim() === "") {
      setIsEmailValid(true);
    } else if (!document.activeElement.isEqualNode(emailInputRef.current)) {
      setIsEmailValid(validateEmail(email));
    }
  };

  const handlePasswordChange = (newPassword) => {
    setPassword(newPassword);
  };

  const twoStepLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    axiosInstance
      .post("/api/verify-code", {
        email,
        checkAttempt: true,
      })
      .then((response) => {
        if (response.data.attempts) {
          setRemainingAttempts(response.data.remainingAttempts);
        } else if (response.data.invalidUser) {
          const allowed = remainingAttempts - 1;
          setRemainingAttempts(allowed);
          if (remainingAttempts <= 1) {
            errorNotification(
              `This account is locked. Contact customer support.`
            );
            setIsAccountLocked1(true);
            setLoading(false);
            return;
          } else {
            setLoading(false);
            return;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });

    try {
      axiosInstance
        .post("/api/verify-code", {
          email,
          verificationCode,
          userIpAddress
        })
        .then(async (response) => {
          if (response.data.success) {
            try {
              await signInWithEmailAndPassword(auth, email, password);
              successNotification(`Account verified. Login successful!`);
              setIsAccountLocked(false);
              setIsAccountLocked1(false);
              setVerificationCode("");
              axiosInstance
                .post("/api/verify-code", {
                  deleteCode: true,
                  verificationCode,
                  userIpAddress,
                })
                .then(async (response) => {})
                .catch((error) => {});
              navigate("/");
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }, 500);
              setLoading(false);
            } catch (loginError) {
              const errorMessage = getFirebaseErrorMessage(loginError.code);
              console.error(errorMessage);
              warningNotification(
                `Invalid verification code/login details. Please check your code and login details before trying again.`
              );
              setVerificationCode("");
              if (remainingAttempts <= 1) {
                axiosInstance.post("/api/disable-user", { email }).then(() => {
                  axiosInstance
                    .post("/api/verify-code", {
                      email,
                      lockAccount: true,
                    })
                    .then(async (response) => {
                      if (response.data.accountLocked) {
                        errorNotification(
                          `Your account is now locked due to many unsuccessful verification attempts. Contact customer support.`
                        );
                        setIsAccountLocked(true);
                        setLoading(false);
                      }
                    })
                    .catch((error) => {
                      setLoading(false);
                    });
                });
              } else {
                setLoading(false);
              }
            }
          } else if (response.data.accountDisabled) {
            errorNotification(
              `This account is locked. Contact customer support.`
            );
            setIsAccountLocked(true);
            setVerificationCode("");
            setIsAccountLocked1(true);
            setLoading(false);
          } else if (response.data.invalidUser) {
            warningNotification(
              `Invalid verification code/login details. Please check your code/login details and try again.`
            );
            setVerificationCode("");
            const allowed = remainingAttempts - 1;
            setRemainingAttempts(allowed);
            if (remainingAttempts <= 1) {
              errorNotification(`Your have 0 remaining attempts.`);
              setIsAccountLocked1(true);
            } else {
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.invalidVerificationCode
          ) {
            warningNotification(
              `Invalid verification code/login details. Please check your code/details and try again.`
            );
            setVerificationCode("");
            if (remainingAttempts <= 1) {
              axiosInstance.post("/api/disable-user", { email }).then(() => {
                axiosInstance
                  .post("/api/verify-code", {
                    email,
                    lockAccount: true,
                  })
                  .then(async (response) => {})
                  .catch((error) => {
                    setLoading(false);
                  });
                errorNotification(
                  `Your account is now locked due to many unsuccessful verification attempts. Contact customer support.`
                );
                setIsAccountLocked(true);
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      {loading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 6000,
            backgroundColor: "rgb(0,0,0,0.3)",
          }}
        >
          <Grid
            height="30%"
            width="30%"
            color="#245e41"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-loader"
            visible={true}
          />
        </div>
      )}
      <VerifyCodePopup>
        <PopupContent>
          <div
            style={{
              width: "100%",
              paddingTop: "0.5%",
              margin: "0",
              marginBottom: "10%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to="/"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <img
                src={logo}
                alt="Kepler Logo"
                style={{
                  width: "100px",
                }}
              />
            </Link>
          </div>
          <PopupTitle>Verify your Account</PopupTitle>
          <form
            onSubmit={twoStepLogin}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormGroup>
              <PopupLabel value={email} valid={isEmailValid}>
                Email:
                <RequiredIndicator transparent={email.trim() !== ""}>
                  *
                </RequiredIndicator>
              </PopupLabel>
              <PopupInput
                type="email"
                value={email}
                name="email"
                onChange={(e) => handleEmailChange(e.target.value)}
                onBlur={() => {
                  if (email.trim() !== "") {
                    setIsEmailValid(validateEmail(email));
                  }
                }}
                valid={isEmailValid}
                ref={emailInputRef}
                required
              />
              {isEmailValid || (
                <PopupError
                  style={{
                    fontSize: "12px",
                    marginLeft: "12px",
                    height: "0px",
                  }}
                >
                  Please enter a valid email address.
                </PopupError>
              )}
            </FormGroup>
            <FormGroup>
              <PopupLabel1 value={password}>
                Password:
                <RequiredIndicator transparent={password.trim() !== ""}>
                  *
                </RequiredIndicator>
              </PopupLabel1>

              <PopupInput1
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
                onKeyUp={(e) =>
                  setIsCapsLockActive(e.getModifierState("CapsLock"))
                }
                autoComplete={"current-password"}
                required
              />
              <ToggleButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <EyeOpenIcon /> : <EyeOffIcon />}
              </ToggleButton>
              {isCapsLockActive && (
                <CapsLockIndicator>
                  <CapsLockIcon />
                </CapsLockIndicator>
              )}
            </FormGroup>
            <FormGroup>
              <PopupLabel1 value={verificationCode}>
                Verification Code (no spaces):
                <RequiredIndicator transparent={verificationCode.trim() !== ""}>
                  *
                </RequiredIndicator>
              </PopupLabel1>
              <PopupInput1
                name="verificationCode"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                autocomplete="off"
                readonly
                onfocus="this.removeAttribute('readonly');"
                required
              />
            </FormGroup>
            <div
              style={{
                padding: "2%",
                marginTop: "1.5rem",
                marginBottom: "1.5rem",
                textAlign: "start",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "auto",
                background: "#e8ffa4",
                borderRadius: "5px",
                width: "98%",
                color: isAccountLocked1 ? "red" : "",
              }}
            >
              {isAccountLocked && !isAccountLocked1 ? (
                <>
                  <div
                    style={{
                      margin: "0%",
                      padding: "0%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      justifyContent: "flex-start",
                      color: "red",
                    }}
                  >
                    <InfoIcon />
                    <p>
                      Your account was locked due to many unsuccessful
                      verification attempts. Contact customer support for
                      assistance.
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      margin: "0%",
                      padding: "0%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <InfoIcon />
                    <div
                      style={{
                        margin: "0%",
                        padding: "0%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "flex-start",
                      }}
                    >
                      {!isAccountLocked1 && (
                        <>
                          <p>
                            A verification code was sent to your email address.
                            Please check your inbox including junk folder to retrieve your code.
                          </p>
                          <p>
                            You have {remainingAttempts} attempt(s) remaining.
                          </p>
                        </>
                      )}
                      {isAccountLocked1 && (
                        <p>
                          You have no remaining attempt. Contact customer
                          support.
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <ButtonContainer2
              style={{
                width: "100%",
                padding: "0",
                margin: "0",
                marginTop: window.innerWidth > 992 ? "10%" : "10%",
                marginBottom: "5%",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3.1rem",
              }}
            >
              <Button4
                type="submit"
                style={{ width: "100%", padding: "0" }}
                disabled={
                  email === "" ||
                  password === "" ||
                  !validateEmail(email) ||
                  verificationCode.trim() === "" ||
                  isAccountLocked ||
                  isAccountLocked1
                }
                className={
                  email === "" ||
                  password === "" ||
                  !validateEmail(email) ||
                  verificationCode.trim() === "" ||
                  isAccountLocked ||
                  isAccountLocked1
                    ? "disabled"
                    : ""
                }
              >
                Verify & Login
                <LoginIcon />
              </Button4>
            </ButtonContainer2>
            <div
              style={{
                marginTop: "2rem",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/signup"
                onClick={() => {
                  setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }, 100);
                }}
                style={{
                  color: "#245e41",
                  fontSize: "15px",
                  textDecoration: "underline",
                  textUnderlinePosition: "under",
                }}
              >
                Back to Login/Signup
              </Link>
            </div>
          </form>
        </PopupContent>
        <Copyright1>
          &copy; {copyrightText} All rights reserved &nbsp;|&nbsp; Built in{" "}
          <CountryFlagIcon country="Africa" width="27px" height="14px" />
          with 🤍 by Kepler Solutions
        </Copyright1>
      </VerifyCodePopup>
    </>
  );
};

export default VerifyCode;
