import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Element } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { Grid } from "react-loader-spinner";
import { FaFacebook, FaLinkedin, FaLink, FaShareAlt } from "react-icons/fa";
import { RightIcon, InfoIcon } from "../Icons";
import { GlobalStyle } from "../StyledComponents";
import axiosInstance from "../../utils/axiosInstance";
import noinsight from "../../assets/images/noinsight.svg";

const XLogo = (props) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 1200 1227"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
      fill="currentColor"
    />
  </svg>
);

const copyToClipboard = (text) => {
  let notification = document.createElement("div");
  notification.textContent = "Link copied to clipboard!";
  notification.style.cssText = `
    position: fixed;
    top: 60vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    width: 195px;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.5s ease;
  `;
  document.body.appendChild(notification);

  if (!navigator.clipboard) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  } else {
    navigator.clipboard.writeText(text);
  }
  setTimeout(() => {
    notification.style.opacity = 1;
  }, 100);

  setTimeout(() => {
    notification.style.opacity = 0;
    setTimeout(() => {
      document.body.removeChild(notification);
    }, 500);
  }, 2500);
};

const InsightDetail = ({ isHPBannerVisible }) => {
  const { id } = useParams();
  const [insight, setInsight] = useState(null);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });

  useEffect(() => {
    const fetchInsightDetails = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/insights/${id}`);
        const fullInsight = response.data;
        setInsight(fullInsight);
      } catch (error) {
        console.error("Error fetching insight details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInsightDetails();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const shareOnSocialMedia = (platform) => {
    const url = window.location.href;
    const title = insight.title;
    const imageUrl = insight.headerPhoto;
    const description = insight.header;

    let shareUrl = "";
    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}&quote=${encodeURIComponent(title)}&og:image=${encodeURIComponent(
          imageUrl
        )}`;
        break;
      case "x":
        if (description) {
          shareUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(
            title
          )}&url=${encodeURIComponent(url)}&description=${encodeURIComponent(
            description
          )}`;
        } else {
          shareUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(
            title
          )}&url=${encodeURIComponent(url)}`;
        }
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          url
        )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
          insight.header
        )}&og:image=${encodeURIComponent(imageUrl)}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, "_blank");
  };

  const handleMobileShare = async () => {
    try {
      const shareData = {
        title: insight.title,
        text: `${insight.title} - ${insight.header}`,
        url: window.location.href,
      };

      if (insight.headerPhoto) {
        shareData.image = insight.headerPhoto;
      }

      if (typeof navigator.share === "function") {
        await navigator.share(shareData);
      } else {
        copyToClipboard(window.location.href);
      }
    } catch (error) {}
  };

  if (loading) {
    return (
      <>
        <GlobalStyle />
        <div
          style={{
            width: "100%",
            height: "70vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 6000,
            backgroundColor: "rgb(0,0,0,0)",
            backdropFilter: "blur(1px)",
            marginBottom: "10vw",
            transparency: "0.5",
          }}
        >
          <Grid
            height="30%"
            width="30%"
            color="#245e41"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-loader"
            visible={true}
          />
        </div>
      </>
    );
  }

  if (!loading && !insight) {
    return (
      <>
        <GlobalStyle />
        <div
          style={{
            marginTop: isMobile ? "20vh" : "10vh",
            marginBottom: "5vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "90vw",
            color: "#245e41",
            backgroundImage: `url(${noinsight})`,
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              height: "5vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "end",
              fontWeight: "600",
              whiteSpace: "nowrap",
            }}
          >
            <div>
              <InfoIcon />
              Something went wrong
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Element
        name="insights-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div
          style={{
            position: "relative",
            paddingLeft: "3%",
            height: "10px",
            zIndex: 3,
            display: "block",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            Home
          </Link>
          <RightIcon />{" "}
          <Link
            to="/insights"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Insights
          </Link>
          <RightIcon /> {insight ? insight.title : "Unique Insight"}
        </div>
        <div
          style={{
            position: "relative",
            padding: isMobile ? "20px" : "30px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {insight && (
            <div>
              <div
                style={{
                  position: "relative",
                  height: "300px",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${insight.headerPhoto})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    filter: "brightness(50%)",
                  }}
                ></div>
                <div
                  style={{
                    position: "relative",
                    zIndex: 1,
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: isMobile ? "10px" : "20px",
                    paddingRight: isMobile ? "10px" : "20px",
                    color: "white",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      height: "15%",
                      fontSize: "12px",
                      textTransform: "uppercase",
                      marginBottom: "20px",
                      color: "#cff",
                    }}
                  >
                    {insight.category}: &nbsp; {insight.keywords.join(" | ")}
                  </div>
                  <h1
                    style={{
                      height: "25%",
                      marginBottom: "10px",
                      display: "flex",
                      alignItems: "end",
                    }}
                  >
                    {insight.title}
                  </h1>
                  <p
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "start",
                      fontSize: "18px",
                      lineHeight: "1.1",
                      height: "25%",
                    }}
                  >
                    {insight.header}
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "14px",
                      lineHeight: "1",
                      padding: "0.5px",
                    }}
                  >
                    by {insight.author}
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "13px",
                      lineHeight: "1",
                      padding: "0.5px",
                    }}
                  >
                    {new Date(
                      insight.dateOfPublication._seconds * 1000
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontStyle: "italic",
                    marginLeft: isMobile ? "10px" : "20px",
                    color: "#7a9795",
                  }}
                >
                  {insight.readTime} min read
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontStyle: "italic",
                      marginRight: isMobile ? "10px" : "20px",
                      color: "#7a9795",
                    }}
                  >
                    share {insight.category}
                  </div>
                  {!isMobile ? (
                    <>
                      <div
                        title="Facebook"
                        onClick={() => shareOnSocialMedia("facebook")}
                        style={{
                          marginRight: isMobile ? "15px" : "20px",
                          cursor: "pointer",
                        }}
                      >
                        <FaFacebook style={{ color: "#7a9795" }} />
                      </div>
                      <div
                        title="X/Twitter"
                        onClick={() => shareOnSocialMedia("x")}
                        style={{
                          marginRight: isMobile ? "15px" : "20px",
                          cursor: "pointer",
                        }}
                      >
                        <XLogo style={{ color: "#7a9795" }} />
                      </div>
                      <div
                        title="LinkedIn"
                        onClick={() => shareOnSocialMedia("linkedin")}
                        style={{
                          marginRight: isMobile ? "10px" : "20px",
                          cursor: "pointer",
                        }}
                      >
                        <FaLinkedin style={{ color: "#7a9795" }} />
                      </div>
                      <div
                        title="Copy Link"
                        onClick={() => copyToClipboard(window.location.href)}
                        style={{
                          marginRight: isMobile ? "10px" : "20px",
                          cursor: "pointer",
                        }}
                      >
                        <FaLink style={{ color: "#7a9795" }} />
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={handleMobileShare}
                      style={{
                        marginRight: isMobile ? "10px" : "20px",
                        cursor: "pointer",
                      }}
                    >
                      <FaShareAlt style={{ color: "#7a9795" }} />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  paddingTop: isMobile ? "10px" : "20px",
                  paddingBottom: isMobile ? "20px" : "50px",
                  paddingLeft: isMobile ? "20px" : "150px",
                  paddingRight: isMobile ? "20px" : "150px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  fontWeight: "300",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: insight.body }} />
              </div>
            </div>
          )}
        </div>
      </Element>
    </>
  );
};

export default InsightDetail;
