import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useMediaQuery } from "react-responsive";
import { Element } from "react-scroll";
import { GlobalStyle } from "../StyledComponents";
import { Editor } from "@tinymce/tinymce-react";

const AdminSendEmail = ({ isHPBannerVisible }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [emailContent, setEmailContent] = useState({
    subject: "",
    body: "",
    from: "",
  });
  const [adminEmails, setAdminEmails] = useState([]);
  const [userEmails, setUserEmails] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "email",
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [emailsPerPage, setEmailsPerPage] = useState(25);
  const [emailCategory, setEmailCategory] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 992 });

  useEffect(() => {
    const fetchAdminEmails = async () => {
      try {
        const response = await axiosInstance.get("/api/admin-emails");
        setAdminEmails(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserEmails = async () => {
      try {
        const response = await axiosInstance.get("/api/users-emails");
        setUserEmails(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAdminEmails();
    fetchUserEmails();
  }, []);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedEmails = React.useMemo(() => {
    let sortableEmails = [...userEmails];
    sortableEmails.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableEmails;
  }, [userEmails, sortConfig]);

  const paginatedEmails = React.useMemo(() => {
    const indexOfLastEmail = currentPage * emailsPerPage;
    const indexOfFirstEmail = indexOfLastEmail - emailsPerPage;
    return sortedEmails.slice(indexOfFirstEmail, indexOfLastEmail);
  }, [currentPage, emailsPerPage, sortedEmails]);

  const handleUserSelect = (email) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(email)
        ? prevSelected.filter((user) => user !== email)
        : [...prevSelected, email]
    );
    };
    
    useEffect(() => {
      setSelectedUsers([]);
    }, [emailCategory]);

  const handleSelectAll = () => {
    const visibleEmails = paginatedEmails.filter(
      (user) =>
        !(emailCategory === "marketing" && user.unsubscribe === "marketing") &&
        !(emailCategory === "insight" && user.unsubscribe === "insight") &&
        user.unsubscribe !== "all"
    );

    if (selectedUsers.length === visibleEmails.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(visibleEmails.map((user) => user.email));
    }
  };

  const handleEditorChange = (content, editor) => {
    setEmailContent({ ...emailContent, body: content });
  };

  const handleImageUpload = (blobInfo) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      axiosInstance
        .post("/api/upload-email-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.location) {
            resolve(response.data.location);
          } else {
            reject("Image URL missing in the response.");
          }
        })
        .catch((error) => {
          reject("Image upload failed: " + error.message);
        });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/api/send-email", {
        recipients: selectedUsers,
        subject: emailContent.subject,
        body: emailContent.body,
        from: emailContent.from,
      });
      alert("Emails sent successfully!");
      setEmailContent({
        subject: "",
        body: "",
        from: "",
      });
      setSelectedUsers([]);
    } catch (error) {
      console.error("Error sending emails:", error);
      alert("Error sending emails.");
    }
  };

  const pageCount = Math.ceil(userEmails.length / emailsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <GlobalStyle />
      <Element
        name="insights-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div style={{ margin: "5vw" }}>
          <h1>Send Email</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label style={{ marginRight: isMobile ? "20px" : "40px" }}>
                From:
              </label>
              <select
                value={emailContent.from}
                onChange={(e) =>
                  setEmailContent({ ...emailContent, from: e.target.value })
                }
                style={{ width: isMobile ? "90vw" : "50vw" }}
                required
              >
                <option value="">Select sender email</option>
                {adminEmails.map((email) => (
                  <option key={email} value={email}>
                    {email}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Subject:</label>
              <input
                type="text"
                value={emailContent.subject}
                onChange={(e) =>
                  setEmailContent({
                    ...emailContent,
                    subject: e.target.value,
                  })
                }
                style={{ width: "90vw" }}
                required
              />
            </div>
            <div>
              <label>Email Category:</label>
              <select
                value={emailCategory}
                onChange={(e) => setEmailCategory(e.target.value)}
                style={{ width: "90vw" }}
                required
              >
                <option value="">Select email category</option>
                <option value="account update">Account Update</option>
                <option value="marketing">Marketing</option>
                <option value="insight">Insights, News & Announcements</option>
              </select>
            </div>
            <div>
              <label>Body:</label>
              <Editor
                value={emailContent.body}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                init={{
                  menubar: true,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect styles | bold italic backcolor forecolor | " +
                    "subscript superscript alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist outdent indent | lineheight removeformat | print | " +
                    "link image | help",
                  images_upload_handler: handleImageUpload,
                }}
                onEditorChange={handleEditorChange}
              />
            </div>
            <div>
              <h2>Select Recipients</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="pagination">
                  Showing {paginatedEmails.length} of {userEmails.length} emails
                </div>
                <div>
                  <label>
                    Show{" "}
                    <select
                      value={emailsPerPage}
                      onChange={(e) => setEmailsPerPage(Number(e.target.value))}
                    >
                      <option value={5}>5</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={userEmails.length}>All</option>
                    </select>{" "}
                    user emails
                  </label>
                </div>
              </div>
              <div className="email-table-container">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={
                            selectedUsers.length > 0 &&
                            selectedUsers.length ===
                              paginatedEmails.filter(
                                (user) =>
                                  !(
                                    emailCategory === "marketing" &&
                                    user.unsubscribe === "marketing"
                                  ) &&
                                  !(
                                    emailCategory === "insight" &&
                                    user.unsubscribe === "insight"
                                  ) &&
                                  user.unsubscribe !== "all"
                              ).length
                          }
                        />
                      </th>
                      <th
                        className="sortable"
                        onClick={() => handleSort("email")}
                      >
                        Email
                      </th>
                      <th
                        className="sortable"
                        onClick={() => handleSort("name")}
                      >
                        Username
                      </th>
                      <th
                        className="sortable"
                        onClick={() => handleSort("unsubscribe")}
                      >
                        Unsubscribe
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedEmails.map((user) => (
                      <tr key={user.email}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedUsers.includes(user.email)}
                            onChange={() => handleUserSelect(user.email)}
                            disabled={
                              user.unsubscribe === "all" ||
                              (emailCategory === "marketing" &&
                                user.unsubscribe === "marketing") ||
                              (emailCategory === "insight" &&
                                user.unsubscribe === "insight")
                            }
                          />
                        </td>
                        <td>{user.email}</td>
                        <td>{user.name}</td>
                        <td>
                          {user.unsubscribe === "none" ? "" : user.unsubscribe}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {pageCount > 1 && (
                <div className="pagination">
                  {Array.from({ length: pageCount }, (_, index) => (
                    <span
                      key={index}
                      className={`page-number ${
                        index + 1 === currentPage ? "current" : ""
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </span>
                  ))}
                </div>
              )}
            </div>
            <button type="submit">Send Email</button>
          </form>
        </div>
      </Element>
    </>
  );
};

export default AdminSendEmail;
