import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

const LeaseTable = ({ lease }) => {
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFlash((prevFlash) => !prevFlash);
    }, 500);
    return () => clearInterval(interval);
  }, [lease.outstandingPayment]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatSelectedNumber = (number) => {
    if (number === null || number === undefined) return "-";
    if (Number.isInteger(number)) return number.toLocaleString("en-US");
    return Math.ceil(number).toLocaleString("en-US");
  };

  const formatMoneyNumber = (number) => {
    if (number === null || number === undefined) return "-";
    return Number(number.toFixed(2)).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const isMobile = useMediaQuery({ maxWidth: 992 });

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const leaseFields = [
    { key: "_id", label: "Lease ID:", value: lease._id },
    {
      key: "status",
      label: "Status:",
      value: capitalizeFirstLetter(lease.status),
    },
    {
      key: "leaseStartDate",
      label: "Lease Start Date:",
      value: formatDate(lease.leaseStartDate),
    },
    {
      key: "leaseMonths",
      label: "Lease Tenor (Months):",
      value: lease.leaseMonths,
    },
    {
      key: "paymentFrequency",
      label: "Payment Frequency:",
      value: capitalizeFirstLetter(lease.paymentFrequency),
    },
    { key: "currency", label: "Currency:", value: lease.currency },
    {
      key: "prepaidPayments",
      label: "Prepaid:",
      value: formatMoneyNumber(lease.prepaidPayments),
    },
    {
      key: "monthlyRatePercent",
      label: "Monthly Interest (%):",
      value: lease.monthlyRatePercent,
    },
    {
      key: "lateChargePercent",
      label: "Late Payment Monthly Interest (%):",
      value: lease.lateChargePercent,
    },
    {
      key: "monthlyPayment",
      label: "Monthly Payment:",
      value: formatMoneyNumber(lease.monthlyPayment),
    },
    {
      key: "nextPaymentDate",
      label: "Next payment Date:",
      value: formatDate(lease.nextPaymentDate),
    },
    {
      key: "paidMonths",
      label: "Months Paid-up:",
      value: formatSelectedNumber(lease.paidMonths),
    },
    {
      key: "totalPeriodicPaymentsMade",
      label: "Total Monthly Payment Made:",
      value: formatMoneyNumber(lease.totalPeriodicPaymentsMade),
    },
    {
      key: "totalLatePaymentsMade",
      label: "Total Late Payment Made:",
      value: formatMoneyNumber(lease.totalLatePaymentsMade),
    },
    {
      key: "finalPayment",
      label: "Final Payment Made:",
      value: formatMoneyNumber(lease.finalPayment),
    },
    {
      key: "actualDelinquentMonths",
      label: "Owed Months:",
      value: formatSelectedNumber(lease.actualDelinquentMonths),
    },
    {
      key: "latePaymentFee",
      label: "Prorated Late Payment Charge:",
      value: formatMoneyNumber(lease.latePaymentFee),
    },
    {
      key: "outstandingPayment",
      label: (
        <span style={{ color: flash ? "red" : "inherit" }}>
          Total Amount Owed (Pay Now!):
        </span>
      ),
      value: (
        <span style={{ color: flash ? "red" : "inherit" }}>
          {formatMoneyNumber(lease.outstandingPayment)}
        </span>
      ),
    },
    {
      key: "actualRemainingMonths",
      label: "Remaining Payment Months:",
      value: formatSelectedNumber(lease.actualRemainingMonths),
    },
    {
      key: "leaseClosurePayment",
      label: "Payment to Exit Lease:",
      value: formatMoneyNumber(lease.leaseClosurePayment),
    },
  ];

  const isZeroValue = (value) => {
    const zeroValues = [0, "0", "0.00", "-", "0.0"];
    if (typeof value === "object" && value.props) {
      const textValue = value.props.children;
      return zeroValues.includes(textValue);
    }
    return zeroValues.includes(value);
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>Field</th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {leaseFields
          .filter(({ value }) => !isZeroValue(value))
          .map(({ key, label, value }) => (
            <tr key={key} style={{ fontSize: isMobile ? "10px" : "13px" }}>
              <td style={{ width: isMobile ? "78%" : "80%" }}>{label}</td>
              <td style={{ width: isMobile ? "22%" : "20%" }}>{value}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default LeaseTable;
