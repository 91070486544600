/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import {
  FooterContainer,
  Section,
  SectionHeading,
  SectionHeadingCalm,
  SocialMediaIcons,
  FooterInfo,
  FooterInfo1,
  Copyright,
} from "./StyledComponents";
import { Navbar } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { EmailIcon, CallIcon, WhatsappIcon } from "./Icons";
import logo from "../assets/logo/kepler_logo.svg";
import nigeriaFlag from "../assets/flags/ng.svg";
import egyptFlag from "../assets/flags/eg.svg";
import africa from "../assets/images/africa.svg";

const XLogo = (props) => (
  <svg
    width="17"
    height="17" // Adjust the width and height as needed
    viewBox="0 0 1200 1227"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
      fill="currentColor"
    />
  </svg>
);

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathsWithoutIcons = ["/signup", "/forgot-password"];

  const shouldShowSocialIcons = !pathsWithoutIcons.includes(location.pathname);

  useEffect(() => {
    const handleLogoClick = () => {
      if (window.location.pathname === "/") {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
      } else {
        navigate("/");
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
      }
    };

    const logoElement = document.getElementById("footer-logo");
    if (logoElement) {
      logoElement.addEventListener("click", handleLogoClick);
    }

    return () => {
      if (logoElement) {
        logoElement.removeEventListener("click", handleLogoClick);
      }
    };
  }, [navigate]);

  const handleNavigateToSolutions = () => {
    navigate("/solutions?section=photo-gallery");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleFAQLinkClick = () => {
    navigate("/");

    const scrollToSection = () => {
      scroller.scrollTo("section-four", {
        smooth: true,
        duration: 0,
        offset: 0,
        delay: 0,
      });
    };
    setTimeout(scrollToSection, 0);
  };

  const handlePhoneLinkClick = (phoneNumber) => {
    const choice = window.confirm(
      "How would you like to contact this number?\n\nClick 'OK' to make a call or 'Cancel' to use WhatsApp."
    );
    if (choice) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      window.open(`https://wa.me/${phoneNumber}`);
    }
  };

  const currentYear = new Date().getFullYear();
  const copyrightText =
    currentYear === 2023 ? `${currentYear}` : `${2023} - ${currentYear}`;

  const CountryFlagIcon = ({ country, width, height }) => {
    const flagMap = {
      Nigeria: nigeriaFlag,
      Egypt: egyptFlag,
      Africa: africa,
    };

    return (
      <div>
        <img
          src={flagMap[country]}
          alt={`${country} Flag`}
          width={width}
          height={height}
        />
      </div>
    );
  };

  return (
    <FooterContainer>
      <div
        id="main-footer"
        style={{
          width: "100%",
          height: "auto",
          padding: "0",
          margin: "0",
          display: "flex",
          flexDirection: window.innerWidth > 750 ? "row" : "column",
          justifyContent: window.innerWidth > 750 ? "left" : "space-between",
        }}
      >
        <Navbar.Brand
          id="footer-logo"
          as={NavLink}
          to="/"
          style={{
            paddingBottom: "6vh",
            width: "25%",
          }}
        >
          <SectionHeading>
            <img src={logo} alt="Logo" width="100px" />
          </SectionHeading>
        </Navbar.Brand>
        <div
          id="part-of-main-footer"
          style={{
            Width: window.innerWidth > 750 ? "" : "100%",
            padding: "0",
            display: "flex",
            flexDirection: window.innerWidth > 750 ? "row" : "column",
            justifyContent:
              window.innerWidth > 750 ? "space-between" : "space-between",
            alignItems: "flex-start",
            minHeight: "80%",
            marginLeft: window.innerWidth > 750 ? "0" : "3%",
          }}
        >
          <div
            style={{
              height: "auto",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Section>
              <SectionHeading onClick={handleNavigateToSolutions}>
                Solutions
              </SectionHeading>
              <FooterInfo>
                <p onClick={() => navigate("/solutions?section=credit")}>
                  Credit-Financing
                </p>
                <p onClick={() => navigate("/solutions?section=PaaS")}>
                  Power-as-a-Service
                </p>
                <p onClick={() => navigate("/solutions?section=direct")}>
                  Direct Purchase
                </p>
                <p onClick={() => navigate("/solutions?section=mini-grids")}>
                  Mini-grids
                </p>
              </FooterInfo>
            </Section>
            <Section>
              <SectionHeadingCalm>Company</SectionHeadingCalm>
              <FooterInfo>
                <p
                  onClick={() => {
                    navigate("/about");
                    setTimeout(() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }, 500);
                  }}
                >
                  About
                </p>
                <p
                  onClick={() => {
                    navigate("/jobs");
                    setTimeout(() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }, 500);
                  }}
                >
                  Jobs
                </p>
                <p onClick={handleFAQLinkClick}>FAQs</p>
                <p
                  onClick={() => {
                    navigate("/insights");
                    setTimeout(() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }, 500);
                  }}
                >
                  Insights
                </p>
              </FooterInfo>
            </Section>
          </div>
          <div
            style={{
              height: "auto",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Section>
              <SectionHeading
                onClick={() => {
                  navigate("/legal");
                  setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }, 500);
                }}
              >
                Legal
              </SectionHeading>
              <FooterInfo>
                <p
                  onClick={() => {
                    navigate("/legal#terms-of-service");
                  }}
                >
                  Terms of Service
                </p>
                <p
                  onClick={() => {
                    navigate("/legal#privacy-policy");
                  }}
                >
                  Privacy Policy
                </p>
                <p
                  onClick={() => {
                    navigate("/legal#cookie-policy");
                  }}
                >
                  Cookie Policy
                </p>
              </FooterInfo>
            </Section>
            <Section
              style={{
                width: window.innerWidth > 350 ? "10rem" : "8rem",
              }}
            >
              <SectionHeadingCalm>Contact</SectionHeadingCalm>
              <FooterInfo1>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div>
                    <EmailIcon />
                    <a
                      href="mailto:solved@keplersolutionshq.com"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        cursor: "pointer",
                        transition: "color 0.2s",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        fontSize:
                          window.innerWidth > 389
                            ? ""
                            : window.innerWidth > 350
                            ? "13.5px"
                            : "12.5px",
                      }}
                      onMouseEnter={(e) => (e.target.style.color = "#cf3")}
                      onMouseLeave={(e) => (e.target.style.color = "inherit")}
                    >
                      solved@keplersolutionshq.com
                    </a>
                  </div>
                </p>
                <p>
                  <CallIcon /> <WhatsappIcon />
                  <p
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <span
                      style={{
                        cursor: "pointer",
                        color: "inherit",
                        textDecoration: "none",
                        transition: "color 0.2s",
                        display: "flex",
                        flexDirection: "row",
                        fontSize:
                          window.innerWidth > 389
                            ? ""
                            : window.innerWidth > 350
                            ? "13.5px"
                            : "12.5px",
                      }}
                      onClick={() => handlePhoneLinkClick("+2347048263658")}
                      onMouseEnter={(e) => (e.target.style.color = "#cf3")}
                      onMouseLeave={(e) => (e.target.style.color = "inherit")}
                    >
                      <CountryFlagIcon
                        country="Nigeria"
                        width="22px"
                        height="15px"
                      />{" "}
                      &nbsp;+234 (0) 704 826 3658
                    </span>
                  </p>
                </p>
                <p></p>
              </FooterInfo1>
            </Section>
          </div>
        </div>
      </div>
      {shouldShowSocialIcons && (
        <SocialMediaIcons>
          <a
            href="https://www.facebook.com/profile.php?id=61552555584772"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://x.com/Keplersoltnshq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <XLogo />
          </a>
          <a
            href="https://www.instagram.com/keplersolutionshq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/company/kepler-solutionshq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </SocialMediaIcons>
      )}
      <Copyright>
        &copy; {copyrightText} All rights reserved &nbsp;|&nbsp; Built in{" "}
        <CountryFlagIcon country="Africa" width="27px" height="14px" />
        with ❤️ by Kepler Solutions
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
