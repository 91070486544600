import React from "react";

const DemoTable = ({ request, isMobile }) => {
  const fieldMappings = {
    name: { label: "Requester:", formatNumber: false },
    org: { label: "Organisation:", formatNumber: false },
    interest: { label: "Interested in:", formatNumber: false },
    comment: { label: "Comment:", formatNumber: false },
  };

  const formatSelectedNumber = (number) => {
    if (number === null || number === undefined) return "-";
    if (Number.isInteger(number)) return number.toLocaleString("en-US");
    return Math.round(number).toLocaleString("en-US");
  };

  const mapInterestToLabel = (interest) => {
    switch (interest) {
      case "home":
        return "Home power solutions";
      case "business":
        return "Business power solutions";
      case "power":
        return "Power-as-a-Service (PaaS)";
      case "mini-grid":
        return "Mini-grid development";
      case "other":
        return "See comment";
      default:
        return "-";
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>Field</th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(request).map(([key, value]) => {
          const shouldRenderRow =
            fieldMappings[key] &&
            value !== undefined &&
            value !== null &&
            value !== "";
          return shouldRenderRow ? (
            <tr key={key} style={{ fontSize: isMobile ? "10px" : "13px" }}>
              <td style={{ width: isMobile ? "78%" : "80%" }}>
                {fieldMappings[key].label}
              </td>
              <td style={{ width: isMobile ? "22%" : "20%" }}>
                {key === "interest"
                  ? mapInterestToLabel(value)
                  : fieldMappings[key].formatNumber
                  ? formatSelectedNumber(value)
                  : value}
              </td>
            </tr>
          ) : null;
        })}
      </tbody>
    </table>
  );
};

export default DemoTable;
