import React from "react";
import "../styles/StyledRolodex.css";
import styled from "styled-components";

const AnimatedContainer = styled.div`
  border-bottom: 1px solid #29344e;
  display: flex;
  align-items: top;
  justify-content: center;
  margin-top: 0rem;
  font-family: "Major Mono Display", monospace;
  font-size: 2rem;
  font-weight: bold;
  height: 2em;

  @media (max-width: 1204px) {
    font-size: 1.8rem;
  }
  @media (max-width: 680px) {
    font-size: 1.6rem;
  }
  @media (max-width: 605px) {
    font-size: 1.4rem;
  }
  @media (max-width: 530px) {
    font-size: 1.2rem;
  }
  @media (max-width: 454px) {
    font-size: 1rem;
  }
  @media (max-width: 420px) {
    font-size: 0.9rem;
  }
  @media (max-width: 379px) {
    font-size: 0.8rem;
  }
  @media (max-width: 303px) {
    font-size: 0.6rem;
  }
`;

const Rolodex = () => {
  return (
    <AnimatedContainer>
      <div class="rolodex">
        <p
          style={{
            paddingRight: "0.3rem",
          }}
        >
          We are
        </p>
        <ul class="roll-one">
          <li
            style={{
              background: "#2a3450",
              position: "relative",
              zIndex: 1,
              paddingLeft: "0.3rem",
              paddingRight: "0.3rem",
            }}
          >
            KEPLER SOLUTIONS.
          </li>
          <li
            style={{
              background: "#2a3450",
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
            }}
          >
            OFFERING{" "}
          </li>
          <ul class="roll-two">
            <li
              style={{
                background: "#cf3",
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
              }}
            >
              FREE ENERGY AUDITS.
            </li>
            <li
              style={{
                background: "#cf3",
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
              }}
            >
              UP TO 40% IN COST SAVINGS.
            </li>
            <li
              style={{
                position: "relative",
                zIndex: 0,
                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
              }}
            >
              to take care of
            </li>
            <ul class="roll-three">
              <li
                style={{
                  background: "#2a3450",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}
              >
                YOUR NEEDS.
              </li>
              <li
                style={{
                  background: "#2a3450",
                  position: "relative",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                  zIndex: 0,
                }}
              >
                OUR PLANET.
              </li>
            </ul>
          </ul>
        </ul>
      </div>
    </AnimatedContainer>
  );
};

export default Rolodex;
