import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { auth } from "../utils/fireBaseConfig";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axiosInstance from "../../utils/axiosInstance";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import {
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getMetadata,
  listAll,
} from "firebase/storage";

import { GlobalStyle, Copyright1 } from "../StyledComponents";
import logo from "../../assets/logo/kepler_logo.svg";
import africa from "../../assets/images/africa1.svg";
import placeholderPhoto from "../../assets/images/placeholderPhoto.png";
import image27 from "../../assets/images/background10.svg";
import {
  ReturnIcon,
  PhotoIcon,
  NameIcon,
  AtIcon,
  PasswordIcon,
  AddressIcon,
  PhoneIcon,
  BigLinkIcon,
  BigAlertIcon,
  EyeOpenIcon,
  EyeOffIcon,
  CapsLockIcon,
  BirthDateIcon,
  GenderIcon,
  BigEmailIcon,
} from "../Icons";
import { Grid } from "react-loader-spinner";

const DashboardContainer = styled.div`
  padding: 20px;
  padding-bottom: 5px;
  position: relative;
  background: linear-gradient(to top left, #fff, #eef7f9);
`;

const TopSection = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;

  @media (max-width: 992px) {
    font-size: 15px;
  }

  @media (max-width: 360px) {
    font-size: 13px;
  }
`;

const ReturnSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding-bottom: 20px;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  @media (max-width: 992px) {
    font-size: 13px;
  }

  @media (max-width: 360px) {
    font-size: 11px;
  }
`;

const Section = styled.div`
  padding: 20px;
  width: 60vw;
  padding-left: 0vw;
  padding-right: 0vw;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e8ebf0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 700px) {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  @media (max-width: 360px) {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
  }

  & > * {
    flex: 1;
    /* Optional: Adjust width and height of items with aspect ratio */
    /* aspect-ratio: 1; */
  }
`;

const LoadingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Button = styled.button`
  margin: 5px;
  width: 120px;
  margin-left: 0px;
  height: 30px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isHovered ? "#e8ffa4" : props.disabled ? "#fff" : "#e3e9e9"};
  border: ${(props) =>
    props.disabled ? "1px solid #f2f2f2" : "1px solid #ccc"};
  color: ${(props) => (props.disabled ? "#e5e5e5" : "")};

  &:hover {
    svg {
      color: #ccff33;
    }
  }
`;

const PopupButton = styled.button`
  margin: 5px;
  width: 60px;
  margin-left: 0px;
  height: 30px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 14px;
  background-color: ${(props) => (props.isHovered ? "#e8ffa4" : "#e3e9e9")};
  border: 1px solid #ccc;

  &:hover {
    svg {
      color: #ccff33;
    }
  }
`;

const DeleteButton = styled.button`
  margin: 5px;
  width: 120px;
  margin-left: 0px;
  height: 30px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isHovered ? "#f9d2cc" : props.disabled ? "#fffa" : "#e3e9e9"};
  border: ${(props) =>
    props.disabled ? "1px solid #f2f2f2" : "1px solid #ccc"};
  color: ${(props) => (props.disabled ? "#e5e5e5" : "")};
`;

const CancelButton = styled.button`
  margin: 5px;
  width: 60px;
  margin-left: 0px;
  height: 30px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 14px;
  background-color: ${(props) => (props.isHovered ? "#f9d2cc" : "#e3e9e9")};
  border: 1px solid #ccc;
`;

const UpdatePhotoButton = styled.label`
  margin: 5px;
  width: 120px;
  margin-left: 0px;
  height: 30px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 14px;
  background-color: ${(props) => (props.isHovered ? "#e8ffa4" : "#e3e9e9")};
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CustomSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 5px;
  margin: 0;
  border: none;
  border-bottom: 1px solid #ccc;
  background: transparent;
  &:focus {
    outline: none;
    border-bottom: 1px solid #245e41;
  }
`;

const Input = styled.input`
  padding: 8px 12px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 15px;
  font-weight: 500;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  /* Style for autofill input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const CapsLockIndicator = styled.div`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 2%;

  svg {
    color: #ff4545;
  }

  &:hover {
    svg {
      color: #e90000;
    }
  }
`;

const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
  margin-bottom: 5px;
`;

const Backdrop = ({ onClick }) => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "150%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 4001,
      backdropFilter: "blur(2px)",
      cursor: "pointer",
    }}
    onClick={onClick}
  />
);

const isValidEmail = (email) => {
  const emailRegex =
    // eslint-disable-next-line no-control-regex
    /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:(?:[0-9]{1,3}\.){3}[0-9]{1,3}|\[[0-9A-Fa-f]{1,4}(?::[0-9A-Fa-f]{1,4}){7}\]))$/;
  return emailRegex.test(email);
};

const getPasswordStrength = (password) => {
  if (password.length < 6) {
    return "weak";
  }

  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[@$!%*?&]/.test(password);

  if (hasUppercase && hasLowercase && hasNumber && hasSpecialChar) {
    return "strong";
  }

  if (
    (hasUppercase && hasLowercase) ||
    (hasUppercase && hasNumber) ||
    (hasUppercase && hasSpecialChar) ||
    (hasLowercase && hasNumber) ||
    (hasLowercase && hasSpecialChar) ||
    (hasNumber && hasSpecialChar)
  ) {
    return "medium";
  }

  return "weak";
};

function formatPhoneNumber(phoneNumber, country) {
  if (!phoneNumber) return "";

  try {
    const phone = parsePhoneNumber(phoneNumber, country);
    if (phone) {
      return phone.formatInternational();
    }
  } catch (error) {}

  return phoneNumber;
}

const Modal = ({
  title,
  placeholder,
  inputType,
  onClose,
  onSave,
  isMobile,
  isEnteringCurrentPassword,
  hasPasswordLoginMethod,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered11, setIsHovered11] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [feedback, setFeedback] = useState("");
  const passwordRequirement = `New password must be at least six (6) characters long and should include uppercase, lowercase, number, and special character(s)`;
  const [isValid, setIsValid] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isCapsLockActive, setIsCapsLockActive] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEnteringCurrentPassword]);

  useEffect(() => {
    if (!isEnteringCurrentPassword) {
      setInputValue("");
    }
  }, [isEnteringCurrentPassword]);

  useEffect(() => {
    if (inputType === "none") {
      setFeedback(
        "Deleting your account will take seven (7) days after which your data will no longer be retrievable. You can reach out to us if you change your mind about deleting your account within this period."
      );
    }
  }, [inputType]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch("https://freeipapi.com/api/json");
        const data = await response.json();
        setCity(data.cityName);
        setState(data.regionName);
        setCountry(data.countryName || "Nigeria");
        setCountryCode(data.countryCode.toLowerCase());
      } catch (error) {
        setCountry("Nigeria");
      }
    };
    fetchCountry();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (inputType === "email") {
      const valid = isValidEmail(value);
      setFeedback(
        valid ? "Valid email address format" : "Invalid email address"
      );
      setIsValid(valid);
    } else if (inputType === "password" && !isEnteringCurrentPassword) {
      const strength = getPasswordStrength(value);
      setFeedback(`Password strength: ${strength}.`);
      setIsValid(strength === "strong");
    } else if (inputType === "address" && title === "Update Address") {
      if (e.target.name === "city") {
        setCity(value);
      } else if (e.target.name === "state") {
        setState(value);
      } else if (e.target.name === "country") {
        setCountry(value);
      }
    }
  };

  const handlePhoneChange = (value, country) => {
    setPhoneCountryCode(country.countryCode.toUpperCase());
    setInputValue(value, phoneCountryCode);
    const valid = isValidPhoneNumber(value, country.countryCode.toUpperCase());

    setFeedback(valid ? "Valid phone number" : "Invalid phone number");
    setIsValid(valid);
  };

  const togglePasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 4002,
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "7px",
        width: isMobile ? "80vw" : "500px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      }}
    >
      <h6 style={{ color: "#245e41" }}>{title}</h6>
      <div style={{ position: "relative", width: "100%" }}>
        {inputType !== "none" && (
          <div style={{ position: "relative", width: "100%" }}>
            {" "}
            {inputType === "dropdown" ? (
              <CustomSelect
                ref={inputRef}
                value={inputValue}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                <option value="F">Female</option>
                <option value="M">Male</option>
                <option value="O">Other</option>
              </CustomSelect>
            ) : inputType === "tel" ? (
              <PhoneInputContainer>
                <PhoneInput
                  country={countryCode}
                  value={inputValue}
                  enableSearch={true}
                  onChange={(phone, country) => {
                    handlePhoneChange(phone, country);
                  }}
                  inputStyle={{
                    width: "100%",
                    padding: "10px",
                    paddingLeft: "50px",
                    marginTop: "20px",
                    marginBottom: "5px",
                  }}
                />
              </PhoneInputContainer>
            ) : (
              <Input
                ref={inputRef}
                type={
                  inputType === "password" && !isEnteringCurrentPassword
                    ? showNewPassword
                      ? "text"
                      : "password"
                    : inputType
                }
                value={inputValue}
                placeholder={placeholder}
                onChange={handleInputChange}
                onKeyUp={(e) => {
                  if (inputType === "password") {
                    setIsCapsLockActive(e.getModifierState("CapsLock"));
                  }
                }}
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "20px",
                  marginBottom: "5px",
                  margin: 0,
                }}
              />
            )}
            {title === "Update Address" && (
              <>
                <div
                  style={{
                    display: "flex",
                    displayDirection: "column",
                  }}
                >
                  <Input
                    type="address"
                    value={city}
                    name="city"
                    placeholder="City"
                    onChange={handleInputChange}
                    style={{ marginTop: "10px", marginRight: "7px" }}
                  />
                  <Input
                    type="address"
                    value={state}
                    name="state"
                    placeholder="State"
                    onChange={handleInputChange}
                    style={{ marginTop: "10px", marginLeft: "7px" }}
                  />
                </div>
                <Input
                  type="address"
                  value={country}
                  name="country"
                  placeholder="Country"
                  onChange={handleInputChange}
                  style={{ marginTop: "10px" }}
                />
              </>
            )}
            {inputType === "password" && !isEnteringCurrentPassword && (
              <button
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showNewPassword ? <EyeOpenIcon /> : <EyeOffIcon />}
              </button>
            )}
            {isCapsLockActive && (
              <CapsLockIndicator>
                <CapsLockIcon />
              </CapsLockIndicator>
            )}
          </div>
        )}
      </div>

      {feedback && (
        <p
          style={{
            height: inputType === "none" ? "100%" : "5px",
            color:
              feedback.includes("Invalid") || feedback.includes("weak")
                ? "red"
                : feedback.includes("medium")
                ? "orange"
                : feedback.includes("strong") || feedback.includes("Valid")
                ? "green"
                : "black",
            fontSize: "12px",
          }}
        >
          {feedback}
        </p>
      )}
      {inputType === "password" && !isEnteringCurrentPassword && (
        <p
          style={{
            height: "100%",
            fontSize: "12px",
          }}
        >
          {passwordRequirement}
        </p>
      )}

      <div
        style={{
          display: "flex",
          displayDirection: "row",
          justifyContent: "space-between",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        {isEnteringCurrentPassword && hasPasswordLoginMethod ? (
          <PopupButton
            onClick={() => onSave(inputValue)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onTouchStart={() => setIsHovered(false)}
            onTouchEnd={() => setIsHovered(false)}
            isHovered={isHovered}
            disabled={!inputValue && inputType !== "none"}
          >
            Next
          </PopupButton>
        ) : (
          <PopupButton
            onClick={() =>
              onSave(inputValue, city, state, country, phoneCountryCode)
            }
            disabled={
              (inputType === "password" && !isValid) ||
              (inputType === "email" && !isValid) ||
              (inputType === "tel" && !isValid) ||
              (!inputValue && inputType !== "none")
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onTouchStart={() => setIsHovered(false)}
            onTouchEnd={() => setIsHovered(false)}
            isHovered={isHovered}
            style={{
              width: inputType === "none" ? "90px" : "",
              color: inputType === "none" ? "red" : "",
            }}
          >
            {inputType === "none" ? `Confirm` : `Save`}
          </PopupButton>
        )}
        <CancelButton
          onClick={onClose}
          onMouseEnter={() => setIsHovered11(true)}
          onMouseLeave={() => setIsHovered11(false)}
          onTouchStart={() => setIsHovered11(false)}
          onTouchEnd={() => setIsHovered11(false)}
          isHovered={isHovered11}
        >
          Cancel
        </CancelButton>
      </div>
    </div>
  );
};

const UserSettings = ({
  user,
  setUser,
  successNotification,
  warningNotification,
  errorNotification,
  infoNotification,
}) => {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);
  const [isHovered8, setIsHovered8] = useState(false);
  const [isHovered9, setIsHovered9] = useState(false);
  const [isHovered10, setIsHovered10] = useState(false);
  const [isHovered12, setIsHovered12] = useState(false);
  const [isHovered13, setIsHovered13] = useState(false);
  const [isUpdatingPhoto, setIsUpdatingPhoto] = useState(false);
  const [photoURL, setPhotoURL] = useState(user.photoURL);
  const [photoError, setPhotoError] = useState(false);
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [isEnteringCurrentPassword, setIsEnteringCurrentPassword] =
    useState(false);
  const [hasPasswordLoginMethod, setHasPasswordLoginMethod] = useState(false);
  const [isUpdatingDOB, setIsUpdatingDOB] = useState(false);
  const [isUpdatingGender, setIsUpdatingGender] = useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
  const [isUpdatingPhoneNumber, setIsUpdatingPhoneNumber] = useState(false);
  const [isDeletingAccount, setIsDeletingAccount] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const navigate = useNavigate();
  const storage = getStorage();
  const currentYear = new Date().getFullYear();
  const copyrightText =
    currentYear === 2023 ? `${currentYear}` : `${2023} - ${currentYear}`;

  const CountryFlagIcon = ({ country, width, height }) => {
    const flagMap = {
      Africa: africa,
    };

    return (
      <div>
        <img
          src={flagMap[country]}
          alt={`${country} Flag`}
          width={width}
          height={height}
        />
      </div>
    );
  };

  const handleNavbarLink = () => {
    navigate("/");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleAccountReturn = () => {
    navigate("/customer-dashboard");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    setPhotoURL(user.photoURL);
  }, [user.photoURL]);

  const handleImageError = async () => {
    setPhotoError(true);
    if (photoURL) {
      try {
        await auth.currentUser.reload();
        const updatedUser = auth.currentUser;
        if (updatedUser.photoURL !== photoURL) {
          setPhotoURL(updatedUser.photoURL);
          setPhotoError(false);
        } else {
          setPhotoError(true);
        }
      } catch (error) {
        setPhotoError(true);
      }
    }
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxWidth = 500;
          const maxHeight = 500;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], file.name, {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            "image/jpeg",
            0.7
          );
        };
        img.onerror = (error) => {
          reject(new Error("Failed to load image."));
        };
      };
      reader.onerror = (error) => {
        reject(new Error("Failed to read file."));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleUpdateProfilePhoto = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setIsUpdatingPhoto(true);
    try {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/bmp",
        "image/svg+xml",
      ];
      if (!allowedTypes.includes(file.type)) {
        errorNotification(
          "Only BMP, JPEG, GIF, PNG, SVG+XML and WEBP image files are allowed."
        );
        return;
      }

      const compressedFile = await compressImage(file);
      const storageRef = ref(
        storage,
        `profile-photos/${user.uid}/${compressedFile.name}`
      );
      const existingPhotos = await listAll(
        ref(storage, `profile-photos/${user.uid}`)
      );
      const existingPhotoNames = existingPhotos.items.map((item) => item.name);
      if (existingPhotoNames.includes(compressedFile.name)) {
        const existingPhotoRef = ref(
          storage,
          `profile-photos/${user.uid}/${compressedFile.name}`
        );
        await deleteObject(existingPhotoRef);
      }

      if (existingPhotoNames.length >= 5) {
        const filesWithMetadata = await Promise.all(
          existingPhotos.items.map(async (item) => {
            const metadata = await getMetadata(item);
            return { item, metadata };
          })
        );
        filesWithMetadata.sort((a, b) => {
          return a.metadata.timeCreated - b.metadata.timeCreated;
        });
        const filesToDelete = filesWithMetadata.slice(
          0,
          filesWithMetadata.length - 4
        );
        filesToDelete.forEach(async (fileToDelete) => {
          await deleteObject(fileToDelete.item);
        });
      }

      await uploadBytes(storageRef, compressedFile);
      const photoURL = await getDownloadURL(storageRef);
      const updatedUser = { ...user, photoURL };
      await updateProfile(auth.currentUser, {
        photoURL: updatedUser.photoURL,
      });
      setUser(updatedUser);
      setPhotoError(false);
      successNotification("Profile photo updated successfully!");
    } catch (error) {
      errorNotification("Failed to update profile photo.");
    } finally {
      setIsUpdatingPhoto(false);
    }
  };

  const openModal = (config) => {
    setModalConfig(config);
    setIsModalOpen(true);
  };

  const handleUpdateDisplayName = async (newDisplayName) => {
    setIsUpdatingName(true);
    setIsModalOpen(false);
    if (newDisplayName) {
      try {
        const uid = user.uid;
        await axiosInstance.put(
          "api/updateDisplayName",
          {
            newDisplayName: newDisplayName,
          },
          {
            headers: {
              uid: uid,
            },
          }
        );
        successNotification("Display name updated successfully!");
        setUser({ ...user, displayName: newDisplayName });
      } catch (error) {
        errorNotification("Failed to update display name. Please try again.");
      } finally {
        setIsUpdatingName(false);
      }
    }
  };

  const handleUpdateEmail = async (newEmail) => {
    setIsUpdatingEmail(true);
    setIsModalOpen(false);
    if (newEmail) {
      try {
        const uid = user.uid;
        await axiosInstance.put(
          "api/updateEmail",
          { newEmail: newEmail },
          {
            headers: {
              uid: uid,
            },
          }
        );
        successNotification("Email address updated successfully!");
        setUser({ ...user, email: newEmail });
      } catch (error) {
        errorNotification("Failed to update email address. Please try again.");
      } finally {
        setIsUpdatingEmail(false);
      }
    }
  };

  useEffect(() => {
    if (user) {
      const hasPasswordMethod = user.providerData.some(
        (userInfo) => userInfo.providerId === EmailAuthProvider.PROVIDER_ID
      );
      setHasPasswordLoginMethod(hasPasswordMethod);
    }
  }, [user]);

  const handleUpdatePassword = async (currentPassword, newPassword) => {
    setIsUpdatingPassword(true);
    setIsModalOpen(false);
    const user = auth.currentUser;

    if (!user) {
      setIsUpdatingPassword(false);
      return;
    }

    try {
      if (currentPassword) {
        const hasPasswordLoginMethod = user.providerData.some(
          (userInfo) => userInfo.providerId === EmailAuthProvider.PROVIDER_ID
        );

        if (hasPasswordLoginMethod) {
          const credential = EmailAuthProvider.credential(
            user.email,
            currentPassword
          );

          await reauthenticateWithCredential(user, credential);
        } else {
          setIsUpdatingPassword(false);
          return;
        }
      }
      await updatePassword(user, newPassword);
      successNotification("Password updated successfully!");
    } catch (error) {
      errorNotification("Failed to update password. Please try again.");
    } finally {
      setIsUpdatingPassword(false);
    }
  };

  const handleUpdateDOB = async (dob) => {
    setIsModalOpen(false);
    setIsUpdatingDOB(true);
    if (dob) {
      try {
        const uid = user.uid;
        await axiosInstance.put(
          "api/updateDOB",
          { dob },
          {
            headers: {
              uid: uid,
            },
          }
        );
        setUser((prevUser) => ({ ...prevUser, dob }));
        successNotification("Birth Date updated successfully!");
      } catch (error) {
        errorNotification("Failed to update birth date. Please try again.");
      } finally {
        setIsUpdatingDOB(false);
      }
    }
  };

  const handleUpdateGender = async (gender) => {
    setIsModalOpen(false);
    setIsUpdatingGender(true);
    if (gender) {
      try {
        const uid = user.uid;
        await axiosInstance.put(
          "api/updateGender",
          { gender },
          {
            headers: {
              uid: uid,
            },
          }
        );
        setUser((prevUser) => ({ ...prevUser, gender }));
        successNotification("Gender updated successfully!");
      } catch (error) {
        errorNotification("Failed to update gender. Please try again.");
      } finally {
        setIsUpdatingGender(false);
      }
    }
  };

  const handleUpdateAddress = async (newAddress, city, state, country) => {
    setIsModalOpen(false);
    setIsUpdatingAddress(true);
    if (newAddress && city && state && country) {
      try {
        const uid = user.uid;
        await axiosInstance.put(
          "api/updateAddress",
          { address: newAddress, city, state, country },
          {
            headers: {
              uid: uid,
            },
          }
        );
        setUser((prevUser) => ({
          ...prevUser,
          address: newAddress,
          city,
          state,
          country,
        }));
        successNotification("Address updated successfully!");
      } catch (error) {
        errorNotification("Failed to update address. Please try again.");
      } finally {
        setIsUpdatingAddress(false);
      }
    } else {
      errorNotification("Please fill in all fields.");
      setIsUpdatingAddress(false);
    }
  };

  const handleUpdatePhoneNumber = async (
    newPhoneNumber,
    city,
    state,
    country,
    phoneCountryCode
  ) => {
    setIsModalOpen(false);
    setIsUpdatingPhoneNumber(true);
    if (newPhoneNumber && phoneCountryCode) {
      try {
        const uid = user.uid;
        await axiosInstance.put(
          "api/updatePhoneNumber",
          {
            phoneNumber: newPhoneNumber,
            phoneCountryCode,
          },
          {
            headers: {
              uid: uid,
            },
          }
        );
        setUser((prevUser) => ({
          ...prevUser,
          phoneNumber: newPhoneNumber,
          countryCode: phoneCountryCode,
        }));
        successNotification("Phone number updated successfully!");
      } catch (error) {
        errorNotification("Failed to update phone number. Please try again.");
      } finally {
        setIsUpdatingPhoneNumber(false);
      }
    }
  };

  const handleEmailPreferencesLink = () => {
    navigate("/email-preferences");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleLinkAccounts = () => {
    navigate("/link-accounts");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleDeleteAccount = async () => {
    setIsDeletingAccount(true);
    try {
      const uid = user.uid;
      const response = await axiosInstance.get("api/checkAccountStatus", {
        headers: {
          uid: uid,
        },
      });

      if (response.data.hasActiveLeasesOrOrders) {
        infoNotification(
          "You have active lease(s) or order(s). Account deletion is not allowed."
        );
        setIsDeletingAccount(false);
      } else {
        openModal({
          title: "Confirm Account Deletion",
          inputType: "none",
          placeholder: "",
          onSave: confirmDeleteAccount,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        infoNotification(
          "You have active leases or orders. Account deletion is not allowed."
        );
      } else {
        errorNotification("Failed to check account status. Please try again.");
      }
      setIsDeletingAccount(false);
    }
  };

  const confirmDeleteAccount = async () => {
    try {
      const uid = user.uid;
      await axiosInstance.put("api/deleteAccountRequest", null, {
        headers: {
          uid: uid,
        },
      });
      warningNotification(
        "Account deletion in seven (7) days scheduled successfully!"
      );
      setUser((prevUser) => ({
        ...prevUser,
        deleteAccount: true,
      }));
    } catch (error) {
      errorNotification(
        "Failed to schedule account deletion. Please try again."
      );
    }
    setIsModalOpen(false);
    setIsDeletingAccount(false);
  };

  return (
    <>
      {" "}
      <GlobalStyle />
      <DashboardContainer
        style={{
          backgroundImage: `url(${image27})`,
          backgroundSize: "auto",
          backgroundPosition: "top",
        }}
      >
        <ReturnSection>
          <img
            src={logo}
            alt="Logo"
            width="100vmax"
            onClick={() => {
              handleNavbarLink();
            }}
            style={{
              cursor: "pointer",
            }}
          />
          <div style={{ marginTop: "30px" }}>
            {" "}
            <TopSection>
              <p
                style={{
                  cursor: "pointer",
                  padding: "5px 5px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  textShadow: isHovered1
                    ? "0px 0px 1px rgba(27, 46, 16, 1)"
                    : "none",
                  backgroundColor: isHovered1 ? "#e8ffa4" : "#e3e9e9",
                  border: "1px solid #ccc",
                  width: isMobile ? "235px" : "255px",
                  whiteSpace: "nowrap",
                }}
                onMouseEnter={() => setIsHovered1(true)}
                onMouseLeave={() => setIsHovered1(false)}
                onTouchStart={() => setIsHovered1(false)}
                onTouchEnd={() => setIsHovered1(false)}
                onClick={handleAccountReturn}
              >
                <ReturnIcon isHovered={isHovered1} /> Return to Account
                Dashboard
              </p>
            </TopSection>
          </div>
        </ReturnSection>

        <SectionsContainer>
          {" "}
          <Section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <PhotoIcon />
                Profile Photo
              </h6>
              {isUpdatingPhoto ? (
                <div
                  style={{ height: isMobile ? "80px" : "75px", width: "100%" }}
                >
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "47%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <img
                  src={photoError || !photoURL ? placeholderPhoto : photoURL}
                  alt="Display"
                  width="80px"
                  height="80px"
                  style={{
                    borderRadius: "50%",
                  }}
                  onError={handleImageError}
                />
              )}
              <UpdatePhotoButton
                htmlFor="photoInput"
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
                onTouchStart={() => setIsHovered2(false)}
                onTouchEnd={() => setIsHovered2(false)}
                isHovered={isHovered2}
              >
                {photoError || !photoURL ? `Add Photo` : `Change Photo`}
              </UpdatePhotoButton>
              <input
                id="photoInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleUpdateProfilePhoto}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {" "}
                <NameIcon /> Name
              </h6>
              {isUpdatingName ? (
                <div
                  style={{ height: isMobile ? "80px" : "75px", width: "100%" }}
                >
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "30%",
                        left: "47%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <p
                  style={{
                    paddingTop: "25px",
                    height: isMobile ? "63px" : "60px",
                    fontSize: isMobile ? "14px" : "16px",
                    color: "#059b00",
                    fontWeight: "600",
                  }}
                >
                  {user.displayName}
                </p>
              )}
              <Button
                onClick={() =>
                  openModal({
                    title: "Update Display Name",
                    inputType: "text",
                    placeholder: "Full Name (First, Middle-Initials, Last)",
                    onSave: handleUpdateDisplayName,
                  })
                }
                onMouseEnter={() => setIsHovered3(true)}
                onMouseLeave={() => setIsHovered3(false)}
                onTouchStart={() => setIsHovered3(false)}
                onTouchEnd={() => setIsHovered3(false)}
                isHovered={isHovered3}
              >
                Update Name
              </Button>
            </div>
          </Section>
          <Section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <AtIcon /> Email
              </h6>
              {isUpdatingEmail ? (
                <div
                  style={{ height: isMobile ? "55px" : "55px", width: "100%" }}
                >
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "47%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <p
                  style={{
                    paddingTop: "10px",
                    height: "40px",
                    fontSize: isMobile ? "14px" : "16px",
                    color: "#059b00",
                    fontWeight: "600",
                  }}
                >
                  {user.email}
                </p>
              )}
              <Button
                onClick={() =>
                  openModal({
                    title: "Update Email Address",
                    inputType: "email",
                    placeholder: "Enter new email address",
                    onSave: handleUpdateEmail,
                  })
                }
                onMouseEnter={() => setIsHovered4(true)}
                onMouseLeave={() => setIsHovered4(false)}
                onTouchStart={() => setIsHovered4(false)}
                onTouchEnd={() => setIsHovered4(false)}
                isHovered={isHovered4}
              >
                Change Email
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <PasswordIcon /> Password
              </h6>
              {isUpdatingPassword ? (
                <div
                  style={{ height: isMobile ? "55px" : "55px", width: "100%" }}
                >
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "47%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <p
                  style={{
                    paddingTop: "10px",
                    height: "40px",
                    fontSize: isMobile ? "14px" : "16px",
                    color: hasPasswordLoginMethod ? "#059b00" : "#ccc",
                    fontWeight: "600",
                  }}
                >
                  ********
                </p>
              )}
              <Button
                onClick={() => {
                  if (hasPasswordLoginMethod) {
                    setIsEnteringCurrentPassword(true);
                    openModal({
                      title: "Update Password",
                      inputType: "password",
                      placeholder: "Enter current password",
                      onSave: (currentPassword) => {
                        setIsEnteringCurrentPassword(false);
                        openModal({
                          title: "Update Password",
                          inputType: "password",
                          placeholder: "Enter new password",
                          onSave: (newPassword) =>
                            handleUpdatePassword(currentPassword, newPassword),
                        });
                      },
                    });
                  } else {
                    openModal({
                      title: "Update Password",
                      inputType: "password",
                      placeholder: "Enter new password",
                      onSave: (newPassword) =>
                        handleUpdatePassword(null, newPassword),
                    });
                  }
                }}
                onMouseEnter={() => setIsHovered5(true)}
                onMouseLeave={() => setIsHovered5(false)}
                onTouchStart={() => setIsHovered5(false)}
                onTouchEnd={() => setIsHovered5(false)}
                isHovered={isHovered5}
                style={{
                  width: "140px",
                }}
              >
                {hasPasswordLoginMethod ? `Change Password` : `Add Password`}
              </Button>
            </div>
          </Section>
          <Section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <BirthDateIcon /> Date of Birth
              </h6>
              {isUpdatingDOB ? (
                <div
                  style={{ height: isMobile ? "55px" : "55px", width: "100%" }}
                >
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "47%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <p
                  style={{
                    paddingTop: "10px",
                    height: "40px",
                    fontSize: isMobile ? "14px" : "16px",
                    color: "#059b00",
                    fontWeight: "600",
                  }}
                >
                  {user.dob ? formatDate(user.dob) : ""}
                </p>
              )}
              <Button
                onClick={() =>
                  openModal({
                    title: "Add Date of Birth",
                    inputType: "date",
                    placeholder: "Enter your date of birth",
                    onSave: handleUpdateDOB,
                  })
                }
                onMouseEnter={() => setIsHovered6(true)}
                onMouseLeave={() => setIsHovered6(false)}
                onTouchStart={() => setIsHovered6(false)}
                onTouchEnd={() => setIsHovered6(false)}
                isHovered={isHovered6}
                disabled={user.dob}
                style={{
                  width: user.dob ? "140px" : "",
                }}
              >
                {user.dob ? `Update Birth Date` : `Add Birth Date`}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <GenderIcon /> Gender
              </h6>
              {isUpdatingGender ? (
                <div
                  style={{ height: isMobile ? "55px" : "55px", width: "100%" }}
                >
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "47%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <p
                  style={{
                    paddingTop: "10px",
                    height: "40px",
                    fontSize: isMobile ? "14px" : "16px",
                    color: hasPasswordLoginMethod ? "#059b00" : "#ccc",
                    fontWeight: "600",
                  }}
                >
                  {user.gender === "M"
                    ? "Male"
                    : user.gender === "F"
                    ? "Female"
                    : user.gender === "O"
                    ? "Other"
                    : ""}
                </p>
              )}
              <Button
                onClick={() =>
                  openModal({
                    title: "Add Gender",
                    inputType: "dropdown",
                    placeholder: "Select your gender",
                    onSave: handleUpdateGender,
                  })
                }
                onMouseEnter={() => setIsHovered7(true)}
                onMouseLeave={() => setIsHovered7(false)}
                onTouchStart={() => setIsHovered7(false)}
                onTouchEnd={() => setIsHovered7(false)}
                isHovered={isHovered7}
                disabled={user.gender}
              >
                {user.gender ? `Update Gender` : `Add Gender`}
              </Button>
            </div>
          </Section>
          <Section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {" "}
                <AddressIcon />
                Address
              </h6>
              {isUpdatingAddress ? (
                <div style={{ height: "85px", width: "100%" }}>
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "48.5%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <>
                  <p
                    style={{
                      paddingTop: "10px",
                      height: "20%",
                      fontSize: isMobile ? "14px" : "16px",
                      color: "#059b00",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {user.address ? user.address : ""}
                  </p>
                  <p
                    style={{
                      marginBottom: "20px",
                      height: "15px",
                      fontSize: isMobile ? "14px" : "16px",
                      color: "#059b00",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {user.address && user.city && user.state && user.country
                      ? `${user.city}, ${user.state}, ${user.country}`
                      : ""}
                  </p>
                </>
              )}
              <Button
                onClick={() =>
                  openModal({
                    title: "Update Address",
                    inputType: "address",
                    placeholder: "Enter new address",
                    onSave: handleUpdateAddress,
                  })
                }
                onMouseEnter={() => setIsHovered8(true)}
                onMouseLeave={() => setIsHovered8(false)}
                onTouchStart={() => setIsHovered8(false)}
                onTouchEnd={() => setIsHovered8(false)}
                isHovered={isHovered8}
              >
                {" "}
                {user.address ? `Change Address` : `Add Address`}
              </Button>
            </div>
          </Section>
          <Section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <PhoneIcon />
                Phone Number
              </h6>
              {isUpdatingPhoneNumber ? (
                <div style={{ height: "58px", width: "100%" }}>
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "48.5%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <p
                  style={{
                    paddingTop: "10px",
                    height: "40px",
                    fontSize: isMobile ? "15px" : "16px",
                    color: "#059b00",
                    fontWeight: "600",
                  }}
                >
                  {user.phoneNumber
                    ? `${formatPhoneNumber(user.phoneNumber, user.countryCode)}`
                    : ""}
                </p>
              )}
              <Button
                onClick={() =>
                  openModal({
                    title: "Update Phone Number",
                    inputType: "tel",
                    placeholder: "Enter new phone number",
                    onSave: handleUpdatePhoneNumber,
                  })
                }
                onMouseEnter={() => setIsHovered9(true)}
                onMouseLeave={() => setIsHovered9(false)}
                onTouchStart={() => setIsHovered9(false)}
                onTouchEnd={() => setIsHovered9(false)}
                isHovered={isHovered9}
                style={{
                  width: "160px",
                }}
              >
                {user.phoneNumber ? `Update Phone Number` : `Add Phone Number`}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "18px",
                }}
              >
                Email Preferences
              </h6>
              <BigEmailIcon />
              <Button
                onClick={() => handleEmailPreferencesLink()}
                onMouseEnter={() => setIsHovered12(true)}
                onMouseLeave={() => setIsHovered12(false)}
                onTouchStart={() => setIsHovered12(false)}
                onTouchEnd={() => setIsHovered12(false)}
                isHovered={isHovered12}
                style={{
                  width: "160px",
                }}
              >
                Change preference
              </Button>
            </div>
          </Section>
          <Section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <h6
                style={{
                  fontSize: "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "18px",
                }}
              >
                Account Linkage
              </h6>
              <BigLinkIcon />
              <Button
                onClick={() => handleLinkAccounts()}
                onMouseEnter={() => setIsHovered13(true)}
                onMouseLeave={() => setIsHovered13(false)}
                onTouchStart={() => setIsHovered13(false)}
                onTouchEnd={() => setIsHovered13(false)}
                isHovered={isHovered13}

              >
                Link Accounts
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                border: "1px solid #f9d2cc",
                marginRight: isMobile ? "" : "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                borderRadius: "5px",
                backgroundColor: "#f4f5f6",
              }}
            >
              <h6
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "18px",
                }}
              >
                Account Deletion
              </h6>
              {isDeletingAccount ? (
                <div style={{ height: "58px", width: "100%" }}>
                  <LoadingContainer>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: isMobile ? "44%" : "47.5%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "rgb(0,0,0,0.3)",
                        zIndex: 3000,
                      }}
                    >
                      <Grid
                        height={20}
                        width={20}
                        color="#245e41"
                        ariaLabel="grid-loading"
                        radius={12.5}
                        wrapperStyle={{}}
                        wrapperClass="grid-loader"
                        visible={true}
                      />
                    </div>
                  </LoadingContainer>
                </div>
              ) : (
                <BigAlertIcon />
              )}
              <DeleteButton
                onClick={handleDeleteAccount}
                onMouseEnter={() => setIsHovered10(true)}
                onMouseLeave={() => setIsHovered10(false)}
                onTouchStart={() => setIsHovered10(false)}
                onTouchEnd={() => setIsHovered10(false)}
                isHovered={isHovered10}
                style={{
                  width: "120px",
                }}
                disabled={user.deleteAccount}
              >
                Delete Account
              </DeleteButton>
            </div>
          </Section>
        </SectionsContainer>
        <Copyright1>
          &copy; {copyrightText} All rights reserved &nbsp;|&nbsp; Built in{" "}
          <CountryFlagIcon country="Africa" width="27px" height="14px" />
          with 🤍 by Kepler Solutions
        </Copyright1>
      </DashboardContainer>
      {isModalOpen && (
        <>
          <Backdrop onClick={() => setIsModalOpen(false)} />
          <Modal
            isMobile={isMobile}
            isEnteringCurrentPassword={isEnteringCurrentPassword}
            title={modalConfig.title}
            inputType={modalConfig.inputType}
            placeholder={modalConfig.placeholder}
            onSave={modalConfig.onSave}
            onClose={() => {
              setIsModalOpen(false);
              setIsEnteringCurrentPassword(false);
              setIsDeletingAccount(false);
            }}
            hasPasswordLoginMethod={hasPasswordLoginMethod}
          />
        </>
      )}
    </>
  );
};

export default UserSettings;
