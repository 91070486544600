import styled from "styled-components";

export const StyledPowerscape = styled.svg`
  position: relative;
  height: 600px;
  min-width: 60%;
  display: flex;
  justify-content: left;
  align-items: left;


  @media (max-width: 992px) {
    height: 600px;
    width: 100%;
   }

  @media (max-width: 768px) {
    height: 500px;
    width: 100%;
    left: 5px;
  }

  @media (max-width: 490px) {
    height: 430px;
    width: 100%;
    left: 5px;

  }

  @media (max-width: 340px) {
    height: 300px;
    width: 100%;
    left: 5px;
  }

@keyframes colorChange {
  0%,
  15% {
    fill: #fff;
    stroke: #fff;
  }
  27.4%,
  27.5% {
    fill: #455640;
    stroke: #455640;
  }
  37%,
  63% {
    fill: #1b2e10;
    stroke: #1b2e10;
  }
  72.4%,
  72.5% {
    fill: #f8cf7e;
    stroke: #f8cf7e;
  }
  85%,
  100% {
    fill: #fff;
    stroke: #fff;
  }
}
.Africa {
  animation: colorChange 180s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes busAnimation {
  0% {
    opacity: 0;
    transform: translateX(calc(100%));
  }
  5% {
    opacity: 0;
    transform: translateX(calc(93%));
  }
  15% {
    opacity: 1;
    transform: translateX(72%);
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  85% {
    opacity: 1;
    transform: translateX(-80%);
  }
  90% {
    opacity: 0;
    transform: translateX(calc(-88%));
  }
  100% {
    opacity: 0;
    transform: translateX(calc(-100%));
  }
}
.bus {
  animation: busAnimation 10s linear infinite;
}
@keyframes bullettrainAnimation {
  0% {
    opacity: 0;
    transform: translateX(calc(100%));
  }
  5% {
    opacity: 0;
    transform: translateX(calc(94%));
  }
  25% {
    opacity: 1;
    transform: translateX(50%);
  }
  50% {
    opacity: 1;
    transform: translateX(0%);
  }
  75% {
    opacity: 1;
    transform: translateX(-57%);
  }
  95% {
    opacity: 0;
    transform: translateX(calc(-90%));
  }
  100% {
    opacity: 0;
    transform: translateX(calc(-100%));
  }
}
.bullettrain {
  animation: bullettrainAnimation 4s linear infinite alternate;
}
.blade {
  position: absolute;
  animation: spinBlade 2s linear infinite;
}
.blade1 {
  animation-delay: 0s;
  transform-origin: 112.9px 37.5px;
}
.blade2 {
  animation-delay: -1s;
  transform-origin: 84.35px 32.9px;
}
.blade3 {
  animation-delay: -3s; /* Negative
delay to offset the rotation timing */
  transform-origin: 96.96px 45.38px;
}
@keyframes spinBlade {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
*/
  }
}
@keyframes tractorAnimation {
  0% {
    opacity: 0;
    transform: translateX(calc(-200%));
  }
  2% {
    opacity: 0;
    transform: translateX(calc(-170%));
  }
  15% {
    opacity: 1;
    transform: translateX(-120%);
  }
  25% {
    opacity: 1;
    transform: translateX(-80%);
  }
  40% {
    opacity: 1;
    transform: translateX(-10%);
  }
  41% {
    opacity: 0;
    transform: translateX(calc(-5%));
  }
  100% {
    opacity: 0;
    transform: translateX(calc(100%));
  }
}
.tractor {
  animation: tractorAnimation 30s linear infinite;
}

@keyframes sunAnimation {
  0%, 15% {
    transform: translateY(0);
    opacity: 1;
  }

  21%, 40% {
    transform: translateY(10%);
    opacity: 0;
  }

  41%, 64% {
    transform: translateY(10%);
    opacity: 0;
  }

  75%, 95% {
    transform: translateY(0);
    opacity: 1;
  }

  96%, 100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.sun {
  transform: translateY(0);
  opacity: 1;
  animation: sunAnimation 180s linear infinite;
}

@keyframes moonAnimation {
  0%, 15% {
    transform: translateY(10%);
    opacity: 0;
  }

  21%, 40% {
    transform: translateY(0);
    opacity: 1;
  }

  41%, 64% {
    transform: translateY(0);
    opacity: 1;
  }

  75%, 95% {
    transform: translateY(10%);
    opacity: 0;
  }

  96%, 100% {
    transform: translateY(10%);
    opacity: 0;
  }
}
.moon {
  transform: translateY(10%);
  opacity: 0;
  animation: moonAnimation 180s linear infinite;
}

@keyframes colorCycle1 {
  0% { fill: #ff0; opacity: 1;}
  33.33%  { fill: #ff0; opacity: 0.46; }
  66.66% { fill: #999;  opacity: 1; }
}

.yllw {
  animation: colorCycle1 15s infinite;
}

@keyframes colorCycle2 {
  0% { fill: #ff0; opacity: 0.46; }
  33.33% { fill: #999;  opacity: 1;}
  66.66% { fill: #ff0;  opacity: 1; }
}

.yllwzero {
  animation: colorCycle2 15s infinite;
}

@keyframes colorCycle3 {
  0% { fill: #999;  opacity: 1;}
  33.33% { fill: #ff0;  opacity: 1; }
  66.66% { fill: #ff0; opacity: 0.46; }
}

.grey {
  animation: colorCycle3 15s infinite;
}

@keyframes streeLight {
  0%, 22% { visibility: hidden;}
  22.1%, 77.9% {  visibility: visible; }
  78%, 100% {  visibility: hidden; }
}

.streetlight {
  animation: streeLight 180s infinite;
}


/* Keyframes for flickering */

@keyframes flicker-AO {
  0%, 0.5%, 0.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  0.4%, 0.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-BF {
  0%, 1.7%, 2.1%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  1.8%, 2.0% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-BI {
  0%, 3.2%, 3.6%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  3.3%, 3.5% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-BJ {
  0%, 6.0%, 6.4%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  6.1%, 6.3% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-BW {
  0%, 7.9%, 8.4%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  8.0%, 8.3% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-CD {
  0%, 8.3%, 8.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  8.4%, 8.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-CF {
  0%, 10.2%, 10.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  10.3%, 10.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-CG {
  0%, 12.2%, 12.6%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  12.3%, 12.5% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-CI {
  0%, 14.2%, 14.6%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  14.3%, 14.5% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-CM {
  0%, 15.5%, 15.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  15.6%, 15.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-CV {
  0%, 17.4%, 17.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  17.5%, 17.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-DJ {
  0%, 19.4%, 19.8%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  19.5%, 19.7% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-DZ {
  0%, 19.4%, 19.8%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  19.5%, 19.7% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-EG {
  0%, 21.5%, 22.0%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  21.6%, 21.9% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-EH {
   0%, 23.0%, 23.4%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  23.1%, 23.3% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-ER {
   0%, 25.3%, 25.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  25.4%, 25.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-ET {
   0%, 26.5%, 26.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  26.6%, 26.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-GA {
   0%, 29.2%, 29.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  29.3%, 29.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-GH {
   0%, 30.6%, 31.0%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  30.7%, 30.9% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-GI {
   0%, 31.0%, 31.5%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  31.1%, 31.4% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-GM {
   0%, 33.7%, 34.1%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  33.8%, 34.0% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-GN {
   0%, 35.3%, 35.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  35.4%, 35.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-GO {
   0%, 36.5%, 36.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  36.6%, 36.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-GQ {
   0%, 38.3%, 38.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  38.4%, 38.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-GW {
   0%, 40.2%, 40.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  40.3%, 40.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-JU {
   0%, 41.7%, 42.1%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  41.8%, 42.0% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-KE {
   0%, 43.3%, 43.8%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  43.4%, 43.7% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-KM {
   0%, 45.3%, 45.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  45.4%, 45.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-LR {
   0%, 47.3%, 47.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  47.4%, 47.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-LS {
   0%, 48.3%, 48.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  48.4%, 48.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-LY {
   0%, 49.4%, 49.8%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  49.5%, 49.7% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-MA {
   0%, 50.7%, 51.1%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  50.8%, 51.0% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-MG {
   0%, 53.3%, 53.8%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  53.4%, 53.7% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-ML {
   0%, 54.0%, 54.4%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  54.1%, 54.3% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-MR {
   0%, 55.6%, 56.1%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  55.7%, 56.0% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-MU {
   0%, 57.6%, 58.0%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  57.7%, 57.9% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-MW {
   0%, 58.8%, 59.2%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  58.9%, 59.1% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-MZ {
   0%, 61.9%, 62.3%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  62.0%, 62.2% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-NA {
   0%, 62.9%, 63.3%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  63.0%, 63.2% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-NE {
   0%, 64.5%, 64.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  64.6%, 64.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-NG {
   0%, 65.6%, 66.1%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  65.7%, 66.0% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-RE {
   0%, 67.4%, 67.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  67.5%, 67.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-RW {
   0%, 69.4%, 69.8%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  69.5%, 69.7% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-SC {
   0%, 71.7%, 72.1%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  71.8%, 72.0% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-SD {
   0%, 72.2%, 72.6%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  72.3%, 72.5% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-SH {
   0%, 74.8%, 75.2%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  74.9%, 75.1% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-SL {
   0%, 76.5%, 76.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  76.6%, 76.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-SN {
   0%, 77.9%, 78.4%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  78.0%, 78.3% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-SO {
   0%, 80.1%, 80.5%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  80.2%, 80.4% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-SS {
   0%, 80.2%, 80.7%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  80.3%, 80.6% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-ST {
   0%, 83.2%, 83.6%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  83.3%, 83.5% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-SZ {
   0%, 84.7%, 85.1%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  84.8%, 85.0% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-TD {
   0%, 86.5%, 86.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  86.6%, 86.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-TG {
   0%, 87.6%, 88.0%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  87.7%, 87.9% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-TN {
   0%, 88.8%, 89.2%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  88.9%, 89.1% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-TZ {
   0%, 90.6%, 91.0%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  90.7%, 90.9% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-UG {
   0%, 92.9%, 93.3%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  93.0%, 93.2% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-YT {
   0%, 93.5%, 93.9%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  93.6%, 93.8% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-ZA {
   0%, 95.0%, 95.4%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  95.1%, 95.3% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-ZM {
   0%, 96.9%, 97.4%, 100% {
    fill: inherit;
    stroke: inherit;
  }
  97.0%, 97.3% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}

@keyframes flicker-ZW {
   0%, 99.6%, 100.0% {
    fill: inherit;
    stroke: inherit;
  }
  99.7%, 99.9% {
    fill: #ccff33;
    stroke: #ccff33;
  }
}


.AO {
  animation: flicker-AO 610s infinite;
}

.BF {
  animation: flicker-BF 610s infinite;
}

.BI {
  animation: flicker-BI 610s infinite;
}

.BJ {
  animation: flicker-BJ 610s infinite;
}

.BW {
  animation: flicker-BW 610s infinite;
}

.CD {
  animation: flicker-CD 610s infinite;
}

.CF {
  animation: flicker-CF 610s infinite;
}

.CG {
  animation: flicker-CG 610s infinite;
}

.CI {
  animation: flicker-CI 610s infinite;
}

.CM {
  animation: flicker-CM 610s infinite;
}

.CV {
  animation: flicker-CV 610s infinite;
}

.DJ {
  animation: flicker-DJ 610s infinite;
}

.DZ {
  animation: flicker-DZ 610s infinite;
}

.EG {
  animation: flicker-EG 610s infinite;
}

.EH {
  animation: flicker-EH 610s infinite;
}

.ER {
  animation: flicker-ER 610s infinite;
}

.ET {
  animation: flicker-ET 610s infinite;
}

.GA {
  animation: flicker-GA 610s infinite;
}

.GH {
  animation: flicker-GH 610s infinite;
}

.GI {
  animation: flicker-GI 610s infinite;
}

.GM {
  animation: flicker-GM 610s infinite;
}

.GN {
  animation: flicker-GN 610s infinite;
}

.GO {
  animation: flicker-GO 610s infinite;
}

.GQ {
  animation: flicker-GQ 610s infinite;
}

.GW {
  animation: flicker-GW 610s infinite;
}

.JU {
  animation: flicker-JU 610s infinite;
}

.KE {
  animation: flicker-KE 610s infinite;
}

.KM {
  animation: flicker-KM 610s infinite;
}

.LR {
  animation: flicker-LR 610s infinite;
}

.LS {
  animation: flicker-LS 610s infinite;
}

.LY {
  animation: flicker-LY 610s infinite;
}

.MA {
  animation: flicker-MA 610s infinite;
}

.MG {
  animation: flicker-MG 610s infinite;
}

.ML {
  animation: flicker-ML 610s infinite;
}

.MR {
  animation: flicker-MR 610s infinite;
}

.MU {
  animation: flicker-MU 610s infinite;
}

.MW {
  animation: flicker-MW 610s infinite;
}

.MZ {
  animation: flicker-MZ 610s infinite;
}

.NA {
  animation: flicker-NA 610s infinite;
}

.NE {
  animation: flicker-NE 610s infinite;
}

.NG {
  animation: flicker-NG 610s infinite;
}

.RE {
  animation: flicker-RE 610s infinite;
}

.RW {
  animation: flicker-RW 610s infinite;
}

.SC {
  animation: flicker-SC 610s infinite;
}

.SD {
  animation: flicker-SD 610s infinite;
}

.SH {
  animation: flicker-SH 610s infinite;
}

.SL {
  animation: flicker-SL 610s infinite;
}

.SN {
  animation: flicker-SN 610s infinite;
}

.SO {
  animation: flicker-SO 610s infinite;
}

.SS {
  animation: flicker-SS 610s infinite;
}

.ST {
  animation: flicker-ST 610s infinite;
}

.SZ {
  animation: flicker-SZ 610s infinite;
}

.TD {
  animation: flicker-TD 610s infinite;
}

.TG {
  animation: flicker-TG 610s infinite;
}

.TN {
  animation: flicker-TN 610s infinite;
}

.TZ {
  animation: flicker-TZ 610s infinite;
}

.UG {
  animation: flicker-UG 610s infinite;
}

.YT {
  animation: flicker-YT 610s infinite;
}

.ZA {
  animation: flicker-ZA 610s infinite;
}

.ZM {
  animation: flicker-ZM 610s infinite;
}

.ZW {
  animation: flicker-ZW 610s infinite;
}

`;
