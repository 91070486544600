import React from "react";

const MiniGridTable = ({ request, isMobile }) => {
  const fieldMappings = {
    name: { label: "Requester:", formatNumber: false },
    org: { label: "Organisation:", formatNumber: false },
    category: { label: "Category:", formatNumber: false },
    community: { label: "Community:", formatNumber: false },
    comment: { label: "Comment:", formatNumber: false },
  };

  const formatSelectedNumber = (number) => {
    if (number === null || number === undefined) return "-";
    if (Number.isInteger(number)) return number.toLocaleString("en-US");
    return Math.round(number).toLocaleString("en-US");
  };

  const mapCategoryToLabel = (category) => {
    switch (category) {
      case "operator":
        return "Grid operator";
      case "landowner":
        return "Landowner";
      case "developer":
        return "Real-estate developer";
      case "supplier":
        return "Power components supplier/contractor incl. EPC";
      case "financier":
        return "Power projects financier";
      case "other":
        return "See comment";
      default:
        return "-";
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>Field</th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(request).map(([key, value]) => {
          const shouldRenderRow =
            fieldMappings[key] &&
            value !== undefined &&
            value !== null &&
            value !== "";
          return shouldRenderRow ? (
            <tr key={key} style={{ fontSize: isMobile ? "10px" : "13px" }}>
              <td style={{ width: isMobile ? "78%" : "80%" }}>
                {fieldMappings[key].label}
              </td>
              <td style={{ width: isMobile ? "22%" : "20%" }}>
                {key === "category"
                  ? mapCategoryToLabel(value)
                  : fieldMappings[key].formatNumber
                  ? formatSelectedNumber(value)
                  : value}
              </td>
            </tr>
          ) : null;
        })}
      </tbody>
    </table>
  );
};

export default MiniGridTable;
