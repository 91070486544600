import React from "react";
import "../styles/StyledLamp.css";

const AnimatedLamp = () => {
  return (
    <sectionA>
      <div class="allAnimation">
        <div class="lampWrap">
          <div class="lampBase"></div>
          <div class="lampStand">
            <div class="bar bar1">
              <div class="bar bar2">
                <div class="rotator">
                  <div class="bar bar3">
                    <div class="cord1"></div>
                    <div class="bar bar4">
                      <div class="cord2"></div>
                      <div class="lampHead">
                        <div class="lightBeam"></div>
                        <div class="bulb"></div>
                        <div class="lampshade"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="boxWrap">
          <div class="boxRight">
            <div class="ribbonVert"></div>
            <div class="ribbonHorz"></div>
          </div>

          <div class="boxCenter">
            <div class="boxLid"></div>
            <div class="boxLidShadow"></div>
          </div>

          <div class="boxTop">
            <div class="boxTopLid"></div>
            <div class="boxTopShadow"></div>
          </div>

          <div class="bow"></div>
        </div>

        <div class="jar">
          <div class="sparkle sparkle1">
            <span></span>
          </div>
          <div class="sparkle sparkle2">
            <span></span>
          </div>
          <div class="sparkle sparkle3">
            <span></span>
          </div>
          <div class="sparkle sparkle4">
            <span></span>
          </div>
          <div class="sparkle sparkle5">
            <span></span>
          </div>
          <div class="sparkle sparkle6">
            <span></span>
          </div>
          <div class="sparkle sparkle7">
            <span></span>
          </div>
          <div class="sparkle sparkle8">
            <span></span>
          </div>
          <div class="sparkle sparkle9">
            <span></span>
          </div>
          <div class="sparkle sparkle10">
            <span></span>
          </div>

          <svg class="lamp" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="path"
              d="M37 131.5C36.3333 126.333 36.1 112.7 40.5 99.5M46 84C54.6667 63.8333 85.3 23.2 138.5 22"
              stroke="black"
              stroke-linecap="round"
            />
          </svg>
        </div>

        <div class="footerMask">
          <div class="deskTop"></div>
        </div>
      </div>
    </sectionA>
  );
};

export default AnimatedLamp;
