import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import clockBackground from "../assets/images/globeclock.svg";
import moment from "moment-timezone";

const ClockWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  transform: scale(0.5);
  @media (max-width: 992px) {
    position: relative;
    top: -5rem;
  }
`;

const LocationReadout = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  padding-top: 1.5vmax;
`;

const ClockContainer = styled.div`
  position: relative;
  height: 18em;
  width: 18em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-bg-color);
  background-image: url(${clockBackground});
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  border: 4px solid var(--main-bg-color);
  box-shadow: 0 -15px 15px rgba(255, 255, 255, 0.05),
    inset 0 -15px 15px rgba(255, 255, 255, 0.05), 0 15px 15px rgba(0, 0, 0, 0.3),
    inset 0 15px 15px rgba(0, 0, 0, 0.3);
  transition: all ease 0.2s;

  &::before {
    content: "";
    height: 0.75rem;
    width: 0.75rem;
    background-color: var(--main-text-color);
    border: 2px solid var(--main-bg-color);
    position: absolute;
    border-radius: 50%;
    z-index: 1000;
    transition: all ease 0.2s;
  }
`;

const Hand = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 7px;
  background-color: ${(props) =>
    props.red ? "#f00" : "var(--main-text-color)"};
  height: ${(props) => (props.hour ? "27%" : props.min ? "34%" : "37%")};
  width: ${(props) => (props.hour ? "6px" : props.min ? "4.5px" : "3px")};
  transition-timing-function: linear;
  transform-origin: bottom;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(50%);

  &:before {
    content: "";
    position: absolute;

    background-color: ${(props) => (props.red ? "#f00" : "transparent")};
    height: 20%;
    width: 3px;
    bottom: -20%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const AnalogClock = () => {
  const [theme, setTheme] = useState("light");
  const hourRef = React.createRef();
  const minRef = React.createRef();
  const secRef = React.createRef();
  const [location, setLocation] = useState("");
  const [isLocationValid, setIsLocationValid] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [serverTime, setServerTime] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [sunriseTime, setSunriseTime] = useState(null);
  const [sunsetTime, setSunsetTime] = useState(null);

  useEffect(() => {
    const setClock = () => {
      let day = new Date();
      let hh = day.getHours() * 30;
      let mm = day.getMinutes() * 6;
      let ss = day.getSeconds() * 6;

      if (hourRef.current)
        hourRef.current.style.transform = `rotateZ(${hh + mm / 12}deg)`;
      if (minRef.current) minRef.current.style.transform = `rotateZ(${mm}deg)`;
      if (secRef.current) secRef.current.style.transform = `rotateZ(${ss}deg)`;
    };

    setClock();

    const interval = setInterval(setClock, 100);
    return () => clearInterval(interval);
  }, [hourRef, minRef, secRef]);

  const fetchSunriseSunset = useCallback(async (latitude, longitude) => {
    if (latitude && longitude) {
      const url = `https://api.sunrisesunset.io/json?lat=${latitude}&lng=${longitude}`;
      try {
        const response = await fetch(url);
        const data = await response.json();

        const timezone = data.results.timezone;

        const sunriseTimeLocal = moment.tz(
          data.results.sunrise,
          "HH:mm:ss A",
          timezone
        );
        const sunsetTimeLocal = moment.tz(
          data.results.sunset,
          "HH:mm:ss A",
          timezone
        );

        const sunriseTimeHere = sunriseTimeLocal.clone().local();
        const sunsetTimeHere = sunsetTimeLocal.clone().local();
        setSunriseTime(sunriseTimeHere);
        setSunsetTime(sunsetTimeHere);

        const currentTime = moment();

        if (sunriseTimeHere.isValid() && sunsetTimeHere.isValid()) {
          if (currentTime.isBetween(sunriseTimeHere, sunsetTimeHere)) {
            setTheme("light");
          } else {
            setTheme("dark");
          }
        } else {
        }
      } catch (error) { }
    }
  }, []);

  const fetchCurrentServerTime = useCallback(async () => {
    try {
      const response = await fetch("https://worldtimeapi.org/api/ip");
      const data = await response.json();
      const serverTimeData = moment.tz(data.utc_datetime, data.timezone);

      if (serverTimeData.isValid()) {
        setServerTime(serverTimeData);

        const currentTime = moment();

        const currentTimeLocal = currentTime.clone().tz(moment.tz.guess());

        const serverTimeOffset = serverTimeData.utcOffset();
        const currentTimeOffset = currentTimeLocal.utcOffset();

        const serverTimeMilliseconds =
          serverTimeData.valueOf() - serverTimeOffset * 60000;
        const currentTimeMilliseconds =
          currentTimeLocal.valueOf() - currentTimeOffset * 60000;

        const timeDifferenceMilliseconds = Math.abs(
          serverTimeMilliseconds - currentTimeMilliseconds
        );

        setIsLocationValid(timeDifferenceMilliseconds <= 300000);
      } else {
        setIsLocationValid(false);
      }
    } catch (error) {
      setIsLocationValid(false);
    }
  }, []);

  useEffect(() => {
    fetchCurrentServerTime();
    const serverTimeInterval = setInterval(() => {
      fetchCurrentServerTime();
    }, 3 * 60 * 60 * 1000);

    return () => {
      clearInterval(serverTimeInterval);
    };
  }, [fetchCurrentServerTime]);

  const getLocation = useCallback(async () => {
    try {
      const response = await fetch("https://freeipapi.com/api/json");
      const data = await response.json();
      setLatitude(parseFloat(data.latitude));
      setLongitude(parseFloat(data.longitude));

      const city = data.cityName;
      const country = data.countryName;
      setLocation(city ? `${city}, ${country}` : country);
    } catch (error) {
      const defaultLatitude = 6.5244;
      const defaultLongitude = 3.3792;
      setLatitude(defaultLatitude);
      setLongitude(defaultLongitude);
    }
  }, []);

  useEffect(() => {
    getLocation();
    const ipLocationInterval = setInterval(() => {
      getLocation();
    }, 3 * 60 * 60 * 1000);

    return () => {
      clearInterval(ipLocationInterval);
    };
  }, [getLocation]);

  useEffect(() => {
    const fetchSunriseSunsetAndUpdate = () => {
      fetchSunriseSunset(latitude, longitude);
    };
    fetchSunriseSunsetAndUpdate();
    const fetchSunriseSunsetInterval = setInterval(
      fetchSunriseSunsetAndUpdate,
      3 * 60 * 60 * 1000
    );

    return () => {
      clearInterval(fetchSunriseSunsetInterval);
    };
  }, [latitude, longitude, fetchSunriseSunset]);

  useEffect(() => {
    const checkAndUpdateTheme = () => {
      if (sunriseTime && sunsetTime) {
        const currentTime = moment();
        if (currentTime.isBetween(sunriseTime, sunsetTime)) {
          setTheme("light");
        } else {
          setTheme("dark");
        }
      }
    };

    checkAndUpdateTheme();

    const interval = setInterval(checkAndUpdateTheme, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [sunriseTime, sunsetTime]);

  return (
    <ClockWrapper>
      <ClockContainer data-theme={theme}>
        <Hand ref={hourRef} hour />
        <Hand ref={minRef} min />
        <Hand ref={secRef} red />
      </ClockContainer>

      {isLocationValid && <LocationReadout>{location}</LocationReadout>}
    </ClockWrapper>
  );
};

export default AnalogClock;
