import React from "react";
import Icon from "@mdi/react";
import {
  mdiSolarPowerVariant,
  mdiCashMultiple,
  mdiPowerPlugBattery,
  mdiHandshake,
  mdiLocationEnter,
  mdiLogin,
  mdiAsteriskCircleOutline,
  mdiReload,
  mdiBullseyeArrow,
  mdiInformationVariantCircle,
  mdiTargetAccount,
  mdiArrowDownDropCircleOutline,
  mdiMovieOpenCheck,
  mdiEmailCheck,
  mdiPhone,
  mdiWhatsapp,
  mdiChevronDown,
  mdiChevronRight,
  mdiNumeric1Box,
  mdiNumeric2Box,
  mdiNumeric3Box,
  mdiListBoxOutline,
  mdiHomeAssistant,
  mdiDomain,
  mdiEmoticonConfused,
  mdiEmoticonHappy,
  mdiEmoticonExcited,
  mdiPower,
  mdiDirectionsFork,
  mdiViewGridPlus,
  mdiEye,
  mdiEyeOff,
  mdiFolderPlusOutline,
  mdiKeyboardCaps,
  mdiInformationSlabCircle,
  mdiCheckCircle,
  mdiAlert,
  mdiCloseCircle,
  mdiWindowClose,
  mdiBookmarkCheck,
  mdiAccountCheck,
  mdiLogout,
  mdiSkipBackward,
  mdiSkipForward,
  mdiNumeric4Box,
  mdiNumeric5Box,
  mdiNumeric6Box,
  mdiNumeric7Box,
  mdiNumeric8Box,
  mdiNumeric9Box,
  mdiPlus,
  mdiTrashCanOutline,
  mdiLinkedin,
  mdiTwitter,
  mdiOrderBoolAscendingVariant,
  mdiCog,
  mdiAccount,
  mdiUnfoldMoreHorizontal,
  mdiCreditCardScanOutline,
  mdiCreditCardScan,
  mdiContentCopy,
  mdiArrowUDownRightBold,
  mdiFormTextboxPassword,
  mdiAt,
  mdiAccountOutline,
  mdiStepBackward2,
  mdiPhoneIncomingOutgoing,
  mdiCameraOutline,
  mdiMapMarkerOutline,
  mdiCakeVariantOutline,
  mdiGenderTransgender,
  mdiEmailOff,
  mdiVectorLink,
} from "@mdi/js";

export const SolarPowerIcon = () => (
  <Icon path={mdiSolarPowerVariant} size={2} />
);

export const MultipleCashIcon = () => <Icon path={mdiCashMultiple} size={2} />;

export const PowerBatteryIcon = () => (
  <Icon path={mdiPowerPlugBattery} size={2} />
);

export const HandShakeIcon = () => <Icon path={mdiHandshake} size={2} />;

export const EnterIcon = () => <Icon path={mdiLocationEnter} size={1} />;

export const LoginIcon = () => <Icon path={mdiLogin} size={0.9} />;

export const CreateIcon = () => <Icon path={mdiFolderPlusOutline} size={1} />;

export const AsteriskIcon = () => (
  <Icon path={mdiAsteriskCircleOutline} size={0.7} />
);

export const ReloadIcon = () => <Icon path={mdiReload} size={1.3} />;

export const GetStartedIcon = () => <Icon path={mdiBullseyeArrow} size={1.1} />;

export const LearnMoreIcon = () => (
  <Icon path={mdiInformationVariantCircle} size={1.1} />
);

export const PointIcon = () => <Icon path={mdiTargetAccount} size={1} />;

export const ArrowIcon = ({ isOpen }) => (
  <Icon
    path={mdiArrowDownDropCircleOutline}
    size={1}
    style={{
      transform: isOpen ? "rotate(180deg)" : "rotate(0)",
      transition: "transform 0.4s ease",
    }}
  />
);

export const ArrowMiniIcon = ({
  isOpen,
  isHovered,
  isMobile,
  isOutstandingPayment,
}) => (
  <Icon
    path={mdiArrowDownDropCircleOutline}
    size={0.7}
    style={{
      transform: isOpen ? "rotate(180deg)" : "rotate(0)",
      transition: "transform 0.4s ease",
      backgroundColor: isOpen && !isOutstandingPayment
        ? "#e8ffa4"
        : isOpen && isOutstandingPayment
        ? "#f8ccc5"
        : isHovered && !isMobile && !isOutstandingPayment
        ? "#e8ffa4"
        : isHovered && !isMobile && isOutstandingPayment
        ? "#f8ccc5"
        : "Transparent",
      borderRadius: "50%",
    }}
  />
);

export const ContactIcon = () => <Icon path={mdiMovieOpenCheck} size={1.5} />;

export const EmailIcon = () => (
  <Icon
    path={mdiEmailCheck}
    size={0.75}
    style={{
      marginRight: "3%",
      flexShrink: 0,
    }}
  />
);

export const CallIcon = () => <Icon path={mdiPhone} size={0.75} />;

export const WhatsappIcon = () => <Icon path={mdiWhatsapp} size={0.75} />;

export const MenuOpenIcon = ({ isNavExpanded }) => (
  <Icon
    path={mdiChevronDown}
    size={1.8}
    style={{
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "50%",
    }}
  />
);

export const MenuCloseIcon = () => (
  <Icon
    path={mdiChevronDown}
    size={1.8}
    style={{
      boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.3)",
      borderRadius: "50%",
      transform: "rotate(180deg)",
    }}
  />
);

export const RightIcon = () => <Icon path={mdiChevronRight} size={0.75} />;
export const NumberOneIcon = () => (
  <Icon
    path={mdiNumeric1Box}
    size={1.5}
    style={{
      marginBottom: "1%",
      flexShrink: 0,
    }}
  />
);
export const NumberTwoIcon = () => (
  <Icon
    path={mdiNumeric2Box}
    size={1.5}
    style={{
      marginBottom: "1%",
      flexShrink: 0,
    }}
  />
);
export const NumberThreeIcon = () => (
  <Icon
    path={mdiNumeric3Box}
    size={1.5}
    style={{
      marginBottom: "1%",
      flexShrink: 0,
    }}
  />
);

export const FormIcon = () => (
  <Icon
    path={mdiListBoxOutline}
    size={1.5}
    style={{
      marginBottom: "2%",
      flexShrink: 0,
    }}
  />
);

export const HomeIcon = () => (
  <Icon
    path={mdiHomeAssistant}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const BusinessIcon = () => (
  <Icon
    path={mdiDomain}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const ConfusedIcon = () => (
  <Icon
    path={mdiEmoticonConfused}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const HappyIcon = () => (
  <Icon
    path={mdiEmoticonHappy}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const ExcitedIcon = () => (
  <Icon
    path={mdiEmoticonExcited}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const PowerIcon = () => (
  <Icon
    path={mdiPower}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const GuideIcon = () => (
  <Icon
    path={mdiDirectionsFork}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const CatalogIcon = () => (
  <Icon
    path={mdiOrderBoolAscendingVariant}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const GridIcon = () => (
  <Icon
    path={mdiViewGridPlus}
    size={1.5}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const EyeOpenIcon = () => (
  <Icon
    path={mdiEye}
    size={0.8}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const EyeOffIcon = () => (
  <Icon
    path={mdiEyeOff}
    size={0.8}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const CapsLockIcon = () => (
  <Icon
    path={mdiKeyboardCaps}
    size={0.9}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const InfoIcon = () => (
  <Icon
    path={mdiInformationSlabCircle}
    size={0.9}
    style={{
      marginBottom: "4%",
      marginRight: "3%",
      flexShrink: 0,
    }}
  />
);

export const SuccessIcon = () => (
  <Icon
    path={mdiCheckCircle}
    size={1.5}
    style={{
      flexShrink: 0,
    }}
  />
);
export const WarningIcon = () => (
  <Icon
    path={mdiAlert}
    size={1.5}
    style={{
      flexShrink: 0,
    }}
  />
);

export const ErrorIcon = () => (
  <Icon
    path={mdiCloseCircle}
    size={1.5}
    style={{
      flexShrink: 0,
    }}
  />
);
export const InfoIcon1 = () => (
  <Icon
    path={mdiInformationSlabCircle}
    size={1.5}
    style={{
      flexShrink: 0,
    }}
  />
);

export const CloseIcon = () => (
  <Icon
    path={mdiWindowClose}
    size={0.9}
    style={{
      marginBottom: "4%",
      flexShrink: 0,
    }}
  />
);

export const BookmarkIcon = () => (
  <Icon
    path={mdiBookmarkCheck}
    size={0.9}
    style={{
      flexShrink: 0,
    }}
  />
);

export const LoggedInUserIcon = () => (
  <Icon
    path={mdiAccountCheck}
    size={0.9}
    style={{
      flexShrink: 0,
      marginRight: "4%",
      marginBottom: "4%",
      marginLeft: "0",
    }}
  />
);

export const LogoutIcon = () => (
  <Icon
    path={mdiLogout}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const PreviousIcon = () => (
  <Icon
    path={mdiSkipBackward}
    size={0.9}
    style={{
      flexShrink: 0,
      marginRight: "3%",
      marginBottom: "1.5%",
      marginLeft: "2%",
    }}
  />
);

export const NextIcon = () => (
  <Icon
    path={mdiSkipForward}
    size={0.9}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1.5%",
      marginLeft: "3%",
    }}
  />
);

export const OneIcon = () => (
  <Icon
    path={mdiNumeric1Box}
    size={1}
    style={{
      flexShrink: 0,
      marginRight: "%",
      marginBottom: "0.5%",
    }}
  />
);

export const TwoIcon = () => (
  <Icon
    path={mdiNumeric2Box}
    size={1}
    style={{
      flexShrink: 0,
      marginRight: "%",
      marginBottom: "0.5%",
    }}
  />
);

export const ThreeIcon = () => (
  <Icon
    path={mdiNumeric3Box}
    size={1}
    style={{
      flexShrink: 0,
      marginRight: "%",
      marginBottom: "0.5%",
    }}
  />
);

export const FourIcon = () => (
  <Icon
    path={mdiNumeric4Box}
    size={1}
    style={{
      flexShrink: 0,
      marginRight: "%",
      marginBottom: "0.5%",
    }}
  />
);

export const FiveIcon = () => (
  <Icon
    path={mdiNumeric5Box}
    size={1}
    style={{
      flexShrink: 0,
      marginRight: "%",
      marginBottom: "0.5%",
    }}
  />
);

export const SixIcon = () => (
  <Icon
    path={mdiNumeric6Box}
    size={1}
    style={{
      flexShrink: 0,
      marginRight: "%",
      marginBottom: "0.5%",
    }}
  />
);

export const SevenIcon = () => (
  <Icon
    path={mdiNumeric7Box}
    size={1}
    style={{
      flexShrink: 0,
      marginBottom: "0.5%",
    }}
  />
);

export const EightIcon = () => (
  <Icon
    path={mdiNumeric8Box}
    size={1}
    style={{
      flexShrink: 0,
      marginBottom: "0.5%",
    }}
  />
);

export const NineIcon = () => (
  <Icon
    path={mdiNumeric9Box}
    size={1}
    style={{
      flexShrink: 0,
      marginBottom: "0.5%",
    }}
  />
);

export const AddIcon = () => (
  <Icon
    path={mdiPlus}
    size={1}
    style={{
      flexShrink: 0,
      marginBottom: "0.5%",
    }}
  />
);

export const TrashCanIcon = () => (
  <Icon
    path={mdiTrashCanOutline}
    size={0.7}
    style={{
      flexShrink: 0,
      marginBottom: "0.5%",
    }}
  />
);

export const LinkedInIcon = () => (
  <Icon
    path={mdiLinkedin}
    size={0.8}
    style={{
      flexShrink: 0,
      color: "#b0c1c0",
      marginRight: "50%",
    }}
  />
);

export const TwitterIcon = () => (
  <Icon
    path={mdiTwitter}
    size={0.8}
    style={{
      flexShrink: 0,
      color: "#b0c1c0",
      marginLeft: "50%",
    }}
  />
);

export const SettingsIcon = () => (
  <Icon
    path={mdiCog}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const AccountIcon = () => (
  <Icon
    path={mdiAccount}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const ViewMoreIcon = () => (
  <Icon
    path={mdiUnfoldMoreHorizontal}
    size={0.7}
    style={{
      flexShrink: 0,
      marginRight: "1%",
      marginLeft: "0",
    }}
  />
);

export const MiniBusinessIcon = () => (
  <Icon
    path={mdiDomain}
    size={0.7}
    style={{
      marginBottom: "2%",
      marginRight: "4%",
      flexShrink: 0,
    }}
  />
);

export const MiniGridIcon = () => (
  <Icon
    path={mdiViewGridPlus}
    size={0.7}
    style={{
      marginBottom: "2%",
      marginRight: "4%",
      flexShrink: 0,
    }}
  />
);

export const MiniCatalogIcon = () => (
  <Icon
    path={mdiOrderBoolAscendingVariant}
    size={0.7}
    style={{
      marginBottom: "2%",
      marginRight: "4%",
      flexShrink: 0,
    }}
  />
);

export const MiniGuideIcon = () => (
  <Icon
    path={mdiDirectionsFork}
    size={0.7}
    style={{
      marginBottom: "2%",
      marginRight: "4%",
      flexShrink: 0,
    }}
  />
);

export const MiniPowerIcon = () => (
  <Icon
    path={mdiPower}
    size={0.7}
    style={{
      marginBottom: "2%",
      marginRight: "4%",
      flexShrink: 0,
    }}
  />
);

export const MiniHomeIcon = () => (
  <Icon
    path={mdiHomeAssistant}
    size={0.7}
    style={{
      marginBottom: "2%",
      marginRight: "4%",
      flexShrink: 0,
    }}
  />
);

export const MiniLeasePayIcon = () => (
  <Icon
    path={mdiCreditCardScanOutline}
    size={0.7}
    style={{
      marginBottom: "2%",
      marginRight: "4%",
      flexShrink: 0,
    }}
  />
);

export const MiniPurchasePayIcon = () => (
  <Icon
    path={mdiCreditCardScan}
    size={0.7}
    style={{
      marginBottom: "2%",
      marginRight: "4%",
      flexShrink: 0,
    }}
  />
);

export const MiniCloseIcon = () => (
  <Icon
    path={mdiCloseCircle}
    size={0.75}
    style={{
      flexShrink: 0,
      color: "#8497a0",
      transition: "color 0.3s",
    }}
    title="Close"
    aria-label="Close"
    onMouseEnter={(e) => {
      e.currentTarget.style.color = "#e75139";
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.color = "#8497a0";
    }}
  />
);

export const CopyIcon = ({ onClick }) => (
  <Icon
    path={mdiContentCopy}
    size={0.55}
    style={{
      marginBottom: "1%",
      marginLeft: "1%",
      flexShrink: 0,
      color: "#8497a0",
      cursor: "pointer",
      transition: "color 0.3s",
    }}
    onClick={onClick}
    title="Copy"
    aria-label="Copy"
    role="button"
    onMouseEnter={(e) => {
      e.currentTarget.style.color = "#007bff";
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.color = "#8497a0";
    }}
  />
);

export const ReturnIcon = ({ isHovered }) => (
  <Icon
    path={mdiArrowUDownRightBold}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "0.8%",
      marginLeft: "3%",
      color: isHovered ? "" : "#245e41",
    }}
  />
);

export const PhotoIcon = () => (
  <Icon
    path={mdiCameraOutline}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const NameIcon = () => (
  <Icon
    path={mdiAccountOutline}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);


export const AtIcon = () => (
  <Icon
    path={mdiAt}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const PasswordIcon = () => (
  <Icon
    path={mdiFormTextboxPassword}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const AddressIcon = () => (
  <Icon
    path={mdiMapMarkerOutline}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const PhoneIcon = () => (
  <Icon
    path={mdiPhoneIncomingOutgoing}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const StepBackIcon = () => (
  <Icon
    path={mdiStepBackward2}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "0.3%",
      marginLeft: "3%",
    }}
  />
);

export const BigAlertIcon = () => (
  <Icon
    path={mdiAlert}
    size={2}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginTop: "5px",
      marginBottom: "5px",
      marginLeft: "2%",
      color: "#f7c2ba",
    }}
  />
);

export const BirthDateIcon = () => (
  <Icon
    path={mdiCakeVariantOutline}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const GenderIcon = () => (
  <Icon
    path={mdiGenderTransgender}
    size={0.8}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginBottom: "1%",
      marginLeft: "3%",
    }}
  />
);

export const BigEmailIcon = () => (
  <Icon
    path={mdiEmailOff}
    size={2}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginTop: "5px",
      marginBottom: "5px",
      marginLeft: "2%",
      color: "#ccc",
    }}
  />
);

export const BigLinkIcon = () => (
  <Icon
    path={mdiVectorLink}
    size={2}
    style={{
      flexShrink: 0,
      marginRight: "2%",
      marginTop: "5px",
      marginBottom: "5px",
      marginLeft: "2%",
      color: "#ccc",
    }}
  />
);

