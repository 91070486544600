import styled from "styled-components";

export const AnimationTextOverlay1 = styled.div`
  position: relative;
  top: -540px;
  left: 2%;
  height: 0;
  transform: translate(0, -50%);
  margin: auto;
  color: #ccff33;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin-right: 0;
  margin-left: 0;
  white-space: nowrap;

  @media (max-width: 992px) {
    font-size: 25px;
    top: -450px;
  }

  @media (max-width: 768px) {
    font-size: 3.2vmax;
    top: -70vh;
    left: 2%;
  }

  @media (max-width: 490px) {
    font-size: 3.5vmax;
    top: -70vh;
    left: 4%;
  }
`;

export const AnimationTextOverlay2 = styled.div`
  position: relative;
  top: -450px;
  left: 3%;
  height: 0;
  transform: translate(0, -50%);
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-left: 0;
  line-height: 1.2;
  white-space: nowrap;
  visibility: hidden;
  animation: overlay2Anim 52s 7;
  animation-timing-function: linear;
  color: #fff;
  @keyframes overlay2Anim {
    0% {
      visibility: hidden;
    }
    3% {
      visibility: hidden;
    }
    4% {
      visibility: visible;
    }
    42% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @media (max-width: 992px) {
    font-size: 20px;
    top: -370px;
    left: 2%;
  }

  @media (max-width: 768px) {
    font-size: 2.7vmax;
    top: -58vh;
    left: 25%;
  }

  @media (max-width: 490px) {
    font-size: 2.8vmax;
    top: -55vh;
    left: 15%;
  }
`;

export const AnimationTextOverlay3 = styled.div`
  position: relative;
  top: -290px;
  left: 5%;
  height: 0;
  transform: translate(0, -50%);
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-right: 0;
  margin-left: 0;
  line-height: 1.2;
  white-space: nowrap;
  p {
    line-height: 0.8;
  }
  visibility: hidden;
  animation: overlay3Anim 52s 7;
  animation-timing-function: linear;
  color: #fff;
  @keyframes overlay3Anim {
    0% {
      visibility: hidden;
    }
    43% {
      visibility: hidden;
    }
    44% {
      visibility: visible;
    }
    75% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @media (max-width: 992px) {
    font-size: 20px;
    top: -235px;
    left: 3%;
    p {
      line-height: 0.5;
    }
  }

  @media (max-width: 768px) {
    font-size: 2.7vmax;
    top: -59vh;
    left: 25%;
    p {
      line-height: 0.3;
    }
  }

  @media (max-width: 490px) {
    font-size: 2.8vmax;
    top: -57vh;
    left: 15%;
    p {
      line-height: 0.3;
    }
  }

  svg {
    font-size: 0.2px;
    margin: 0;
  }
`;

export const AnimationTextOverlay4 = styled.div`
  position: relative;
  top: -133px;
  left: 16%;
  height: 0;
  transform: translate(0, -50%);
  margin: auto;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-right: 0;
  margin-left: 0;
  line-height: 1.2;
  visibility: hidden;
  white-space: nowrap;
  animation: overlay4Anim 52s 7;
  animation-timing-function: linear;
  color: #fff;
  @keyframes overlay4Anim {
    0% {
      visibility: hidden;
    }
    76% {
      visibility: hidden;
    }
    77% {
      visibility: visible;
    }
    99.9% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  @media (max-width: 992px) {
    font-size: 20px;
    top: -120px;
    left: 12%;
  }

  @media (max-width: 768px) {
    font-size: 2.7vmax;
    top: -58vh;
    left: 25%;
  }

  @media (max-width: 490px) {
    font-size: 2.8vmax;
    top: -55vh;
    left: 15%;
  }
`;

export const ReloadButton = styled.button`
  position: relative;
  top: -12px;
  left: 2%;
  height: 0;
  display: flex;
  align-items: flex-end;
  margin: 0px;
  padding: 0px;
  background-color: #1b2e10;
  color: #ccff33;
  border: 0px solid #fff;
  border-radius: 50%;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  font-style: italic;

  &:hover {
    background-color: #1b2e10;
  }

  &:active {
    color: #e75139;
  }

  @media (max-width: 992px) {
    top: -10px;
    left: 2%;
    font-size: 13px;
  }

  @media (max-width: 768px) {
    top: -10px;
    left: 2%;
  }

  @media (max-width: 490px) {
    top: -8px;
    left: 2%;
  }
`;


export const StyledSvg = styled.svg`
  position: relative;
  width: 950px;
  height: 550px;
  background-image: radial-gradient(
      farthest-side at bottom left,
      rgba(34, 42, 53, 1),
      transparent
    ),
    radial-gradient(
      farthest-side at top left,
      rgba(34, 42, 53, 1),
      transparent 80px
    ),
    radial-gradient(
      farthest-corner at bottom right,
      rgba(27, 46, 16, 1),
      transparent 400px
    ),
    radial-gradient(
      farthest-corner at top right,
      rgba(55, 109, 19, 1),
      transparent 400px
    ),
    radial-gradient(
      farthest-corner at center,
      rgba(34, 42, 53, 1),
      transparent 500px
    ),
    radial-gradient(
      farthest-corner at bottom center,
      rgba(192, 217, 4, 0.6),
      transparent 350px
    ),
    radial-gradient(farthest-side at center, rgba(12, 21, 7, 1), transparent),
    radial-gradient(
      circle at 100% 100%,
      #1b2e10 0%,
      #222a35 50%,
      #376d13 80%,
      #0c1507 100%
    );

  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: baseline;

  @media (max-width: 1200px) {
    height: 550px;
  }

  @media (max-width: 992px) {
    width: 750px;
    height: 450px;
  }

  @media (max-width: 768px) {
    width: 96vw;
    height: 70vh;
  }

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    max-width: 950px;

    @media (max-width: 1200px) {
      height: 550px;
    }

    @media (max-width: 992px) {
      width: 750px;
      height: 550px;
    }
    @media (max-width: 768px) {
      width: 96vw;
      height: 75vh;
    }

    .rectangles {
      fill: #223614;
    }

    .youcr1,
    .applycr,
    .wecr1,
    .prequalcr,
    .youcr2,
    .wecr2,
    .auditcr,
    .youcr3,
    .wecr3,
    .supinstcr,
    .youcr4,
    .yousv1,
    .applysv,
    .wesv1,
    .auditsv,
    .yousv2,
    .wesv2,
    .managesv,
    .yousv3,
    .youdr1,
    .buydr,
    .wedr,
    .supinstdr,
    .youdr2,
    .Acredit,
    .Adelaycr1,
    .Adelaycr2,
    .Ainternal,
    .Afinal1,
    .Adelaycr3,
    .Aservice,
    .Afinal2,
    .Adelaysv,
    .Adirect,
    .Afinal3,
    .Adelaydr,
    .Bcredit,
    .Bdelaycr1,
    .Bdelaycr2,
    .Binternal,
    .Bfinal1,
    .BDelaycr3,
    .Bservice,
    .Bfinal2,
    .Bdelaysv,
    .Bdirect,
    .Bfinal3,
    .Bdelaydr,
    .Ccredit,
    .Cdelaycr1,
    .Cdelaycr2,
    .Cinternal,
    .Cfinal1,
    .Cdelaycr3,
    .Cservice,
    .Cfinal2,
    .Cdelaysv,
    .Cdirect,
    .Cfinal3,
    .Cdelaydr,
    .Dcredit,
    .Ddelaycr1,
    .Ddelaycr2,
    .Dinternal,
    .Dfinal1,
    .Ddelaycr3,
    .Dservice,
    .Dfinal2,
    .Ddelaysv,
    .Ddirect,
    .Dfinal3,
    .Ddelaydr,
    .Ecredit,
    .Edelaycr1,
    .Edelaycr2,
    .Einternal,
    .Efinal1,
    .Edelaycr3,
    .Eservice,
    .Efinal2,
    .Edelaysv,
    .Edirect,
    .Efinal3,
    .Edelaydr,
    .Fcredit,
    .Fdelaycr1,
    .Fdelaycr2,
    .Finternal,
    .Ffinal1,
    .Fdelaycr3,
    .Fservice,
    .Ffinal2,
    .Fdelaysv,
    .Fdirect,
    .Ffinal3,
    .Fdelaydr,
    .Gcredit,
    .Gdelaycr1,
    .Gdelaycr2,
    .Ginternal,
    .Gfinal1,
    .Gdelaycr3,
    .Gservice,
    .Gfinal2,
    .Gdelaysv,
    .Gdirect,
    .Gfinal3,
    .Gdelaydr {
      visibility: hidden;
      animation-fill-mode: both;
    }

    .youcr1 {
      animation: youcr1Anim 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes youcr1Anim {
      0% {
        visibility: hidden;
      }
      9% {
        visibility: hidden;
      }
      10% {
        visibility: visible;
      }
      19% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .applycr {
      animation: applycrAnim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes applycrAnim {
      0% {
        visibility: hidden;
      }
      10% {
        visibility: hidden;
      }
      11% {
        visibility: visible;
      }
      19% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .wecr1 {
      animation: wecr1Anim 52s 7;
      animation-timing-function: linear;
      fill: #ccff33;
    }
    @keyframes wecr1Anim {
      0% {
        visibility: hidden;
      }
      16% {
        visibility: hidden;
      }
      17% {
        visibility: visible;
      }
      27% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .prequalcr {
      animation: prequalcrAnim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes prequalcrAnim {
      0% {
        visibility: hidden;
      }
      17% {
        visibility: hidden;
      }
      18% {
        visibility: visible;
      }
      27% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .youcr2 {
      animation: youcr2 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes youcr2 {
      0% {
        visibility: hidden;
      }
      18% {
        visibility: hidden;
      }
      19% {
        visibility: visible;
      }
      27% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .wecr2 {
      animation: wecr2Anim 52s 7;
      animation-timing-function: linear;
      fill: #ccff33;
    }
    @keyframes wecr2Anim {
      0% {
        visibility: hidden;
      }
      24% {
        visibility: hidden;
      }
      25% {
        visibility: visible;
      }
      35% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }
    .auditcr {
      animation: auditcrAnim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes auditcrAnim {
      0% {
        visibility: hidden;
      }
      25% {
        visibility: hidden;
      }
      26% {
        visibility: visible;
      }
      35% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .youcr3 {
      animation: youcr3Anim 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes youcr3Anim {
      0% {
        visibility: hidden;
      }
      26% {
        visibility: hidden;
      }
      27% {
        visibility: visible;
      }
      35% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .wecr3 {
      animation: wecr3Anim 52s 7;
      animation-timing-function: linear;
      fill: #ccff33;
    }
    @keyframes wecr3Anim {
      0% {
        visibility: hidden;
      }
      32% {
        visibility: hidden;
      }
      33% {
        visibility: visible;
      }
      42% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .supinstcr {
      animation: supinstcrAnim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes supinstcrAnim {
      0% {
        visibility: hidden;
      }
      33% {
        visibility: hidden;
      }
      34% {
        visibility: visible;
      }
      42% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .youcr4 {
      animation: youcr4Anim 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes youcr4Anim {
      0% {
        visibility: hidden;
      }
      34% {
        visibility: hidden;
      }
      35% {
        visibility: visible;
      }
      42% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .yousv1 {
      animation: yousv1Anim 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes yousv1Anim {
      0% {
        visibility: hidden;
      }
      49% {
        visibility: hidden;
      }
      50% {
        visibility: visible;
      }
      60% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .applysv {
      animation: applysvAnim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes applysvAnim {
      0% {
        visibility: hidden;
      }
      50% {
        visibility: hidden;
      }
      51% {
        visibility: visible;
      }
      60% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .wesv1 {
      animation: wesv1Anim 52s 7;
      animation-timing-function: linear;
      fill: #ccff33;
    }
    @keyframes wesv1Anim {
      0% {
        visibility: hidden;
      }
      57% {
        visibility: hidden;
      }
      58% {
        visibility: visible;
      }
      67% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .auditsv {
      animation: auditsvAnim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes auditsvAnim {
      0% {
        visibility: hidden;
      }
      58% {
        visibility: hidden;
      }
      59% {
        visibility: visible;
      }
      67% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .yousv2 {
      animation: yousv2Anim 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes yousv2Anim {
      0% {
        visibility: hidden;
      }
      59% {
        visibility: hidden;
      }
      60% {
        visibility: visible;
      }
      67% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .wesv2 {
      animation: wesv2Anim 52s 7;
      animation-timing-function: linear;
      fill: #ccff33;
    }
    @keyframes wesv2Anim {
      0% {
        visibility: hidden;
      }
      64% {
        visibility: hidden;
      }
      65% {
        visibility: visible;
      }
      75% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .managesv {
      animation: managesvAnim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes managesvAnim {
      0% {
        visibility: hidden;
      }
      65% {
        visibility: hidden;
      }
      66% {
        visibility: visible;
      }
      75% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }
    .yousv3 {
      animation: yousv3Anim 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes yousv3Anim {
      0% {
        visibility: hidden;
      }
      66% {
        visibility: hidden;
      }
      67% {
        visibility: visible;
      }
      75% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .youdr1 {
      animation: youdr1Anim 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes youdr1Anim {
      0% {
        visibility: hidden;
      }
      82% {
        visibility: hidden;
      }
      83% {
        visibility: visible;
      }
      92% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .buydr {
      animation: buydr1Anim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes buydr1Anim {
      0% {
        visibility: hidden;
      }
      83% {
        visibility: hidden;
      }
      84% {
        visibility: visible;
      }
      92% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
      }
    }

    .wedr {
      animation: wedrAnim 52s 7;
      animation-timing-function: linear;
      fill: #ccff33;
    }
    @keyframes wedrAnim {
      0% {
        visibility: hidden;
      }
      89% {
        visibility: hidden;
      }
      90% {
        visibility: visible;
      }
      100% {
        visibility: hidden;
      }
    }

    .supinstdr {
      animation: supinstdrAnim 52s 7;
      animation-timing-function: linear;
      fill: #fff;
    }
    @keyframes supinstdrAnim {
      0% {
        visibility: hidden;
      }
      90% {
        visibility: hidden;
      }
      91% {
        visibility: visible;
      }
      100% {
        visibility: hidden;
      }
    }

    .youdr2 {
      animation: youdr2Anim 52s 7;
      animation-timing-function: linear;
      fill: #e75139;
    }
    @keyframes youdr2Anim {
      0% {
        visibility: hidden;
      }
      91% {
        visibility: hidden;
      }
      92% {
        visibility: visible;
      }
      100% {
        visibility: hidden;
      }
    }

    .Acredit {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 6s;
    }

    .Adelaycr1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 7s;
    }

    .Adelaycr2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 7.5s;
    }

    .Ainternal {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 11s;
    }

    .Afinal1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 15s;
    }

    .Adelaycr3 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 18s;
    }

    .Aservice {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 28s;
    }

    .Afinal2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 32s;
    }

    .Adelaysv {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 35s;
    }

    .Adirect {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 45s;
    }

    .Adelaydr {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 48s;
    }

    .Bcredit {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 58s;
    }

    .Bdelaycr1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 59s;
    }

    .Bdelaycr2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 59.5s;
    }

    .Binternal {
      animation: draw 4s 1s forwards;
      animation-delay: 63s;
      animation-timing-function: linear;
    }

    .Bfinal1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 67s;
    }

    .Bdelaycr3 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 70s;
    }

    .Bservice {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 80s;
    }

    .Bfinal2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 84s;
    }

    .Bdelaysv {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 87s;
    }

    .Bdirect {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 97s;
    }

    .Bdelaydr {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 100s;
    }

    .Ccredit {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 110s;
    }

    .Cdelaycr1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 111s;
    }

    .Cdelaycr2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 111.5s;
    }

    .Cinternal {
      animation: draw 4s 1s forwards;
      animation-delay: 115s;
      animation-timing-function: linear;
    }

    .Cfinal1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 119s;
    }

    .Cdelaycr3 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 122s;
    }

    .Cservice {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 132s;
    }

    .Cfinal2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 136s;
    }

    .Cdelaysv {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 139s;
    }

    .Cdirect {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 149s;
    }

    .Cdelaydr {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 152s;
    }

    .Dcredit {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 162s;
    }

    .Ddelaycr1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 163s;
    }

    .Ddelaycr2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 163.5s;
    }

    .Dinternal {
      animation: draw 4s 1s forwards;
      animation-delay: 167s;
      animation-timing-function: linear;
    }

    .Dfinal1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 171s;
    }

    .Ddelaycr3 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 174s;
    }

    .Dservice {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 184s;
    }

    .Dfinal2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 188s;
    }

    .Ddelaysv {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 191s;
    }

    .Ddirect {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 201s;
    }

    .Ddelaydr {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 204s;
    }

    .Ecredit {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 214s;
    }

    .Edelaycr1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 215s;
    }

    .Edelaycr2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 215.5s;
    }

    .Einternal {
      animation: draw 4s 1s forwards;
      animation-delay: 219s;
      animation-timing-function: linear;
    }

    .Efinal1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 223s;
    }

    .Edelaycr3 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 226s;
    }

    .Eservice {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 236s;
    }

    .Efinal2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 240s;
    }

    .Edelaysv {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 243s;
    }

    .Edirect {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 253s;
    }

    .Edelaydr {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 256s;
    }

    .Fcredit {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 266s;
    }

    .Fdelaycr1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 267s;
    }

    .Fdelaycr2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 267.5s;
    }

    .Finternal {
      animation: draw 4s 1s forwards;
      animation-delay: 271s;
      animation-timing-function: linear;
    }

    .Ffinal1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 275s;
    }

    .Fdelaycr3 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 278s;
    }

    .Fservice {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 288s;
    }

    .Ffinal2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 292s;
    }

    .Fdelaysv {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 295s;
    }

    .Fdirect {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 305s;
    }

    .Fdelaydr {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 308s;
    }

    .Gcredit {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 318s;
    }

    .Gdelaycr1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 319s;
    }

    .Gdelaycr2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 318.5s;
    }

    .Ginternal {
      animation: draw 4s 1s forwards;
      animation-delay: 323s;
      animation-timing-function: linear;
    }

    .Gfinal1 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 327s;
    }

    .Gdelaycr3 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 330s;
    }

    .Gservice {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 340s;
    }

    .Gfinal2 {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 344s;
    }

    .Gdelaysv {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 347s;
    }

    .Gdirect {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 357s;
    }

    .Ddelaydr {
      animation: draw 4s 1s forwards;
      animation-timing-function: linear;
      animation-delay: 360s;
    }

    @keyframes draw {
      0% {
        visibility: hidden;
        stroke-dashoffset: 2000;
      }
      1% {
        visibility: visible;
      }
      99% {
        visibility: hidden;
      }
      100% {
        visibility: hidden;
        stroke-dashoffset: 0;
      }
    }
  }
`;
