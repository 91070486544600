import React from "react";

const DirectOrder = ({ order, isMobile }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatMoneyNumber = (number) => {
    if (number === null || number === undefined) return "-";
    return Number(number.toFixed(2)).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  let fieldsToDisplay = [];

  if (order.type === "direct") {
    fieldsToDisplay = order.components.map((component, index) => ({
      label: `Component ${index + 1}`,
      value: `${component.quantity} x ${component.component} (${component.specification}) - ${component.comment}`,
    }));
  }

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>Field</th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {fieldsToDisplay.map((field, index) => (
          <tr key={index} style={{ fontSize: isMobile ? "10px" : "13px" }}>
            <td style={{ width: isMobile ? "78%" : "80%" }}>{field.label}</td>
            <td style={{ width: isMobile ? "22%" : "20%" }}>{field.value}</td>
          </tr>
        ))}
        <tr style={{ fontSize: isMobile ? "10px" : "13px" }}>
          <td style={{ width: isMobile ? "78%" : "80%" }}>Payment Currency</td>
          <td style={{ width: isMobile ? "22%" : "20%" }}>
            {order.paymentCurrency}
          </td>
        </tr>
        <tr style={{ fontSize: isMobile ? "10px" : "13px" }}>
          <td style={{ width: isMobile ? "78%" : "80%" }}>Amount Paid</td>
          <td style={{ width: isMobile ? "22%" : "20%" }}>
            {formatMoneyNumber(order.amountPaid)}
          </td>
        </tr>
        <tr style={{ fontSize: isMobile ? "10px" : "13px" }}>
          <td style={{ width: isMobile ? "78%" : "80%" }}>Payment Date</td>
          <td style={{ width: isMobile ? "22%" : "20%" }}>
            {formatDate(order.paymentDate)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default DirectOrder;
