import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { auth } from "./utils/fireBaseConfig";
import { signOut } from "firebase/auth";
import logo from "../assets/logo/kepler_logo.svg";
import {
  HoverLink,
  HoverLinkButton,
  WrapperMenu,
  LineMenu,
  UserHoverLinkButton,
  MobileHoverLinkButton,
  BannerContainer,
  DropdownMenu,
  DropdownMenuItem,
} from "./StyledComponents";
import {
  LoginIcon,
  BookmarkIcon,
  LoggedInUserIcon,
  LogoutIcon,
  AccountIcon,
} from "./Icons";

const CustomNavbar = ({
  onLogoLoad,
  user,
  setUser,
  infoNotification,
  hPBannerMessage,
  isHPBannerVisible,
}) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isScrolling, setIsScrolling] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isReturning, setIsReturning] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const attemptedSignup = localStorage.getItem("attemptedSignup");
    if (attemptedSignup === "true") {
      setIsReturning(true);
    } else {
      setIsReturning(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavbarLink = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleNavToggle = () => {
    if (!isAnimating) {
      setIsAnimating(true);

      if (isNavExpanded) {
        setIsOpen(false);
        document.body.classList.remove("no-scroll");
      } else {
        document.body.classList.add("no-scroll");
        setIsOpen(true);
      }

      setIsNavExpanded((prevExpanded) => !prevExpanded);

      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
  };
  const handleLinkClick = () => {
    if (isNavExpanded) {
      setIsNavExpanded(false);
      setIsOpen(false);
      document.body.classList.remove("no-scroll");
    }
  };

  const handleFAQLinkClick = () => {
    navigate("/");

    const scrollToSection = () => {
      scroller.scrollTo("section-four", {
        smooth: true,
        duration: 300,
        offset: 0,
      });
    };
    setTimeout(scrollToSection, 0);
  };

  const handleAccountClick = () => {
    navigate("/customer-dashboard");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  useEffect(() => {
    const handleScrollLock = () => {
      if (isNavExpanded && isMobile) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    };

    handleScrollLock();

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isNavExpanded, isMobile]);

  let prevScrollPos = window.pageYOffset;

  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;
    const navbar = document.querySelector(".navbar");

    if (!navbar) {
      return;
    }

    if (currentScrollPos <= 70) {
      navbar.style.top = "0";
    } else if (prevScrollPos > currentScrollPos) {
      navbar.style.top = "0";
    } else {
      navbar.style.top = "-10rem";
    }

    prevScrollPos = currentScrollPos;
  };

  const handleLogoLoaded = () => {
    onLogoLoad();
  };

  const handleLogout = () => {
    signOut(auth, user)
      .then(() => {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
        infoNotification(`${user.displayName} logged out successfully!`);
        setUser(null);
      })
      .catch((error) => {});
  };

  const handleBannerClick = () => {
    navigate(`/solutions?section=three-ways`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  return (
    <nav
      style={{
        padding: "0 !important",
        margin: "0 !important",
      }}
    >
      <Navbar
        style={{
          padding: "0 !important",
          margin: "0 !important",
          backgroundColor: "#fff",
          height: isHPBannerVisible ? "10rem" : "7rem",
          position: "fixed",
          marginRight: "0",
          top: "0px",
          overflowY: isMobile && !isNavExpanded ? "scroll" : "unset",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          width: "100%",
          transition: "top 0.5s ease-in-out",
          border: "0",
          zIndex: 4001,
        }}
        variant="light"
        expand="lg"
        className={`fixed-top${isMobile ? "navbar" : "navbar"}`}
        expanded={isNavExpanded}
      >
        <div
          style={{
            position: "absolute",
            top: "0px",
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            margin: "0",
            paddingTop: isHPBannerVisible ? "3rem" : "0rem",
            alignItems: "center",
            justifyContent: "center",
            height: isHPBannerVisible ? "10rem" : "7rem",
          }}
        >
          <BannerContainer
            isHPBannerVisible={isHPBannerVisible}
            onClick={handleBannerClick}
          >
            {isHPBannerVisible && (
              <span
                style={{
                  margin: "0 !important",
                  fontWeight: "500",
                }}
              >
                {hPBannerMessage} <BookmarkIcon />
              </span>
            )}
          </BannerContainer>

          <Container fluid>
            <Navbar.Brand
              as={NavLink}
              to="/"
              style={{
                paddingLeft: "3px",
                paddingRight: "",
                paddingTop: "0px",
                paddingBottom: "0px",
                zIndex: 2,
              }}
              onLoad={handleLogoLoaded}
              onClick={() => {
                handleNavbarLink();
                handleLinkClick();
              }}
            >
              <img src={logo} alt="Logo" width="100px" />
            </Navbar.Brand>
            {isMobile && (
              <>
                {!isNavExpanded && (
                  <Nav
                    className="ml-auto"
                    style={{
                      marginRight: "7%",
                    }}
                  >
                    {user && user.emailVerified ? (
                      <MobileHoverLinkButton
                        as={NavLink}
                        to="/customer-dashboard"
                        activeClassName="active"
                        isNavExpanded={isNavExpanded}
                        style={{
                          textDecoration: "none",
                        }}
                        onClick={handleNavbarLink}
                      >
                        <LoggedInUserIcon />
                        {user.displayName}
                      </MobileHoverLinkButton>
                    ) : (
                      <HoverLinkButton
                        as={NavLink}
                        to="/signup"
                        activeClassName="active"
                        isNavExpanded={isNavExpanded}
                        style={{
                          textDecoration: "none",
                        }}
                        onClick={handleNavbarLink}
                      >
                        {isReturning ? "Log in" : "Apply"} <LoginIcon />
                      </HoverLinkButton>
                    )}
                  </Nav>
                )}
                <WrapperMenu
                  aria-controls="basic-navbar-nav"
                  className={isOpen ? "open" : ""}
                  onClick={handleNavToggle}
                >
                  <LineMenu className="line-menu half start" />
                  <LineMenu className="line-menu" />
                  <LineMenu className="line-menu half end" />
                </WrapperMenu>
              </>
            )}
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={
                isMobile
                  ? `mobile-menu ${
                      isHPBannerVisible ? "is-visible" : "is-hidden"
                    }`
                  : `
                `
              }
              in={isNavExpanded}
              style={{
                zIndex: 1,
              }}
            >
              {isMobile && (
                <Nav
                  className={`mx-auto menu-animation${
                    isNavExpanded ? " expanded" : ""
                  }`}
                >
                  <HoverLink
                    as={NavLink}
                    to="/"
                    activeClassName="active"
                    isNavExpanded={isNavExpanded}
                    className="nav-link"
                    onClick={() => {
                      handleNavbarLink();
                      handleLinkClick();
                    }}
                  >
                    Home
                  </HoverLink>
                  <HoverLink
                    as={NavLink}
                    to="/solutions"
                    activeClassName="active"
                    isNavExpanded={isNavExpanded}
                    className="nav-link"
                    onClick={() => {
                      handleNavbarLink();
                      handleLinkClick();
                    }}
                  >
                    Solutions
                  </HoverLink>
                  <HoverLink
                    as={NavLink}
                    to="/"
                    activeClassName="active"
                    isNavExpanded={isNavExpanded}
                    style={{
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      handleFAQLinkClick();
                      handleLinkClick();
                    }}
                  >
                    FAQs
                  </HoverLink>
                  <HoverLink
                    as={NavLink}
                    to="/about"
                    activeClassName="active"
                    isNavExpanded={isNavExpanded}
                    className="nav-link"
                    onClick={() => {
                      handleNavbarLink();
                      handleLinkClick();
                    }}
                  >
                    {" "}
                    About Us
                  </HoverLink>
                  <HoverLink
                    as={NavLink}
                    to="/jobs"
                    activeClassName="active"
                    isNavExpanded={isNavExpanded}
                    className="nav-link"
                    onClick={() => {
                      handleNavbarLink();
                      handleLinkClick();
                    }}
                  >
                    Jobs
                  </HoverLink>
                  <HoverLink
                    as={NavLink}
                    to="/insights"
                    activeClassName="active"
                    isNavExpanded={isNavExpanded}
                    className="nav-link"
                    onClick={() => {
                      handleNavbarLink();
                      handleLinkClick();
                    }}
                    style={{
                      height: "5%",
                    }}
                  >
                    Insights
                  </HoverLink>
                  {user && user.emailVerified ? (
                    <MobileHoverLinkButton
                      as={NavLink}
                      to="/"
                      activeClassName="login"
                      isNavExpanded={isNavExpanded}
                      style={{
                        textDecoration: "none",
                      }}
                      onClick={() => {
                        handleLogout();
                        handleLinkClick();
                      }}
                    >
                      Log out <LogoutIcon />
                      {user.displayName}
                    </MobileHoverLinkButton>
                  ) : (
                    <HoverLinkButton
                      as={NavLink}
                      to="/signup"
                      activeClassName="login"
                      isNavExpanded={isNavExpanded}
                      style={{
                        textDecoration: "none",
                      }}
                      onClick={handleNavbarLink}
                    >
                      {isReturning ? "Log in" : "Apply"} <LoginIcon />
                    </HoverLinkButton>
                  )}
                </Nav>
              )}
              {!isMobile && (
                <Nav className="mx-auto">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <HoverLink
                      as={NavLink}
                      to="/"
                      activeClassName="active"
                      className="nav-link"
                      isNavExpanded={isNavExpanded}
                      onClick={handleNavbarLink}
                    >
                      Home
                    </HoverLink>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <HoverLink
                      as={NavLink}
                      to="/solutions"
                      activeClassName="active"
                      className="nav-link"
                      isNavExpanded={isNavExpanded}
                      onClick={handleNavbarLink}
                    >
                      Solutions
                    </HoverLink>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <HoverLink
                      as={NavLink}
                      to="/"
                      activeClassName="active"
                      isNavExpanded={isNavExpanded}
                      style={{
                        textDecoration: "none",
                      }}
                      onClick={() => {
                        handleFAQLinkClick();
                      }}
                    >
                      FAQs
                    </HoverLink>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <HoverLink
                      as={NavLink}
                      to="/about"
                      activeClassName="active"
                      className="nav-link"
                      isNavExpanded={isNavExpanded}
                      onClick={handleNavbarLink}
                    >
                      About Us
                    </HoverLink>
                  </div>

                  <HoverLink
                    as={NavLink}
                    to="/jobs"
                    activeClassName="active"
                    className="nav-link"
                    isNavExpanded={isNavExpanded}
                    onClick={handleNavbarLink}
                  >
                    Jobs
                  </HoverLink>
                  <HoverLink
                    as={NavLink}
                    to="/insights"
                    activeClassName="active"
                    className="nav-link"
                    isNavExpanded={isNavExpanded}
                    onClick={handleNavbarLink}
                  >
                    Insights
                  </HoverLink>
                </Nav>
              )}
              {!isMobile && (
                <Nav
                  className="ml-auto"
                  style={{
                    marginRight: "3%",
                  }}
                >
                  {user && user.emailVerified ? (
                    <div
                      style={{ position: "relative" }}
                      onMouseEnter={() => setShowDropdown(true)}
                      onMouseLeave={() => setShowDropdown(false)}
                    >
                      <UserHoverLinkButton
                        as={NavLink}
                        to="/"
                        activeClassName="active"
                        isNavExpanded={isNavExpanded}
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <LoggedInUserIcon />
                        {user.displayName}
                      </UserHoverLinkButton>
                      {showDropdown && (
                        <DropdownMenu
                          onMouseEnter={() => setShowDropdown(true)}
                          onMouseLeave={() => setShowDropdown(false)}
                        >
                          <DropdownMenuItem
                            style={{
                              textDecoration: "none",
                            }}
                            onClick={handleAccountClick}
                          >
                            Account <AccountIcon />
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            style={{
                              textDecoration: "none",
                            }}
                            onClick={handleLogout}
                          >
                            Log out <LogoutIcon />
                          </DropdownMenuItem>
                        </DropdownMenu>
                      )}
                    </div>
                  ) : (
                    <HoverLinkButton
                      as={NavLink}
                      to="/signup"
                      activeClassName="active"
                      isNavExpanded={isNavExpanded}
                      style={{
                        textDecoration: "none",
                      }}
                      onClick={handleNavbarLink}
                    >
                      {isReturning ? "Log in" : "Apply"} <LoginIcon />
                    </HoverLinkButton>
                  )}
                </Nav>
              )}
            </Navbar.Collapse>
          </Container>
        </div>
      </Navbar>
    </nav>
  );
};

export default CustomNavbar;
