import React from "react";
import styled, { keyframes, css } from "styled-components";
import image13 from "../assets/images/background1.svg";

const fadeIn = keyframes`
  from {
    opacity: 0;

  }
  to {
    opacity: 1;
 
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;

  100% {
    opacity: 0;

  }
`;

const TilePulseAnimation = keyframes`
  0% {
    background: #245e41;
    transform: scale(1);
  }
  25% { 
    background:  #e8ffa4;
    transform: scale(1.05);
    border: 0.5px solid red;
  }
  50% {
    background: #245e41;
    transform: scale(1);
  }
`;

const BackgroundContainer = styled.div`
  background-image: url(${image13});
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* Set a minimum height to cover the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TilesContainer = styled.div`
  width: 247.5px;
  opacity: 0;
  animation: 1s ease 0s ${fadeIn};
  animation-fill-mode: forwards;

  ${({ loading }) =>
    loading
      ? css`
          animation: 1s ease 0s ${fadeIn};
          animation-fill-mode: forwards;
        `
      : css`
          animation: 100s ${fadeOut} forwards;
        `}
`;

const Tile = styled.div`
  border-radius: 50%;
  float: left;
  height: 75px;
  margin: 3.75px;
  width: 75px;
  background: #1b2e10;

  @media (max-width: 992px) {
    height: 75px;
    width: 75px;
  }
  ${({ loading }) =>
    loading &&
    css`
      animation: 1s ease 0s ${TilePulseAnimation} infinite;
      animation-fill-mode: both;
    `}

  // Delay the animation. Delay increases as items loop.
  &:nth-child(1) {
    animation-delay: 0.056s;
  }
  &:nth-child(2) {
    animation-delay: 0.1111s;
  }
  &:nth-child(3) {
    animation-delay: 0.1667s;
  }
  &:nth-child(4) {
    animation-delay: 0.2222s;
  }
  &:nth-child(5) {
    animation-delay: 0.2778s;
  }
  &:nth-child(6) {
    animation-delay: 0.3333s;
  }
  &:nth-child(7) {
    animation-delay: 0.3889s;
  }
  &:nth-child(8) {
    animation-delay: 0.4444s;
  }
  &:nth-child(9) {
    animation-delay: 0.5s;
  }


`;

const LoadingAnimation = ({ loading }) => {
  return (
    <BackgroundContainer>
      <TilesContainer loading={loading}>
        {[...Array(9)].map((_, index) => (
          <Tile key={index} loading={loading} />
        ))}
      </TilesContainer>
    </BackgroundContainer>
  );
};

export default LoadingAnimation;
