import React, { useState, useRef } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { Element } from "react-scroll";
import { GlobalStyle } from "../StyledComponents";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCEEditor = ({ value, onChange }) => {
  const handleEditorChange = (content, editor) => {
    onChange(content);
  };

  const handleImageUpload = (blobInfo) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());

      axiosInstance
        .post("/api/upload-insight-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.location) {
            resolve(response.data.location);
          } else {
            reject("Image URL missing in the response.");
          }
        })
        .catch((error) => {
          reject("Image upload failed: " + error.message);
        });
    });
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      value={value}
      init={{
        menubar: true,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "code",
          "help",
          "wordcount",
        ],
        toolbar:
          "undo redo | formatselect styles | bold italic backcolor forecolor | " +
          "subscript superscript alignleft aligncenter alignright alignjustify | " +
          "bullist numlist outdent indent | lineheight removeformat | print | " +
          "link image | help",
        images_upload_handler: handleImageUpload,
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

const InsightForm = ({ isHPBannerVisible }) => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [header, setHeader] = useState("");
  const [category, setCategory] = useState("insight"); // Default value for category
  const [keywords, setKeywords] = useState("");
  const [body, setBody] = useState("");
  const [dateOfPublication, setDateOfPublication] = useState("");
  const [headerPhoto, setHeaderPhoto] = useState(null);
  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitButton = document.querySelector('button[type="submit"]');
    submitButton.disabled = true;

    if (submitButton.dataset.submitting === "true") return;
    submitButton.dataset.submitting = "true";

    const formData = new FormData();
    formData.append("title", title);
    formData.append("author", author);
    formData.append("header", header);
    formData.append("category", category);
    formData.append("keywords", keywords);
    formData.append("body", body);
    formData.append("dateOfPublication", dateOfPublication);
    formData.append("headerPhoto", headerPhoto);

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axiosInstance.post("/api/insights", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      alert("Insight created successfully!");

      setTitle("");
      setAuthor("");
      setHeader("");
      setCategory("insight"); // Reset to default value
      setKeywords("");
      setBody("");
      setDateOfPublication("");
      setHeaderPhoto(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      alert("Error creating insight:", error);
    } finally {
      submitButton.disabled = false;
      submitButton.dataset.submitting = "false";
    }
  };

  return (
    <>
      <GlobalStyle />
      <Element
        name="insights-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{ padding: "20px", marginBottom: "20vh" }}
        >
          <div>
            <label style={{ width: "50vw" }}>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              style={{ width: "90vw" }}
            />
          </div>

          <div>
            <label style={{ width: "50vw" }}>Header:</label>
            <input
              type="text"
              value={header}
              onChange={(e) => setHeader(e.target.value)}
              required
              style={{ width: "90vw" }}
            />
          </div>
          <div>
            <label style={{ width: "50vw" }}>Author:</label>
            <input
              type="text"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              required
              style={{ width: "90vw" }}
            />
          </div>
          <div>
            <label style={{ width: "50vw" }}>Category:</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
              style={{ width: "90vw" }}
            >
              <option value="news">News</option>
              <option value="insight">Insight</option>
              <option value="announcement">Announcement</option>
            </select>
          </div>
          <div>
            <label style={{ width: "50vw" }}>Keywords (comma separated):</label>
            <input
              type="text"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              required
              style={{ width: "90vw" }}
            />
          </div>
          <div>
            <label style={{ width: "90vw" }}>Date of Publication:</label>
            <input
              type="date"
              value={dateOfPublication}
              onChange={(e) => setDateOfPublication(e.target.value)}
              required
              style={{ marginLeft: "10vw" }}
            />
          </div>
          <div>
            <label style={{ width: "90vw" }}>Header Photo:</label>
            <input
              ref={fileInputRef}
              type="file"
              onChange={(e) => setHeaderPhoto(e.target.files[0])}
              required
              style={{ marginLeft: "10vw", width: "80vw" }}
            />
          </div>
          <div>
            <label>Body:</label>
            <TinyMCEEditor value={body} onChange={setBody} />
          </div>
          <button type="submit">Submit</button>
        </form>
      </Element>
    </>
  );
};

export default InsightForm;
