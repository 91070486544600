import React, { useState, useCallback } from "react";
import axiosInstance from "../../utils/axiosInstance";

const AdminDashboard = ({
  showBanner,
  isHPBannerVisible,
  setHPBannerMessage,
}) => {
  const [inputValue, setInputValue] = useState("");

  const memoizedSetHPBannerMessage = useCallback(setHPBannerMessage, [
    setHPBannerMessage,
  ]);
  const memoizedShowBanner = useCallback(showBanner, [showBanner]);

  const fetchBannerMessage = async () => {
    try {
      const response = await axiosInstance.get("/api/banner-message");
      if (response.status === 200) {
        const bannerMessage = response.data.message;
        if (bannerMessage.trim() !== "") {
          memoizedSetHPBannerMessage(bannerMessage);
          memoizedShowBanner(bannerMessage);
        }
        setInputValue(bannerMessage);
      } else {
        console.error("Failed to fetch banner message");
      }
    } catch (error) {
      console.error("Error fetching banner message:", error);
    } finally {
    }
  };

  const handleInputChange = (e) => {
    const message = e.target.value;
    setInputValue(message);
  };

  const updateBannerMessage = async () => {
    if (inputValue.trim() !== "") {
      try {
        const response = await axiosInstance.post(
          "/api/update-banner-message",
          { message: inputValue }
        );
        if (response.status === 200) {
          memoizedSetHPBannerMessage(inputValue);
          memoizedShowBanner(inputValue);
        } else {
          console.error("Failed to update banner message");
        }
      } catch (error) {
        console.error("Error updating banner message:", error);
      }
    }
  };

  const deleteBannerMessage = async () => {
    try {
      const response = await axiosInstance.delete("/api/delete-banner-message");
      if (response.status === 200) {
        memoizedSetHPBannerMessage("");
        memoizedShowBanner("");
        setInputValue("");
      } else {
        console.error("Failed to delete banner message");
      }
    } catch (error) {
      console.error("Error deleting banner message:", error);
    }
  };

  const toggleBanner = () => {
    if (isHPBannerVisible) {
      if (inputValue.trim() === "") {
        deleteBannerMessage();
      } else {
        memoizedShowBanner("");
      }
    } else {
      updateBannerMessage();
      fetchBannerMessage();
    }
  };

  return (
    <div
      style={{
        paddingTop: "10rem",
        height: "30rem",
      }}
    >
      <>
        <h2>Admin Dashboard</h2>
        <label>
          Home Page Banner Message:
          <input type="text" value={inputValue} onChange={handleInputChange} />
        </label>
        <button onClick={toggleBanner}>
          {isHPBannerVisible
            ? inputValue.trim() === ""
              ? "Delete Banner Message"
              : "<-Empty Home Page Banner"
            : inputValue.trim() === ""
            ? "<- Enter Banner Message"
            : "Save Home Page Banner"}
        </button>
      </>
    </div>
  );
};

export default AdminDashboard;
