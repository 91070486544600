import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  GlobalStyle,
  SectionContainer,
  ImageContainer,
  Image,
  TextOverlay,
  SubTextOverlay,
  ContentContainer,
  WelcomeContainer,
  Welcome,
  Heading,
  RestHeading,
  Paragraph,
  ButtonContainer1,
  ButtonContainer2,
  ParagraphContainer,
  Button,
  Button4,
  ContinueButton,
  Highlight,
  HighlightContainer,
  HighlightContainer1,
  GetStartedButton,
  LearnMoreButton,
} from "./StyledComponents";
import SubWelcomeAnimation from "./SubWelcomeAnimation";
import Rolodex from "./Rolodex";
import ElectricLineAnimation from "./AnimatedCircuit";
import PowerScapeAnimation from "./PowerScape";
import CarbonOffsetAnimation from "./CarbonOffset";
import FAQSection from "./FAQSection";
import AnalogClock from "./AnalogClock";
import PowerGenData from "./PowerGen";
import { Element } from "react-scroll";
import {
  SolarPowerIcon,
  MultipleCashIcon,
  PowerBatteryIcon,
  HandShakeIcon,
  EnterIcon,
  GetStartedIcon,
  LearnMoreIcon,
  PointIcon,
  ContactIcon,
} from "./Icons";
import image1 from "../assets/images/image1.svg";
import image13 from "../assets/images/background1.svg";

const LandingSections = ({ isLoading, isHPBannerVisible }) => {
  const [focusedButton, setFocusedButton] = useState(null);
  const continueButtonRef = useRef(null);
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);
  const sectionFourRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subWelcomeTexts = useMemo(
    () => [
      "Renewable Energy",
      "Affordable Electricity",
      "Credit Financing",
      "Installations",
      "Mini-grid Development",
      "Electric Mobility",
      "Carbon Accounting",
      "Hydroelectric Power",
    ],
    []
  );

  const handleButtonFocus = (buttonId) => {
    setFocusedButton(buttonId);
  };

  useEffect(() => {
    if (focusedButton && continueButtonRef.current) {
      const buttonPosition = continueButtonRef.current.getBoundingClientRect();
      const windowBottom = window.innerHeight;

      if (buttonPosition.bottom > windowBottom) {
        window.scrollTo({
          top: window.scrollY + buttonPosition.bottom - windowBottom + 30,
          behavior: "smooth",
        });
      }
    }
  }, [focusedButton]);

  const handleContinueButtonClick = () => {
    setFocusedButton((prevFocusedButton) => {
      let sectionId = "";
      if (prevFocusedButton === "button1") {
        sectionId = "credit";
      } else if (prevFocusedButton === "button2") {
        sectionId = "PaaS";
      } else if (prevFocusedButton === "button3") {
        sectionId = "direct";
      }

      if (sectionId) {
        navigate(`/solutions?section=${sectionId}`);
      }

      return prevFocusedButton;
    });
    setFocusedButton(null);
  };

  const handleGetStartedButtonClick = () => {
    navigate(`/solutions?section=three-ways`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleLearnMoreButtonClick = () => {
    navigate(`/solutions?section=solutions`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handlePartnerButtonClick = () => {
    navigate(`/solutions?section=mini-grids`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleDemoButtonClick = () => {
    navigate(`/demo`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const isScreenSizeSmall = window.innerWidth < 630;

  return (
    <>
      {" "}
      <GlobalStyle />
      <Element
        name="section-one"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div
          style={{
            position: "absolute",
            paddingLeft: "3%",
            height: "10px",
            zIndex: 3,
            display: window.innerWidth <= 991 ? "block" : "none",
          }}
        >
          Home
        </div>
        <SectionContainer id="section-one" ref={sectionOneRef}>
          <ContentContainer
            id="subsection-one"
            style={{
              paddingTop: "7vw",
              backgroundImage: `url(${image13})`,
              backgroundSize: "cover",
              backgroundPosition: "center ",
            }}
          >
            <WelcomeContainer>
              <div>
                <Welcome id="welcome-element">
                  The{" "}
                  <HighlightContainer1>
                    <Highlight>all-in-one</Highlight>
                  </HighlightContainer1>{" "}
                  clean energy shop.
                </Welcome>
                <SubWelcomeAnimation texts={subWelcomeTexts} />
              </div>
            </WelcomeContainer>
            <div
              id="part-of-section-one"
              style={{
                paddingRight: "0rem",
                background: "#fff",
                borderRadius: "7px",
              }}
            >
              <Heading
                style={{
                  background: "transparent",
                }}
              >
                We help businesses & households in Africa to easily access clean
                and affordable electricity.
              </Heading>
            </div>{" "}
            <div
              id="rest-of-section-one"
              style={{
                width: "100%",
                paddingTop: "1rem",
                paddingBottom: "3rem",
                paddingLeft: "0.7rem",
                paddingRight: "0.7rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Paragraph
                style={{
                  fontWeight: "bold",
                }}
              >
                Would you like to...
              </Paragraph>
              <ButtonContainer1>
                <Button
                  onClick={() => handleButtonFocus("button1")}
                  isFocused={focusedButton === "button1"}
                >
                  <MultipleCashIcon />
                  Get financing for solar panels, inverter & batteries.
                </Button>
                <Button
                  onClick={() => handleButtonFocus("button2")}
                  isFocused={focusedButton === "button2"}
                >
                  <PowerBatteryIcon />
                  Enjoy premium energy management service.
                </Button>
                <Button
                  onClick={() => handleButtonFocus("button3")}
                  isFocused={focusedButton === "button3"}
                >
                  <SolarPowerIcon />
                  Purchase high-efficiency power products.
                </Button>
              </ButtonContainer1>
              <ContinueButton
                isFocused={
                  focusedButton === "button1" ||
                  focusedButton === "button2" ||
                  focusedButton === "button3"
                }
                ref={continueButtonRef}
                onClick={handleContinueButtonClick}
              >
                Continue
                <EnterIcon />
              </ContinueButton>
            </div>
          </ContentContainer>
        </SectionContainer>
      </Element>
      <ImageContainer>
        <Image src={image1} alt="Solar panels & wind turbines." />
        <TextOverlay>
          <p>The Kepler Advantage.</p>
        </TextOverlay>
        <SubTextOverlay>
          <p>Affordable, reliable, clean power!</p>
        </SubTextOverlay>
      </ImageContainer>
      <PowerGenData />
      <Rolodex />
      <Element name="section-two">
        <SectionContainer id="section-two" ref={sectionTwoRef}>
          <ContentContainer id="subsection-two">
            <div
              id="part-of-section-two"
              style={{
                width: "100%",
                paddingTop: "3rem",
                paddingLeft: "0",
                paddingRight: "0",
                paddingBottom: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <RestHeading>
                {" "}
                <HighlightContainer>
                  <Highlight>Easy access</Highlight>
                </HighlightContainer>{" "}
                to electricity for businesses and homes.
              </RestHeading>
              <ElectricLineAnimation />
              <div
                gap="2vmax"
                style={{
                  display: "flex",
                  gap: "2vmax",
                  paddingTop: "2rem",
                }}
              >
                <GetStartedButton onClick={handleGetStartedButtonClick}>
                  Get started
                  <GetStartedIcon />
                </GetStartedButton>
                <LearnMoreButton onClick={handleLearnMoreButtonClick}>
                  Learn More
                  <LearnMoreIcon />
                </LearnMoreButton>
              </div>
            </div>
          </ContentContainer>
        </SectionContainer>
      </Element>
      <Element name="section-three">
        <SectionContainer
          id="section-three"
          ref={sectionThreeRef}
          style={{
            background: "#E8EBF0",
          }}
        >
          <ContentContainer
            id="subsection-three"
            style={{
              background: "#E8EBF0",
              paddingLeft: "0",
              paddingRight: "0",
              marginLeft: "0",
              marginRight: "0",
            }}
          >
            <div
              id="part-of-section-three"
              style={{
                width: "100%",
                paddingTop: "0",
                paddingBottom: "1rem",
                paddingLeft: "0",
                paddingRight: "0",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <RestHeading>
                Enabling{" "}
                <HighlightContainer>
                  <Highlight>mini-grids,</Highlight>
                </HighlightContainer>{" "}
                improving power supply in our communities.
              </RestHeading>
              <Heading
                style={{
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                Kepler Solutions develops clean power projects, and facilitates
                grid/off-grid electricity sales
              </Heading>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: window.innerWidth > 992 ? "row" : "column",
                  alignItems: "center",
                  paddingLeft: "0",
                  paddingRight: "0",
                  paddingBottom: "70px",
                }}
              >
                <PowerScapeAnimation />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: window.innerWidth > 992 ? "left" : "center",
                    paddingLeft: "0",
                    paddingRight: "0",
                  }}
                >
                  <ParagraphContainer>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Paragraph>Are you a...</Paragraph>
                    </div>
                    <Paragraph>
                      <PointIcon />
                      Private or government-owned grid operator wanting clean
                      power supply?
                    </Paragraph>
                    <Paragraph>
                      <PointIcon />
                      Landowner interested in power generation joint venture?
                    </Paragraph>
                    <Paragraph>
                      <PointIcon />
                      Real-estate developer looking to incorporate reliable
                      power supply into your projects?
                    </Paragraph>
                    <Paragraph>
                      <PointIcon />
                      Supplier of power solution hardware components or
                      software?
                    </Paragraph>
                  </ParagraphContainer>
                  <ButtonContainer2>
                    <Button4
                      onClick={() => handlePartnerButtonClick("button4")}
                    >
                      <HandShakeIcon />
                      Partner with Kepler Solutions
                    </Button4>
                  </ButtonContainer2>
                </div>
              </div>
            </div>
          </ContentContainer>
        </SectionContainer>
      </Element>
      <div
        style={{
          background: "#6D7971",
          width: "100%",
          display: "flex",
          flexDirection: window.innerWidth > 992 ? "row" : "column",
          alignItems: "right",
          justifyContent: "center",
          paddingTop: window.innerWidth > 992 ? "0" : "5vh",
          paddingLeft: "0",
          paddingRight: "0",
          margin: "0",
        }}
      >
        <RestHeading
          style={{
            color: "#fff",
            width: "100%",
            display: "flex",
            flexDirection: "",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: window.innerWidth > 400 ? "5rem" : "1rem",
            paddingLeft: window.innerWidth > 400 ? "5rem" : "1rem",
            margin: "0",
          }}
        >
          We lower your costs with carbon credits...
        </RestHeading>
        <CarbonOffsetAnimation />
      </div>
      <div
        style={{
          background: "#5e6861",
          width: "100%",
          display: "flex",
          flexDirection: window.innerWidth > 992 ? "row" : "column",
          alignItems: "right",
          justifyContent: "center",
          paddingLeft: "0",
          paddingRight: "0",
          paddingTop: "30px",
          paddingBottom: "30px",
          margin: "0",
        }}
      >
        <RestHeading
          style={{
            color: "#cff",
            width: "100%",
            display: "flex",
            flexDirection: "",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: "1rem",
            paddingLeft: "1rem",
            margin: "0",
            fontSize: window.innerWidth > 992 ? "1.8rem" : "2.5vmax",
          }}
        >
          ...and artificial intelligence, lowering credit risk and enabling more
          savings for you.
        </RestHeading>
      </div>
      <Element name="section-four">
        <SectionContainer id="section-four" ref={sectionFourRef}>
          <ContentContainer
            id="subsection-four"
            style={{
              paddingTop: "10vh",
              paddingLeft: "0",
              paddingRight: "0",
              marginLeft: "0",
              marginRight: "0",
              width: "100%",
            }}
          >
            <div
              id="part-of-section-four"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0",
                margin: "0",
                background: "#fff",
              }}
            >
              <RestHeading
                style={{
                  padding: "0",
                  margin: "0",
                  background: "#fff",
                }}
              >
                {isScreenSizeSmall ? (
                  <>
                    <HighlightContainer>
                      <Highlight>FAQs</Highlight>
                    </HighlightContainer>
                  </>
                ) : (
                  <>
                    Frequently Asked{" "}
                    <HighlightContainer>
                      <Highlight>Questions</Highlight>
                    </HighlightContainer>
                  </>
                )}
              </RestHeading>
              <FAQSection />
            </div>
            <div
              id="demo=request"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "0",
                margin: "0",
                paddingBottom: window.innerWidth > 992 ? "10%" : "",
                backgroundImage: `url(${image13})`,
                backgroundSize: "cover",
                backgroundPosition: window.innerWidth > 992 ? "" : "top right",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "spread-between",
                  flexDirection: window.innerWidth > 992 ? "row" : "column",
                  alignItems: "center",
                  padding: "0",
                  margin: "0",
                  borderRadius: "12px",
                  height: window.innerWidth > 992 ? "13rem" : "",
                }}
              >
                <AnalogClock />
                <RestHeading
                  style={{
                    padding: "0",
                    margin: "0",
                    textAlign: window.innerWidth > 992 ? "left" : "center",
                    width: "100%",
                    whiteSpace: "pre-line",
                    paddingBottom: "1.8rem",
                    position: "relative",
                    top: window.innerWidth > 992 ? "0" : "-8rem",
                  }}
                >
                  <p
                    style={{
                      paddingTop: "0rem",
                      padding: "0.2rem",
                      margin: "0",
                    }}
                  >
                    Powering your world.
                  </p>
                  <p
                    style={{
                      padding: "0.2rem",
                      margin: "0",
                      fontSize: window.innerWidth > 992 ? "2.3rem" : "4vmax",
                    }}
                  >
                    Freeing your time.
                  </p>
                  <p
                    style={{
                      padding: "0.2rem",
                      margin: "0",
                      fontSize: window.innerWidth > 992 ? "2rem" : "3.6vmax",
                    }}
                  >
                    Saving our planet.
                  </p>
                </RestHeading>
              </div>
              <ButtonContainer2
                style={{
                  padding: "0",
                  margin: "0",
                }}
              >
                <Button4
                  onClick={() => handleDemoButtonClick("button4")}
                  style={{
                    position: "relative",
                    top: window.innerWidth > 992 ? "0" : "-6rem",
                  }}
                >
                  <ContactIcon />
                  Request a Demo
                </Button4>
              </ButtonContainer2>
            </div>
          </ContentContainer>
        </SectionContainer>
      </Element>
    </>
  );
};

export default LandingSections;
