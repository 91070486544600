import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import { Element } from "react-scroll";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Recaptcha from "react-google-recaptcha";
import axiosInstance from "../utils/axiosInstance";
import { FormIcon } from "./Icons";
import WhatsApp from "./WhatsAppButton";
import { Grid } from "react-loader-spinner";

import image21 from "../assets/images/background5.svg";
import {
  GlobalStyle,
  RestHeading,
  Paragraph,
  Button4,
  ButtonContainer2,
  HighlightContainer2,
  Highlight,
} from "./StyledComponents";

const DemoFormContainer = styled.div`
  width: 50vw;
  margin: 3vw;
  margin-left: 0;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding: 3vw;
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  border: 1px solid #b6c6c5;
  background: #fff;

  @media (max-width: 992px) {
    flex-direction: column;
    width: 90vw;
    margin: 1vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin-bottom: 10vh;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  min-height: 50px;
`;

const Label = styled.label`
  position: absolute;
  padding-bottom: 20px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-15px" : "50%")};
  transform: translateY(-30%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.value ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;

  ${FormGroup}:focus-within & {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }
`;
const Label1 = styled.label`
  position: absolute;
  margin-top: 5px;
  padding-bottom: 20px;
  left: 12px;
  transform: translateY(-30%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.value ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;

  ${FormGroup}:focus-within & {
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }
`;

const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 1.5rem;
  margin-left: 20px;
`;

const RequiredIndicator = styled.span`
  color: ${(props) =>
    !props.focused && props.transparent ? "transparent" : "red"};
  margin-left: 1px;
  font-size: 14px;
`;

const Input = styled.input`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  /* Style for autofill input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const Select = styled.select`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }
`;

const TextArea = styled.textarea`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  min-height: 25px;
  font-weight: 500;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }
`;

const MiniGridForm = ({
  user,
  successNotification,
  warningNotification,
  errorNotification,
  infoNotification,
  isHPBannerVisible,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    org: "",
    community: "",
    category: "",
    howDidYouHear: "",
    howDidYouHearOther: "",
    comment: "",
    InvisibleRecaptchaSuccess: false,
  });
  const lineHeight = 25;
  const howDidYouHearOtherInputRef = useRef(null);
  const [userCountry, setUserCountry] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userState, setUserState] = useState("");
  const [userCountryName, setUserCountryName] = useState("");
  const [userIpAddress, setUserIpAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState("");
  const [isOrgOrCommunityFilled, setIsOrgOrCommunityFilled] = useState(false);
  const [isCategoryOther, setIsCategoryOther] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showV2Checkbox, setShowV2Checkbox] = useState(false);
  const navigate = useNavigate();
  const recaptchaAction = "submit_form";

  useEffect(() => {
    async function fetchUserCountry() {
      try {
        const response = await fetch("https://freeipapi.com/api/json");
        const data = await response.json();
        setUserCountry(data.countryCode.toLowerCase());
        setUserCity(data.cityName);
        setUserState(data.regionName);
        setUserCountryName(data.countryName);
        setUserIpAddress(data.ipAddress);
      } catch (error) {
        setUserCountry("");
        setUserCity("");
        setUserState("");
        setUserCountryName("");
        setUserIpAddress("");
      }
    }

    fetchUserCountry();
  }, [userCountry]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const truncatedValue = value.slice(0, 300);
    const noConsecutiveSpacesValue = truncatedValue.replace(/\s\s+/g, " ");

    if (value.trim() !== "" || value === "") {
      setFormData({ ...formData, [name]: noConsecutiveSpacesValue });

      if (name === "org" || name === "community") {
        if (
          noConsecutiveSpacesValue === "" &&
          (formData.org === "" || formData.community === "")
        ) {
          setIsOrgOrCommunityFilled(false);
        } else {
          setIsOrgOrCommunityFilled(true);
        }
      }

      if (noConsecutiveSpacesValue === "other") {
        setIsCategoryOther(true);
      } else {
        setIsCategoryOther(false);
      }
    }
  };

  const handleTextAreaChange = (event) => {
    const textarea = event.target;
    const previousRows = textarea.rows;
    textarea.rows = 1;
    const currentRows = Math.floor(textarea.scrollHeight / lineHeight);
    textarea.rows = currentRows;

    if (currentRows < previousRows) {
      textarea.rows = currentRows;
    }

    handleInputChange(event);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    if (value === "others") {
      setFormData({ ...formData, [name]: value, howDidYouHearOther: "" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    if (
      formData.howDidYouHear === "others" &&
      howDidYouHearOtherInputRef.current
    ) {
      howDidYouHearOtherInputRef.current.focus();
    }
  }, [formData.howDidYouHear]);

  useEffect(() => {
    window.grecaptcha.enterprise.ready(async () => {
      try {
        const token = await window.grecaptcha.enterprise.execute(
          "6LfJC88nAAAAAOcWC0BdRL28919IAUprLIhagDUr",
          { action: recaptchaAction }
        );
        setFormData((prevFormData) => ({
          ...prevFormData,
          recaptchaToken: token,
          InvisibleRecaptchaSuccess: true,
        }));
      } catch (error) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          InvisibleRecaptchaSuccess: false,
        }));
      }
    });
  }, [recaptchaAction]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let usedRecaptchaTokenName = "recaptchaToken";

    if (formData.grecaptchaToken) {
      usedRecaptchaTokenName = "grecaptchaToken";
    }

    if (
      !formData.InvisibleRecaptchaSuccess &&
      usedRecaptchaTokenName === "recaptchaToken"
    ) {
      errorNotification(`Please complete reCAPTCHA verification.`);
      setShowV2Checkbox(true);
      setLoading(false);
      return;
    }
    const currentTime = new Date();

    const formDataWithTime = {
      ...formData,
      phoneNumber,
      countryCode,
      cityName: userCity,
      stateName: userState,
      countryName: userCountryName,
      userIpAddress: userIpAddress,
      submissionTime: currentTime.toISOString(),
    };

    for (const key in formDataWithTime) {
      if (typeof formDataWithTime[key] === "string") {
        formDataWithTime[key] = formDataWithTime[key].trim();
      }
    }

    const formDataWithToken = { ...formDataWithTime };
    if (usedRecaptchaTokenName === "grecaptchaToken") {
      delete formDataWithToken.recaptchaToken;
    }

    axiosInstance
      .post("/api/submit-minigridform", formDataWithToken, {
        headers: user && user.uid ? { "user-uid": user.uid } : {},
      })
      .then((response) => {
        successNotification(
          `Your information has been saved successfully. Someone will be in touch shortly. Thank you!`
        );
        setFormData({
          name: "",
          email: "",
          org: "",
          interest: "",
          howDidYouHear: "",
          howDidYouHearOther: "",
          comment: "",
          InvisibleRecaptchaSuccess: false,
        });

        setTimeout(() => {
          navigate("/customer-dashboard");
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 500);
        }, 0);
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.backendScoreAssessmentFailed
        ) {
          infoNotification(`Please complete reCAPTCHA verification.`);
          setShowV2Checkbox(true);
          setLoading(false);
        } else {
          errorNotification(
            `An error occurred while saving your information. Sorry for the inconvenience. Try later.`
          );
          setLoading(false);
        }
      });
  };

  const handleCheckboxChange = (token) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      grecaptchaToken: token,
    }));
  };

  return (
    <>
      <GlobalStyle />
      {loading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 6000,
            backgroundColor: "rgb(0,0,0,0.3)",
          }}
        >
          <Grid
            height="30%"
            width="30%"
            color="#245e41"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-loader"
            visible={true}
          />
        </div>
      )}
      <Element
        name="minigrid-form"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div
          id="top-demo"
          style={{
            margin: "0",
            padding: "0",
            width: "100%",
            display: "flex",
            flexDirection: window.innerWidth > 992 ? "row" : "column",
            justifyContent: "center",
            alignItems: window.innerWidth > 992 ? "stretch" : "center",
            backgroundImage: `url(${image21})`,
            backgroundSize: window.innerWidth > 992 ? "auto" : "cover",
            backgroundPosition: "bottom left",
          }}
        >
          <div
            style={{
              margin: "0",
              paddingTop: "10%",
              paddingLeft: "7%",
              width: window.innerWidth > 992 ? "50vw" : "",
              minHeight: "50vw",
              filter: "opacity(90%)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "flex-start",
            }}
          >
            <RestHeading
              style={{
                textAlign: "left",
                paddingTop: window.innerWidth > 992 ? "" : "10%",
                paddingBottom: "4%",
                margin: "0",
                fontSize: "2.5rem",
              }}
            >
              <HighlightContainer2>
                <Highlight> Right connection</Highlight>
              </HighlightContainer2>{" "}
            </RestHeading>
            <RestHeading
              style={{
                textAlign: "left",
                paddingBottom: "7%",
                margin: "0",
                fontSize: "1.5rem",
              }}
            >
              Let's build mini-grids!
            </RestHeading>
            <Paragraph
              style={{
                paddingTop: "2%",
                paddingBottom: window.innerWidth > 992 ? "3%" : "5%",
                paddingRight: "5%",
                fontSize: window.innerWidth > 992 ? "1.2rem" : "1rem",
              }}
            >
              Let us know more about you, your situation and opportunity so we
              can reach out to develop the appropriate plan and power solution
              for our communities.
            </Paragraph>
            <Paragraph
              style={{
                paddingTop: "1%",
                paddingBottom: window.innerWidth > 992 ? "5%" : "5%",
                paddingRight: "5%",
                fontSize: window.innerWidth > 992 ? "1.2rem" : "1rem",
              }}
            >
              We look forward to partnering and building mutually beneficial
              power solutions.
            </Paragraph>
            <div
              style={{
                display: "flex",
                width: "95%",
                paddingTop: "1%",
                paddingBottom: window.innerWidth > 992 ? "5%" : "10%",
                paddingRight: "5%",
                justifyContent: "center",
              }}
            >
              <WhatsApp />
            </div>
          </div>
          <DemoFormContainer>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  autoComplete="name"
                  required
                />
                <Label value={formData.name}>
                  Your name
                  <RequiredIndicator transparent={formData.name !== ""}>
                    *
                  </RequiredIndicator>
                </Label>
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  autoComplete="email"
                  required
                />
                <Label value={formData.email}>
                  Your email
                  <RequiredIndicator transparent={formData.email !== ""}>
                    *
                  </RequiredIndicator>
                </Label>
              </FormGroup>
              <FormGroup>
                <Label1 value={phoneNumber}>Your Phone</Label1>
                <PhoneInputContainer>
                  <PhoneInput
                    country={userCountry}
                    enableSearch={true}
                    value={phoneNumber}
                    autoComplete="tel"
                    onChange={(phone, country) => {
                      setPhoneNumber(phone);
                      setCountryCode(country);
                    }}
                  ></PhoneInput>
                </PhoneInputContainer>
              </FormGroup>
              <FormGroup>
                <Select
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled hidden></option>
                  <option value="operator">Grid operator</option>
                  <option value="landowner">Landowner</option>
                  <option value="developer">Real-estate developer</option>
                  <option value="supplier">
                    Power components supplier/contractor incl. EPC
                  </option>
                  <option value="financier">Power projects financier</option>
                  <option value="other">Other - Will leave a message</option>
                </Select>
                <Label value={formData.category}>
                  Your category
                  <RequiredIndicator transparent={formData.category !== ""}>
                    *
                  </RequiredIndicator>
                </Label>
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="org"
                  value={formData.org}
                  onChange={handleInputChange}
                  autoComplete="organization"
                />
                <Label value={formData.org}>Your organisation</Label>
              </FormGroup>
              <FormGroup
                style={{
                  marginBottom: isOrgOrCommunityFilled ? "" : "0%",
                }}
              >
                <Input
                  type="text"
                  name="community"
                  value={formData.community}
                  onChange={handleInputChange}
                />
                <Label value={formData.community}>
                  Your community (town, region)
                </Label>
              </FormGroup>
              {!isOrgOrCommunityFilled && (
                <p
                  style={{
                    marginLeft: "5%",
                    marginBottom: window.innerWidth > 992 ? "5%" : "10%",
                    color: "red",
                    fontSize: "12px",
                    textAlign: "left",
                  }}
                >
                  Please fill the organization or community field.
                </p>
              )}
              <FormGroup>
                <Select
                  name="howDidYouHear"
                  value={formData.howDidYouHear}
                  onChange={handleSelectChange}
                  className={
                    formData.howDidYouHear === "others" ? "no-border" : ""
                  }
                  required
                >
                  <option value="" disabled hidden></option>
                  <option value="search">
                    Search engine (Google, Bing, Yahoo etc.){" "}
                  </option>
                  <option value="social">
                    Social media (Twitter, Facebook, Instagram, LinkedIn, etc.)
                  </option>
                  <option value="recommended">
                    Recommended by a friend or colleague
                  </option>
                  <option value="rep">Kepler Solutions Representative</option>
                  <option value="publication">Blog or publication</option>
                  <option value="others">Other:</option>
                </Select>
                {formData.howDidYouHear === "others" && (
                  <Input
                    ref={howDidYouHearOtherInputRef}
                    type="text"
                    name="howDidYouHearOther"
                    value={formData.howDidYouHearOther}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className="no-padding"
                    required
                  />
                )}
                <Label value={formData.howDidYouHear}>
                  How did you hear about us?
                  <RequiredIndicator
                    transparent={formData.howDidYouHear !== ""}
                  >
                    *
                  </RequiredIndicator>
                </Label>
              </FormGroup>
              <FormGroup>
                <TextArea
                  name="comment"
                  value={formData.comment}
                  onChange={handleTextAreaChange}
                  rows="1"
                  required={isCategoryOther}
                />
                <Label value={formData.comment}>
                  Your message
                  {formData.category === "other" && (
                    <RequiredIndicator
                      transparent={
                        formData.category !== "other" || formData.comment
                      }
                    >
                      *
                    </RequiredIndicator>
                  )}
                </Label>
              </FormGroup>
              <div
                style={{
                  width: "100%",
                  padding: "0",
                  marginTop: window.innerWidth > 992 ? "1%" : "1%",
                  marginBottom: "2%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {showV2Checkbox && (
                  <Recaptcha
                    sitekey="6Lf85tcnAAAAAF4kSVTHVukg4RbPIbudx3-1Y5dn"
                    onChange={(token) => handleCheckboxChange(token)}
                  />
                )}
              </div>
              <ButtonContainer2
                style={{
                  margin: "0",
                  width: "100%",
                  padding: "0",
                  marginTop: window.innerWidth > 992 ? "5%" : "10%",
                  marginBottom: "2%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button4
                  type="submit"
                  style={{
                    width: "85%",
                  }}
                  disabled={
                    !isOrgOrCommunityFilled ||
                    formData.name === "" ||
                    formData.email === "" ||
                    formData.category === "" ||
                    formData.howDidYouHear === "" ||
                    (formData.category === "other" &&
                      formData.comment === "") ||
                    (showV2Checkbox && !formData.grecaptchaToken)
                  }
                  className={
                    !isOrgOrCommunityFilled ||
                    formData.name === "" ||
                    formData.email === "" ||
                    formData.category === "" ||
                    formData.howDidYouHear === "" ||
                    (formData.category === "other" &&
                      formData.comment === "") ||
                    (showV2Checkbox && !formData.grecaptchaToken)
                      ? "disabled"
                      : ""
                  }
                >
                  <FormIcon />
                  Submit
                </Button4>
              </ButtonContainer2>
            </Form>
          </DemoFormContainer>
        </div>
      </Element>
    </>
  );
};

export default MiniGridForm;
