import React from "react";

const PaaSTable = ({ request, isMobile }) => {
  const fieldMappings = {
    name: { label: "Requester:", formatNumber: false },
    org: { label: "Business/Estate Name:", formatNumber: false },
    address: { label: "Business/Estate Address:", formatNumber: false },
    gridHours: { label: "Average Daily Grid Supply:", formatNumber: false },
    genCount: { label: "Number of Diesel Generators:", formatNumber: false },
    genSizeKVA: {
      label: "Combined Generators Capacity (kVA):",
      formatNumber: true,
    },
    comment: { label: "Comment:", formatNumber: false },
  };

  const formatSelectedNumber = (number) => {
    if (number === null || number === undefined) return "-";
    if (Number.isInteger(number)) return number.toLocaleString("en-US");
    return Math.round(number).toLocaleString("en-US");
  };

  const mapGridHoursToLabel = (gridHours) => {
    switch (gridHours) {
      case "0":
        return "Off grid";
      case "1-4":
        return "1 - 4 hours";
      case "5-8":
        return "5 - 8 hours";
      case "9-12":
        return "9 - 12 hours";
      case "13-16":
        return "13 - 16 hours";
      case ">16":
        return "More than 16 hours";
      default:
        return "-";
    }
  };

  const mapGenCountToLabel = (genCount) => {
    switch (genCount) {
      case "0":
        return "None";
      case "1":
        return "1";
      case "2":
        return "2";
      case "3":
        return "3";
      case "4":
        return "4";
      case ">4":
        return "More than 4";
      default:
        return "-";
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>Field</th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(request).map(([key, value]) => {
          const shouldRenderRow = fieldMappings[key] &&
            value !== undefined && value !== null && value !== "";
          return shouldRenderRow ? (
            <tr key={key} style={{ fontSize: isMobile ? "10px" : "13px" }}>
              <td style={{ width: isMobile ? "78%" : "80%" }}>
                {fieldMappings[key].label}
              </td>
              <td style={{ width: isMobile ? "22%" : "20%" }}>
                {key === "gridHours"
                  ? mapGridHoursToLabel(value)
                  : key === "genCOunt"
                  ? mapGenCountToLabel(value)
                  : fieldMappings[key].formatNumber
                  ? formatSelectedNumber(value)
                  : value}
              </td>
            </tr>
          ) : null;
        })}
      </tbody>
    </table>
  );
};

export default PaaSTable;
