import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../utils/fireBaseConfig";
import styled from "styled-components";
import logo from "../../assets/logo/kepler_logo.svg";
import image21 from "../../assets/images/background5.svg";
import {
  Button4,
  ButtonContainer2,
  GlobalStyle,
  Copyright1,
} from "../StyledComponents";
import { InfoIcon } from "../Icons";
import africa from "../../assets/images/africa1.svg";
import { Grid } from "react-loader-spinner";

const ForgotPasswordPopup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-image: url(${image21}); // Replace with your image URL
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: 15vh;
  margin-bottom: 10vh;
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 35%;
  border: 1px solid #b6c6c5;

  @media (max-width: 992px) {
    width: 87%;
    border-radius: 5px;
  }
`;

const PopupTitle = styled.h2`
  width: 100%;
  margin-top: 3vh;
  margin-bottom: 5vh;
  color: #245e41;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
`;

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  width: 100%;
`;

const PopupLabel = styled.label`
  position: absolute;
  padding-bottom: 20px;
  left: 12px;
  top: ${(props) =>
    props.focused || props.value || !props.valid ? "-15px" : "50%"};
  transform: translateY(-30%);
  font-size: ${(props) =>
    props.focused || props.value || !props.valid ? "14px" : "16px"};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.value && props.valid ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 98%;

  ${FormGroup}:focus-within & {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }
`;

const PopupInput = styled.input`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid
    ${(props) => (props.value && !props.valid ? "red" : "#ccc")};
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  background: transparent;

  &:focus {
    outline: none;
    border-bottom: 1px solid #245e41;
  }

  /* Style for autofill input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: bold;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const PopupError = styled.p`
  color: red;
  font-size: 14px;
`;

const ForgotPassword = ({
  successNotification,
  warningNotification,
  errorNotification,
}) => {
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [redirectTimer, setRedirectTimer] = useState(15);
  const [isAccountLocked, setIsAccountLocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const emailInputRef = useRef(null);
  const [userIpAddress, setUserIpAddress] = useState("");
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const copyrightText =
    currentYear === 2023 ? `${currentYear}` : `${2023} - ${currentYear}`;

  const CountryFlagIcon = ({ country, width, height }) => {
    const flagMap = {
      Africa: africa,
    };

    return (
      <div>
        <img
          src={flagMap[country]}
          alt={`${country} Flag`}
          width={width}
          height={height}
        />
      </div>
    );
  };

  useEffect(() => {
    async function fetchUserIPAdress() {
      try {
        const response = await fetch("https://freeipapi.com/api/json");
        const data = await response.json();
        setUserIpAddress(data.ipAddress);
      } catch (error) {
        setUserIpAddress("");
      }
    }

    fetchUserIPAdress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    if (email.trim() === "") {
      setIsEmailValid(true);
    } else if (!document.activeElement.isEqualNode(emailInputRef.current)) {
      setIsEmailValid(validateEmail(email));
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsAccountLocked(false);

    try {
      axiosInstance
        .post("/api/verify-code", {
          resetPassword: true,
          email,
          userIpAddress,
        })
        .then(async (response) => {
          if (response.data.success) {
            try {
              await sendPasswordResetEmail(auth, email);
              successNotification(
                `Password reset email sent. Please check your inbox.`
              );
              setIsEmailSent(true);
              setLoading(false);
              const countdownInterval = setInterval(() => {
                setRedirectTimer((prevTime) => prevTime - 1);
              }, 1000);
              setTimeout(() => {
                navigate("/signup");
                window.scrollTo({ top: 0, behavior: "smooth" });
                clearInterval(countdownInterval);
              }, redirectTimer * 1000);
            } catch (passwordResetError) {
              warningNotification(`Error sending password reset email.`);
              setLoading(false);
            }
          } else if (response.data.accountDisabled) {
            errorNotification(
              `This account is locked or pending verification. Verify account or contact customer support.`
            );
            setIsAccountLocked(true);
            setLoading(false);
          } else if (response.data.invalidUser) {
            errorNotification(`Error resetting password.`);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {
      warningNotification(`Error sending password reset email.`);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEmailSent && redirectTimer === 0) {
      navigate("/signup");
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    }
  }, [isEmailSent, redirectTimer, navigate]);

  return (
    <>
      <GlobalStyle />
      {loading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 6000,
            backgroundColor: "rgb(0,0,0,0.3)",
          }}
        >
          <Grid
            height="30%"
            width="30%"
            color="#245e41"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-loader"
            visible={true}
          />
        </div>
      )}
      <ForgotPasswordPopup>
        <PopupContent>
          <div
            style={{
              width: "100%",
              paddingTop: "0.5%",
              margin: "0",
              marginBottom: "10%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link
              to="/"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <img
                src={logo}
                alt="Kepler Logo"
                style={{
                  width: "100px",
                }}
              />
            </Link>
          </div>
          <PopupTitle>Reset Password</PopupTitle>
          {isEmailSent ? (
            <>
              <div
                style={{
                  padding: "2%",
                  marginTop: "1.5rem",
                  marginBottom: "1.5rem",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  justifyContent: "flex-start",
                  height: "auto",
                  background: "#e8ffa4",
                  borderRadius: "5px",
                  width: "98%",
                }}
              >
                <InfoIcon />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "flex-start",
                  }}
                >
                  <p
                    style={{
                      color: "#245e41",
                      textAlign: "start",
                    }}
                  >
                    Password reset email has been sent to {email}. Please check
                    your inbox.
                  </p>

                  <p
                    style={{
                      color: "#245e41",
                      textAlign: "left",
                    }}
                  >
                    You will be redirected to the log-in page in {redirectTimer}{" "}
                    seconds.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <form
              onSubmit={handleResetPassword}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormGroup>
                <PopupLabel value={email} valid={isEmailValid}>
                  Email:
                </PopupLabel>
                <PopupInput
                  type="email"
                  value={email}
                  name="email"
                  onChange={(e) => handleEmailChange(e.target.value)}
                  onBlur={() => {
                    if (email.trim() !== "") {
                      setIsEmailValid(validateEmail(email));
                    }
                  }}
                  valid={isEmailValid}
                  ref={emailInputRef}
                  required
                />
                {isEmailValid || (
                  <PopupError
                    style={{
                      fontSize: "12px",
                      marginLeft: "12px",
                      height: "0px",
                    }}
                  >
                    Please enter a valid email address
                  </PopupError>
                )}
              </FormGroup>

              {isAccountLocked && (
                <div
                  style={{
                    margin: "0",
                    padding: "2%",
                    marginTop: "1.5rem",
                    marginBottom: "1.5rem",
                    textAlign: "start",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "center",
                    height: "auto",
                    background: "#e8ffa4",
                    borderRadius: "5px",
                    color: "red",
                    width: "98%",
                  }}
                >
                  <InfoIcon />
                  <p>
                    This account is locked or pending verification. Verify your
                    account or contact customer support for assistance.
                  </p>
                </div>
              )}
              <ButtonContainer2
                style={{
                  width: "100%",
                  padding: "0",
                  margin: "0",
                  marginTop: window.innerWidth > 992 ? "10%" : "10%",
                  marginBottom: "5%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "3.1rem",
                }}
              >
                <Button4
                  type="submit"
                  style={{ width: "100%", padding: "0" }}
                  disabled={email === "" || !validateEmail(email)}
                  className={
                    email === "" || !validateEmail(email) ? "disabled" : ""
                  }
                >
                  Reset Password
                </Button4>
              </ButtonContainer2>
            </form>
          )}
          <div
            style={{
              marginTop: "2rem",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              to="/signup"
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }, 100);
              }}
              style={{
                color: "#245e41",
                fontSize: "15px",
                textDecoration: "underline",
                textUnderlinePosition: "under",
              }}
            >
              Back to Login/Signup
            </Link>
          </div>
        </PopupContent>
        <Copyright1>
          &copy; {copyrightText} All rights reserved &nbsp;|&nbsp; Built in{" "}
          <CountryFlagIcon country="Africa" width="27px" height="14px" />
          with 🤍 by Kepler Solutions
        </Copyright1>
      </ForgotPasswordPopup>
    </>
  );
};

export default ForgotPassword;
