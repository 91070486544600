import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  linkWithPopup,
  EmailAuthProvider,
  linkWithCredential,
} from "firebase/auth";
import { auth } from "../utils/fireBaseConfig";
import { GlobalStyle } from "../StyledComponents";
import { useMediaQuery } from "react-responsive";
import { StepBackIcon, EyeOpenIcon, EyeOffIcon, CapsLockIcon } from "../Icons";

import Google from "../../assets/coloredIcons/google.svg";
import Facebook from "../../assets/coloredIcons/facebook.svg";
import Yahoo from "../../assets/coloredIcons/yahoo.svg";
import Microsoft from "../../assets/coloredIcons/microsoft.svg";
import X from "../../assets/coloredIcons/x.svg";
import image27 from "../../assets/images/background10.svg";

const SocialLoginButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 1%;
  padding-bottom: 3%;
  gap: 50px;
`;

const SocialLoginButton = styled.button`
  padding: 1%;
  background-color: #f3f3f3;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding-top: 2%;
  padding-left: 3%;
  padding-right: 3%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #fff;
  }
`;

const Input = styled.input`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  background-color: #f3f3f3;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
    background-color: #fff;
  }

  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const CapsLockIndicator = styled.div`
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 2%;

  svg {
    color: #ff4545;
  }

  &:hover {
    svg {
      color: #e90000;
    }
  }
`;

const AccountLink = ({
  isHPBannerVisible,
  successNotification,
  warningNotification,
  errorNotification,
  infoNotification,
  user,
}) => {
  const [currentProvider, setCurrentProvider] = useState(null);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isCapsLockActive, setIsCapsLockActive] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 992 });

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setCurrentProvider(currentUser.providerData[0]?.providerId);
    }
  }, []);

  const getFirebaseErrorMessage = (errorCode) => {
    const errorMessages = {
      "auth/user-not-found": "User not found. Please create account.",
      "auth/wrong-password": "Incorrect details. Please update and try again.",
      "auth/invalid-email": "Incorrect details. Please update and try again.",
      "auth/email-already-exists":
        "This email is already in use. Please login.",
      "auth/too-many-requests": "Too many log in attempts. Try again later",
      "auth/network-request-failed": "Check your connection and try again",
      "auth/user-cancelled": "User cancelled login attempt.",
      "auth/internal-error": "Log in server error",
      "auth/account-exists-with-different-credential":
        "A user account with the same email already exists. Login with that account and link it with your Facebook account",
      // Add more error messages as needed
      default: errorCode,
    };

    return errorMessages[errorCode] || errorMessages.default;
  };

  const handleLinkAccount = async (providerId) => {
    setError(null);

    try {
      let provider;
      switch (providerId) {
        case "google":
          provider = new GoogleAuthProvider();
          break;
        case "x":
          provider = new TwitterAuthProvider();
          break;
        case "facebook":
          provider = new FacebookAuthProvider();
          break;
        case "yahoo":
          provider = new OAuthProvider("yahoo.com");
          break;
        case "microsoft":
          provider = new OAuthProvider("microsoft.com");
          break;
        default:
          throw new Error("Unsupported provider");
      }

      await linkWithPopup(auth.currentUser, provider);
      successNotification("Account linked successfully!");
    } catch (error) {
      const errorMessage = getFirebaseErrorMessage(error.code);
      setError(errorMessage);
      errorNotification("Account linking error: " + error.message);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex =
      // eslint-disable-next-line no-control-regex
      /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}|(?:(?:[0-9]{1,3}\.){3}[0-9]{1,3}|\[[0-9A-Fa-f]{1,4}(?::[0-9A-Fa-f]{1,4}){7}\]))$/;
    return emailRegex.test(email);
  };

  const getPasswordStrength = (password) => {
    if (password.length < 6) {
      return "weak";
    }

    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);

    if (hasUppercase && hasLowercase && hasNumber && hasSpecialChar) {
      return "strong";
    }

    if (
      (hasUppercase && hasLowercase) ||
      (hasUppercase && hasNumber) ||
      (hasUppercase && hasSpecialChar) ||
      (hasLowercase && hasNumber) ||
      (hasLowercase && hasSpecialChar) ||
      (hasNumber && hasSpecialChar)
    ) {
      return "medium";
    }

    return "weak";
  };

  const handleEmailAndPasswordLink = async () => {
    setError(null);

    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }

    if (!isValidEmail(email)) {
      setError("Invalid email format.");
      return;
    }

    if (getPasswordStrength(password) !== "strong") {
      setError(
        "Invalid password. New account password must be at least six (6) characters long and should include uppercase, lowercase, number, and special character(s)"
      );
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(email, password);
      await linkWithCredential(auth.currentUser, credential);
      successNotification(
        "Account linked with email and password successfully!"
      );
    } catch (error) {
      const errorMessage = getFirebaseErrorMessage(error.code);
      setError(errorMessage);
      errorNotification("Email and password linking error: " + error.message);
    }
  };

  const handleReturnToUserSettings = () => {
    navigate("/user-settings");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const togglePasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };

  return (
    <>
      <GlobalStyle />
      <Element
        name="insights-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
          backgroundImage: `url(${image27})`,
          backgroundSize: "auto",
          backgroundPosition: "top",
        }}
      >
        <div
          style={{
            height: "80vh",
            width: isMobile ? "85vw" : "90vw",
            margin: isMobile ? "7.5vw" : "5vw",
            marginTop: isMobile ? "15vw" : "5vw",
            marginBottom: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
            Link Accounts
          </h3>
          {error && (
            <p
              style={{
                color: "red",
                width: isMobile ? "85vw" : "40vw",
                textAlign: "center",
              }}
            >
              {error}
            </p>
          )}

          <SocialLoginButtons>
            <SocialLoginButton onClick={() => handleLinkAccount("google")}>
              <img src={Google} alt="Google" />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                Google
              </p>
            </SocialLoginButton>
            <SocialLoginButton onClick={() => handleLinkAccount("yahoo")}>
              <img
                src={Yahoo}
                alt="Yahoo"
                style={{
                  margin: "4.5px",
                }}
              />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                Yahoo
              </p>
            </SocialLoginButton>
            <SocialLoginButton onClick={() => handleLinkAccount("microsoft")}>
              <img src={Microsoft} alt="Microsoft" />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                Microsoft
              </p>
            </SocialLoginButton>
          </SocialLoginButtons>
          <SocialLoginButtons>
            <SocialLoginButton onClick={() => handleLinkAccount("facebook")}>
              <img src={Facebook} alt="Facebook" />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                Facebook
              </p>
            </SocialLoginButton>
            <SocialLoginButton onClick={() => handleLinkAccount("x")}>
              <img src={X} alt="X/Twitter" />
              <p
                style={{
                  paddingTop: "2%",
                  padding: "0",
                  margin: "0",
                  textAlign: "center",
                  color: "#245e41",
                  fontSize: "12px",
                }}
              >
                X/Twitter
              </p>
            </SocialLoginButton>
          </SocialLoginButtons>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <p>Current Provider: {currentProvider}</p>
            {currentProvider !== "password" && (
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div style={{ position: "relative", width: "100%" }}>
                  <Input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyUp={(e) => {
                      setIsCapsLockActive(e.getModifierState("CapsLock"));
                    }}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {showNewPassword ? <EyeOpenIcon /> : <EyeOffIcon />}
                  </button>
                  {isCapsLockActive && (
                    <CapsLockIndicator>
                      <CapsLockIcon />
                    </CapsLockIndicator>
                  )}
                </div>
                <button
                  onClick={handleEmailAndPasswordLink}
                  style={{
                    marginTop: "10px",
                    backgroundColor: "#e3e9e9",
                    width: "200px",
                    padding: "4px",
                    borderRadius: "5px",
                    boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
                    border: "1px solid #7a9795",
                  }}
                >
                  Link Email & Password
                </button>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "25px",
                color: "",
                cursor: "pointer",
              }}
              onClick={handleReturnToUserSettings}
            >
              <StepBackIcon />
              Return to Account Settings
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};

export default AccountLink;
