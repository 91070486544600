import React from "react";
import styled from "styled-components";

const PasswordStrengthCheck = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${({ strength }) => {
    if (strength === "weak") return "red";
    if (strength === "medium") return "orange";
    if (strength === "strong") return "green";
    return "black";
  }};
`;

const PasswordStrengthRequirements = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const PasswordStrengthRequirement = styled.li`
  margin-top: 2px;
`;

const PasswordStrengthCheckUI = ({ password }) => {
    if (!password) {
      return null;
    }
  
  const getPasswordStrength = () => {
    if (password.length < 6) {
      return "weak";
    }

    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);

    if (hasUppercase && hasLowercase && hasNumber && hasSpecialChar) {
      return "strong";
    }

    if (
      (hasUppercase && hasLowercase) ||
      (hasUppercase && hasNumber) ||
      (hasUppercase && hasSpecialChar) ||
      (hasLowercase && hasNumber) ||
      (hasLowercase && hasSpecialChar) ||
      (hasNumber && hasSpecialChar)
    ) {
      return "medium";
    }

    return "weak";
  };

  const strength = getPasswordStrength();

  return (
    <PasswordStrengthCheck strength={strength}>
      Password Strength: {strength.toUpperCase()}
      <PasswordStrengthRequirements>
        <PasswordStrengthRequirement>
          At least 6 characters
        </PasswordStrengthRequirement>
        <PasswordStrengthRequirement>
          {strength === "weak" && (
            <span style={{ color: "red" }}>
              Password not long enough.
            </span>
          )}
          {strength === "medium" && (
            <span style={{ color: "orange" }}>
              Missing some of uppercase, lowercase,
              number, or special character(s).
            </span>
          )}
          {strength === "strong" && (
            <span style={{ color: "green" }}>
              Adequate - includes uppercase, lowercase, number, and special
              character(s)
            </span>
          )}
        </PasswordStrengthRequirement>
      </PasswordStrengthRequirements>
    </PasswordStrengthCheck>
  );
};

export default PasswordStrengthCheckUI;
