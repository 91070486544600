import styled from "styled-components";



export const StyledCarbonoffset = styled.svg`
  position: relative;
  height: 320px;
  min-width: 70%;
  display: flex;
  justify-content: left;
  align-items: left;

  @media (max-width: 1400px) {
    width: 60%;
  }
  @media (max-width: 1200px) {
    width: 30%;
  }

  @media (max-width: 992px) {
    height: 250px;
    width: 100%;
  }

  @media (max-width: 768px) {
    height: 220px;
    width: 100%;
  }

  @media (max-width: 490px) {
    height: 150px;
    width: 100%;
  }

  @media (max-width: 370px) {
    height: 120px;
    width: 100%;
    left: 5px;
    transform: scale(0.95);
  }

  .NO2,
  .CH4,
  .SFC,
  .PFC,
  .HFC,
  .CO2 {
 
    font-size: 10px;
    font-weight: bold;
    fill: #cff;
    animation: flashText 0.7s ease-out alternate infinite;
    opacity: 0;
  }

  .carbonOffset1 {

    font-size: 10px;
    font-weight: bold;
    fill: #cf3;
  }

  .carbonOffset2 {
    font-size: 10px;
    font-weight: bold;
    fill: #cff;
    position: absolute;
    display: inline-block;
    left: 0;
    top: -10%;
    width: 100%;
    height: 120%;
    filter: blur(2.5px);
    content: "";
    opacity: 0;
    animation: flash 0.7s ease-out alternate infinite;
  }

  @keyframes flash {
    to {
      opacity: 1;
    }
  }

  @keyframes flashText {
    to {
      opacity: 0.15;
    }
  }

  .backdrop {
    fill: #6d7971;
    stroke: #6d7971;
  }

  .cert1,
  .cert2,
  .cert3,
  .cert4 {
    stroke: #6d7971;
    fill: #1b2e10;
  }

  .check1 {
    stroke: #cf3;
    opacity: 0.5;
    animation: flash 0.7s ease-out alternate infinite;
  }

.check2 {
    position: absolute;
    display: inline-block;
    left: 0;
    top: -10%;
    width: 100%;
    height: 120%;
    filter: blur(1.5px);
 
    stroke: #cff;
    opacity: 0;
    animation: flash 0.7s ease-out alternate infinite;
  }


  }
`;
