import React, { useState } from "react";
import {
  FAQContainer,
  Container,
  Item,
  SectionTitle,
  Question,
  Answer,
} from "./StyledFAQ";
import { ArrowIcon } from "./Icons";
import AnimatedLamp from "./LampAnimation";
import image14 from "../assets/images/background2.svg";

const FAQSection = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (questionId) => {
    setOpenQuestion((prevOpenQuestion) =>
      prevOpenQuestion === questionId ? null : questionId
    );
  };

  return (
    <FAQContainer
      style={{
        backgroundImage: `url(${image14})`,
        backgroundSize: "cover",
        backgroundPosition: window.innerWidth > 992 ? "" : "top right",
      }}
    >
      <Container>
        <Item
          style={{
            color: "#6d7971",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AnimatedLamp />
        </Item>
        <Item>
          <SectionTitle>Company</SectionTitle>
          <Question onClick={() => toggleQuestion("keplerSolutions")}>
            What is Kepler Solutions?
            <ArrowIcon isOpen={openQuestion === "keplerSolutions"} />
          </Question>
          <Answer
            isOpen={openQuestion === "keplerSolutions"}
            style={{
              boxShadow: "-5px 0px 0px 0px #ccff33",
              background: "#fff",
            }}
          >
            We are an Africa-focused clean energy solutions provider helping
            businesses & households access clean & cost-effective
            electricity/energy via off-grid, mini-grid & grid-based solutions
            with or without credit financing. This strikes at the heart of
            solving Africa's unreliable power quality and poor access issues. We
            offer competitive terms, innovative and bespoke solutions to power
            your aspirations.
          </Answer>

          <Question onClick={() => toggleQuestion("countries")}>
            In what countries can I enjoy Kepler Solutions' services?
            <ArrowIcon isOpen={openQuestion === "countries"} />
          </Question>
          <Answer
            isOpen={openQuestion === "countries"}
            style={{
              boxShadow: "-5px 0px 0px 0px #ccff33",
              background: "#fff",
            }}
          >
            Egypt and Nigeria. Expect us in the rest of Africa soon.
          </Answer>

          <Question onClick={() => toggleQuestion("energyAudit")}>
            What is an energy audit?
            <ArrowIcon isOpen={openQuestion === "energyAudit"} />
          </Question>
          <Answer
            isOpen={openQuestion === "energyAudit"}
            style={{
              boxShadow: "-5px 0px 0px 0px #ccff33",
              background: "#fff",
            }}
          >
            Also known as an energy assessment, energy analysis, or power audit,
            an energy audit allows us to evaluate and assess the energy usage
            and efficiency at your facility/property to identify opportunities
            for energy savings and efficiency improvements to reduce energy
            costs, and lower greenhouse gas emissions. If you apply for credit
            financing or Power-as-a-Service, and pre-qualify, we will provide a
            complementary energy audit of the subject business/home location as
            part of our process.
          </Answer>

          <Question onClick={() => toggleQuestion("carbonOffsets")}>
            What are carbon offsets?
            <ArrowIcon isOpen={openQuestion === "carbonOffsets"} />
          </Question>
          <Answer
            isOpen={openQuestion === "carbonOffsets"}
            style={{
              boxShadow: "-5px 0px 0px 0px #ccff33",
              background: "#fff",
            }}
          >
            Carbon offsets are a mechanism to counterbalance or "offset" carbon
            dioxide and other greenhouse gas (GHG) emissions produced by
            individuals, businesses, or organizations. They are used as a tool
            to mitigate the environmental impact of activities that release
            greenhouse gases into the atmosphere, such as burning fossil fuels
            for energy or transportation. By installing and using clean energy,
            we can lower your interest rates hence costs by receiving carbon
            credits from our partners for abated emissions as part of a
            comprehensive strategy to combat climate change and promote
            sustainability.
          </Answer>
        </Item>

        <Item>
          <SectionTitle>Credit Financing</SectionTitle>
          <Question onClick={() => toggleQuestion("creditFinancing")}>
            What does credit financing cover?
            <ArrowIcon isOpen={openQuestion === "creditFinancing"} />
          </Question>
          <Answer
            isOpen={openQuestion === "creditFinancing"}
            style={{
              boxShadow: "-5px 0px 0px 0px #4F7302",
              background: "#fff",
            }}
          >
            Credit financing at Kepler Solutions covers power solutions like
            solar panels, inverters, and batteries. We provide these to
            pre-qualified customers who pay back over a time-frame at an agreed
            interest structure.
          </Answer>
          <Question onClick={() => toggleQuestion("creditFinancingAccess")}>
            How do I access financing?
            <ArrowIcon isOpen={openQuestion === "creditFinancingAccess"} />
          </Question>
          <Answer
            isOpen={openQuestion === "creditFinancingAccess"}
            style={{
              boxShadow: "-5px 0px 0px 0px #4F7302",
              background: "#fff",
            }}
          >
            You can apply here by providing key information that allows you to
            get pre-qualified, a necessary step to enjoy our credit financing.
            It's a relatively easy and quick process.
          </Answer>
        </Item>

        <Item>
          <SectionTitle>Power-as-a-Service</SectionTitle>
          <Question onClick={() => toggleQuestion("powerAsAService")}>
            What does PaaS entail?
            <ArrowIcon isOpen={openQuestion === "powerAsAService"} />
          </Question>
          <Answer
            isOpen={openQuestion === "powerAsAService"}
            style={{
              boxShadow: "-5px 0px 0px 0px #e75139",
              background: "#fff",
            }}
          >
            Our PaaS (Power-as-a-Service) allows us to take over power supply
            operations in your facility (business locations - factories,
            warehouses, office buildings) and homes (residential estates) so
            that you can enjoy reliable power supply at affordable and
            no-surprise rates. This may include Kepler Solutions deploying its
            own solutions at your site.
          </Answer>
          <Question onClick={() => toggleQuestion("powerAsAServiceHow")}>
            How do I enjoy PaaS?
            <ArrowIcon isOpen={openQuestion === "powerAsAServiceHow"} />
          </Question>
          <Answer
            isOpen={openQuestion === "powerAsAServiceHow"}
            style={{
              boxShadow: "-5px 0px 0px 0px #e75139",
              background: "#fff",
            }}
          >
            You can apply here by providing information on your needs including
            service location and power requirements and we will contact you to
            proceed with setting up your PaaS in no time.
          </Answer>
        </Item>

        <Item>
          <SectionTitle>Direct Purchase</SectionTitle>
          <Question onClick={() => toggleQuestion("directPurchase")}>
            What does direct purchase entail?
            <ArrowIcon isOpen={openQuestion === "directPurchase"} />
          </Question>
          <Answer
            isOpen={openQuestion === "directPurchase"}
            style={{
              boxShadow: "-5px 0px 0px 0px #58a79d",
              background: "#fff",
            }}
          >
            Direct purchase allows you to immediately buy clean power solutions
            including solar panels, inverters, and batteries. We are also
            working tirelessly to introduce other energy-saving and reliable
            products at the forefront of rapid R&D progress in this space.
          </Answer>
          <Question onClick={() => toggleQuestion("directPurchaseHow")}>
            What products can I buy?
            <ArrowIcon isOpen={openQuestion === "directPurchaseHow"} />
          </Question>
          <Answer
            isOpen={openQuestion === "directPurchaseHow"}
            style={{
              boxShadow: "-5px 0px 0px 0px #58a79d",
              background: "#fff",
            }}
          >
            Clean power solutions including solar panels, inverters, and
            batteries. We are working tirelessly to introduce other types of
            energy-saving and reliable products at the forefront of the rapid
            pace of R&D in this space.
          </Answer>
        </Item>

        <Item>
          <SectionTitle>Mini-grids</SectionTitle>
          <Question onClick={() => toggleQuestion("miniGridsWhat")}>
            What are Mini-grids?
            <ArrowIcon isOpen={openQuestion === "miniGridsWhat"} />
          </Question>
          <Answer
            isOpen={openQuestion === "miniGridsWhat"}
            style={{
              boxShadow: "-5px 0px 0px 0px #6D7971",
              background: "#fff",
            }}
          >
            Mini-grids are localized electricity distribution systems that
            generate and distribute power to a small, specific area or
            community. They operate independently or in conjunction with the
            main grid and typically serve areas where extending the central grid
            might be logistically challenging or uneconomical. Mini-grids
            consist of local power generation sources, often based on renewable
            energy like solar, wind, or hydro, combined with energy storage and
            distribution infrastructure, typically between 10kW and 10MW in
            capacity. They provide reliable electricity access to communities
            that might otherwise lack consistent power supply, fostering
            economic development and improving quality of life. Rural
            electrification with clean energy mini-grids is a fast way to supply
            highly reliable electricity to rural towns and villages.
          </Answer>
          <Question onClick={() => toggleQuestion("miniGrids")}>
            How does partnering with Kepler Solutions on Mini-grids work?
            <ArrowIcon isOpen={openQuestion === "miniGrids"} />
          </Question>
          <Answer
            isOpen={openQuestion === "miniGrids"}
            style={{
              boxShadow: "-5px 0px 0px 0px #6D7971",
              background: "#fff",
            }}
          >
            We set up partnerships with real estate project developers,
            land-owners, and power utility companies to install clean power
            solutions and enable power offtake/sale. You can contact us here to
            inquire more.
          </Answer>
        </Item>
      </Container>
    </FAQContainer>
  );
};

export default FAQSection;
