import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../utils/fireBaseConfig";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axiosInstance from "../../utils/axiosInstance";
import ReactCountryFlag from "react-country-flag";

import { GlobalStyle } from "../StyledComponents";
import logo from "../../assets/logo/kepler_logo.svg";
import image27 from "../../assets/images/background10.svg";
import {
  SettingsIcon,
  LogoutIcon,
  ArrowMiniIcon,
  ViewMoreIcon,
  MiniHomeIcon,
  MiniBusinessIcon,
  MiniPowerIcon,
  MiniGuideIcon,
  MiniGridIcon,
  MiniCatalogIcon,
  MiniLeasePayIcon,
  MiniPurchasePayIcon,
  CopyIcon,
  MiniCloseIcon,
} from "../Icons";
import AuditTable from "./AuditTable";
import DeployedTable from "./DeployedTable";
import LeaseTable from "./LeaseTable";
import PaymentTable from "./PaymentTable";
import AuditOrder from "./AuditOrder";
import DirectOrder from "./DirectOrder";
import DemoTable from "./DemoTable";
import MiniGridTable from "./MiniGridTable";
import PaaSTable from "./PaaSTable";
import PaymentInfo from "./PaymentInfo";
import { Grid } from "react-loader-spinner";

import image23 from "../../assets/images/minibackground1.svg";
import image24 from "../../assets/images/minibackground2.svg";
import image25 from "../../assets/images/minibackground3.svg";
import image26 from "../../assets/images/minibackground4.svg";

const StripAnimation = keyframes`
  0% { background-position: 200% 0; }
  100% { background-position: -200% 0; }
`;

const DashboardContainer = styled.div`
  padding: 20px;
  position: relative; /* Add relative positioning */
`;

const TopSection = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;

  @media (max-width: 992px) {
    font-size: 15px;
  }

  @media (max-width: 360px) {
    font-size: 13px;
  }
`;

const WelcomeSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding-bottom: 20px;
`;

const WelcomeMessage = styled.div`
  font-size: 20px;
  color: #ffffff;
  background-color: #223614;
  padding: 10px 30px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  position: absolute; /* Add absolute positioning */
  right: 0;
  background: linear-gradient(
    to right,
    #222a35,
    #1b2e10 20%,
    #336512 40%,
    #1b2e10 60%,
    #223614 80%,
    #222a35 100%
  );
  background-size: 200% 100%; /* Make the background size double the element width */
  animation: ${StripAnimation} 10s linear infinite; /* Apply the strip animation */

  @media (max-width: 992px) {
    font-size: 15px;
    padding: 10px 10px;
  }

  @media (max-width: 360px) {
    font-size: 13px;
    padding: 10px 10px;
  }
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 992px) {
    font-size: 13px;
  }

  @media (max-width: 360px) {
    font-size: 11px;
  }
`;

const Section = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e8ebf0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`;

const LoadingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Dropdown = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const LeaseDataCell = styled.td`
  width: ${(props) => (props.isMobile ? "20%" : "30%")};
  vertical-align: top;
  white-space: normal;
  ${(props) =>
    props.flash &&
    css`
      animation: flashRed 1s infinite;
    `}

  @keyframes flashRed {
    0% {
      color: inherit;
    }
    49% {
      color: inherit;
    }
    50% {
      color: red;
    }
    100% {
      color: red;
    }
  }
`;

const ViewMoreButton = styled.button`
  margin-top: 10px;
  margin-left: ${(props) => (props.isMobile ? "37%" : "46%")};
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  font-size: 13px;
  white-space: nowrap;

  &:hover:not(:focus):not(:active) {
    background-color: #e8ffa4;
    text-shadow: 0 0 1px #1b2e10;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 992px) {
    font-size: 11px;
  }
`;

const BottomSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: linear-gradient(to bottom right, #fff, #eef7f9);
  border: 1px solid #c0cbd1;
  margin-top: 50px;
  margin-bottom: 30px;
  border-radius: 5px;
  padding-top: 20px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 30px;
  @media (max-width: 992px) {
    font-size: 13px;
  }

  @media (max-width: 360px) {
    font-size: 11px;
  }
`;

const BottomSection = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  justify-content: space-between;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.isMobile ? "15px" : "10px")};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: ${(props) => (props.isMobile ? "25px" : "12px")};
  padding-right: ${(props) => (props.isMobile ? "25px" : "12px")};
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
`;

const Button = styled.button`
  margin: 5px;
  width: 200px;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #223614;
  color: #fff;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);

  &:hover {
    svg {
      color: #ccff33;
    }
  }

  &:active {
    color: #ccff33;
    background-color: #1b2e10;
  }
`;

const DetailsToggle = ({
  isOpen,
  toggleDropdown,
  children,
  isMobile,
  isOutstandingPayment,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <Dropdown
        onClick={toggleDropdown}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        Details{" "}
        <ArrowMiniIcon
          isOpen={isOpen}
          isHovered={isHovered}
          isMobile={isMobile}
          isOutstandingPayment={isOutstandingPayment}
        />
      </Dropdown>
      {isOpen && children}
    </div>
  );
};

const LeasePopup = ({ paymentInfo, isMobile, country, onClose }) => {
  const [selectedCountry, setSelectedCountry] = useState(country);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  const copyToClipboard = (text) => {
    let notification = document.createElement("div");
    notification.textContent = "Copied to clipboard";
    notification.style.cssText = `
    position: fixed;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    width: 190px;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.5s ease;
  `;
    document.body.appendChild(notification);

    if (!navigator.clipboard) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    } else {
      navigator.clipboard.writeText(text);
    }
    setTimeout(() => {
      notification.style.opacity = 1;
    }, 100);
    setTimeout(() => {
      notification.style.opacity = 0;
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 500);
    }, 2500);
  };

  const countryPaymentInfo = paymentInfo[selectedCountry] || {};
  const leasePaymentInfo = countryPaymentInfo.lease || {};
  const countryCode = paymentInfo[selectedCountry]?.countryCode || "";
  const formattedCountryName = selectedCountry.split("_").join(" ");
  const dropdownWidth = `${Math.max(formattedCountryName.length * 12, 85)}px`;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background:
          "linear-gradient(to bottom right, #fff, #fff, #fff, #eef7f9, #fff,#fff)",
        padding: "20px",
        borderRadius: "7px",
        zIndex: 5000,
        width: isMobile ? "80vw" : "40vw",
        minHeight: isMobile ? "25vh" : "20vh",
      }}
    >
      <div
        style={{
          fontSize: "18px",
        }}
      >
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            borderRadius: "50%",
            marginBottom: "3px",
            width: "1.2em",
            height: "1.2em",
          }}
        />
        <select
          value={selectedCountry}
          onChange={handleCountryChange}
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            width: dropdownWidth,
          }}
        >
          {Object.keys(paymentInfo).map((country) => (
            <option key={country} value={country}>
              {" "}
              <ReactCountryFlag
                countryCode={paymentInfo[country]?.countryCode}
                svg
              />
              {country.split("_").join(" ")}
            </option>
          ))}
        </select>{" "}
        Lease & PaaS Payment
      </div>
      <div style={{ fontSize: isMobile ? "15px" : "16px" }}>
        <div
          style={{
            color: "#8497a0",
          }}
        >
          Pay to:
        </div>
        {leasePaymentInfo.bankName && (
          <div
            style={{
              borderTop: "1px solid #8497a0",
              marginTop: "5px",
              paddingTop: "15px",
            }}
          >
            Bank Name: {leasePaymentInfo.bankName}
          </div>
        )}
        {leasePaymentInfo.routingNumber && (
          <div>
            Routing Number: {leasePaymentInfo.routingNumber}
            <CopyIcon
              onClick={() => copyToClipboard(leasePaymentInfo.routingNumber)}
            />
          </div>
        )}
        {leasePaymentInfo.sortCode && (
          <div>
            Sort Code: {leasePaymentInfo.sortCode}{" "}
            <CopyIcon
              onClick={() => copyToClipboard(leasePaymentInfo.sortCode)}
            />
          </div>
        )}
        {leasePaymentInfo.transitNumber && (
          <div>
            Transit Number: {leasePaymentInfo.transitNumber}{" "}
            <CopyIcon
              onClick={() => copyToClipboard(leasePaymentInfo.transitNumber)}
            />
          </div>
        )}
        {leasePaymentInfo.institutionNumber && (
          <div>
            Institution Number: {leasePaymentInfo.institutionNumber}{" "}
            <CopyIcon
              onClick={() =>
                copyToClipboard(leasePaymentInfo.institutionNumber)
              }
            />
          </div>
        )}
        {leasePaymentInfo.accountNumber && (
          <div>
            Account Number: {leasePaymentInfo.accountNumber}{" "}
            <CopyIcon
              onClick={() => copyToClipboard(leasePaymentInfo.accountNumber)}
            />
          </div>
        )}
        {leasePaymentInfo.reference && (
          <div>Reference: {leasePaymentInfo.reference}</div>
        )}
      </div>
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
          cursor: "pointer",
          border: "none",
          backgroundColor: "transparent",
        }}
      >
        <MiniCloseIcon />
      </button>
    </div>
  );
};

const PurchaseOrderPopup = ({ paymentInfo, isMobile, country, onClose }) => {
  const [selectedCountry, setSelectedCountry] = useState(country);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  const copyToClipboard = (text) => {
    let notification = document.createElement("div");
    notification.textContent = "Copied to clipboard";
    notification.style.cssText = `
    position: fixed;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.5s ease;
  `;
    document.body.appendChild(notification);

    if (!navigator.clipboard) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
    } else {
      navigator.clipboard.writeText(text);
    }
    setTimeout(() => {
      notification.style.opacity = 1;
    }, 100);

    setTimeout(() => {
      notification.style.opacity = 0;
      setTimeout(() => {
        document.body.removeChild(notification);
      }, 500);
    }, 1500);
  };

  const countryPaymentInfo = paymentInfo[selectedCountry] || {};
  const purchaseOrderPaymentInfo = countryPaymentInfo.purchaseOrder || {};
  const countryCode = paymentInfo[selectedCountry]?.countryCode || "";
  const formattedCountryName = selectedCountry.split("_").join(" ");
  const dropdownWidth = `${Math.max(formattedCountryName.length * 12, 85)}px`;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background:
          "linear-gradient(to top right, #fff, #fff, #fff, #eef7f9, #fff,#fff)",
        padding: "20px",
        borderRadius: "7px",
        zIndex: 5000,
        minWidth: isMobile ? "80vw" : "40vw",
        minHeight: isMobile ? "25vh" : "20vh",
      }}
    >
      <div
        style={{
          fontSize: "18px",
        }}
      >
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            borderRadius: "50%",
            marginBottom: "3px",
            width: "1.2em",
            height: "1.2em",
          }}
        />
        <select
          value={selectedCountry}
          onChange={handleCountryChange}
          style={{
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            width: dropdownWidth,
          }}
        >
          {Object.keys(paymentInfo).map((country) => (
            <option key={country} value={country}>
              <ReactCountryFlag
                countryCode={paymentInfo[country]?.countryCode}
                svg
              />
              {country.split("_").join(" ")}
            </option>
          ))}
        </select>{" "}
        Purchase Order Payment
      </div>
      <div style={{ fontSize: isMobile ? "15px" : "16px" }}>
        <div
          style={{
            color: "#8497a0",
          }}
        >
          Pay to:
        </div>
        {purchaseOrderPaymentInfo.bankName && (
          <div
            style={{
              borderTop: "1px solid #8497a0",
              marginTop: "5px",
              paddingTop: "15px",
            }}
          >
            Bank Name: {purchaseOrderPaymentInfo.bankName}
          </div>
        )}
        {purchaseOrderPaymentInfo.routingNumber && (
          <div>
            Routing Number: {purchaseOrderPaymentInfo.routingNumber}{" "}
            <CopyIcon
              onClick={() =>
                copyToClipboard(purchaseOrderPaymentInfo.routingNumber)
              }
            />
          </div>
        )}
        {purchaseOrderPaymentInfo.sortCode && (
          <div>
            Sort Code: {purchaseOrderPaymentInfo.sortCode}{" "}
            <CopyIcon
              onClick={() => copyToClipboard(purchaseOrderPaymentInfo.sortCode)}
            />
          </div>
        )}
        {purchaseOrderPaymentInfo.transitNumber && (
          <div>
            Transit Number: {purchaseOrderPaymentInfo.transitNumber}{" "}
            <CopyIcon
              onClick={() =>
                copyToClipboard(purchaseOrderPaymentInfo.transitNumber)
              }
            />
          </div>
        )}
        {purchaseOrderPaymentInfo.institutionNumber && (
          <div>
            Institution Number: {purchaseOrderPaymentInfo.institutionNumber}{" "}
            <CopyIcon
              onClick={() =>
                copyToClipboard(purchaseOrderPaymentInfo.institutionNumber)
              }
            />
          </div>
        )}
        {purchaseOrderPaymentInfo.accountNumber && (
          <div>
            Account Number: {purchaseOrderPaymentInfo.accountNumber}{" "}
            <CopyIcon
              onClick={() =>
                copyToClipboard(purchaseOrderPaymentInfo.accountNumber)
              }
            />
          </div>
        )}
        {purchaseOrderPaymentInfo.reference && (
          <div>Reference: {purchaseOrderPaymentInfo.reference}</div>
        )}
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            cursor: "pointer",
            border: "none",
            backgroundColor: "transparent",
          }}
        >
          <MiniCloseIcon />
        </button>
      </div>
    </div>
  );
};

const Backdrop = ({ onClick }) => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "150%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 4001,
      backdropFilter: "blur(2px)",
      cursor: "pointer",
    }}
    onClick={onClick}
  />
);

const Dashboard = ({ user, setUser, infoNotification }) => {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [leasedSolutions, setLeasedSolutions] = useState([]);
  const [leaseDetails, setLeaseDetails] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loadingLeasedSolutions, setLoadingLeasedSolutions] = useState(false);
  const [loadingLeaseDetails, setLoadingLeaseDetails] = useState(false);
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);
  const [loadingOrderHistory, setLoadingOrderHistory] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [openIndex1, setOpenIndex1] = useState(-1);
  const [openIndex2, setOpenIndex2] = useState(-1);
  const [openIndex3, setOpenIndex3] = useState(-1);
  const [openIndex4, setOpenIndex4] = useState(-1);
  const [openIndex5, setOpenIndex5] = useState(-1);
  const [displayedLeasedSolutions, setDisplayedLeasedSolutions] = useState(5);
  const [displayedLeaseDetails, setDisplayedLeaseDetails] = useState(5);
  const [displayedPaymentHistory, setDisplayedPaymentHistory] = useState(5);
  const [displayedOrderHistory, setDisplayedOrderHistory] = useState(5);
  const [displayedRequests, setDisplayedRequests] = useState(5);
  const [showLeasePopup, setShowLeasePopup] = useState(false);
  const [showPurchaseOrderPopup, setShowPurchaseOrderPopup] = useState(false);
  const [country, setCountry] = useState("Nigeria");
  const navigate = useNavigate();
  const [isOutstandingPayment, setIsOutstandingPayment] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 992 });

  const handleNavbarLink = () => {
    navigate("/");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleUserSettings = () => {
    navigate("/user-settings");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleLogout = () => {
    signOut(auth, user)
      .then(() => {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
        infoNotification(`${user.displayName} logged out successfully!`);
        setUser(null);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        try {
          const response = await axiosInstance.get(
            `api/user/${currentUser.uid}`
          );
          if (response.status === 200) {
            setUser({ ...currentUser, ...response.data });
          } else {
          }
        } catch (error) {}
      }
    });
    return () => unsubscribeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewMoreLeasedSolutions = () => {
    setDisplayedLeasedSolutions((prev) => prev + 5);
  };

  const handleViewMoreLeaseDetails = () => {
    setDisplayedLeaseDetails((prev) => prev + 5);
  };

  const handleViewMorePaymentHistory = () => {
    setDisplayedPaymentHistory((prev) => prev + 5);
  };

  const handleViewMoreOrderHistory = () => {
    setDisplayedOrderHistory((prev) => prev + 5);
  };

  const handleViewMoreRequests = () => {
    setDisplayedRequests((prev) => prev + 5);
  };

  const toggleDropdown1 = (index) => {
    setOpenIndex1((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const toggleDropdown2 = (index) => {
    setOpenIndex2((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const toggleDropdown3 = (index) => {
    setOpenIndex3((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const toggleDropdown4 = (index) => {
    setOpenIndex4((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const toggleDropdown5 = (index) => {
    setOpenIndex5((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatMoneyNumber = (number) => {
    if (number === null || number === undefined) return "-";
    return Number(number.toFixed(2)).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    const fetchLeasedSolutions = async () => {
      setLoadingLeasedSolutions(true);
      try {
        const response = await axiosInstance.get("/api/application-solutions", {
          headers: {
            "Content-Type": "application/json",
            "user-uid": user.uid,
          },
        });
        const leasedSolutionsData = response.data;
        const sortedLeasedSolutions = leasedSolutionsData.sort((a, b) => {
          const dateA = new Date(
            a.approveDate || a.applyDate || a.instalDate || 0
          );
          const dateB = new Date(
            b.approveDate || b.applyDate || b.instalDate || 0
          );
          return dateB - dateA;
        });

        setLeasedSolutions(sortedLeasedSolutions);
      } catch (error) {
        setError(error.message);
      }
      setLoadingLeasedSolutions(false);
    };

    fetchLeasedSolutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid]);

  useEffect(() => {
    const fetchLeaseDetails = async () => {
      setLoadingLeaseDetails(true);
      try {
        const response = await axiosInstance.get("/api/lease-details", {
          headers: {
            "Content-Type": "application/json",
            "user-uid": user.uid,
          },
        });

        const leaseDetailsData = response.data;

        // Process data: Add duePayment and isOutstandingPayment properties
        const leaseDetailsWithOutstandingPayment = leaseDetailsData.map(
          (lease) => {
            const isOutstandingPayment =
              lease.outstandingPayment > lease.monthlyPayment;

            return {
              ...lease,
              duePayment:
                isNaN(lease.outstandingPayment) ||
                lease.outstandingPayment === 0
                  ? lease.monthlyPayment
                  : lease.outstandingPayment,
              isOutstandingPayment: isOutstandingPayment,
            };
          }
        );
        const sortedLeaseDetails = leaseDetailsWithOutstandingPayment.sort(
          (a, b) => {
            if (a.status === "closed" && b.status !== "closed") return 1;
            if (a.status !== "closed" && b.status === "closed") return -1;

            return new Date(a.nextPaymentDate) - new Date(b.nextPaymentDate);
          }
        );

        setLeaseDetails(sortedLeaseDetails);
        setIsOutstandingPayment(
          sortedLeaseDetails.map((lease) => lease.isOutstandingPayment)
        );
      } catch (error) {
        setError(error.message);
      }
      setLoadingLeaseDetails(false);
    };

    fetchLeaseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid, paymentHistory]);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      setLoadingPaymentHistory(true);
      try {
        const response = await axiosInstance.get("/api/payment-history", {
          headers: {
            "Content-Type": "application/json",
            "user-uid": user.uid,
          },
        });
        setPaymentHistory(response.data);
      } catch (error) {
        setError(error.message);
      }
      setLoadingPaymentHistory(false);
    };

    fetchPaymentHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid]);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      setLoadingOrderHistory(true);
      try {
        const response = await axiosInstance.get("/api/order-history", {
          headers: {
            "Content-Type": "application/json",
            "user-uid": user.uid,
          },
        });
        setOrderHistory(response.data);
      } catch (error) {
        setError(error.message);
      }
      setLoadingOrderHistory(false);
    };
    fetchOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid]);

  useEffect(() => {
    const fetchRequests = async () => {
      setLoadingRequests(true);
      try {
        const response = await axiosInstance.get("/api/requests", {
          headers: {
            "Content-Type": "application/json",
            "user-uid": user.uid,
          },
        });
        setRequests(response.data);
      } catch (error) {
        setError(error.message);
      }
      setLoadingRequests(false);
    };
    fetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch("https://freeipapi.com/api/json");
        const data = await response.json();
        setCountry(data.country || "Nigeria");
      } catch (error) {
        setCountry("Nigeria");
      }
    };
    fetchCountry();
  }, []);

  const handlePaymentButtonClick = (buttonName) => {
    switch (buttonName) {
      case "Lease & PaaS Payment":
        setShowLeasePopup(true);
        break;
      case "Purchase Order Payment":
        setShowPurchaseOrderPopup(true);
        break;
      default:
        break;
    }
  };

  const handleButtonClick = (buttonName) => {
    switch (buttonName) {
      case "Business Application":
        navigate(`/business-form`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
        break;
      case "Home Application":
        navigate(`/home-form`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
        break;
      case "Guided Purchase":
        navigate(`/guided-purchase`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
        break;
      case "Direct Purchase":
        navigate(`/direct-purchase`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
        break;
      case "Power-as-a-Service":
        navigate(`/power-as-a-service`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
        break;
      case "Mini-grids":
        navigate(`/minigrid`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {" "}
      <GlobalStyle />
      <DashboardContainer
        style={{
          backgroundImage: `url(${image27})`,
          backgroundSize: "auto",
          backgroundPosition: "top",
        }}
      >
        <WelcomeSection>
          <img
            src={logo}
            alt="Logo"
            width="100vmax"
            onClick={() => {
              handleNavbarLink();
            }}
            style={{
              cursor: "pointer",
            }}
          />
          <div style={{}}>
            {" "}
            <TopSection>
              <p
                style={{
                  cursor: "pointer",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  textShadow: isHovered1
                    ? "0px 0px 1px rgba(27, 46, 16, 1)"
                    : "none",
                  backgroundColor: isHovered1 ? "#e8ffa4" : "#e3e9e9",
                  border: "1px solid #ccc",
                  width: "100px",
                }}
                onMouseEnter={() => setIsHovered1(true)}
                onMouseLeave={() => setIsHovered1(false)}
                onClick={() => {
                  handleUserSettings();
                }}
              >
                Settings <SettingsIcon />
              </p>
              <p
                style={{
                  marginLeft: window.innerWidth > 992 ? "50px" : "30px",
                  cursor: "pointer",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  textShadow: isHovered2
                    ? "0px 0px 1px rgba(27, 46, 16, 1)"
                    : "none",
                  backgroundColor: isHovered2 ? "#e8ffa4" : "#e3e9e9",
                  border: "1px solid #ccc",
                  width: window.innerWidth > 992 ? "105px" : "100px",
                }}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
                onClick={() => {
                  handleLogout();
                }}
              >
                Log out <LogoutIcon />
              </p>
            </TopSection>
            <WelcomeMessage>
              {isMobile ? (
                <span>Welcome, {user.displayName}</span>
              ) : (
                <span>Welcome to your account, {user.displayName}</span>
              )}
            </WelcomeMessage>
          </div>
        </WelcomeSection>
        <SectionsContainer>
          <Section
            style={{
              borderTop: "4px solid #ccff33",
            }}
          >
            <h5>Leased Solutions</h5>
            {loadingLeasedSolutions ||
            loadingLeaseDetails ||
            loadingPaymentHistory ||
            loadingOrderHistory ||
            loadingRequests ? (
              <LoadingContainer>
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "49%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgb(0,0,0,0.3)",
                    zIndex: 3000,
                  }}
                >
                  <Grid
                    height={20}
                    width={20}
                    color="#ccff33"
                    ariaLabel="grid-loading"
                    radius={12.5}
                    wrapperStyle={{}}
                    wrapperClass="grid-loader"
                    visible={true}
                  />
                </div>
              </LoadingContainer>
            ) : leasedSolutions.length === 0 ? (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#a7baba",
                }}
              >
                No leased solution. Scroll down to make an application.
              </p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Application
                      </th>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Status
                      </th>
                      <th style={{ width: isMobile ? "60%" : "40%" }}>
                        Solutions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {leasedSolutions
                      .slice(0, displayedLeasedSolutions)
                      .map((solution, index) => (
                        <tr key={index} className="userDashboardSection1">
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            {openIndex1 === index ? (
                              <>
                                <div>
                                  {solution.applicationType
                                    .charAt(0)
                                    .toUpperCase() +
                                    solution.applicationType.slice(1)}
                                </div>

                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  {solution.serviceAddress}
                                </div>
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  {solution.serviceCity},{" "}
                                  {solution.serviceCountry}
                                </div>
                                {solution.leaseId && (
                                  <div
                                    style={{
                                      fontSize: isMobile ? "10px" : "13px",
                                    }}
                                  >
                                    Lease ID: {solution.leaseId}
                                  </div>
                                )}
                              </>
                            ) : (
                              <div>
                                {solution.applicationType
                                  .charAt(0)
                                  .toUpperCase() +
                                  solution.applicationType.slice(1)}
                              </div>
                            )}
                          </td>
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            {openIndex1 === index ? (
                              <>
                                <div>
                                  {solution.status.charAt(0).toUpperCase() +
                                    solution.status.slice(1)}
                                </div>
                                {solution.status === "pending" && (
                                  <div
                                    style={{
                                      fontSize: isMobile ? "10px" : "13px",
                                    }}
                                  >
                                    since {formatDate(solution.applyDate)}
                                  </div>
                                )}
                                {solution.status === "approved" && (
                                  <div
                                    style={{
                                      fontSize: isMobile ? "10px" : "13px",
                                    }}
                                  >
                                    on {formatDate(solution.approveDate)}
                                  </div>
                                )}
                                {solution.status === "installed" && (
                                  <div
                                    style={{
                                      fontSize: isMobile ? "10px" : "13px",
                                    }}
                                  >
                                    on {formatDate(solution.instalDate)}
                                  </div>
                                )}
                                {solution.status === "rejected" && (
                                  <div
                                    style={{
                                      fontSize: isMobile ? "10px" : "13px",
                                    }}
                                  >
                                    on {formatDate(solution.approveDate)}
                                  </div>
                                )}
                              </>
                            ) : (
                              <div>
                                {solution.status.charAt(0).toUpperCase() +
                                  solution.status.slice(1)}
                              </div>
                            )}
                          </td>
                          <td
                            style={{
                              width: isMobile ? "60%" : "40%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            {solution.deployed ? (
                              <DetailsToggle
                                isOpen={openIndex1 === index}
                                isMobile={isMobile}
                                toggleDropdown={() => toggleDropdown1(index)}
                              >
                                <DeployedTable deployed={solution.deployed} />
                              </DetailsToggle>
                            ) : (
                              <DetailsToggle
                                isOpen={openIndex1 === index}
                                isMobile={isMobile}
                                toggleDropdown={() => toggleDropdown1(index)}
                              >
                                <AuditTable audit={solution.audit} />
                              </DetailsToggle>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div>
                  {displayedLeasedSolutions < leasedSolutions.length && (
                    <ViewMoreButton
                      onClick={handleViewMoreLeasedSolutions}
                      isMobile={isMobile}
                    >
                      <ViewMoreIcon />
                      View More
                    </ViewMoreButton>
                  )}
                </div>
              </>
            )}
          </Section>
          <Section
            style={{
              borderTop: "4px solid #4F7302",
            }}
          >
            <h5>Lease Details</h5>
            {loadingLeasedSolutions ||
            loadingLeaseDetails ||
            loadingPaymentHistory ||
            loadingOrderHistory ||
            loadingRequests ? (
              <LoadingContainer>
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "49%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgb(0,0,0,0.3)",
                    zIndex: 3000,
                  }}
                >
                  <Grid
                    height={20}
                    width={20}
                    color="#4F7302"
                    ariaLabel="grid-loading"
                    radius={12.5}
                    wrapperStyle={{}}
                    wrapperClass="grid-loader"
                    visible={true}
                  />
                </div>
              </LoadingContainer>
            ) : leaseDetails.length === 0 ? (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#a7baba",
                }}
              >
                No lease detail. Scroll down to make an application.
              </p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Amount Due
                      </th>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Due Date
                      </th>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>Terms</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaseDetails
                      .slice(0, displayedLeaseDetails)
                      .map((lease, index) => (
                        <tr key={lease._id} className="userDashboardSection2">
                          <LeaseDataCell
                            isMobile={isMobile}
                            flash={isOutstandingPayment[index]}
                          >
                            {lease.status !== "closed"
                              ? `${lease.currency} ${formatMoneyNumber(
                                  lease.duePayment
                                )}`
                              : `${lease.currency} 0`}
                            <div
                              style={{
                                fontSize: isMobile ? "10px" : "13px",
                                color: "#e75139",
                              }}
                            >
                              {lease.status !== "closed" &&
                              (isOutstandingPayment[index] ||
                                new Date(
                                  lease.nextPaymentDate
                                ).toDateString() === new Date().toDateString())
                                ? `Pay Now!`
                                : ``}
                            </div>
                          </LeaseDataCell>
                          <LeaseDataCell
                            isMobile={isMobile}
                            flash={isOutstandingPayment[index]}
                          >
                            {lease.status !== "closed"
                              ? `${formatDate(lease.nextPaymentDate)}`
                              : `None`}
                            <div
                              style={{
                                fontSize: isMobile ? "10px" : "13px",
                                color: "#e75139",
                              }}
                            >
                              {lease.status !== "closed" &&
                              isOutstandingPayment[index]
                                ? `Delinquent!`
                                : ``}
                              {lease.status !== "closed" &&
                              new Date(lease.nextPaymentDate).toDateString() ===
                                new Date().toDateString()
                                ? `Today`
                                : ``}
                            </div>
                          </LeaseDataCell>

                          <td
                            style={{
                              width: isMobile ? "60%" : "40%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            <DetailsToggle
                              isOpen={openIndex2 === index}
                              isOutstandingPayment={isOutstandingPayment[index]}
                              isMobile={isMobile}
                              toggleDropdown={() => toggleDropdown2(index)}
                            >
                              <LeaseTable lease={lease} />
                            </DetailsToggle>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div>
                  {" "}
                  {displayedLeaseDetails < leaseDetails.length && (
                    <ViewMoreButton
                      onClick={handleViewMoreLeaseDetails}
                      isMobile={isMobile}
                    >
                      <ViewMoreIcon />
                      View More
                    </ViewMoreButton>
                  )}
                </div>
              </>
            )}
          </Section>
          <Section
            style={{
              borderTop: "4px solid #e75139",
            }}
          >
            <h5>Payment History</h5>
            {loadingLeasedSolutions ||
            loadingLeaseDetails ||
            loadingPaymentHistory ||
            loadingOrderHistory ||
            loadingRequests ? (
              <LoadingContainer>
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "49%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgb(0,0,0,0.3)",
                    zIndex: 3000,
                  }}
                >
                  <Grid
                    height={20}
                    width={20}
                    color="#e75139"
                    ariaLabel="grid-loading"
                    radius={12.5}
                    wrapperStyle={{}}
                    wrapperClass="grid-loader"
                    visible={true}
                  />
                </div>
              </LoadingContainer>
            ) : paymentHistory.length === 0 ? (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#a7baba",
                }}
              >
                No payment history. Scroll down to make a payment.
              </p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Amount
                      </th>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Payment Date
                      </th>
                      <th style={{ width: isMobile ? "60%" : "40%" }}>
                        Transaction Info
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistory
                      .sort(
                        (a, b) =>
                          new Date(b.paymentDate) - new Date(a.paymentDate)
                      )
                      .slice(0, displayedPaymentHistory)
                      .map((payment, index) => (
                        <tr key={index} className="userDashboardSection3">
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            {payment.currencyCode}{" "}
                            {formatMoneyNumber(payment.amount)}
                          </td>
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            {formatDate(payment.paymentDate)}
                          </td>
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            <DetailsToggle
                              isOpen={openIndex3 === index}
                              isMobile={isMobile}
                              toggleDropdown={() => toggleDropdown3(index)}
                            >
                              <PaymentTable payment={payment} />
                            </DetailsToggle>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div>
                  {" "}
                  {displayedPaymentHistory < paymentHistory.length && (
                    <ViewMoreButton
                      onClick={handleViewMorePaymentHistory}
                      isMobile={isMobile}
                    >
                      <ViewMoreIcon />
                      View More
                    </ViewMoreButton>
                  )}
                </div>
              </>
            )}
          </Section>
          <Section
            style={{
              borderTop: "4px solid #58a79d",
            }}
          >
            <h5>Order History</h5>
            {loadingLeasedSolutions ||
            loadingLeaseDetails ||
            loadingPaymentHistory ||
            loadingOrderHistory ||
            loadingRequests ? (
              <LoadingContainer>
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "49%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgb(0,0,0,0.3)",
                    zIndex: 3000,
                  }}
                >
                  <Grid
                    height={20}
                    width={20}
                    color="#58a79d"
                    ariaLabel="grid-loading"
                    radius={12.5}
                    wrapperStyle={{}}
                    wrapperClass="grid-loader"
                    visible={true}
                  />
                </div>
              </LoadingContainer>
            ) : orderHistory.length === 0 ? (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#a7baba",
                }}
              >
                No order history. Scroll down to place an order.
              </p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Order Type
                      </th>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Status
                      </th>
                      <th style={{ width: isMobile ? "60%" : "40%" }}>
                        History Info
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderHistory
                      .sort((a, b) => {
                        const dateA = new Date(
                          a.directOrderDate || a.auditOrderDate
                        );
                        const dateB = new Date(
                          b.directOrderDate || b.auditOrderDate
                        );
                        return dateB - dateA;
                      })
                      .slice(0, displayedOrderHistory)
                      .map((order, index) => (
                        <tr key={index} className="userDashboardSection4">
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            <>
                              <div>
                                {order.type.charAt(0).toUpperCase() +
                                  order.type.slice(1)}
                              </div>

                              <div
                                style={{
                                  fontSize: isMobile ? "10px" : "13px",
                                }}
                              >
                                placed on{" "}
                                {order.directOrderDate
                                  ? formatDate(order.directOrderDate)
                                  : formatDate(order.auditOrderDate)}
                              </div>
                            </>
                          </td>
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            <>
                              <div>
                                {order.status.charAt(0).toUpperCase() +
                                  order.status.slice(1)}
                              </div>
                              {order.status === "pending" &&
                                order.amountPaid && (
                                  <div
                                    style={{
                                      fontSize: isMobile ? "10px" : "13px",
                                    }}
                                  ></div>
                                )}
                              {order.status === "pending" &&
                                !order.amountPaid && (
                                  <div
                                    style={{
                                      fontSize: isMobile ? "10px" : "13px",
                                      color: "#e75139",
                                    }}
                                  >
                                    payment
                                  </div>
                                )}
                              {order.status === "approved" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  on {formatDate(order.approvalDate)}
                                </div>
                              )}
                              {order.status === "dispatched" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  on {formatDate(order.dispatchDate)}
                                </div>
                              )}
                              {order.status === "delivered" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  on {formatDate(order.deliveryDate)}
                                </div>
                              )}
                              {order.status === "installed" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  on {formatDate(order.installationDate)}
                                </div>
                              )}
                              {order.status === "cancelled" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  on {formatDate(order.cancellationDate)}
                                </div>
                              )}
                            </>
                          </td>
                          <td
                            style={{
                              width: isMobile ? "60%" : "40%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            {order.type === "direct" ? (
                              <DetailsToggle
                                isOpen={openIndex4 === index}
                                isMobile={isMobile}
                                toggleDropdown={() => toggleDropdown4(index)}
                              >
                                <DirectOrder
                                  order={order}
                                  isMobile={isMobile}
                                />
                              </DetailsToggle>
                            ) : order.type === "guided" ? (
                              <DetailsToggle
                                isOpen={openIndex4 === index}
                                isMobile={isMobile}
                                toggleDropdown={() => toggleDropdown4(index)}
                              >
                                <AuditOrder order={order} isMobile={isMobile} />
                              </DetailsToggle>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div>
                  {displayedOrderHistory < orderHistory.length && (
                    <ViewMoreButton
                      onClick={handleViewMoreOrderHistory}
                      isMobile={isMobile}
                    >
                      <ViewMoreIcon />
                      View More
                    </ViewMoreButton>
                  )}
                </div>
              </>
            )}
          </Section>
          <Section
            style={{
              borderTop: "4px solid #6D7971",
            }}
          >
            <h5>Requests</h5>
            {loadingLeasedSolutions ||
            loadingLeaseDetails ||
            loadingPaymentHistory ||
            loadingOrderHistory ||
            loadingRequests ? (
              <LoadingContainer>
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "49%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgb(0,0,0,0.3)",
                    zIndex: 3000,
                  }}
                >
                  <Grid
                    height={20}
                    width={20}
                    color="#6D7971"
                    ariaLabel="grid-loading"
                    radius={12.5}
                    wrapperStyle={{}}
                    wrapperClass="grid-loader"
                    visible={true}
                  />
                </div>
              </LoadingContainer>
            ) : requests.length === 0 ? (
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#a7baba",
                }}
              >
                No request. Scroll down to make a request.
              </p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Request Type
                      </th>
                      <th style={{ width: isMobile ? "20%" : "30%" }}>
                        Status
                      </th>
                      <th style={{ width: isMobile ? "60%" : "40%" }}>
                        Request Info
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests
                      .sort(
                        (a, b) =>
                          new Date(b.submissionTime) -
                          new Date(a.submissionTime)
                      )
                      .map((request, index) => (
                        <tr key={index} className="userDashboardSection5">
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            {openIndex5 === index ? (
                              <>
                                <div>
                                  {request.type === "demoRequests"
                                    ? "Demo"
                                    : request.type === "miniGridRequests"
                                    ? "Mini Grid"
                                    : "Power-as-a-Service"}
                                </div>

                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  submitted on{" "}
                                  {formatDate(request.submissionTime)}
                                </div>
                              </>
                            ) : (
                              <div>
                                {request.type === "demoRequests"
                                  ? "Demo"
                                  : request.type === "miniGridRequests"
                                  ? "Mini Grid"
                                  : "Power-as-a-Service"}
                              </div>
                            )}
                          </td>
                          <td
                            style={{
                              width: isMobile ? "20%" : "30%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            <>
                              <div>
                                {request.status.charAt(0).toUpperCase() +
                                  request.status.slice(1)}
                              </div>
                              {(request.status === "pending" ||
                                request.status === "progressing" ||
                                request.status === "rejected") && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                ></div>
                              )}
                              {request.status === "resolved" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  on {formatDate(request.resolutionDate)}
                                </div>
                              )}
                              {request.status === "active" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  since {formatDate(request.activationDate)}
                                </div>
                              )}
                              {request.status === "inactive" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  since {formatDate(request.inactivationDate)}
                                </div>
                              )}
                              {request.status === "withdrawn" && (
                                <div
                                  style={{
                                    fontSize: isMobile ? "10px" : "13px",
                                  }}
                                >
                                  on {formatDate(request.withdrawalDate)}
                                </div>
                              )}
                            </>
                          </td>
                          <td
                            style={{
                              width: isMobile ? "60%" : "40%",
                              verticalAlign: "top",
                              whiteSpace: "normal",
                            }}
                          >
                            {request.type === "demoRequests" ? (
                              <DetailsToggle
                                isOpen={openIndex5 === index}
                                isMobile={isMobile}
                                toggleDropdown={() => toggleDropdown5(index)}
                              >
                                <DemoTable
                                  request={request}
                                  isMobile={isMobile}
                                />
                              </DetailsToggle>
                            ) : request.type === "miniGridRequests" ? (
                              <DetailsToggle
                                isOpen={openIndex5 === index}
                                isMobile={isMobile}
                                toggleDropdown={() => toggleDropdown5(index)}
                              >
                                <MiniGridTable
                                  request={request}
                                  isMobile={isMobile}
                                />
                              </DetailsToggle>
                            ) : request.type === "paasRequests" ? (
                              <DetailsToggle
                                isOpen={openIndex5 === index}
                                isMobile={isMobile}
                                toggleDropdown={() => toggleDropdown5(index)}
                              >
                                <PaaSTable
                                  request={request}
                                  isMobile={isMobile}
                                />
                              </DetailsToggle>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div>
                  {displayedRequests < requests.length && (
                    <ViewMoreButton
                      onClick={handleViewMoreRequests}
                      isMobile={isMobile}
                    >
                      <ViewMoreIcon />
                      View More
                    </ViewMoreButton>
                  )}
                </div>
              </>
            )}
          </Section>
        </SectionsContainer>
        <BottomSectionsContainer>
          <h5>Useful Links</h5>
          <BottomSection isMobile={isMobile}>
            <ButtonGroup
              style={{
                backgroundImage: `url(${image26})`,
                backgroundSize: "cover",
              }}
              isMobile={isMobile}
            >
              <h6>Payments</h6>
              <Button
                onClick={() => handlePaymentButtonClick("Lease & PaaS Payment")}
              >
                <MiniLeasePayIcon />
                Lease & PaaS Payment
              </Button>
              <Button
                onClick={() =>
                  handlePaymentButtonClick("Purchase Order Payment")
                }
              >
                <MiniPurchasePayIcon />
                Purchase Order Payment
              </Button>
            </ButtonGroup>

            <ButtonGroup
              style={{
                backgroundImage: `url(${image23})`,
                backgroundSize: "cover",
              }}
              isMobile={isMobile}
            >
              <h6>Credit Financing</h6>
              <Button onClick={() => handleButtonClick("Business Application")}>
                <MiniBusinessIcon />
                Business Application
              </Button>
              <Button onClick={() => handleButtonClick("Home Application")}>
                <MiniHomeIcon />
                Home Application
              </Button>
            </ButtonGroup>

            <ButtonGroup
              style={{
                backgroundImage: `url(${image25})`,
                backgroundSize: "cover",
              }}
              isMobile={isMobile}
            >
              <h6>Order Placement</h6>
              <Button onClick={() => handleButtonClick("Guided Purchase")}>
                <MiniGuideIcon />
                Guided Purchase
              </Button>
              <Button onClick={() => handleButtonClick("Direct Purchase")}>
                <MiniCatalogIcon />
                Direct Purchase
              </Button>
            </ButtonGroup>

            <ButtonGroup
              style={{
                backgroundImage: `url(${image24})`,
                backgroundSize: "cover",
              }}
              isMobile={isMobile}
            >
              <h6>Requests</h6>
              <Button onClick={() => handleButtonClick("Power-as-a-Service")}>
                <MiniPowerIcon />
                Power-as-a-Service
              </Button>
              <Button onClick={() => handleButtonClick("Mini-grids")}>
                <MiniGridIcon />
                Mini-grids
              </Button>
            </ButtonGroup>
          </BottomSection>
          {showLeasePopup && (
            <>
              <Backdrop onClick={() => setShowLeasePopup(false)} />
              <LeasePopup
                paymentInfo={PaymentInfo}
                isMobile={isMobile}
                country={country}
                onClose={() => setShowLeasePopup(false)}
              />
            </>
          )}
          {showPurchaseOrderPopup && (
            <>
              <Backdrop onClick={() => setShowPurchaseOrderPopup(false)} />
              <PurchaseOrderPopup
                paymentInfo={PaymentInfo}
                isMobile={isMobile}
                country={country}
                onClose={() => setShowPurchaseOrderPopup(false)}
              />
            </>
          )}
        </BottomSectionsContainer>
      </DashboardContainer>
    </>
  );
};

export default Dashboard;
