import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import {
  SuccessIcon,
  InfoIcon1,
  WarningIcon,
  ErrorIcon,
  CloseIcon,
} from "./Icons";

const slideDown = keyframes`
  0% {
    top: -170px;
  }
  100% {
    top: 15px;
  }
`;

const slideUp = keyframes`
  0% {
    top: 15px;
  }
  100% {
    top: -170px;
  }
`;

const StyledNotificationBanner = styled.div`
  position: fixed;
  width: 40%;
  min-height: 5rem;
  top: ${(props) => (props.isVisible ? "15px" : "-170px")};
  left: 30%;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.3);
  background-color: ${(props) =>
    props.type === "success"
      ? "#223614"
      : props.type === "warning"
      ? "#223614"
      : props.type === "error"
      ? "#223614"
      : props.type === "info"
      ? "#223614"
      : ""};
  color: ${(props) =>
    props.type === "success"
      ? "#ccff33"
      : props.type === "warning"
      ? "#e28b0d"
      : props.type === "error"
      ? "#dc3545"
      : props.type === "info"
      ? "#bccbca"
      : ""};
  border-left: ${(props) =>
    props.type === "success"
      ? "10px solid #ccff33"
      : props.type === "warning"
      ? "10px solid #e28b0d"
      : props.type === "error"
      ? "10px solid #dc3545"
      : props.type === "info"
      ? "10px solid #bccbca"
      : ""};

  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  animation: ${(props) =>
    props.isVisible
      ? css`
          ${slideDown} 0.3s ease-in
        `
      : css`
          ${slideUp} 0.3s ease-out
        `};
  animation-play-state: ${(props) =>
    props.animationPaused ? "paused" : "running"};

  z-index: 8000;

  @media (max-width: 992px) {
    width: 94%;
    left: 3%;
  }
`;

const StyledIcon = styled.div``;

const StyledTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
    color: ${(props) =>
    props.type === "success"
      ? "#ccff33"
      : props.type === "warning"
      ? "#e28b0d"
      : props.type === "error"
      ? "#dc3545"
      : props.type === "info"
      ? "#bccbca"
      : ""};
`;
const StyledMessage = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #fff;
`;

const NotificationBanner = ({ message, type, isVisible, setIsVisible }) => {
  const [animationPaused, setAnimationPaused] = useState(false);

  const getIcon = () => {
    switch (type) {
      case "success":
        return <SuccessIcon />;
      case "warning":
        return <WarningIcon />;
      case "error":
        return <ErrorIcon />;
      case "info":
        return <InfoIcon1 />;
      default:
        return null;
    }
  };

  const getTitle = () => {
    switch (type) {
      case "success":
        return "Success";
      case "warning":
        return "Warning";
      case "error":
        return "Error";
      case "info":
        return "Info";
      default:
        return null;
    }
  };

  const handleCloseNotification = () => {
    setAnimationPaused(false);
    setIsVisible(false);
  };

  const handleMouseEnter = () => {
    setAnimationPaused(true);
  };

  const handleMouseLeave = () => {
    setAnimationPaused(false);
  };

  const handleTouchStart = () => {
    setAnimationPaused(true);
  };

  const handleTouchEnd = () => {
    setAnimationPaused(false);
  };

  const handlePageBlur = () => {
    setAnimationPaused(true);
  };

  const handlePageFocus = () => {
    setAnimationPaused(false);
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      setAnimationPaused(true);
    } else {
      setAnimationPaused(false);
    }
  };

  useEffect(() => {
    window.addEventListener("blur", handlePageBlur);
    window.addEventListener("focus", handlePageFocus);

    return () => {
      window.removeEventListener("blur", handlePageBlur);
      window.removeEventListener("focus", handlePageFocus);
    };
  }, []);

  useEffect(() => {
    const notificationElement = document.querySelector(".notification-banner");
    notificationElement.addEventListener("touchstart", handleTouchStart);
    notificationElement.addEventListener("touchend", handleTouchEnd);
    notificationElement.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );

    return () => {
      notificationElement.removeEventListener("touchstart", handleTouchStart);
      notificationElement.removeEventListener("touchend", handleTouchEnd);
      notificationElement.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
    };
  }, []);

  return (
    <StyledNotificationBanner
      type={type}
      isVisible={isVisible}
      animationPaused={animationPaused}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="notification-banner"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "10%",
          minHeight: "5rem",
        }}
      >
        <StyledIcon>{getIcon()}</StyledIcon>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          width: "80%",
          height: "100%",
          margin: "3%",
        }}
      >
        <StyledTitle type={type}>{getTitle()}</StyledTitle>
        <StyledMessage>{message}</StyledMessage>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "end",
          width: "10%",
        }}
      >
        {" "}
        <button
          style={{
            border: "none",
            color: "#bccbca",
            backgroundColor: "transparent",
          }}
          onMouseUp={handleCloseNotification}
        >
          <CloseIcon />
        </button>
      </div>
    </StyledNotificationBanner>
  );
};

export default NotificationBanner;
