import styled from "styled-components";
import { ArrowIcon } from "./Icons";

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  margin-top: 0;
  padding-left: 16px;
  padding-right: 16px;
  margin: 20px;
    @media (max-width: 992px) {
    margin 0;
    padding: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Item = styled.div`
  margin: 2%;
  padding: 2%;
  border-radius: 10px;
  justify-content: center;
  @media (min-width: 992px) {
    flex-basis: 40%;
    margin: 1%;
    padding: 1%;
  }
  @media (max-width: 300px) {
    width: 90%;
  }
`;

export const SectionTitle = styled.h2`
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 1.5rem;
  @media (max-width: 400px) {
    font-size: 1.35rem;
  }
  @media (max-width: 300px) {
    font-size: 1rem;
  }
`;

export const Question = styled.div`
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 0px;
  border-bottom: 0.5px solid #6d7971;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`;

export const Arrow = styled(ArrowIcon)`
  display: inline-block;
  transition: transform 0.4s ease;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

export const Answer = styled.div`
  max-height: ${(props) => (props.isOpen ? "500px" : "0")};
  overflow: hidden;
  margin-bottom: 16px;
  transition: max-height 0.5s ease-in-out;
  padding-left: 10px;
  adding-right: 5px;
  margin-top: 5px;
`;
