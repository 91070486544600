export const FORM_STEPS = [
  {
    label: "Energy Demand Estimation",
    fields: [
      {
        name: "applianceName",
        label: "Appliance Name",
      },
      {
        name: "powerRating",
        label: "Power Rating (Watts)",
        type: "number",
      },
      {
        name: "dailyUsageHours",
        label: "Daily Usage (Hours)",
        type: "number",
      },
      {
        name: "quantity",
        label: "Quantity",
        type: "number",
      },
    ],
  },
  {
    label: "Power Factors",
    fields: [
      {
        name: "dailyOperatingHours",
        label: "Operating Hours per Day (Business only)",
        type: "number",
      },
      {
        name: "hourlyElectricitySupply",
        label: "Grid Electricity Supply, Hours per Day",
        type: "number",
        required: true,
      },
      {
        name: "operatingDaysPerWeek",
        label: "Number of Operating Days per Week (Business only)",
        type: "number",
      },
      {
        name: "currency",
        label: "Your Currency",
        required: true,
        options: [
          { label: "AED - United Arab Emirates Dirham", value: "AED" },
          { label: "AOA - Angolan Kwanza", value: "AOA" },
          { label: "BHD - Bahraini Dinar", value: "BHD" },
          { label: "BIF - Burundian Franc", value: "BIF" },
          { label: "BWP - Botswanan Pula", value: "BWP" },
          { label: "CDF - Congolese Franc", value: "CDF" },
          { label: "CVE - Cape Verdean Escudo", value: "CVE" },
          { label: "DJF - Djiboutian Franc", value: "DJF" },
          { label: "DZD - Algerian Dinar", value: "DZD" },
          { label: "EGP - Egyptian Pound", value: "EGP" },
          { label: "ERN - Eritrean Nakfa", value: "ERN" },
          { label: "ETB - Ethiopian Birr", value: "ETB" },
          { label: "GHS - Ghanaian Cedi", value: "GHS" },
          { label: "GMD - Gambian Dalasi", value: "GMD" },
          { label: "GNF - Guinean Franc", value: "GNF" },
          { label: "ILS - Israeli New Sheqel", value: "ILS" },
          { label: "IQD - Iraqi Dinar", value: "IQD" },
          { label: "IRR - Iranian Rial", value: "IRR" },
          { label: "KES - Kenyan Shilling", value: "KES" },
          { label: "KWD - Kuwaiti Dinar", value: "KWD" },
          { label: "LBP - Lebanese Pound", value: "LBP" },
          { label: "LRD - Liberian Dollar", value: "LRD" },
          { label: "LSL - Lesotho Loti", value: "LSL" },
          { label: "LYD - Libyan Dinar", value: "LYD" },
          { label: "MAD - Moroccan Dirham", value: "MAD" },
          { label: "MGA - Malagasy Ariary", value: "MGA" },
          { label: "MRU - Mauritanian Ouguiya", value: "MRU" },
          { label: "MUR - Mauritian Rupee", value: "MUR" },
          { label: "MWK - Malawian Kwacha", value: "MWK" },
          { label: "MZN - Mozambican Metical", value: "MZN" },
          { label: "NAD - Namibian Dollar", value: "NAD" },
          { label: "NGN - Nigerian Naira", value: "NGN" },
          { label: "OMR - Omani Rial", value: "OMR" },
          { label: "QAR - Qatari Rial", value: "QAR" },
          { label: "RWF - Rwandan Franc", value: "RWF" },
          { label: "SAR - Saudi Riyal", value: "SAR" },
          { label: "SCR - Seychellois Rupee", value: "SCR" },
          { label: "SDG - Sudanese Pound", value: "SDG" },
          { label: "SHP - Saint Helena Pound", value: "SHP" },
          { label: "SLL - Sierra Leonean Leone", value: "SLL" },
          { label: "SOS - Somali Shilling", value: "SOS" },
          { label: "SSP - South Sudanese Pound", value: "SSP" },
          { label: "STN - São Tomé and Príncipe Dobra", value: "STN" },
          { label: "SYP - Syrian Pound", value: "SYP" },
          { label: "SZL - Swazi Lilangeni", value: "SZL" },
          { label: "TND - Tunisian Dinar", value: "TND" },
          { label: "TRY - Turkish Lira", value: "TRY" },
          { label: "TZS - Tanzanian Shilling", value: "TZS" },
          { label: "UGX - Ugandan Shilling", value: "UGX" },
          { label: "XAF - Central African CFA Franc", value: "XAF" },
          { label: "XOF - West African CFA Franc", value: "XOF" },
          { label: "YER - Yemeni Rial", value: "YER" },
          { label: "ZAR - South African Rand", value: "ZAR" },
          { label: "ZMW - Zambian Kwacha", value: "ZMW" },
          { label: "ZWL - Zimbabwean Dollar", value: "ZWL" },
          {
            label: "-------------------------------------",
            value: "",
            disabled: true,
          },
          { label: "CAD - Canadian Dollar", value: "CAD" },
          { label: "CNY - Chinese Yuan", value: "CNY" },
          { label: "EUR - Euro", value: "EUR" },
          { label: "GBP - British Pound Sterling", value: "GBP" },
          { label: "INR - Indian Rupee", value: "INR" },
          { label: "JPY - Japanese Yen", value: "JPY" },
          { label: "RUB - Russian Ruble", value: "RUB" },
          { label: "USD - United States Dollar", value: "USD" },
        ],
      },
      {
        name: "monthlyEnergyCost",
        label: "Total Power Cost per Month",
        type: "number",
        required: true,
      },
      {
        name: "monthlyDieselCost",
        label: "Monthly Diesel/Petrol Costs for Power",
        type: "number",
        required: true,
      },
      {
        name: "businessEstateName",
        label: "Business Name/Estate Name",
        required: true,
      },
      {
        name: "serviceAddress",
        label: "Business/Home Address (applying for)",
        required: true,
      },
      {
        name: "serviceCity",
        label: "City/Town",
        required: true,
      },
      {
        name: "serviceState",
        label: "State/Region",
        required: true,
      },
      {
        name: "serviceCountry",
        label: "Country",
        required: true,
      },
    ],
  },
  {
    label: "Contact Info & Savings",
    fields: [
      {
        name: "serviceEmail",
        label: "Email",
        required: true,
      },
      {
        name: "servicePhone",
        label: "Phone",
        type: "phone",
        required: true,
      },
      {
        name: "industryOfOperations",
        label: "Business Industry/Sector (if applicable)",
        options: [
          {
            label:
              "Aerospace & Defense - e.g. Aircraft, aerospace & defense equipment & parts manufacturing",
            value: "aeroDefense",
          },
          {
            label:
              "Automotive - e.g. automotive assembly & parts manufacturing",
            value: "auto",
          },
          {
            label:
              "Beverage, Food, & Tobacco e.g. food processing & packaging, brewery",
            value: "food",
          },
          {
            label: "Capital Equipment - e.g. heavy machinery manufacturing",
            value: "equipment",
          },
          {
            label: "Chemicals, Plastics & Rubber - e.g. chemicals production",
            value: "chemical",
          },
          { label: "Construction & Building", value: "construction" },
          {
            label:
              "Consumer Goods: Durable - e.g. electronics, furniture, stationery manufacturing",
            value: "durableGood",
          },
          {
            label:
              "Consumer Goods: Non-Durable - e.g. toiletries, clothing, personal care products manufacturing",
            value: "nonDurableGood",
          },
          {
            label:
              "Containers, Packaging, & Glass - e.g. paper, metal, glass containers manufacturing",
            value: "container",
          },
          {
            label: "Education e.g. schools, colleges & tertiary institutions",
            value: "education",
          },
          {
            label:
              "Energy: Electricity e.g. Non-utility electricity production & sales",
            value: "nonUtilityElectricity",
          },
          {
            label: "Energy: Oil & Gas e.g. oil-field operations & servicing",
            value: "oilGas",
          },
          {
            label:
              "Environmental Industries e.g. environmental services & waste management",
            value: "environment",
          },
          { label: "Finance: Asset Management", value: "assetManagement" },
          {
            label: "Finance: Banking & Credit Services",
            value: "banking",
          },
          {
            label: "Finance: Insurance",
            value: "insurance",
          },
          {
            label:
              "Healthcare & Pharmaceuticals e.g. hospitals, pharmacies, medical device & drugs manufacturers",
            value: "health",
          },
          {
            label:
              "High Tech Industries e.g. computer hardware & software, IT services, transaction processing",
            value: "hiTech",
          },
          {
            label:
              "Hotel, Gaming & Leisure e.g. restaurants, amusement parks, resorts, lodging, arcade galleries",
            value: "hospitality",
          },
          { label: "Media: Print, Broadcast, & Production", value: "media" },
          {
            label: "Metals & Mining e.g. coal, metal production & recycling",
            value: "mining",
          },
          {
            label:
              "Retail, Trading & Distribution e.g. Supermarkets, grocery stores, general goods s",
            value: "retail",
          },
          {
            label:
              "Services: Business e.g. consulting, legal services, HR & admin, security & protection ",
            value: "serviceBusiness",
          },
          {
            label:
              "Services: Consumer e.g. barbershop & hair salon, auto & equipment repairs, realtor, cleaning & laundry",
            value: "serviceConsumer",
          },
          {
            label:
              "Services: Not-for-Profit e.g. charities, NGOs, religious organisations",
            value: "serviceNGO",
          },
          {
            label:
              "Services: Public e.g. central, regional & local governments, agencies, military, policing &  traffic management",
            value: "serviceGovernment",
          },
          {
            label:
              "Telecommunications e.g. internet service providers, towers, satellite equipment & services, data centers",
            value: "telecom",
          },
          {
            label: "Transportation: Cargo e.g. trucking, shipping, air-freight",
            value: "transportCargo",
          },
          {
            label:
              "Transportation: Consumer e.g. airports, airlines, passenger railroad & commuter transportation",
            value: "transportConsumer",
          },
          {
            label:
              "Utilities: Electricity e.g. regulated Electricity network, transmission & distribution",
            value: "utilityElectricity",
          },
          {
            label:
              "Utilities: Water e.g. regulated & unregulated water utilities",
            value: "utilityWater",
          },
          {
            label: "Wholesale Distribution",
            value: "wholesale",
          },
        ],
      },
      {
        name: "powerTopCost",
        label: "Is power your biggest expense?",
        type: "radio",
        required: true,
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        name: "preferredContact",
        label: "Preferred Means of Contact",
        required: true,
        options: [
          { label: "Email", value: "email" },
          { label: "Phone", value: "phone" },
        ],
      },
      {
        name: "contactPerson",
        label: "Name of Contact Person",
        required: true,
      },
    ],
  },
];
