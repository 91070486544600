import React, { useState } from "react";
import styled from "styled-components";

const Select = styled.select`
  width: 98%;
  padding: 8px 12px 5px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  color: #1b2e10;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const ApplianceSelector = ({ onChange }) => {
  const [selectedAppliance, setSelectedAppliance] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [powerRating, setPowerRating] = useState("");

  const appliances = [
    { name: "Air Conditioner - Evaporative", powerRating: "2600" },
    { name: "Air Conditioner - Inverter", powerRating: "1550" },
    { name: "Air Conditioner - Large (4HP) Split Unit", powerRating: "2990" },
    { name: "Air Conditioner - Medium (2HP) Split Unit", powerRating: "1500" },
    { name: "Air Conditioner - Portable(Mobile)", powerRating: "1100" },
    { name: "Air Conditioner - Small (1.5HP) Split Unit", powerRating: "1120" },
    { name: "Air Conditioner - Small (1HP) Split Unit", powerRating: "750" },
    { name: "Air Conditioner - Window Unit", powerRating: "1000" },
    { name: "Air Purifier", powerRating: "30" },
    { name: "Aquarium Pump", powerRating: "40" },
    { name: "AV Receiver", powerRating: "450" },
    {
      name: "Blender - Mixer, Food Processor, Juice Blender, Juice Mixer",
      powerRating: "450",
    },
    { name: "Chromebook", powerRating: "50" },
    { name: "Clothes Dryer", powerRating: "2500" },
    { name: "Coffee - Espresso Machine", powerRating: "1400" },
    { name: "Coffee - Percolator", powerRating: "950" },
    { name: "Coffee Maker - Drip", powerRating: "1100" },
    { name: "Computer - Monitor", powerRating: "30" },
    { name: "Computer - Desktop", powerRating: "280" },
    { name: "Computer - Gaming PC", powerRating: "450" },
    { name: "Computer - Laptop", powerRating: "80" },
    { name: "Computer - Tablet Charger", powerRating: "20" },
    { name: "Cooker - 1 Plate", powerRating: "1500" },
    { name: "Cooker - 2 Plate", powerRating: "2500" },
    { name: "Cooker - 2 Plate + Oven", powerRating: "3300" },
    { name: "Cooker - 4 Plate", powerRating: "4500" },
    {
      name: "Cooker - Induction Stove,Induction Cooking Stove,Electric Cooker,Induction Cooktop (per hob)",
      powerRating: "1600",
    },
    { name: "Cooker - Pressure Cooker", powerRating: "700" },
    { name: "Cooker - Rice Cooker", powerRating: "500" },
    { name: "Cooker - Slow Cooker", powerRating: "170" },
    { name: "Cooker Hood", powerRating: "30" },
    { name: "Corded Electric Drill", powerRating: "730" },
    { name: "Corded Electric Handheld Leaf Blower", powerRating: "2500" },
    { name: "Cordless Drill Charger", powerRating: "110" },
    { name: "CPAP machine", powerRating: "50" },
    { name: "Dehumidifier", powerRating: "240" },
    { name: "Dishwasher", powerRating: "1350" },
    { name: "DVD Player", powerRating: "50" },
    { name: "Electric Blanket", powerRating: "200" },
    { name: "Electric Boiler", powerRating: "9000" },
    { name: "Electric Kettle", powerRating: "2100" },
    { name: "Electric Mower", powerRating: "1500" },
    { name: "Electric Oven", powerRating: "2150" },
    { name: "Electric Power Shower", powerRating: "9000" },
    { name: "Electric Pressure Cooker", powerRating: "1000" },
    { name: "Electric Shaver", powerRating: "20" },
    { name: "Electric Stove", powerRating: "2000" },
    { name: "Electric Thermal Radiator", powerRating: "500" },
    { name: "EV Car Charger", powerRating: "4500" },
    { name: "EV Home Charger", powerRating: "2500" },
    { name: "Fan - Air Cooler", powerRating: "80" },
    { name: "Fan - Bathroom Extractor", powerRating: "20" },
    { name: "Fan - Ceiling", powerRating: "90" },
    { name: "Fan - Desk", powerRating: "20" },
    { name: "Fan - Electric Heater", powerRating: "2500" },
    { name: "Fan - Kitchen Extractor", powerRating: "200" },
    { name: "Fan - Standing, tower, wall", powerRating: "70" },
    { name: "Fan - Table", powerRating: "40" },
    { name: "Food (Tray) Dehydrator", powerRating: "800" },
    { name: "Freezer - Chest Freezer", powerRating: "50" },
    { name: "Fryer - Air", powerRating: "1500" },
    { name: "Fryer - Deep Fat Fryer,Deep Fryer", powerRating: "1000" },
    { name: "Game Console", powerRating: "160" },
    { name: "Game Console - Playstation 4", powerRating: "90" },
    { name: "Game Console - Playstation 5", powerRating: "180" },
    { name: "Game Console - Xbox One", powerRating: "80" },
    { name: "Garage Door Opener", powerRating: "350" },
    { name: "Google Home Mini", powerRating: "20" },
    { name: "Grass Strimmer", powerRating: "400" },
    { name: "Guitar Amplifier", powerRating: "30" },
    { name: "Hair - Blow Dryer", powerRating: "2150" },
    { name: "Hair - Curling Iron", powerRating: "30" },
    { name: "Hair - Straightening Iron", powerRating: "190" },
    { name: "Heated Bathroom Mirror", powerRating: "80" },
    { name: "Hair - Heat Rollers", powerRating: "400" },
    { name: "Heater - 100 litre Water", powerRating: "3000" },
    { name: "Heater - 50 litre Water", powerRating: "1500" },
    { name: "Heater - Bathroom Towel Heater", powerRating: "110" },
    { name: "Heater - Electric Tankless Water Heater", powerRating: "7700" },
    { name: "Heater - Hand Wash Oversink Water Heater", powerRating: "3000" },
    { name: "Heater - Hot Water Immersion", powerRating: "3000" },
    { name: "Heater - Space Heater", powerRating: "3500" },
    { name: "Home Sound System", powerRating: "100" },
    { name: "Humidifier", powerRating: "40" },
    { name: "iMac", powerRating: "150" },
    { name: "Hot Tub - Jacuzzi", powerRating: "5250" },
    { name: "Hot Tub - Outdoor Hot Tub", powerRating: "280" },
    { name: "Kettle - Medium", powerRating: "2200" },
    { name: "Kettle - Small", powerRating: "1850" },
    { name: "Lawnmower", powerRating: "1200" },
    { name: "Light bulb - 100W Incandescent", powerRating: "100" },
    { name: "Light bulb - 60W Incandescent", powerRating: "60" },
    { name: "Light bulb - Energy Saving (LED)", powerRating: "20" },
    { name: "Light Bulb - Fluorescent Lamp", powerRating: "40" },
    { name: "Microwave - Large", powerRating: "1150" },
    { name: "Microwave - Medium", powerRating: "900" },
    { name: "Nintendo Switch AC Adapter", powerRating: "30" },
    { name: "Pressing Iron - Dry", powerRating: "1200" },
    { name: "Pressing Iron - Steam", powerRating: "2350" },
    { name: "Pressure Washer", powerRating: "1500" },
    { name: "Printer - Inkjet", powerRating: "30" },
    { name: "Printer - Laser", powerRating: "700" },
    { name: "Printer - Scanner", powerRating: "20" },
    { name: "Projector", powerRating: "250" },
    { name: "Refrigerator - Large", powerRating: "280" },
    { name: "Refrigerator - Medium", powerRating: "150" },
    { name: "Refrigerator - Small", powerRating: "80" },
    { name: "Refrigerator - Table Top (Mini)", powerRating: "20" },
    { name: "Ring Spotlight Cam Wired", powerRating: "20" },
    { name: "Sander - 3 Inch Belt", powerRating: "1000" },
    { name: "Sander - 9 inch Disc", powerRating: "1200" },
    { name: "Sandwich Maker/ Toaster", powerRating: "850" },
    { name: "Set Top Box (Cable Box)", powerRating: "30" },
    { name: "Sewing Machine", powerRating: "100" },
    { name: "Sky Q 2TB Box", powerRating: "40" },
    { name: "Soldering Iron", powerRating: "50" },
    { name: "Soundbar", powerRating: "30" },
    { name: "Steriliser & Baby Warmer", powerRating: "650" },
    {
      name: "Submersible Water Pump - Pool Pump,Sump Pump,Well Pump",
      powerRating: "300",
    },
    { name: "Television - 19 Inch colour", powerRating: "70" },
    { name: "Television - 22 Inch LED", powerRating: "20" },
    { name: "Television - 25 Inch colour", powerRating: "150" },
    { name: "Television - 32 Inch LED TV", powerRating: "40" },
    { name: "Television - 42 Inch LED TV", powerRating: "60" },
    { name: "Television - 42 Inch Plasma TV", powerRating: "530" },
    { name: "Television - 46 Inch LED TV", powerRating: "70" },
    { name: "Television - 49 Inch LED TV", powerRating: "90" },
    { name: "Television - 55 Inch LED TV", powerRating: "120" },
    { name: "Television - 65 Inch LED TV", powerRating: "130" },
    { name: "Television - 82 Inch LED TV", powerRating: "270" },
    { name: "Toaster - Large", powerRating: "1400" },
    { name: "Toaster - Medium", powerRating: "1000" },
    { name: "Toaster - Small", powerRating: "750" },
    { name: "Treadmill", powerRating: "590" },
    { name: "Vacuum Cleaner", powerRating: "1400" },
    { name: "Washing Machine - Wash only", powerRating: "500" },
    { name: "Washing Machine - With spinning", powerRating: "1000" },
    {
      name: "Washing Machine - With spinning, dryer & others",
      powerRating: "2100",
    },
    { name: "Water Dispenser - Cold and Hot water", powerRating: "1250" },
    { name: "Water Dispenser - Cold Water", powerRating: "100" },
    { name: "Water Feature - Home Fountain", powerRating: "40" },
    { name: "Water Pump - Medium (1.5HP)", powerRating: "1120" },
    { name: "Water Pump - Small (1HP)", powerRating: "750" },
    { name: "Water Pump -Domestic (Shower)", powerRating: "250" },
    { name: "WiFi Router", powerRating: "10" },
    { name: "Wine Cooler (18 bottles)", powerRating: "90" },
    { name: "Other - Enter power rating manually", powerRating: "" },
  ];

  const handleApplianceChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue !== "") {
      setSelectedAppliance(selectedValue);

      const selectedApplianceData = appliances.find(
        (appliance) => appliance.name === selectedValue
      );
      if (selectedApplianceData) {
        setPowerRating(selectedApplianceData.powerRating);
        onChange(selectedValue, selectedApplianceData.powerRating);
      } else {
        setPowerRating("");
        onChange(selectedValue, "");
      }
    } else {
      setSelectedAppliance("");
      setPowerRating("");
      onChange("", "");
    }
  };

  return (
    <>
      <Select
        name="applianceName"
        defaultValue={selectedAppliance}
        onChange={handleApplianceChange}
        value={selectedAppliance}
      >
        <option value="" disabled hidden></option>
        {appliances.map((appliance) => (
          <option key={appliance.name} value={appliance.name}>
            {appliance.name}
          </option>
        ))}
      </Select>
    </>
  );
};

export default ApplianceSelector;
