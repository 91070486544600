import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { Link, useNavigate } from "react-router-dom";
import { useForm, useFormState } from "react-hook-form";
import { Grid } from "react-loader-spinner";
import { isValid as isDateValid } from "date-fns";
import axiosInstance from "../utils/axiosInstance";
import styled from "styled-components";
import {
  getCurrencyAbbreviation,
  formatLocaleCurrency,
} from "country-currency-map";
import {
  GlobalStyle,
  RestHeading,
  Paragraph,
  HighlightContainer2,
  HighlightContainer3,
  Highlight,
  Copyright2,
} from "./StyledComponents";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  NextIcon,
  PreviousIcon,
  FormIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
  FourIcon,
  FiveIcon,
  SixIcon,
  SevenIcon,
  AddIcon,
  TrashCanIcon,
} from "./Icons";
import logo from "../assets/logo/kepler_logo.svg";
import image21 from "../assets/images/background6.svg";
import africa from "../assets/images/africa2.svg";
import { FORM_STEPS } from "./BusinessFormSteps";
import PowerSwitch from "./PowerswitchAnimation";
import ApplianceSelector from "./ApplianceSelector";

const StepList = styled.ul`
  position: relative;
  display: flex;
  list-style: none;
  padding: 0px;
  width: 100%;
  z-index: 3;
`;

const StepItem = styled.li`
  flex: 1;
  border-radius: 15px 15px 0 0;
  padding-top: 0.1rem;
  text-align: center;
  font-size: 15px;
  margin-bottom: 2vw;
  border: ${(props) =>
    props.isActive
      ? "solid 1px #1b2e10"
      : props.index < props.currentPage ||
        (props.index >= props.currentPage &&
          props.isValid &&
          props.navigationAllowed &&
          props.nextNavigationAllowed &&
          props.totalDailyEnergyNeed !== 0 &&
          props.totalInstantaneousLoad !== 0)
      ? "solid 1px #245e41"
      : "solid 1px transparent"};
  border-bottom: ${(props) =>
    props.isActive ? "solid 2px #1b2e10" : "1px solid #cdd8d8"};
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  color: ${(props) =>
    props.isActive
      ? "#fff"
      : props.index >= props.currentPage &&
        props.isValid &&
        props.navigationAllowed &&
        !props.nextNavigationAllowed
      ? "#245e41"
      : props.index < props.currentPage ||
        (props.index >= props.currentPage &&
          props.isValid &&
          props.navigationAllowed &&
          props.nextNavigationAllowed &&
          props.totalDailyEnergyNeed !== 0 &&
          props.totalInstantaneousLoad !== 0)
      ? "#ecf0f0"
      : "#7a9795"};
  background-color: ${(props) =>
    props.isActive
      ? "#1b2e10"
      : props.index >= props.currentPage &&
        props.isValid &&
        props.navigationAllowed &&
        !props.nextNavigationAllowed
      ? "#e8ffa4"
      : props.index < props.currentPage ||
        (props.index >= props.currentPage &&
          props.isValid &&
          props.navigationAllowed &&
          props.nextNavigationAllowed &&
          props.totalDailyEnergyNeed !== 0 &&
          props.totalInstantaneousLoad !== 0)
      ? "#245e41"
      : "#ecf0f0"};
  cursor: ${(props) =>
    props.isActive ||
    (props.index > props.currentPage && !props.navigationAllowed)
      ? "default"
      : "pointer"};
  line-height: 1;

  &:hover {
    color: ${(props) =>
      props.isActive
        ? "#fff"
        : (props.index >= props.currentPage &&
            props.isValid &&
            props.navigationAllowed) ||
          props.index < props.currentPage
        ? "#ecf0f0"
        : "#7a9795"};
    background-color: ${(props) =>
      props.isActive
        ? "#1b2e10"
        : (props.index >= props.currentPage &&
            props.isValid &&
            props.navigationAllowed) ||
          props.index < props.currentPage
        ? "#1b2e10"
        : "#ecf0f0"};
  }

  @media (max-width: 992px) {
    margin-bottom: 6vw;
    border-radius: 9px 9px 0 0;
  }
`;

const FormContainer = styled.div`
  width: 50vw;
  margin: 3vw;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
  margin-left: 0;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 3vw;
  padding-right: 3vw;
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  border: 1px solid #b6c6c5;
  background: #fff;

  @media (max-width: 992px) {
    flex-direction: column;
    width: 90vw;
    margin: 1vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-bottom: 5vh;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormContent = styled.div`
  position: relative;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  position: relative;
  padding-bottom: 20px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-35px" : "50%")};
  transform: translateY(-10%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    (!props.focused && props.value) ||
    (props.htmlFor === "applianceName" &&
      !props.focused &&
      props.selectedAppliance) ||
    (props.htmlFor === "powerRating" &&
      !props.focused &&
      props.selectedPowerRating) ||
    (props.htmlFor === "quantity" &&
      !props.focused &&
      props.selectedQuantity !== "") ||
    (props.htmlFor === "dailyUsageHours" &&
      !props.focused &&
      props.selectedUsageHours) ||
    (props.htmlFor === "operationsStartDate" &&
      !props.focused &&
      props.dateIsValid) ||
    (props.htmlFor === "applicantDOB" && !props.focused && props.dateIsValid)
      ? "transparent"
      : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 0px;
  padding-top: 15px;
  margin-bottom: 30px;

  ${FormContent} div:focus-within & {
    top: -35px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};

    ${FormContent} div:focus-within & {
      font-size: 13px;
    }
  }
`;

const Label1 = styled.label`
  position: absolute;
  margin-top: 5px;
  padding-bottom: 20px;
  left: 12px;
  transform: translateY(-70%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.fileName !== "" ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  margin-top: 30px;

  ${FormContent} div:focus-within & {
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};
    ${FormContent} div:focus-within & {
      font-size: 13px;
    }
  }
`;

const Label2 = styled.label`
  position: relative;
  padding-top: 5px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-35px" : "50%")};
  transform: translateY(-70%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused &&
    (props.value || props.defaultValue !== "" || props.isEmpty) &&
    props.value !== ""
      ? "transparent"
      : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 0px;
  height: 10px;

  ${FormContent} div:focus-within & {
    top: -35px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};
  }
  ${FormContent} div:focus-within & {
    font-size: 13px;
  }
`;

const Label3 = styled.label`
  position: relative;
  padding-top: 5px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-35px" : "50%")};
  transform: translateY(-70%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    (!props.focused &&
      (props.value || props.defaultValue !== "") &&
      props.value !== "") ||
    (!props.focused && props.dateIsValid)
      ? "transparent"
      : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 0px;
  height: 10px;

  ${FormContent} div:focus-within & {
    top: -35px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 992px) {
    left: 5px;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};

    ${FormContent} div:focus-within & {
      font-size: 13px;
    }
  }
`;

const Label4 = styled.label`
  position: relative;
  padding-top: 5px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-35px" : "50%")};
  transform: translateY(-70%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.isPhoneValid ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 0px;
  height: 10px;

  ${FormContent} div:focus-within & {
    top: -35px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};
  }
  ${FormContent} div:focus-within & {
    font-size: 13px;
  }
`;

const RequiredIndicator = styled.span`
  color: ${(props) =>
    !props.focused && props.transparent ? "transparent" : "red"};
  margin-left: 1px;
  font-size: 14px;
`;

const Input = styled.input`
  position: absolute;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  background-color: transparent;
  white-space: pre-line;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  /* Style for autofill Input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const Input1 = styled.input`
  position: absolute;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  height: 40px;
  white-space: pre-line;
  background-color: transparent;
  color: ${(props) =>
    !props.focused && !props.dateIsValid ? "transparent" : "#1b2e10"};

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  /* Style for autofill Input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const Input2 = styled.input`
  position: absolute;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  width: 12vw;
  background-color: transparent;
  white-space: pre-line;
  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  /* Style for autofill Input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }

  @media (max-width: 992px) {
    width: 25vw;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const NextButton = styled.button`
  height: 40px;
  width: 120px;
  font-size: 16px;
  background-color: ${(props) =>
    props.isValid && props.navigationAllowed && !props.nextNavigationAllowed
      ? "#e8ffa4"
      : props.isValid && props.navigationAllowed && props.nextNavigationAllowed
      ? "#245e41"
      : "transparent"};
  color: ${(props) =>
    props.isValid && props.navigationAllowed && !props.nextNavigationAllowed
      ? "#1b2e10"
      : props.isValid && props.navigationAllowed && props.nextNavigationAllowed
      ? "#ecf0f0"
      : "#b6c6c5"};
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  @media (max-width: 992px) {
    font-size: 17px;
  }
`;

const Button = styled.button`
  height: 40px;
  width: 120px;
  font-size: 16px;
  background-color: #245e41;
  color: #ecf0f0;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  @media (max-width: 992px) {
    font-size: 17px;
  }
`;

const SubmitButton = styled.button`
  height: 40px;
  padding-left: 3vmax;
  padding-right: 3vmax;
  font-size: 18px;
  background-color: transparent;
  color: #fff;
  background-color: #1b2e10;
  border: none;
  border-radius: 5px;
  white-space: nowrap;

  &:hover {
    svg {
      color: #ccff33;
    }
  }

  &:active {
    color: #ccff33;
    background-color: #1b2e10;
  }
  @media (max-width: 992px) {
    font-size: 2vmax;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const CustomFileButton = styled.label`
  background-color: #ecf0f0;
  color: #7a9795;
  padding: 8px 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #b6c6c5;
    color: #1b2e10;
  }
`;

const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
  margin-bottom: 3rem;
`;

const Select = styled.select`
  width: 98%;
  padding: 8px 12px 5px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  color: #1b2e10;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const BusinessForm = ({
  user,
  successNotification,
  warningNotification,
  errorNotification,
  infoNotification,
}) => {
  const methods = useForm({
    shouldUseNativeValidation: true,
  });
  const { register, handleSubmit, control, setValue, trigger } = methods;
  const { isDirty, isValid } = useFormState({
    control,
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [fieldValues, setFieldValues] = useState({});
  const [dateValues, setDateValues] = useState({});
  const [isDateInputValid, setIsDateInputValid] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [fileNames, setFileNames] = useState({});
  const [fileUploaded, setFileUploaded] = useState({});
  const MAX_FILE_SIZE_BYTES = 16 * 1024 * 1024; // 16MB
  const [selectedAppliance, setSelectedAppliance] = useState("");
  const [selectedPowerRating, setSelectedPowerRating] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedUsageHours, setSelectedUsageHours] = useState("");
  const [totalInstantaneousLoad, setTotalInstantaneousLoad] = useState(0);
  const [totalDailyEnergyNeed, setTotalDailyEnergyNeed] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isEmpty, setIsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userRegion, setUserRegion] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userCountryName, setUserCountryName] = useState("");
  const [businessCity, setBusinessCity] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessCountry, setBusinessCountry] = useState("");
  const [applicantCity, setApplicantCity] = useState("");
  const [applicantState, setApplicantState] = useState("");
  const [applicantCountry, setApplicantCountry] = useState("");
  const [userIpAddress, setUserIpAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [irradiation, setIrradiation] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState({});
  const [isPhoneValid, setIsPhoneValid] = useState({});
  const [phoneCountryCodes, setPhoneCountryCodes] = useState({});
  const [appliancesList, setAppliancesList] = useState([]);
  const [applianceSelectorKey, setApplianceSelectorKey] = useState(0);
  const [isLitigatedAssetsRequired, setIsLitigatedAssetsRequired] =
    useState(false);
  const [isLitigatedLiabilitiesRequired, setIsLitigatedLiabilitiesRequired] =
    useState(false);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [dieselFactor, setDieselFactor] = useState(null);
  const [currencyCode, setCurrencyCode] = useState("USD");
  const [electricityTariff, setElectricityTariff] = useState(null);
  const [monthlyElectricityCost, setMonthlyElectricityCost] = useState(null);
  const [nonSolarCost, setNonSolarCost] = useState(null);
  const [solarCost, setSolarCost] = useState(null);
  const [avoidedEmissions, setAvoidedEmissions] = useState(null);
  const solarCutOff = 16;
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const copyrightText =
    currentYear === 2023 ? `${currentYear}` : `${2023} - ${currentYear}`;

  const CountryFlagIcon = ({ country, width, height }) => {
    const flagMap = {
      Africa: africa,
    };

    return (
      <div>
        <img
          src={flagMap[country]}
          alt={`${country} Flag`}
          width={width}
          height={height}
        />
      </div>
    );
  };

  const iconMap = {
    0: <OneIcon />,
    1: <TwoIcon />,
    2: <ThreeIcon />,
    3: <FourIcon />,
    4: <FiveIcon />,
    5: <SixIcon />,
    6: <SevenIcon />,
  };

  function addCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const PHONE_INPUT_FIELDS = ["businessPhone", "applicantPhone"];

  useEffect(() => {
    async function fetchUserCountry() {
      try {
        const response = await fetch("https://freeipapi.com/api/json");
        const data = await response.json();
        setUserCountry(data.countryCode.toLowerCase());
        const phoneInputCountryCodes = {};
        for (const field of PHONE_INPUT_FIELDS) {
          phoneInputCountryCodes[field.name] = data.countryCode.toLowerCase();
        }
        setPhoneCountryCodes(phoneInputCountryCodes);
        setUserCity(data.cityName);
        setUserRegion(data.regionName);
        setUserCountryName(data.countryName);
        setUserIpAddress(data.ipAddress);
        setLongitude(data.longitude);
        setLatitude(data.latitude);
        if (userCity && !businessCity) {
          handleInputChange("businessCity", userCity, "text");
          setBusinessCity(userCity);
        }
        if (userRegion && !businessState) {
          handleInputChange("businessState", userRegion, "text");
          setBusinessState(userRegion);
        }
        if (userCountryName && !businessCountry) {
          handleInputChange("businessCountry", userCountryName, "text");
          setBusinessCountry(userCountryName);
        }
        if (userCity && !applicantCity) {
          handleInputChange("applicantCity", userCity, "text");
          setApplicantCity(userCity);
        }
        if (userRegion && !applicantState) {
          handleInputChange("applicantState", userRegion, "text");
          setApplicantState(userRegion);
        }
        if (userCountryName && !applicantCountry) {
          handleInputChange("applicantCountry", userCountryName, "text");
          setApplicantCountry(userCountryName);
        }
      } catch (error) {
        setUserCountry("");
        setUserCity("");
        setUserRegion("");
        setUserCountryName("");
        setUserIpAddress("");
        setLongitude("");
        setLatitude("");
      }
    }

    fetchUserCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicantCity,
    applicantCountry,
    applicantState,
    businessCity,
    businessCountry,
    businessState,
    setValue,
    userCountry,
    userCity,
    userCountryName,
    userRegion,
    latitude,
    longitude,
  ]);

  useEffect(() => {
    async function fetchLongitudeAndLatitude() {
      try {
        if (fieldValues.businessCity && fieldValues.businessCountry) {
          const serviceCityResponse = await fetch(
            `https://nominatim.openstreetmap.org/search.php?q=${fieldValues.businessCity},${fieldValues.businessCountry}&format=json`
          );
          const serviceCityData = await serviceCityResponse.json();
          if (serviceCityData && serviceCityData.length > 0) {
            const serviceLongitude = serviceCityData[0].lon;
            const serviceLatitude = serviceCityData[0].lat;
            setLongitude(serviceLongitude);
            setLatitude(serviceLatitude);
            const solarResponse = await fetch(
              `https://power.larc.nasa.gov/api/temporal/monthly/point?parameters=ALLSKY_SFC_SW_DWN,T2M&community=RE&longitude=${serviceLongitude}&latitude=${serviceLatitude}&format=JSON&start=2018&end=2022`
            );
            const solarData = await solarResponse.json();
            const irradiationValues = Object.values(
              solarData.properties.parameter.ALLSKY_SFC_SW_DWN
            );
            const minIrradiation = Math.min(...irradiationValues);
            const filteredIrradiationValues = irradiationValues.filter(
              (val) => val !== -999
            );
            const sumIrradiation = filteredIrradiationValues.reduce(
              (acc, val) => acc + val,
              0
            );
            const averageIrradiation =
              sumIrradiation / filteredIrradiationValues.length;
            setIrradiation(
              (averageIrradiation - minIrradiation) * 0.2 + minIrradiation
            );
          } else {
          }
        }
      } catch (error) {}
    }
    fetchLongitudeAndLatitude();
  }, [irradiation, fieldValues.businessCity, fieldValues.businessCountry]);

  const handleInputChange = (fieldName, value, fieldType) => {
    setFieldValues((prevFieldValues) => ({
      ...prevFieldValues,
      [fieldName]: value,
    }));
    if (fieldType !== "number") {
      setValue(fieldName, value);
    }
    if (fieldName === "quantity") {
      setSelectedQuantity(value);
    }
    if (fieldName === "dailyUsageHours") {
      setSelectedUsageHours(value);
    }
    if (fieldName === "powerRating") {
      setSelectedPowerRating(value);
      setValue("powerRating", value);
    }
    trigger(fieldName);
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleFileInputChange = (fieldName, file) => {
    if (file) {
      setSelectedFiles((prevSelectedFiles) => ({
        ...prevSelectedFiles,
        [fieldName]: file,
      }));
      setFileUploaded((prevFileUploaded) => ({
        ...prevFileUploaded,
        [fieldName]: true,
      }));
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [fieldName]: file.name || "",
      }));
    } else {
      setSelectedFiles((prevSelectedFiles) => ({
        ...prevSelectedFiles,
        [fieldName]: null,
      }));
      setFileUploaded((prevFileUploaded) => ({
        ...prevFileUploaded,
        [fieldName]: false,
      }));
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [fieldName]: "",
      }));
    }
  };

  const handleApplianceChange = (selectedAppliance, selectedPowerRating) => {
    setValue("applianceName", selectedAppliance);
    setValue("powerRating", selectedPowerRating);
    setSelectedAppliance(selectedAppliance);
    setSelectedPowerRating(selectedPowerRating);
  };

  const calculateLoadAndEnergy = (watt, quantity, hours) => {
    const instantaneousLoad = (watt * quantity) / 1000;
    const dailyEnergyNeed = instantaneousLoad * hours;
    return {
      instantaneousLoad,
      dailyEnergyNeed,
    };
  };

  const handleNextPage = () => {
    if (currentPage < FORM_STEPS.length - 1) {
      const isCurrentPageValid = isNavigationAllowed(currentPage + 1);

      if (isCurrentPageValid && isValid) {
        setCurrentPage(currentPage + 1);
      } else {
        warningNotification(`Complete required inputs to progress.`);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRemoveAppliance = (index) => {
    const updatedAppliancesList = [...appliancesList];
    const removedAppliance = updatedAppliancesList.splice(index, 1)[0];
    setAppliancesList(updatedAppliancesList);

    const removedInstantaneousLoad = removedAppliance.instantaneousLoad || 0;
    const removedDailyEnergyNeeds = removedAppliance.dailyEnergyNeed || 0;

    setTotalInstantaneousLoad(
      totalInstantaneousLoad - removedInstantaneousLoad
    );
    setTotalDailyEnergyNeed(totalDailyEnergyNeed - removedDailyEnergyNeeds);
  };

  const handlePhoneInputChange = (fieldName, phone, country) => {
    setPhoneNumbers((prevPhoneNumbers) => ({
      ...prevPhoneNumbers,
      [fieldName]: phone,
    }));
    setPhoneCountryCodes((prevPhoneCountryCodes) => ({
      ...prevPhoneCountryCodes,
      [fieldName]: country.countryCode,
    }));
    handleInputChange(fieldName, phone, "phone");
    const isValid = isValidPhoneNumber(
      phone,
      country.countryCode.toUpperCase()
    );
    setIsPhoneValid((prevIsPhoneValid) => ({
      ...prevIsPhoneValid,
      [fieldName]: isValid,
    }));
  };

  const isPhoneNumberValid = (fieldName) => {
    const phone = phoneNumbers[fieldName] || "";
    const country = phoneCountryCodes[fieldName] || userCountry;
    const isValid = isValidPhoneNumber(phone, country.toUpperCase());
    const field = FORM_STEPS.flatMap((step) => step.fields).find(
      (field) => field.name === fieldName
    );
    return !field || (!field.required && phone === "") || isValid;
  };

  const calculateSolarPanels = () => {
    if (
      !irradiation ||
      !totalDailyEnergyNeed ||
      fieldValues.hourlyElectricitySupply >= solarCutOff
    )
      return 0;
    return (totalDailyEnergyNeed * 1.3) / irradiation;
  };

  const calculateHybridInverter = () => {
    if (!totalInstantaneousLoad) return 0;
    return totalInstantaneousLoad * 1.3;
  };

  const calculateBatteries = () => {
    if (!totalDailyEnergyNeed || !fieldValues.hourlyElectricitySupply) return 0;
    return fieldValues.dailyOperatingHours &&
      // eslint-disable-next-line eqeqeq
      fieldValues.hourlyElectricitySupply != 24
      ? (1000 * totalDailyEnergyNeed * fieldValues.dailyOperatingHours) /
          (24 * 48 * 0.8)
      : (1000 *
          totalDailyEnergyNeed *
          (24 - fieldValues.hourlyElectricitySupply)) /
          (24 * 48 * 0.8);
  };

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        if (fieldValues.businessCountry) {
          const serviceCurrencyCode = getCurrencyAbbreviation(
            fieldValues.businessCountry
          );
          setCurrencyCode(serviceCurrencyCode);
          if (!serviceCurrencyCode) {
            throw new Error(
              `Currency code not available for ${fieldValues.businessCountry}`
            );
          }
          const response = await fetch(
            `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_EXCHANGE_RATE_ID}/pair/USD/${serviceCurrencyCode}`
          );
          const data = await response.json();
          const eRate = data.conversion_rate;
          if (!eRate) {
            throw new Error(
              `Exchange rate not available for ${fieldValues.businessCountry}`
            );
          }
          setExchangeRate(eRate);
        }
      } catch (error) {}
    };
    if (fieldValues.businessCountry) {
      fetchExchangeRate();
    }
  }, [fieldValues.businessCountry]);

  useEffect(() => {
    const dieselHours = parseFloat(24 - fieldValues.hourlyElectricitySupply);
    if (!isNaN(dieselHours)) {
      const dieselFactor = dieselHours / 24;
      setDieselFactor(dieselFactor);
    }
  }, [fieldValues.hourlyElectricitySupply, fieldValues.businessCountry]);

  useEffect(() => {
    const calculateNonSolarCost = () => {
      const dieselLCOEMap = {
        Afghanistan: 0.592126582,
        Albania: 0.939417722,
        Algeria: 0.103417722,
        AmericanSamoa: 1.803797468,
        Andorra: 0.712379747,
        Angola: 0.077924051,
        AntiguaandBarbuda: 1.810724051,
        Argentina: 0.516126582,
        Armenia: 0.702278481,
        Aruba: 0.57,
        Australia: 0.570962025,
        Austria: 0.872075949,
        Azerbaijan: 0.226556962,
        Bahamas: 0.685443038,
        Bahrain: 0.229924051,
        Bangladesh: 0.47764557,
        Barbados: 0.826379747,
        Belarus: 0.346810127,
        Belgium: 0.906708861,
        Belize: 0.761443038,
        Benin: 0.552202532,
        Bhutan: 0.411746835,
        Bolivia: 0.25878481,
        BosniaandHerzegovina: 0.688810127,
        Botswana: 0.596455696,
        Brazil: 0.566151899,
        BritishVirginIslands: 1.943291139,
        Brunei: 0.105822785,
        Bulgaria: 0.693139241,
        BurkinaFaso: 0.532481013,
        Burma: 0.597898734,
        Burundi: 0.712860759,
        Cambodia: 0.500253165,
        Cameroon: 0.651772152,
        Canada: 0.607037975,
        CapeVerde: 0.592126582,
        CaymanIslands: 0.664278481,
        CentralAfricaRepublic: 1.062556962,
        Chad: 0.649367089,
        Chile: 0.511797468,
        China: 0.506987342,
        Colombia: 0.295822785,
        Comoros: 0.476202532,
        Congo: 0.57721519,
        CookIslands: 1.438227848,
        CostaRica: 0.588278481,
        Croatia: 0.804253165,
        Cuba: 0.505063291,
        Curacao: 0.501696203,
        Cyprus: 0.758556962,
        CzechRepublic: 0.763848101,
        DemocraticRepublicofCongo: 0.563265823,
        Denmark: 0.940379747,
        Djibouti: 0.471392405,
        Dominica: 0.523341772,
        DominicanRepublic: 0.47956962,
        Ecuador: 0.222227848,
        Egypt: 0.12843038,
        ElSalvador: 0.501696203,
        EquatorialGuinea: 0.329974684,
        Eritrea: 0.639746835,
        Estonia: 0.827341772,
        Eswatini: 0.556531646,
        Ethiopia: 0.679189873,
        FalklandIslands: 0.606075949,
        FaroeIslands: 0.307367089,
        Fiji: 0.535367089,
        Finland: 0.953848101,
        France: 0.927873418,
        FrenchGuiana: 0.952405063,
        FrenchPolynesia: 0.793670886,
        Gabon: 0.452632911,
        Georgia: 0.595493671,
        Germany: 0.891797468,
        Ghana: 0.523822785,
        Greece: 0.876886076,
        Greenland: 0.288607595,
        Grenada: 0.583949367,
        Guadeloupe: 0.870632911,
        Guam: 0.897212514,
        Guatemala: 0.510835443,
        Guinea: 0.670531646,
        GuineaBissau: 0.606075949,
        Guyana: 0.575772152,
        Haiti: 0.597898734,
        Honduras: 0.462734177,
        HongKong: 1.417544304,
        Hungary: 0.857164557,
        Iceland: 1.119797468,
        India: 0.541139241,
        Indonesia: 0.467063291,
        Iran: 0.14,
        Iraq: 0.21164557,
        Ireland: 0.870632911,
        Israel: 0.952405063,
        Italy: 0.930759494,
        CotedIvoire: 0.564227848,
        Jamaica: 0.646,
        Japan: 0.503139241,
        Jordan: 0.488227848,
        Kazakhstan: 0.307848101,
        Kenya: 0.606556962,
        Kiribati: 0.639746835,
        Kosovo: 0.663797468,
        Kuwait: 0.179898734,
        Kyrgyzstan: 0.383848101,
        Laos: 0.453113924,
        Latvia: 0.821088608,
        Lebanon: 0.379037975,
        Lesotho: 0.558936709,
        Liberia: 0.567113924,
        Libya: 0.014911392,
        Liechtenstein: 1.083721519,
        Lithuania: 0.807139241,
        Luxembourg: 0.791746835,
        Macao: 0.97164557,
        Madagascar: 0.519012658,
        Malawi: 0.781164557,
        Malaysia: 0.216936709,
        Maldives: 0.456481013,
        Mali: 0.682075949,
        Malta: 0.625797468,
        Martinique: 0.909113924,
        Mauritania: 0.524303797,
        Mauritius: 0.665240506,
        Mayotte: 0.791265823,
        Mexico: 0.676303797,
        Moldova: 0.603189873,
        Monaco: 1.091417722,
        Mongolia: 0.710936709,
        Montenegro: 0.755189873,
        Montserrat: 0.832151899,
        Morocco: 0.671493671,
        Mozambique: 0.686886076,
        Namibia: 0.533924051,
        Nauru: 0.59164557,
        Nepal: 0.571443038,
        Netherlands: 0.909594937,
        NewZealand: 0.623873418,
        Nicaragua: 0.565670886,
        Niger: 0.500253165,
        Nigeria: 0.421367089,
        NorthCaledonia: 0.649367089,
        NorthKorea: 0.707088608,
        NorthMacedonia: 0.654177215,
        Norway: 0.980303797,
        Oman: 0.322278481,
        Pakistan: 0.480050633,
        Palestine: 0.875443038,
        Panama: 0.438202532,
        PapuaNewGuinea: 0.57721519,
        Paraguay: 0.480050633,
        Peru: 0.478607595,
        Philippines: 0.532962025,
        Poland: 0.773949367,
        Portugal: 0.841291139,
        PuertoRico: 0.471392405,
        Qatar: 0.270810127,
        Reunion: 0.865822785,
        Romania: 0.757594937,
        Russia: 0.342,
        Rwanda: 0.61521519,
        SaintKittsandNevis: 1.159240506,
        SaintLucia: 0.646,
        SaintPierreandMiquelon: 0.673417722,
        SaintVincentandtheGrenadines: 0.669659433,
        Samoa: 1.842278481,
        SaoTomeandPrincipe: 0.755189873,
        SanMarino: 0.861493671,
        SaudiArabia: 0.147670886,
        Senegal: 0.595974684,
        Serbia: 0.883139241,
        Seychelles: 0.76721519,
        SierraLeone: 0.733063291,
        Singapore: 0.921620253,
        Slovakia: 0.803772152,
        Slovenia: 0.753746835,
        SolomonIslands: 0.00721519,
        Somalia: 0.476202532,
        SouthAfrica: 0.609443038,
        SouthKorea: 0.543063291,
        SouthSudan: 0.894683544,
        Spain: 0.779721519,
        SriLanka: 0.55556962,
        Sudan: 0.315544304,
        Suriname: 0.574810127,
        Sweden: 0.86678481,
        Switzerland: 1.082759494,
        Syria: 0.427139241,
        Taiwan: 0.420886076,
        Tajikistan: 0.331898734,
        Tanzania: 0.581544304,
        Thailand: 0.404531646,
        TimorEste: 0.673417722,
        Togo: 0.671012658,
        Tonga: 2.092405063,
        TrinidadandTobago: 0.312658228,
        Tunisia: 0.339594937,
        Turkey: 0.655620253,
        Turkmenistan: 0.185670886,
        TurksandCaicosIslands: 1.433417722,
        Uganda: 0.627721519,
        Ukraine: 0.683037975,
        UnitedArabEmirate: 0.522379747,
        UnitedKingdom: 0.90478481,
        UnitedStates: 0.495443038,
        UnitedStatesVirginIslands: 1.471898734,
        Uruguay: 0.661873418,
        Uzbekistan: 0.43243038,
        Venezuela: 0.14,
        Vietnam: 0.418481013,
        WallisandFutuna: 0.952405063,
        WesternSahara: 0.57721519,
        Zambia: 0.570962025,
        Zimbabwe: 0.798481013,
      };

      const electricityTariffMap = {
        Afghanistan: 0.043,
        Albania: 0.119,
        Algeria: 0.0365,
        AmericanSamoa: 0.26,
        Andorra: 0.14,
        Angola: 0.019,
        AntiguaandBarbuda: 0.37,
        Argentina: 0.065,
        Armenia: 0.11,
        Aruba: 0.2835,
        Australia: 0.234,
        Austria: 0.415,
        Azerbaijan: 0.056,
        Bahamas: 0.401,
        Bahrain: 0.0625,
        Bangladesh: 0.079,
        Barbados: 0.265,
        Belarus: 0.1125,
        Belgium: 0.29,
        Belize: 0.195,
        Benin: 0.195,
        Bhutan: 0.018,
        Bolivia: 0.163,
        BosniaandHerzegovina: 0.1035,
        Botswana: 0.104,
        Brazil: 0.154,
        BritishVirginIslands: 0.225,
        Brunei: 0.039,
        Bulgaria: 0.1325,
        BurkinaFaso: 0.201,
        Burundi: 0.142,
        Cambodia: 0.149,
        Cameroon: 0.1295,
        Canada: 0.113,
        CapeVerde: 0.2555,
        CaymanIslands: 0.4035,
        CentralAfricaRepublic: 0.091,
        Chad: 0.33,
        Chile: 0.169,
        China: 0.084,
        Colombia: 0.1745,
        Comoros: 0.3,
        Congo: 0.07323,
        CookIslands: 0.57,
        CostaRica: 0.187,
        Croatia: 0.162,
        Cuba: 0.03,
        Curacao: 0.29,
        Cyprus: 0.3275,
        CzechRepublic: 0.273,
        DemocraticRepublicofCongo: 0.081,
        Denmark: 0.187,
        Djibouti: 0.32,
        Dominica: 0.25,
        DominicanRepublic: 0.155,
        Ecuador: 0.0905,
        Egypt: 0.033,
        ElSalvador: 0.218,
        EquatorialGuinea: 0.2,
        Eritrea: 0.24,
        Estonia: 0.25,
        Eswatini: 0.1005,
        Ethiopia: 0.0145,
        FalklandIslands: 0.32,
        FaroeIslands: 0.11,
        Fiji: 0.341,
        Finland: 0.1725,
        France: 0.232,
        Gabon: 0.204,
        Georgia: 0.0985,
        Germany: 0.344,
        Ghana: 0.121,
        Greece: 0.237,
        Greenland: 0.25,
        Grenada: 0.03,
        Guadeloupe: 0.19,
        Guam: 0.264,
        Guatemala: 0.243,
        Guinea: 0.178,
        GuineaBissau: 0.238,
        Guyana: 0.32,
        Haiti: 0.09793,
        Honduras: 0.246,
        HongKong: 0.195,
        Hungary: 0.1415,
        Iceland: 0.1145,
        India: 0.105,
        Indonesia: 0.086,
        Iran: 0.003,
        Iraq: 0.029,
        Ireland: 0.515,
        Israel: 0.1625,
        Italy: 0.5065,
        CotedIvoire: 0.163,
        Jamaica: 0.285,
        Japan: 0.253,
        Jordan: 0.092,
        Kazakhstan: 0.0565,
        Kenya: 0.1895,
        Kiribati: 0.41,
        Kosovo: 0.076,
        Kuwait: 0.039,
        Kyrgyzstan: 0.025,
        Laos: 0.034,
        Latvia: 0.2505,
        Lebanon: 0.1835,
        Lesotho: 0.059,
        Liberia: 0.25,
        Libya: 0.0085,
        Liechtenstein: 0.4375,
        Lithuania: 0.194,
        Luxembourg: 0.2125,
        Macao: 0.12,
        Madagascar: 0.1495,
        Malawi: 0.1305,
        Malaysia: 0.092,
        Maldives: 0.111,
        Mali: 0.188,
        Malta: 0.154,
        Martinique: 1.12,
        Mauritania: 0.1716,
        Mauritius: 0.1325,
        Mayotte: 0.001,
        Mexico: 0.161,
        Moldova: 0.1525,
        Monaco: 0.232,
        Mongolia: 0.05073,
        Montenegro: 0.12,
        Montserrat: 0.53,
        Morocco: 0.111,
        Mozambique: 0.1035,
        Myanmar: 0.0535,
        Namibia: 0.115,
        Nauru: 0.23,
        Nepal: 0.057,
        Netherlands: 0.302,
        NewZealand: 0.199,
        Nicaragua: 0.2075,
        Niger: 0.17862,
        Nigeria: 0.0595,
        NorthCaledonia: 0.182,
        NorthKorea: 0.004375,
        NorthMacedonia: 0.164,
        Norway: 0.1105,
        Oman: 0.1245,
        Pakistan: 0.0935,
        Palestine: 0.16134,
        Panama: 0.1785,
        PapuaNewGuinua: 0.21,
        Paraguay: 0.051,
        Peru: 0.18,
        Philippines: 0.1805,
        Poland: 0.3705,
        Portugal: 0.145,
        PuertoRico: 0.215,
        Qatar: 0.034,
        Reunion: 0.54,
        Romania: 0.203,
        Russia: 0.0815,
        Rwanda: 0.1565,
        SaintKittsandNevis: 0.26,
        SaintLucia: 0.37,
        SaintPierreandMiquelon: 0.25,
        SaintVincentandtheGrenadines: 0.26,
        Samoa: 0.52,
        SaoTomeandPrincipe: 0.17,
        SanMarino: 0.27,
        SaudiArabia: 0.0585,
        Senegal: 0.182,
        Serbia: 0.118,
        Seychelles: 0.3,
        SierraLeone: 0.163,
        Singapore: 0.2675,
        Slovakia: 0.311,
        Slovenia: 0.1985,
        SolomonIslands: 1.03,
        Somalia: 0.563,
        SouthAfrica: 0.131,
        SouthKorea: 0.1075,
        SouthSudan: 0.244,
        Spain: 0.189,
        SriLanka: 0.1605,
        Sudan: 0.026,
        Suriname: 0.016,
        Sweden: 0.235,
        Switzerland: 0.2985,
        Syria: 0.015,
        Taiwan: 0.115,
        Tajikistan: 0.04066,
        Tanzania: 0.0985,
        Thailand: 0.1405,
        TimorEste: 0.24,
        Togo: 0.185,
        Tonga: 0.45,
        TrinidadandTobago: 0.0525,
        Tunisia: 0.091,
        Turkey: 0.1275,
        Turkmenistan: 0.01,
        TurksandCaicosIslands: 0.49,
        Uganda: 0.148,
        Ukraine: 0.073,
        UnitedArabEmirates: 0.095,
        UnitedKingdom: 0.4255,
        UnitedStates: 0.1565,
        Uruguay: 0.1875,
        Uzbekistan: 0.039,
        Venezuela: 0.0925,
        Vietnam: 0.08,
        WallisandFutuna: 0.232,
        WesternSahara: 0.09,
        Zambia: 0.038,
        Zimbabwe: 0.047,
      };
      if (fieldValues.businessCountry) {
        const formattedServiceCountry = fieldValues.businessCountry.replace(
          /[\s'-]/g,
          ""
        );
        const dieselLCOE = dieselLCOEMap[formattedServiceCountry];
        const electricTariff = electricityTariffMap[formattedServiceCountry];
        setElectricityTariff(electricTariff);

        if (
          typeof dieselLCOE === "undefined" ||
          typeof electricTariff === "undefined"
        ) {
          return;
        }

        const dieselCostPerMonth =
          totalDailyEnergyNeed * dieselLCOE * dieselFactor * (365.25 / 13);
        const electricityCostPerMonth =
          totalDailyEnergyNeed *
          electricTariff *
          (1 - dieselFactor) *
          (365.25 / 13);
        setMonthlyElectricityCost(electricityCostPerMonth);
        const totalEnergyCostPerMonth =
          dieselCostPerMonth + electricityCostPerMonth;

        try {
          if (exchangeRate === null) {
            throw new Error("Exchange rate is null");
          }
          const totalEnergyCostInUserCurrency =
            totalEnergyCostPerMonth * exchangeRate;
          return totalEnergyCostInUserCurrency;
        } catch (error) {
          return totalEnergyCostPerMonth;
        }
      }
    };

    const fetchNonSolarCost = async () => {
      try {
        const cost = await calculateNonSolarCost();
        setNonSolarCost(cost);
      } catch (error) {}
    };

    fetchNonSolarCost();
  }, [
    dieselFactor,
    totalDailyEnergyNeed,
    exchangeRate,
    fieldValues.businessCountry,
  ]);

  useEffect(() => {
    const calculateSolarCost = async () => {
      const solarPanelsCost = () => {
        if (fieldValues.hourlyElectricitySupply >= solarCutOff) return 0;
        return ((totalDailyEnergyNeed * 1.3) / irradiation) * 1000 || 0;
      };

      const inverterCost = totalInstantaneousLoad * 1.3 * 200 || 0;
      const batteriesCost =
        fieldValues.dailyOperatingHours &&
        // eslint-disable-next-line eqeqeq
        fieldValues.hourlyElectricitySupply != 24 &&
        fieldValues.dailyOperatingHours > fieldValues.hourlyElectricitySupply
          ? ((totalDailyEnergyNeed * fieldValues.dailyOperatingHours) /
              (24 * 0.8)) *
              350 || 0
          : ((totalDailyEnergyNeed *
              (24 - fieldValues.hourlyElectricitySupply)) /
              (24 * 0.8)) *
              350 || 0;

      const totalSolarCostPerMonth =
        (solarPanelsCost() === 0
          ? monthlyElectricityCost + inverterCost + batteriesCost
          : solarPanelsCost() +
            (electricityTariff *
              Math.max(
                0,
                24 - fieldValues.hourlyElectricitySupply - irradiation
              )) /
              (24 * 1000) +
            inverterCost +
            batteriesCost) / 120 || 0;

      try {
        if (exchangeRate === null) {
          throw new Error("Exchange rate is null");
        }
        const totalSolarCostInUserCurrency =
          totalSolarCostPerMonth * exchangeRate;
        return totalSolarCostInUserCurrency;
      } catch (error) {
        return totalSolarCostPerMonth;
      }
    };

    const fetchSolarCost = async () => {
      try {
        const cost = await calculateSolarCost();
        setSolarCost(cost);
      } catch (error) {}
    };

    fetchSolarCost();
  }, [
    totalDailyEnergyNeed,
    irradiation,
    totalInstantaneousLoad,
    exchangeRate,
    fieldValues.hourlyElectricitySupply,
    fieldValues.dailyOperatingHours,
    monthlyElectricityCost,
    electricityTariff,
  ]);

  const calculateSavings = () => {
    if (nonSolarCost !== null && solarCost !== null) {
      return nonSolarCost - solarCost;
    }
    return 0;
  };

  useEffect(() => {
    const countryCarbonIntensity = {
      Afghanistan: 120.481926,
      Albania: 23.437498,
      Algeria: 485.4895,
      AmericanSamoa: 687.5,
      Andorra: 172.4435247,
      Angola: 195.98296,
      AntiguaandBarbuda: 657.1429,
      Argentina: 344.3068,
      Armenia: 222.6776,
      Aruba: 591.3978,
      Australia: 501.6999,
      Austria: 158.22209,
      Azerbaijan: 469.58176,
      Bahamas: 698.1133,
      Bahrain: 494.0176,
      Bangladesh: 574.2783,
      Barbados: 644.85974,
      Belarus: 425.90057,
      Belgium: 167.10457,
      Belize: 484.375,
      Benin: 666.6667,
      Bhutan: 24.444445,
      Bolivia: 335.4037,
      BosniaandHerzegovina: 553.47437,
      Botswana: 794.5205,
      Brazil: 102.04292,
      BritishVirginIslands: 714.2857,
      Brunei: 493.5897,
      Bulgaria: 399.72305,
      BurkinaFaso: 611.4286,
      Burundi: 250.00002,
      Cambodia: 509,
      Cameroon: 278.26086,
      Canada: 125.84192,
      CapeVerde: 600,
      CaymanIslands: 684.9315,
      CentralAfricanRepublic: 0,
      Chad: 677.4193,
      Chile: 332.61234,
      China: 533.9828,
      Colombia: 163.98486,
      Comoros: 714.2857,
      Congo: 395.5224,
      CookIslands: 400,
      CostaRica: 37.212986,
      CotedIvoire: 410.74686,
      Croatia: 246.28452,
      Cuba: 654.68353,
      Cyprus: 589.3536,
      Czechia: 414.80347,
      DemocraticRepublicofCongo: 25.36232,
      Denmark: 180.41837,
      Djibouti: 666.6667,
      Dominica: 529.4118,
      DominicanRepublic: 549.80084,
      Ecuador: 183.63031,
      Egypt: 469.63,
      ElSalvador: 194.22572,
      EquatorialGuinea: 492.95773,
      Eritrea: 688.88885,
      Estonia: 460.261,
      Eswatini: 189.1892,
      Ethiopia: 25.187202,
      FalklandIslands: 500,
      FaroeIslands: 428.57147,
      Fiji: 289.4737,
      Finland: 131.70932,
      France: 84.879,
      FrenchGuiana: 254.71701,
      FrenchPolynesia: 471.4286,
      Gabon: 397.37994,
      Gambia: 699.99994,
      Georgia: 134.83145,
      Germany: 385.38934,
      Ghana: 361.20398,
      Greece: 344.41263,
      Greenland: 133.33333,
      Grenada: 714.28577,
      Guadeloupe: 623.52936,
      Guam: 670.3297,
      Guatemala: 304.70917,
      Guinea: 208.63307,
      GuineaBissau: 750,
      Guyana: 642.2764,
      Haiti: 606.06067,
      Honduras: 373.95663,
      HongKong: 609.9291,
      Hungary: 222.09758,
      Iceland: 28.556593,
      India: 633.3976,
      Indonesia: 619.0262,
      Iran: 487.8637,
      Iraq: 531.3592,
      Ireland: 346.4286,
      Israel: 537.56665,
      Italy: 372.6337,
      Jamaica: 537.93097,
      Japan: 494.85748,
      Jordan: 391.12537,
      Kazakhstan: 635.57367,
      Kenya: 101.13269,
      Kiribati: 666.6667,
      Kosovo: 767.0044,
      Kuwait: 574.5608,
      Kyrgyzstan: 104.426,
      Laos: 242.18164,
      Latvia: 183.43195,
      Lebanon: 663.1016,
      Lesotho: 20,
      Liberia: 304.34784,
      Libya: 558.8511,
      Lithuania: 195.70406,
      Luxembourg: 162.60162,
      Macao: 491.5254,
      Madagascar: 483.25363,
      Malawi: 133.80281,
      Malaysia: 543.8732,
      Maldives: 651.5152,
      Mali: 463.12686,
      Malta: 433.4764,
      Martinique: 698.6301,
      Mauritania: 526.59576,
      Mauritius: 611.1111,
      Mexico: 423.80542,
      Moldova: 666.6666,
      Mongolia: 749.6561,
      Montenegro: 392.74924,
      Montserrat: 1000,
      Morocco: 630.7506,
      Mozambique: 126.63316,
      Myanmar: 330.80017,
      Namibia: 63.694267,
      Nauru: 750,
      Nepal: 24.509806,
      Netherlands: 354.3093,
      NewCaledonia: 610.119,
      NewZealand: 105.215416,
      Nicaragua: 354.21164,
      Niger: 622.2222,
      Nigeria: 368.10962,
      NorthKorea: 102.42215,
      NorthMacedonia: 543.7063,
      Norway: 28.924809,
      Oman: 488.27176,
      Pakistan: 344.15454,
      Palestine: 465.11627,
      Panama: 152.67857,
      PapuaNewGuinea: 526.74896,
      Paraguay: 25.487255,
      Peru: 256.51236,
      Philippines: 594.4508,
      Poland: 633.22766,
      Portugal: 234.60472,
      PuertoRico: 612.3903,
      Qatar: 490.27856,
      Reunion: 519.0311,
      Romania: 264.23935,
      Russia: 363.67453,
      Rwanda: 294.11765,
      SaintKittsandNevis: 681.81824,
      SaintLucia: 685.71423,
      SaintPierreandMiquelon: 800,
      SaintVincentandtheGrenadines: 500,
      Samoa: 470.58823,
      SaoTomeandPrincipe: 600,
      SaudiArabia: 557.7828,
      Senegal: 523.1317,
      Serbia: 582.1277,
      Seychelles: 615.3846,
      SierraLeone: 47.61905,
      Singapore: 488.77533,
      Slovakia: 140.13454,
      Slovenia: 237.37755,
      SolomonIslands: 727.2727,
      Somalia: 634.1463,
      SouthAfrica: 708.20654,
      SouthKorea: 437.60202,
      SouthSudan: 684.2105,
      Spain: 217.42178,
      SriLanka: 501.52902,
      Sudan: 288.1254,
      Suriname: 356.43567,
      Sweden: 45.119328,
      Switzerland: 41.27851,
      Syria: 541.16943,
      Taiwan: 560.9782,
      Tajikistan: 83.62899,
      Tanzania: 366.74817,
      Thailand: 501.57162,
      TimorEste: 698.1133,
      Togo: 460.3174,
      Tonga: 625,
      TrinidadandTobago: 491.40897,
      Tunisia: 469.428,
      Turkey: 413.60278,
      Turkmenistan: 490.1869,
      TurksandCaicosIslands: 703.7037,
      Uganda: 52.272728,
      Ukraine: 232.74129,
      UnitedArabEmirates: 407.97467,
      UnitedKingdom: 261.1549,
      UnitedStates: 368.10196,
      UnitedStatesVirginIslands: 685.71423,
      Uruguay: 150.12721,
      Uzbekistan: 505.40543,
      Vanuatu: 571.4286,
      Venezuela: 212.47626,
      Vietnam: 386.49127,
      WesternSahara: 666.6666,
      Yemen: 559.65906,
      WallisandFutuna: 636.3636364,
      Zambia: 84.69792,
      Zimbabwe: 392.279,
    };
    if (fieldValues.businessCountry) {
      const formattedServiceCountry = fieldValues.businessCountry.replace(
        /[\s'-]/g,
        ""
      );
      const gridEmission =
        (totalDailyEnergyNeed *
          (1 - dieselFactor) *
          countryCarbonIntensity[formattedServiceCountry]) /
        1000;
      const dieselEmission = totalDailyEnergyNeed * dieselFactor * 1.94;
      const totalNonSolarEmissions = gridEmission + dieselEmission;

      const calculateSolarEmission = () => {
        const batterySize =
          fieldValues.dailyOperatingHours &&
          // eslint-disable-next-line eqeqeq
          fieldValues.hourlyElectricitySupply != 24
            ? (totalDailyEnergyNeed * fieldValues.dailyOperatingHours) /
              (24 * 0.8)
            : (totalDailyEnergyNeed *
                (24 - fieldValues.hourlyElectricitySupply)) /
              (24 * 0.8);

        const solarEmission =
          totalDailyEnergyNeed *
            ((countryCarbonIntensity[formattedServiceCountry] *
              Math.max(
                0,
                24 - fieldValues.hourlyElectricitySupply - irradiation
              )) /
              (24 * 1000)) +
          (0.0058 * Math.log(batterySize) + 0.054);
        return solarEmission;
      };

      const totalAvoidedEmmissions =
        totalNonSolarEmissions - calculateSolarEmission();
      setAvoidedEmissions(totalAvoidedEmmissions);
    }
  }, [
    totalDailyEnergyNeed,
    dieselFactor,
    fieldValues.hourlyElectricitySupply,
    fieldValues.dailyOperatingHours,
    irradiation,
    fieldValues.businessCountry,
  ]);

  const isNavigationAllowed = (targetStep) => {
    if (targetStep >= 2) {
      if (totalDailyEnergyNeed === 0 || totalInstantaneousLoad === 0) {
        return false;
      }
    }
    for (let i = 0; i < targetStep; i++) {
      const stepFields = FORM_STEPS[i].fields;
      if (
        !stepFields.every((field) => {
          if (field.type === "file") {
            const isFileValid =
              !field.required ||
              (selectedFiles[field.name] !== undefined &&
                selectedFiles[field.name] !== null);
            return isFileValid;
          } else if (field.required) {
            const isFieldValid =
              field.type === "dropdown"
                ? fieldValues[field.name] !== undefined
                : field.type === "date"
                ? isDateValid(fieldValues[field.name])
                : field.type === "phone"
                ? isPhoneNumberValid(field.name)
                : fieldValues[field.name] !== undefined &&
                  fieldValues[field.name].trim() !== "";
            return isFieldValid;
          }
          return true;
        })
      ) {
        return false;
      }
    }
    return true;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const formData = new FormData();

      Object.keys(data).forEach((fieldName) => {
        const fieldValue = data[fieldName];
        if (
          fieldValue !== null &&
          fieldValue !== undefined &&
          fieldValue !== "" &&
          fieldName !== "applianceName" &&
          fieldName !== "powerRating" &&
          fieldName !== "dailyUsageHours" &&
          fieldName !== "quantity"
        ) {
          const processedValue =
            typeof fieldValue === "string"
              ? fieldValue.replace(/,/g, "")
              : fieldValue;
          formData.append(fieldName, processedValue);
        }
      });

      Object.keys(phoneNumbers).forEach((fieldName) => {
        const phoneNumber = phoneNumbers[fieldName];
        const country = phoneCountryCodes[fieldName];

        if (phoneNumber && country) {
          formData.append(`${fieldName}_countryCode`, country);
        }
      });

      Object.keys(selectedFiles).forEach((fieldName) => {
        const fieldFiles = selectedFiles[fieldName];
        if (
          fieldFiles !== null &&
          fieldFiles !== undefined &&
          fieldFiles !== ""
        ) {
          formData.append(fieldName, fieldFiles);
        }
      });

      appliancesList.forEach((appliance, index) => {
        formData.append(`applianceName_${index}`, appliance.name);
        formData.append(`powerRating_${index}`, appliance.powerRating);
        formData.append(`dailyUsageHours_${index}`, appliance.dailyUsageHours);
        formData.append(`quantity_${index}`, appliance.quantity);
        formData.append(
          `instantaneousLoad_${index}`,
          appliance.instantaneousLoad.toFixed(2)
        );
        formData.append(
          `dailyEnergyNeed_${index}`,
          appliance.dailyEnergyNeed.toFixed(2)
        );
      });

      formData.append(
        "totalInstantaneousLoad",
        totalInstantaneousLoad.toFixed(2)
      );
      formData.append("totalDailyEnergyNeed", totalDailyEnergyNeed.toFixed(2));
      formData.append("ipAddress", userIpAddress);
      formData.append("solarPanelsKW", Math.ceil(calculateSolarPanels()));
      formData.append(
        "hybridInverterKVA",
        Math.ceil(calculateHybridInverter())
      );
      formData.append("lFP48VBatteriesAH", Math.ceil(calculateBatteries()));
      formData.append("autoCurrencyCode", currencyCode);
      formData.append("exchangeRate", exchangeRate);
      formData.append("monthlySavings", Math.ceil(calculateSavings()));
      formData.append(
        "percentSavings",
        (((nonSolarCost - solarCost) / nonSolarCost) * 100).toFixed(1)
      );
      formData.append("solarCost", solarCost.toFixed(2) || 0);
      formData.append("nonSolarCost", nonSolarCost.toFixed(2) || 0);
      formData.append(
        "abatedEmissionsMTCO2",
        ((avoidedEmissions * 365.25) / (12 * 1000)).toFixed(2)
      );

      let totalFormDataSize = 0;

      for (const pair of formData.entries()) {
        // eslint-disable-next-line no-unused-vars
        const [name, value] = pair;
        if (value instanceof File) {
          totalFormDataSize += value.size;
        } else {
          totalFormDataSize += new Blob([value]).size;
        }
      }

      if (totalFormDataSize > MAX_FILE_SIZE_BYTES) {
        warningNotification(
          "The total size of your submission is too large. Please reduce the file sizes before submitting."
        );
        setLoading(false);
        return;
      }

      axiosInstance
        .post("/api/businessApplication", formData, {
          headers: { "user-uid": user.uid },
        })
        .then((response) => {
          successNotification(
            `Your application for business power credit financing has been saved successfully. Someone will be in touch shortly. Thank you!`
          );
          setLoading(false);
          setTimeout(() => {
            navigate("/customer-dashboard");
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }, 100);
          }, 0);
        })
        .catch((error) => {
          errorNotification(`Error submitting form. Please try again later.`);
          setLoading(false);
        });
    } catch (error) {
      errorNotification(
        `Error submitting form. User profile not found. Login and try again.`
      );
      setLoading(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      {loading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 6000,
            backgroundColor: "rgb(0,0,0,0.3)",
          }}
        >
          <Grid
            height="30%"
            width="30%"
            color="#245e41"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-loader"
            visible={true}
          />
        </div>
      )}
      <Element>
        <div
          style={{
            margin: "0",
            padding: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundImage: `url(${image21})`,
            backgroundSize: window.innerWidth > 992 ? "auto" : "cover",
            backgroundPosition: "bottom left",
          }}
        >
          <div
            id="top-demo"
            style={{
              margin: "0",
              padding: "0",
              width: "100%",
              display: "flex",
              flexDirection: window.innerWidth > 992 ? "row" : "column",
              justifyContent: "center",
              alignItems: window.innerWidth > 992 ? "stretch" : "center",
              minHeight: "100vh",
            }}
          >
            <div
              style={{
                margin: "0",
                paddingTop: "7%",
                paddingLeft: window.innerWidth > 992 ? "7%" : "1%",
                paddingRight: window.innerWidth > 992 ? "7%" : "1%",
                width: window.innerWidth > 992 ? "50vw" : "",
                minHeight: "50vw",
                filter: "opacity(90%)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingTop: "0.5%",
                  margin: "0",
                  marginBottom: "10%",
                  marginLeft: window.innerWidth > 992 ? "20%" : "0",
                  marginRight: window.innerWidth > 992 ? "0" : "40%",
                  display: "flex",
                  justifyContent: window.innerWidth > 992 ? "start" : "center",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/"
                  onClick={() => {
                    setTimeout(() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }, 100);
                  }}
                >
                  <img
                    src={logo}
                    alt="Kepler Logo"
                    style={{
                      width: "100px",
                    }}
                  />
                </Link>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <RestHeading
                  style={{
                    textAlign: window.innerWidth > 992 ? "left" : "center",
                    paddingTop: window.innerWidth > 992 ? "" : "10%",
                    paddingBottom: "4%",
                    margin: "0",
                    fontSize: window.innerWidth > 992 ? "2.5rem" : "2.3rem",
                    color: "#1b2e10",
                  }}
                >
                  <HighlightContainer2>
                    <Highlight> Business Application </Highlight>
                  </HighlightContainer2>{" "}
                </RestHeading>
                <RestHeading
                  style={{
                    textAlign: window.innerWidth > 992 ? "left" : "center",
                    paddingBottom: "7%",
                    margin: "0",
                    fontSize: "1.5rem",
                    color: "#1b2e10",
                  }}
                >
                  Let's power your business!
                </RestHeading>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <PowerSwitch />
                </div>
                <div
                  style={{
                    color: "#223614",
                    fontSize: window.innerWidth > 992 ? "13px" : "11px",
                    fontWeight: "500",
                    margin: "5px",
                    padding: "5px",
                  }}
                >
                  {" "}
                  <p>
                    It may help to have copies of your business incorporation
                    certificate, business bank account statements, and your
                    identification document ready for upload before starting the
                    application.
                  </p>
                  <p>
                    Your information is not automatically saved mid-session. Try
                    to submit in one attempt.
                  </p>
                  <p>
                    Note that your application will fail to submit if the
                    combined size of your uploaded files is greater than 16MB
                    (sixteen megabytes). Reduce your file size before
                    re-uploading if you encounter this issue.
                  </p>
                  <p>
                    Fields marked with a red asterisk (
                    <span style={{ color: "red" }}>*</span>) are required.
                    Ensure that the asterisks disappear before you can progress
                    to the next step.
                  </p>
                  <p>
                    Filling out fields without a red asterisk (
                    <span style={{ color: "red" }}>*</span>) improves the
                    chances of your application getting approved and receiving
                    even more favorable terms.
                  </p>
                  <p>
                    Annualised revenue or expense implies taking total revenue
                    or expense, dividing it by the number of months over which
                    the revenue or expenditure was made and multiplying by 12.
                    For example 10,000 in revenue over 6 months = 10,000/6 x 12
                    = 20,000 annualised revenue.
                  </p>
                  <p>
                    Note that by filling out and submitting this form, you
                    attest that you are providing factually correct information
                    and are liable to be held responsible if found to have
                    knowingly provided wrong information. You also agree to our{" "}
                    <a
                      href="/legal#terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#14412d" }}
                    >
                      terms of service
                    </a>{" "}
                    and our{" "}
                    <a
                      href="/legal#privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#14412d" }}
                    >
                      privacy policy.
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <FormContainer>
              <form onSubmit={handleSubmit(onSubmit)}>
                <StepList>
                  {FORM_STEPS.map((step, index) => (
                    <StepItem
                      key={index}
                      isActive={index === currentPage}
                      onClick={() => {
                        if (index < currentPage) {
                          setCurrentPage(index);
                        } else if (index > currentPage) {
                          const allowNavigation = isNavigationAllowed(index);

                          if (allowNavigation & isValid) {
                            setCurrentPage(index);
                          } else {
                            warningNotification(
                              `Not allowed! Complete required inputs to progress.`
                            );
                          }
                        }
                      }}
                      index={index} // Pass the index of the step
                      currentPage={currentPage}
                      isValid={isValid}
                      navigationAllowed={isNavigationAllowed(index)}
                      nextNavigationAllowed={isNavigationAllowed(index + 1)}
                      totalDailyEnergyNeed={totalDailyEnergyNeed}
                      totalInstantaneousLoad={totalInstantaneousLoad}
                    >
                      {index + 1}
                    </StepItem>
                  ))}
                </StepList>

                <RestHeading
                  style={{
                    textAlign: "left",
                    paddingBottom: "10%",
                    margin: "0",
                    marginLeft: "1%",
                    fontSize: "1.5rem",
                  }}
                >
                  {iconMap[currentPage]} {FORM_STEPS[currentPage].label}
                </RestHeading>
                <FormContent>
                  <Form>
                    {FORM_STEPS[currentPage].fields.map((field) => (
                      <div key={field.name || field}>
                        {field.type === "dropdown" ? (
                          <select
                            name={
                              typeof field === "object" ? field.name : field
                            }
                            {...register(
                              typeof field === "object" ? field.name : field,
                              {
                                required: field.required,
                              }
                            )}
                            defaultValue={isDirty ? undefined : ""}
                          >
                            {/* Add dropdown options here */}
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                          </select>
                        ) : field.type === "phone" ? (
                          <div>
                            <Label4
                              htmlFor={field.name}
                              value={phoneNumbers[field.name] || ""}
                              isPhoneValid={isPhoneValid[field.name]}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null &&
                                    isPhoneValid[field.name]
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label4>
                            <PhoneInputContainer>
                              <PhoneInput
                                style={{
                                  width: "250px !important",
                                }}
                                country={
                                  phoneCountryCodes[field.name] || userCountry
                                }
                                enableSearch={true}
                                value={phoneNumbers[field.name] || ""}
                                onChange={(phone, country) => {
                                  handlePhoneInputChange(
                                    field.name,
                                    phone,
                                    country
                                  );
                                }}
                              />
                            </PhoneInputContainer>
                          </div>
                        ) : field.name === "ownBuilding" ? (
                          <div>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              style={{ display: "block", marginTop: "5px" }}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "absolute",
                                left:
                                  window.innerWidth > 600 ? "140px" : "85px",
                                top: window.innerWidth > 600 ? "71px" : "68px",
                                gap: window.innerWidth > 600 ? "70px" : "3px",
                                fontSize:
                                  window.innerWidth > 600 ? "14px" : "12px",
                                alignItems: "start",
                              }}
                            >
                              {field.options.map((option, index) => (
                                <div
                                  key={index}
                                  style={{ marginRight: "20px" }}
                                >
                                  <span
                                    htmlFor={`${field.name}_${option.value}`}
                                    value={fieldValues[field.name]}
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50px",
                                      left: "40px",
                                      alignText: "left",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    {option.label}
                                  </span>

                                  <Input1
                                    type="radio"
                                    id={`${field.name}_${option.value}`}
                                    name={field.name}
                                    value={option.value}
                                    {...register(field.name, {
                                      required: field.required,
                                    })}
                                    onChange={(e) =>
                                      handleInputChange(
                                        field.name,
                                        e.target.value,
                                        field.type
                                      )
                                    }
                                    style={{
                                      width: "15px",
                                      backgroundColor: "#245e41",
                                      marginLeft: "55px",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : field.name === "assetLitigation" ? (
                          <div>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              style={{ display: "block", marginTop: "5px" }}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "absolute",
                                left:
                                  window.innerWidth > 600 ? "270px" : "160px",
                                top:
                                  window.innerWidth > 600 ? "608px" : "590px",
                                gap: window.innerWidth > 600 ? "70px" : "0px",
                                fontSize:
                                  window.innerWidth > 600 ? "14px" : "12px",
                                alignItems: "start",
                              }}
                            >
                              {field.options.map((option, index) => (
                                <div
                                  key={index}
                                  style={{ marginRight: "20px" }}
                                >
                                  <span
                                    htmlFor={`${field.name}_${option.value}`}
                                    value={fieldValues[field.name]}
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50px",
                                      left: "53px",
                                      alignText: "left",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    {option.label}
                                  </span>

                                  <Input1
                                    type="radio"
                                    id={`${field.name}_${option.value}`}
                                    name={field.name}
                                    value={option.value}
                                    {...register(field.name, {
                                      required: field.required,
                                    })}
                                    onChange={(e) => {
                                      handleInputChange(
                                        field.name,
                                        e.target.value,
                                        field.type
                                      );
                                      const isLitigationSelected =
                                        e.target.value === "yes";
                                      setIsLitigatedAssetsRequired(
                                        isLitigationSelected
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      backgroundColor: "#245e41",
                                      marginLeft: "55px",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : field.name === "liabilityLitigation" ? (
                          <div>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              style={{ display: "block", marginTop: "15px" }}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "absolute",
                                left:
                                  window.innerWidth > 600 ? "260px" : "160px",
                                top:
                                  window.innerWidth > 600 ? "437px" : "423px",
                                gap: window.innerWidth > 600 ? "70px" : "0px",
                                fontSize:
                                  window.innerWidth > 600 ? "14px" : "12px",
                                alignItems: "start",
                              }}
                            >
                              {field.options.map((option, index) => (
                                <div
                                  key={index}
                                  style={{ marginRight: "20px" }}
                                >
                                  <span
                                    htmlFor={`${field.name}_${option.value}`}
                                    value={fieldValues[field.name]}
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50px",
                                      left: "53px",
                                      alignText: "left",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    {option.label}
                                  </span>

                                  <Input1
                                    type="radio"
                                    id={`${field.name}_${option.value}`}
                                    name={field.name}
                                    value={option.value}
                                    {...register(field.name, {
                                      required: field.required,
                                    })}
                                    onChange={(e) => {
                                      handleInputChange(
                                        field.name,
                                        e.target.value,
                                        field.type
                                      );
                                      const isLitigationSelected =
                                        e.target.value === "yes";
                                      setIsLitigatedLiabilitiesRequired(
                                        isLitigationSelected
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      backgroundColor: "#245e41",
                                      marginLeft: "55px",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : field.name === "powerTopCost" ? (
                          <div>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              style={{ display: "block", marginTop: "15px" }}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "absolute",
                                left:
                                  window.innerWidth > 600 ? "250px" : "160px",
                                top:
                                  window.innerWidth > 600 ? "437px" : "421px",
                                gap: window.innerWidth > 600 ? "70px" : "0px",
                                fontSize:
                                  window.innerWidth > 600 ? "14px" : "12px",
                                alignItems: "start",
                              }}
                            >
                              {field.options.map((option, index) => (
                                <div
                                  key={index}
                                  style={{ marginRight: "20px" }}
                                >
                                  <span
                                    htmlFor={`${field.name}_${option.value}`}
                                    value={fieldValues[field.name]}
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50px",
                                      left: "53px",
                                      alignText: "left",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    {option.label}
                                  </span>

                                  <Input1
                                    type="radio"
                                    id={`${field.name}_${option.value}`}
                                    name={field.name}
                                    value={option.value}
                                    {...register(field.name, {
                                      required: field.required,
                                    })}
                                    onChange={(e) => {
                                      handleInputChange(
                                        field.name,
                                        e.target.value,
                                        field.type
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      backgroundColor: "#245e41",
                                      marginLeft: "55px",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : field.name === "applicantGender" ? (
                          <div>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              style={{ display: "block", marginTop: "15px" }}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "absolute",
                                left:
                                  window.innerWidth > 600 ? "140px" : "85px",
                                top:
                                  window.innerWidth > 600 ? "635px" : "613px",
                                gap: window.innerWidth > 600 ? "70px" : "0px",
                                fontSize:
                                  window.innerWidth > 600 ? "14px" : "12px",
                                alignItems: "start",
                              }}
                            >
                              {field.options.map((option, index) => (
                                <div
                                  key={index}
                                  style={{ marginRight: "20px" }}
                                >
                                  <span
                                    htmlFor={`${field.name}_${option.value}`}
                                    value={fieldValues[field.name]}
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50px",
                                      left: "53px",
                                      alignText: "left",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    {option.label}
                                  </span>

                                  <Input1
                                    type="radio"
                                    id={`${field.name}_${option.value}`}
                                    name={field.name}
                                    value={option.value}
                                    {...register(field.name, {
                                      required: field.required,
                                    })}
                                    onChange={(e) => {
                                      handleInputChange(
                                        field.name,
                                        e.target.value,
                                        field.type
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      backgroundColor: "#245e41",
                                      marginLeft: "55px",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : field.type === "file" ? (
                          <div
                            key={field.name || field}
                            style={{ marginBottom: "10px" }}
                          >
                            <Label1
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              fileName={fileNames[field.name] || ""}
                            >
                              {field.label}
                              {field.required && !fileUploaded[field.name] && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label1>
                            <FileInput
                              type="file"
                              name={field.name}
                              onChange={(e) => {
                                const file = e.target.files[0];
                                handleFileInputChange(field.name, file);
                              }}
                              id={field.name}
                              {...(field.required ? { required: true } : {})}
                              fileName={fileNames[field.name] || ""}
                            />
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <CustomFileButton
                                htmlFor={field.name}
                                style={{
                                  marginTop: "30px",
                                  marginLeft: "20px",
                                  fontSize: "15px",
                                }}
                              >
                                Choose File
                              </CustomFileButton>
                              <Paragraph
                                style={{
                                  color:
                                    (fileNames[field.name] || "").trim() !== ""
                                      ? "initial"
                                      : "red",
                                  marginTop: "41px",
                                  marginLeft: "20px",
                                  fontSize: "14px",
                                  whiteSpace: "pre-line",
                                  overflow: "hidden",
                                }}
                              >
                                {(fileNames[field.name] || "").trim() !== ""
                                  ? fileNames[field.name]
                                  : "No file chosen"}
                              </Paragraph>
                            </div>
                          </div>
                        ) : field.type === "date" ? (
                          <div key={field.name || field}>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              dateIsValid={isDateInputValid[field.name]}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null &&
                                    isDateInputValid[field.name]
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <Input1
                              type="date"
                              name={field.name}
                              onChange={(e) => {
                                const fieldName = field.name;
                                const date = e.target.value;
                                setDateValues((prevDateValues) => ({
                                  ...prevDateValues,
                                  [fieldName]: date,
                                }));

                                const parsedDate = new Date(date);
                                if (!isNaN(parsedDate)) {
                                  setIsDateInputValid((prevIsValid) => ({
                                    ...prevIsValid,
                                    [fieldName]: true,
                                  }));
                                  handleInputChange(
                                    fieldName,
                                    parsedDate,
                                    field.type
                                  );
                                } else {
                                  setIsDateInputValid((prevIsValid) => ({
                                    ...prevIsValid,
                                    [fieldName]: false,
                                  }));
                                }
                              }}
                              onBlur={(e) => {
                                const fieldName = field.name;
                                const date = e.target.value;
                                const parsedDate = new Date(date);
                                if (date === "" || isNaN(parsedDate)) {
                                  setIsDateInputValid((prevIsValid) => ({
                                    ...prevIsValid,
                                    [fieldName]: false,
                                  }));

                                  handleInputChange(fieldName, "", field.type);
                                } else if (!isNaN(parsedDate)) {
                                  setIsDateInputValid((prevIsValid) => ({
                                    ...prevIsValid,
                                    [fieldName]: true,
                                  }));
                                }
                              }}
                              value={dateValues[field.name] || ""}
                              dateIsValid={isDateInputValid[field.name]}
                              id={field.name}
                              {...(field.required ? { required: true } : {})}
                              max={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        ) : field.name === "businessCity" ||
                          field.name === "businessState" ||
                          field.name === "businessCountry" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: window.innerWidth > 992 ? "40vw" : "85vw",
                            }}
                          >
                            {field.name === "businessCity" && (
                              <div
                                style={{
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "18vw",
                                }}
                              >
                                <Label2
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userCity}
                                >
                                  {field.label}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label2>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userCity ||
                                        ""
                                  }
                                  defaultValue={userCity}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                            {field.name === "businessState" && (
                              <div
                                style={{
                                  position: "relative",
                                  left:
                                    window.innerWidth > 992 ? "14.5vw" : "28vw",
                                  bottom: "27px",
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "23vw",
                                }}
                              >
                                <Label3
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userRegion}
                                >
                                  {field.label}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label3>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userRegion ||
                                        ""
                                  }
                                  defaultValue={userRegion}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                            {field.name === "businessCountry" && (
                              <div
                                style={{
                                  position: "relative",
                                  left:
                                    window.innerWidth > 992 ? "29vw" : "57vw",
                                  bottom: "54px",
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "20vw",
                                }}
                              >
                                <Label2
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userCountryName}
                                >
                                  {field.label}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label2>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userCountryName ||
                                        ""
                                  }
                                  defaultValue={userCountryName}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : field.name === "applicantCity" ||
                          field.name === "applicantState" ||
                          field.name === "applicantCountry" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: window.innerWidth > 992 ? "40vw" : "85vw",
                            }}
                          >
                            {field.name === "applicantCity" && (
                              <div
                                style={{
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "18vw",
                                }}
                              >
                                <Label2
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userCity}
                                >
                                  {field.label}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label2>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userCity ||
                                        ""
                                  }
                                  defaultValue={userCity}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                            {field.name === "applicantState" && (
                              <div
                                style={{
                                  position: "relative",
                                  left:
                                    window.innerWidth > 992 ? "14.5vw" : "28vw",
                                  bottom: "27px",
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "23vw",
                                }}
                              >
                                <Label3
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userRegion}
                                >
                                  {field.label}{" "}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label3>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userRegion ||
                                        ""
                                  }
                                  defaultValue={userRegion}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                            {field.name === "applicantCountry" && (
                              <div
                                style={{
                                  position: "relative",
                                  left:
                                    window.innerWidth > 992 ? "29vw" : "57vw",
                                  bottom: "54px",
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "20vw",
                                }}
                              >
                                <Label2
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userCountryName}
                                >
                                  {field.label}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label2>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userCountryName ||
                                        ""
                                  }
                                  defaultValue={userCountryName}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : field.name === "applianceName" ? (
                          <>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              selectedAppliance={selectedAppliance}
                            >
                              {field.label}:
                            </Label>
                            <ApplianceSelector
                              key={applianceSelectorKey}
                              onChange={handleApplianceChange}
                            />
                          </>
                        ) : field.name === "currency" ||
                          field.name === "industryOfOperations" ||
                          field.name === "ownershipType" ||
                          field.name === "applicantRelationship" ? (
                          <div>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <Select
                              name={field.name}
                              {...register(field.name, {
                                required: field.required,
                              })}
                              defaultValue={isDirty ? undefined : ""}
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                if (
                                  selectedValue &&
                                  !field.options.find(
                                    (option) => option.value === selectedValue
                                  )?.disabled
                                ) {
                                  handleInputChange(
                                    field.name,
                                    selectedValue,
                                    field.type
                                  );
                                }
                              }}
                            >
                              <option value="" disable hidden></option>
                              {field.options.map((option) => (
                                <option
                                  key={option.value}
                                  value={option.value}
                                  disabled={option.disabled}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                          </div>
                        ) : (
                          <>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              selectedPowerRating={selectedPowerRating}
                              selectedQuantity={selectedQuantity}
                              selectedUsageHours={selectedUsageHours}
                            >
                              {field.label}
                              {(field.required ||
                                (field.name === "assetLitigationValue" &&
                                  isLitigatedAssetsRequired) ||
                                (field.name === "liabilityLitigationValue" &&
                                  isLitigatedLiabilitiesRequired)) && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <Input
                              type="text"
                              name={
                                typeof field === "object" ? field.name : field
                              }
                              {...register(
                                typeof field === "object" ? field.name : field,
                                {
                                  required:
                                    field.name === "assetLitigationValue"
                                      ? isLitigatedAssetsRequired
                                      : field.name ===
                                        "liabilityLitigationValue"
                                      ? isLitigatedLiabilitiesRequired
                                      : field.required,
                                }
                              )}
                              defaultValue={isDirty ? undefined : ""}
                              onKeyDown={(e) => {
                                const isDecimalField = field.type === "number";

                                if (isDecimalField) {
                                  if (
                                    (e.key === "." &&
                                      !e.target.value.includes(".")) ||
                                    /[0-9]/.test(e.key) ||
                                    e.key === "Backspace" ||
                                    e.key === "Delete"
                                  ) {
                                  } else {
                                    e.preventDefault();
                                  }
                                }
                              }}
                              onChange={(e) => {
                                const fieldName =
                                  typeof field === "object"
                                    ? field.name
                                    : field;
                                let value = e.target.value;

                                if (field.type === "number") {
                                  value = value.replace(/[^0-9.]/g, "");
                                  const decimalParts = value.split(".");
                                  if (decimalParts[1]) {
                                    value =
                                      decimalParts[0] +
                                      "." +
                                      decimalParts[1].slice(0, 2);
                                  }
                                  if (fieldName === "operatingDaysPerWeek") {
                                    const numericValue = parseFloat(value);
                                    if (numericValue < 1) {
                                      value = "1";
                                    } else if (numericValue > 7) {
                                      value = "7";
                                    }
                                  } else if (
                                    fieldName === "dailyOperatingHours" ||
                                    fieldName === "dailyUsageHours" ||
                                    fieldName === "hourlyElectricitySupply"
                                  ) {
                                    const numericValue = parseFloat(value);
                                    if (numericValue < 0) {
                                      value = "1";
                                    } else if (numericValue > 24) {
                                      value = "24";
                                    }
                                  }
                                  const formattedValue =
                                    numberWithCommas(value);
                                  e.target.value = formattedValue;
                                  handleInputChange(
                                    fieldName,
                                    value,
                                    field.type
                                  );
                                } else {
                                  handleInputChange(
                                    fieldName,
                                    value,
                                    field.type
                                  );
                                }
                              }}
                              inputMode={
                                field.type === "number" ? "decimal" : "text"
                              }
                            />
                          </>
                        )}
                      </div>
                    ))}
                  </Form>{" "}
                </FormContent>
                <FormContent>
                  <Form>
                    {FORM_STEPS[currentPage].label ===
                      "Energy Demand Estimation" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "98%",
                          }}
                        >
                          <CustomFileButton
                            style={{
                              fontSize:
                                window.innerWidth > 992 ? "13px" : "12px",
                              marginBottom: "0rem",
                            }}
                            onClick={() => {
                              if (
                                selectedAppliance &&
                                selectedQuantity &&
                                selectedPowerRating &&
                                selectedUsageHours
                              ) {
                                const { instantaneousLoad, dailyEnergyNeed } =
                                  calculateLoadAndEnergy(
                                    selectedPowerRating,
                                    selectedQuantity,
                                    selectedUsageHours
                                  );
                                setTotalInstantaneousLoad(
                                  totalInstantaneousLoad + instantaneousLoad
                                );
                                setTotalDailyEnergyNeed(
                                  totalDailyEnergyNeed + dailyEnergyNeed
                                );

                                const newAppliance = {
                                  name: selectedAppliance,
                                  quantity: selectedQuantity,
                                  powerRating: selectedPowerRating,
                                  dailyUsageHours: selectedUsageHours,
                                  instantaneousLoad,
                                  dailyEnergyNeed,
                                };

                                setAppliancesList([
                                  ...appliancesList,
                                  newAppliance,
                                ]);

                                setFieldValues({
                                  ...fieldValues,
                                  applianceName: "",
                                  quantity: "",
                                  powerRating: "",
                                  dailyUsageHours: "",
                                });
                                setSelectedAppliance("");
                                setSelectedPowerRating("");
                                setSelectedQuantity("");
                                setSelectedUsageHours("");
                                setValue("applianceName", "");
                                setValue("powerRating", "");
                                setValue("quantity", "");
                                setValue("dailyUsageHours", "");

                                setApplianceSelectorKey(
                                  (prevKey) => prevKey + 1
                                );
                              } else {
                                infoNotification(
                                  `Please select/enter complete information to estimate your energy demand.`
                                );
                              }
                            }}
                          >
                            Add Appliance{" "}
                            <RequiredIndicator
                              transparent={
                                totalDailyEnergyNeed && totalInstantaneousLoad
                              }
                            >
                              *
                            </RequiredIndicator>
                            <AddIcon />
                          </CustomFileButton>
                          {appliancesList.length > 0 && (
                            <div
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "13px" : "13px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "70%",
                                lineHeight: "1.2",
                                borderTop: "1px solid",
                                borderBottom: "1px solid",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                Total Load: {totalInstantaneousLoad.toFixed(1)}
                                kW
                              </div>
                              <div>
                                Daily Energy Demand:{" "}
                                {totalDailyEnergyNeed.toFixed(1)}kWh
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            position: "relative",
                            fontSize: window.innerWidth > 992 ? "13px" : "12px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "start",
                            textAlign: "left",
                            minHeight: "370px",
                            marginTop: "2%",
                            width: "100%",
                            whiteSpace: "pre-line",
                            marginBottom: "5%",
                          }}
                        >
                          {appliancesList.length > 0 && (
                            <table>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "15.5vw"
                                          : "25vw",
                                    }}
                                  >
                                    Appliance
                                  </th>

                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "7.5vw"
                                          : "12.5vw",
                                      textAlign: "center",
                                    }}
                                  >
                                    Wattage (W)
                                  </th>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "6vw"
                                          : "11.5vw",
                                      textAlign: "center",
                                    }}
                                  >
                                    Hours
                                  </th>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "6vw"
                                          : "11.5vw",
                                      textAlign: "center",
                                    }}
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "7.5vw"
                                          : "11.5vw",
                                      textAlign: "center",
                                    }}
                                  >
                                    Load (kW)
                                  </th>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "7vw"
                                          : "11.5vw",
                                      textAlign: "center",
                                    }}
                                  >
                                    Daily Demand (kWh)
                                  </th>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992 ? "4vw" : "4vw",
                                      textAlign: "center",
                                    }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {appliancesList.map((appliance, index) => (
                                  <tr key={index}>
                                    <td>{appliance.name}</td>

                                    <td
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "2.5vw",
                                      }}
                                    >
                                      {appliance.powerRating}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "2.5vw",
                                      }}
                                    >
                                      {appliance.dailyUsageHours}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "2.5vw",
                                      }}
                                    >
                                      {appliance.quantity}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "2.5vw",
                                      }}
                                    >
                                      {parseFloat(
                                        appliance.instantaneousLoad
                                      ).toFixed(1)}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "2.5vw",
                                      }}
                                    >
                                      {parseFloat(
                                        appliance.dailyEnergyNeed
                                      ).toFixed(1)}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleRemoveAppliance(index)
                                      }
                                    >
                                      <TrashCanIcon />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </>
                    )}
                  </Form>
                  <Form>
                    {FORM_STEPS[currentPage].label ===
                      "Recommended System & Benefits" && (
                      <>
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#f6ffdb",
                            borderRadius: "5px",
                            paddingTop: "20px",
                            paddingBottom: "0px",
                            padding: "10px",
                            boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.3)",
                            marginTop: "0px",
                            marginBottom: "200px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "99%",
                            }}
                          >
                            <h3
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "15px" : "14px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "25%",
                                lineHeight: "1.2",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              Energy Need
                            </h3>
                            <div
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "13px" : "12px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "75%",
                                lineHeight: "2",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                Total Load: {totalInstantaneousLoad.toFixed(1)}
                                kW
                              </div>
                              <div>
                                Daily Energy Demand:{" "}
                                {totalDailyEnergyNeed.toFixed(1)}kWh
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "99%",
                            }}
                          >
                            <h3
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "15px" : "14px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "25%",
                                lineHeight: "1.2",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "nowrap",
                              }}
                            >
                              System Size<sup>*</sup>
                            </h3>
                            <div
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "13px" : "12px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "75%",
                                lineHeight: "2",
                                borderTop: "1px solid",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                Solar Panels:{" "}
                                {Math.ceil(calculateSolarPanels())}kW
                              </div>
                              <div>
                                Hybrid Inverter:{" "}
                                {Math.ceil(calculateHybridInverter())}kVA
                              </div>
                              <div>
                                Batteries: 48V{" "}
                                {addCommas(Math.ceil(calculateBatteries()))}Ah
                                LiFePO<sub>4</sub>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "99%",
                            }}
                          >
                            <h3
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "15px" : "14px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "25%",
                                lineHeight: "1.2",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "nowrap",
                              }}
                            >
                              Cost Savings<sup>†‡</sup>
                            </h3>
                            <div
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "13px" : "13px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "75%",
                                lineHeight: "2",
                                borderTop: "1px solid",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <HighlightContainer3>
                                <Highlight>
                                  <div>
                                    Savings per month:{" "}
                                    {formatLocaleCurrency(
                                      calculateSavings() || 0,
                                      exchangeRate ? currencyCode : "USD"
                                    )}{" "}
                                    {nonSolarCost !== null &&
                                      solarCost !== null && (
                                        <>
                                          (
                                          {(
                                            ((nonSolarCost - solarCost) /
                                              nonSolarCost) *
                                            100
                                          ).toFixed(1)}
                                          % )
                                        </>
                                      )}
                                  </div>
                                </Highlight>
                              </HighlightContainer3>

                              <div>
                                {" "}
                                Solar power cost/month:{" "}
                                {formatLocaleCurrency(
                                  solarCost || 0,
                                  exchangeRate ? currencyCode : "USD"
                                )}{" "}
                              </div>

                              <div>
                                Old power cost/month:{" "}
                                {formatLocaleCurrency(
                                  nonSolarCost || 0,
                                  exchangeRate ? currencyCode : "USD"
                                )}{" "}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "99%",
                            }}
                          >
                            <h3
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "15px" : "14px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "25%",
                                lineHeight: "1.2",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "nowrap",
                              }}
                            >
                              Abated
                              <br />
                              Emissions
                            </h3>
                            <div
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "13px" : "12px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "75%",
                                lineHeight: "2",
                                borderTop: "1px solid",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                {addCommas(
                                  (
                                    (avoidedEmissions * 365.25) /
                                    (12 * 1000)
                                  ).toFixed(2)
                                )}{" "}
                                metric tons of CO<sub>2</sub> eq. per month
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize:
                                window.innerWidth > 992 ? "10.5px" : "10px",
                              padding: "5px 0px",
                              paddingBottom: "0px",
                              marginLeft: "7px",
                              color: "#245e41",
                              width: "99%",
                              lineHeight: "1.4",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "left",
                              justifyContent: "center",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            <div>
                              * Kepler Solutions is not liable for any
                              experiences or damages incurred by you
                              independently using this estimate to design power
                              solutions.
                            </div>
                            <div>
                              † This is not a quote but an indication of
                              potential savings by switching to appropriately
                              sized solar system based on your input.
                            </div>
                            <div>
                              ‡ Does not consider the benefit of feed-in-tariffs
                              (FIT) where they exist.
                            </div>
                            <div
                              style={{
                                textAlign: "center",
                                color: "#1b2e10",
                                fontSize: "10px",
                                paddingTop: "10px",
                              }}
                            >
                              ©{" "}
                              {new Date().toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}{" "}
                              Kepler Solutions
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Form>
                </FormContent>
                <div
                  className="button-container"
                  style={{
                    position: "relative",
                    width: window.innerWidth > 992 ? "100%" : "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  {" "}
                  {currentPage === 0 && (
                    <button
                      type="button"
                      style={{
                        border: "none",
                        background: "transparent",
                      }}
                      disabled
                    ></button>
                  )}
                  {currentPage > 0 && (
                    <Button type="button" onClick={handlePreviousPage}>
                      <PreviousIcon />
                      Previous
                    </Button>
                  )}
                  {currentPage < FORM_STEPS.length - 1 && (
                    <NextButton
                      type="button"
                      onClick={handleNextPage}
                      isValid={isValid}
                      navigationAllowed={isNavigationAllowed(currentPage + 1)}
                      nextNavigationAllowed={isNavigationAllowed(
                        currentPage + 2
                      )}
                      disabled={
                        !isValid || !isNavigationAllowed(currentPage + 1)
                      }
                    >
                      Next
                      <NextIcon />
                    </NextButton>
                  )}
                  {currentPage === FORM_STEPS.length - 1 && (
                    <SubmitButton
                      type="submit"
                      disabled={
                        !isValid || !isNavigationAllowed(currentPage + 1)
                      }
                      className={
                        !isValid || !isNavigationAllowed(currentPage + 1)
                          ? "disabled"
                          : ""
                      }
                    >
                      <FormIcon />
                      Submit
                    </SubmitButton>
                  )}
                </div>
              </form>
            </FormContainer>
          </div>
          <div>
            <Copyright2>
              &copy; {copyrightText} All rights reserved &nbsp;|&nbsp; Built in{" "}
              <CountryFlagIcon country="Africa" width="27px" height="14px" />
              with 💚 by Kepler Solutions
            </Copyright2>
          </div>
        </div>
      </Element>
    </>
  );
};

export default BusinessForm;
