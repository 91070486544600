import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import axiosInstance from "./utils/axiosInstance";
import { auth } from "./components/utils/fireBaseConfig";
import { signOut, onAuthStateChanged } from "firebase/auth";
import CustomNavbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoadingAnimation from "./components/LoadingAnimation";
import LandingSections from "./components/LandingSections";
import Solutions from "./components/Solutions";
import About from "./components/About";
import Insights from "./components/insights/Insights";
import InsightDetails from "./components/insights/InsightDetails";
import Jobs from "./components/Jobs";
import Legal from "./components/Legal";
import Demo from "./components/DemoForm";
import Minigrid from "./components/MinigridForm";
import Signup from "./components/Auth/SignUp";
import BusinessForm from "./components/BusinessForm";
import HomeForm from "./components/HomeForm";
import { FormProvider } from "react-hook-form";
import ForgotPassword from "./components/Auth/ForgotPassword";
import NotificationBanner from "./components/Notifications";
import VerifyCode from "./components/Auth/VerifyCode";
import NotFound from "./components/NotFound";
import GuidedPurchaseForm from "./components/GuidedPurchaseForm";
import PowerasaServiceForm from "./components/PowerasaServiceForm";
import DirectPurchaseForm from "./components/DirectPurchaseForm";
import UserAccountDashboard from "./components/userDashboard/UserAccountDashboard";
import UserSettings from "./components/userDashboard/UserSettings";
import CookieConsent from "./components/CookieConsent";
import AccountLink from "./components/userDashboard/LinkAccounts";
import Unsubscribe from "./components/EmailUnsubscribe";

import AdminDashboard from "./components/admin/AdminDashboard";
import AdminInsightForm from "./components/admin/AdminInsightEditor";
import AdminSendEmail from "./components/admin/AdminSendEmail";
import PowerGraphs from "./components/admin/PowerGraphs";

const showCustomNotification = (message) => {
  let notification = document.createElement("div");
  notification.textContent = message;
  notification.style.cssText = `
      position: fixed;
      top: 90vh;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 14px;
      z-index: 9999;
      opacity: 0;
      transition: opacity 0.5s ease;
    `;
  document.body.appendChild(notification);

  setTimeout(() => {
    notification.style.opacity = 1;
  }, 500);
  setTimeout(() => {
    notification.style.opacity = 0;
    setTimeout(() => {
      document.body.removeChild(notification);
    }, 800);
  }, 6000);
};

const AppWrapper = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(() => {
    const sessionLoading = sessionStorage.getItem("loading");
    return sessionLoading ? sessionLoading !== "true" : true;
  });
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [footerLoading, setFooterLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [notification, setNotification] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [shouldShowNavbar, setShouldShowNavbar] = useState(true);
  const [shouldShowFooter, setShouldShowFooter] = useState(true);
  const [isHPBannerVisible, setIsHPBannerVisible] = useState(false);
  const [hPBannerMessage, setHPBannerMessage] = useState("");
  const [csrfTokenExpiry, setCsrfTokenExpiry] = useState(null);
  const isDevelopment = process.env.NODE_ENV === "development";

  const fetchCsrfToken = async () => {
    try {
      const response = await axiosInstance.get("api/csrf-token");
      if (isDevelopment) {
        document.cookie = `csrfToken=${response.data.csrfToken}; path=/`;
        document.cookie = `sessionID=${response.data.sessionID}; path=/`;
      }
      const { expiry } = response.data;
      setCsrfTokenExpiry(new Date(expiry));
    } catch (error) {}
  };

  useEffect(() => {
    fetchCsrfToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (csrfTokenExpiry) {
      const remainingTime = csrfTokenExpiry.getTime() - Date.now();
      if (remainingTime > 0) {
        const timer = setTimeout(() => {
          handleTokenExpiry();
        }, remainingTime);
        return () => clearTimeout(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csrfTokenExpiry]);

  const handleTokenExpiry = async () => {
    if (user) {
      await handleLogout();
    } else {
      await fetchCsrfToken();
      showCustomNotification("Stale session has been renewed. Continue.");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth, user);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
      infoNotification(`Your session has expired. Please log in again.`);
      setUser(null);
      fetchCsrfToken();
      setTimeout(() => {
        showCustomNotification(
          "Your session has expired. Please log in again."
        );
      }, 5000);
    } catch (error) {}
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        try {
          const response = await axiosInstance.get(
            `api/user/${currentUser.uid}`
          );
          if (response.status === 200) {
            setUser({ ...currentUser, ...response.data });
          } else {
          }
        } catch (error) {}
      }
    });
    return () => unsubscribeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogoLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 15000);
  }, []);

  useEffect(() => {
    if (!loading) {
      const delay = setTimeout(() => {
        setLoadingComplete(true);
      }, 0);
      return () => clearTimeout(delay);
    }
  }, [loading]);

  useEffect(() => {
    const hideNavbarOnPaths = [
      "/signup",
      "/forgot-password",
      "/verify",
      "/business-form",
      "/home-form",
      "/guided-purchase",
      "/direct-purchase",
      "/customer-dashboard",
      "/user-settings",
    ];
    const hideFooterOnPaths = [
      "/signup",
      "/forgot-password",
      "/verify",
      "/business-form",
      "/home-form",
      "/guided-purchase",
      "/direct-purchase",
    ];

    setShouldShowNavbar(!hideNavbarOnPaths.includes(location.pathname));
    setShouldShowFooter(!hideFooterOnPaths.includes(location.pathname));

    if (location.pathname === "*") {
      setShouldShowNavbar(false);
      setShouldShowFooter(false);
    }
  }, [location.pathname]);

  const successNotification = async (message) => {
    const type = "success";
    const notificationDuration = 4000;
    setNotification({ message, type, notificationDuration });
    setIsVisible(true);
  };

  const warningNotification = async (message) => {
    const type = "warning";
    const notificationDuration = 4000;
    setNotification({ message, type, notificationDuration });
    setIsVisible(true);
  };

  const errorNotification = async (message) => {
    const type = "error";
    const notificationDuration = 4000;
    setNotification({ message, type, notificationDuration });
    setIsVisible(true);
  };

  const infoNotification = async (message) => {
    const type = "info";
    const notificationDuration = 4000;
    setNotification({ message, type, notificationDuration });
    setIsVisible(true);
  };

  useEffect(() => {
    if (isVisible && notification.notificationDuration) {
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, notification.notificationDuration);

      return () => clearTimeout(timeout);
    }
  }, [isVisible, notification?.notificationDuration, setIsVisible]);

  const showBanner = (hPBannerMessage) => {
    setHPBannerMessage(hPBannerMessage);
    setIsHPBannerVisible(!isHPBannerVisible);
  };

  const fetchBannerMessage = async () => {
    try {
      const response = await axiosInstance.get("/api/banner-message");
      if (response.status === 200) {
        const bannerMessage = response.data.message;
        if (bannerMessage.trim() !== "") {
          setHPBannerMessage(bannerMessage);
          showBanner(bannerMessage);
        }
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBannerMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  axiosInstance.defaults.logout = handleLogout;

  return (
    <Container fluid className="custom-container">
      <div style={{ position: "relative" }}>
        {loading && (
          <LoadingAnimation loading={loading} isLoading={footerLoading} />
        )}
        <div
          style={{
            opacity: loadingComplete ? 1 : 0,
            pointerEvents: loadingComplete ? "auto" : "none",
            position: loadingComplete ? "static" : "absolute",
            transition: "opacity 1s ease",
          }}
        >
          {shouldShowNavbar && (
            <CustomNavbar
              onLogoLoad={handleLogoLoad}
              SetLoading={setFooterLoading}
              user={user}
              setUser={setUser}
              infoNotification={infoNotification}
              isHPBannerVisible={isHPBannerVisible}
              hPBannerMessage={hPBannerMessage}
            />
          )}
          <FormProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <LandingSections
                    isLoading={footerLoading}
                    isHPBannerVisible={isHPBannerVisible}
                  />
                }
              />
              <Route
                path="/solutions"
                element={<Solutions isHPBannerVisible={isHPBannerVisible} />}
              />
              <Route
                path="/about"
                element={<About isHPBannerVisible={isHPBannerVisible} />}
              />
              <Route
                path="/jobs"
                element={<Jobs isHPBannerVisible={isHPBannerVisible} />}
              />
              <Route
                path="/insights"
                element={<Insights isHPBannerVisible={isHPBannerVisible} />}
              />
              <Route
                path="/insights/:id"
                element={
                  <InsightDetails isHPBannerVisible={isHPBannerVisible} />
                }
              />
              <Route
                path="/legal"
                element={<Legal isHPBannerVisible={isHPBannerVisible} />}
              />
              <Route
                path="/demo"
                element={
                  <Demo
                    user={user}
                    successNotification={successNotification}
                    warningNotification={warningNotification}
                    errorNotification={errorNotification}
                    infoNotification={infoNotification}
                    isHPBannerVisible={isHPBannerVisible}
                  />
                }
              />
              <Route
                path="/minigrid"
                element={
                  <Minigrid
                    user={user}
                    successNotification={successNotification}
                    warningNotification={warningNotification}
                    errorNotification={errorNotification}
                    infoNotification={infoNotification}
                    isHPBannerVisible={isHPBannerVisible}
                  />
                }
              />
              <Route
                path="/signup"
                element={
                  user && user.emailVerified ? (
                    <Navigate to="/" replace />
                  ) : (
                    <Signup
                      user={user}
                      setUser={setUser}
                      successNotification={successNotification}
                      warningNotification={warningNotification}
                      errorNotification={errorNotification}
                      infoNotification={infoNotification}
                    />
                  )
                }
              />
              <Route
                path="/verify"
                element={
                  user && user.emailVerified ? (
                    <Navigate to="/" replace />
                  ) : (
                    <VerifyCode
                      successNotification={successNotification}
                      warningNotification={warningNotification}
                      errorNotification={errorNotification}
                      infoNotification={infoNotification}
                    />
                  )
                }
              />
              <Route
                path="/business-form"
                element={
                  user && user.emailVerified ? (
                    <BusinessForm
                      user={user}
                      successNotification={successNotification}
                      warningNotification={warningNotification}
                      errorNotification={errorNotification}
                      infoNotification={infoNotification}
                    />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route
                path="/home-form"
                element={
                  user && user.emailVerified ? (
                    <HomeForm
                      user={user}
                      successNotification={successNotification}
                      warningNotification={warningNotification}
                      errorNotification={errorNotification}
                      infoNotification={infoNotification}
                    />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <ForgotPassword
                    successNotification={successNotification}
                    warningNotification={warningNotification}
                    errorNotification={errorNotification}
                    infoNotification={infoNotification}
                  />
                }
              />
              <Route
                path="/guided-purchase"
                element={
                  user && user.emailVerified ? (
                    <GuidedPurchaseForm
                      user={user}
                      successNotification={successNotification}
                      warningNotification={warningNotification}
                      errorNotification={errorNotification}
                      infoNotification={infoNotification}
                    />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route
                path="/direct-purchase"
                element={
                  <DirectPurchaseForm
                    user={user}
                    successNotification={successNotification}
                    warningNotification={warningNotification}
                    errorNotification={errorNotification}
                    infoNotification={infoNotification}
                  />
                }
              />
              <Route
                path="/admin-dashboard"
                element={
                  <AdminDashboard
                    showBanner={showBanner}
                    hPBannerMessage={hPBannerMessage}
                    setHPBannerMessage={setHPBannerMessage}
                    isHPBannerVisible={isHPBannerVisible}
                  />
                }
              />
              <Route
                path="/insight-editor"
                element={
                  <AdminInsightForm isHPBannerVisible={isHPBannerVisible} />
                }
              />
              <Route
                path="/send-email"
                element={
                  <AdminSendEmail isHPBannerVisible={isHPBannerVisible} />
                }
              />
              <Route
                path="/email-preferences"
                element={
                  <Unsubscribe
                    isHPBannerVisible={isHPBannerVisible}
                    user={user}
                  />
                }
              />
              <Route
                path="/power-graphs"
                element={
                  <PowerGraphs isHPBannerVisible={isHPBannerVisible} />
                }
              />
              <Route
                path="/power-as-a-service"
                element={
                  <PowerasaServiceForm
                    user={user}
                    successNotification={successNotification}
                    warningNotification={warningNotification}
                    errorNotification={errorNotification}
                    infoNotification={infoNotification}
                    isHPBannerVisible={isHPBannerVisible}
                  />
                }
              />
              <Route
                path="/customer-dashboard"
                element={
                  user && user.emailVerified ? (
                    <UserAccountDashboard
                      user={user}
                      setUser={setUser}
                      infoNotification={infoNotification}
                    />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route
                path="/user-settings"
                element={
                  user && user.emailVerified ? (
                    <UserSettings
                      user={user}
                      setUser={setUser}
                      successNotification={successNotification}
                      warningNotification={warningNotification}
                      errorNotification={errorNotification}
                      infoNotification={infoNotification}
                    />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route
                path="/link-accounts"
                element={
                  user && user.emailVerified ? (
                    <AccountLink
                      user={user}
                      successNotification={successNotification}
                      warningNotification={warningNotification}
                      errorNotification={errorNotification}
                      infoNotification={infoNotification}
                    />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route
                path="*"
                element={
                  <NotFound
                    isHPBannerVisible={isHPBannerVisible}
                    setShouldShowNavbar={setShouldShowNavbar}
                    setShouldShowFooter={setShouldShowFooter}
                  />
                }
              />
            </Routes>
          </FormProvider>
          {shouldShowFooter && <Footer setLoading={setFooterLoading} />}
          {notification && (
            <NotificationBanner
              message={notification.message}
              type={notification.type}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          )}
        </div>
      </div>
      <CookieConsent onConsent={fetchCsrfToken} />
    </Container>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <AppWrapper />
    </BrowserRouter>
  );
};

export default App;
