import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { RightIcon } from "./Icons";
import {
  GlobalStyle,
  RestHeading,
  Paragraph,
  Highlight,
  HighlightContainer,
} from "./StyledComponents";
import { LinkedInIcon, TwitterIcon } from "./Icons";

import LoadingAnimation from "./LoadingAnimation";
import image21 from "../assets/images/grass1.svg";
import image22 from "../assets/images/grass2.svg";
import image23 from "../assets/images/grass3.svg";
import image24 from "../assets/images/cofounder1.svg";
import image25 from "../assets/images/cofounder2.svg";
import image26 from "../assets/images/background2.svg";

const About = ({ isHPBannerVisible }) => {
  const images = useMemo(
    () => [image21, image22, image23, image24, image25],
    []
  );
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const preloadImages = async () => {
      try {
        await Promise.all(
          images.map((imgSrc) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = imgSrc;
              img.onload = () => {
                resolve();
              };
              img.onerror = (error) => {
                console.error(`Error loading image ${imgSrc}:`, error);
                reject();
              };
            });
          })
        );
        setImagesLoaded(true);
      } catch (error) {
        console.error("Error preloading images:", error);
      }
    };

    preloadImages();
  }, [images]);

  return (
    <>
      <GlobalStyle />
      <Element
        name="about-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
          backgroundImage: `url(${image26})`,
          backgroundSize: "contain",
          backgroundPosition: window.innerWidth > 992 ? "" : "top left",
        }}
      >
        <div
          style={{
            position: "relative",
            paddingLeft: "3%",
            height: "10px",
            zIndex: 3,
            display: window.innerWidth <= 991 ? "block" : "none",
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            Home{" "}
          </Link>
          <RightIcon /> About Us
        </div>
        <div
          style={{
            height: imagesLoaded ? "" : "1000vh",
            paddingTop: "0vw",
            paddingBottom: "0vw",
          }}
        >
          {imagesLoaded ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                paddingLeft: window.innerWidth >= 992 ? "10%" : "5%",
                paddingRight: window.innerWidth >= 992 ? "10%" : "2%",
                marginTop: window.innerWidth >= 992 ? "0%" : "10px",
                paddingBottom: "5%",
              }}
            >
              <RestHeading
                style={{
                  paddingTop: "4%",
                  paddingBottom: "3%",
                }}
              >
                <HighlightContainer>
                  <Highlight> The Kepler Way</Highlight>
                </HighlightContainer>
              </RestHeading>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "stretch",
                  justifyContent: "left",
                }}
              >
                <img
                  src={image21}
                  alt="Grass"
                  style={{
                    width: "30%",
                    height: "100%",
                    marginBottom: window.innerWidth >= 600 ? "5%" : "10%",
                    borderRadius: "5px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "65%",
                    marginBottom: window.innerWidth >= 600 ? "5%" : "10%",
                    marginLeft: "5%",
                    paddingLeft: "1%",
                    paddingRight: "1%",
                  }}
                >
                  <Paragraph
                    style={{
                      fontWeight: "bold",
                      fontSize:
                        window.innerWidth > 400
                          ? "1.5rem"
                          : window.innerWidth > 300
                          ? "1.35rem"
                          : "1rem",
                      paddingBottom: "1%",
                    }}
                  >
                    Our Mission
                  </Paragraph>
                  <p
                    style={{
                      fontSize:
                        window.innerWidth > 400
                          ? "1rem"
                          : window.innerWidth > 300
                          ? "0.9rem"
                          : "0.8rem",
                    }}
                  >
                    Kepler Clean Energy and Sustainability Solutions Ltd. (also
                    known as Kepler Solutions), is addressing the significant
                    energy access challenges faced by many regions in Africa and
                    the Middle East. These challenges are rooted in issues such
                    as inadequate financing, ineffective policy formulation, and
                    a lack of awareness regarding clean energy solutions. These
                    factors not only perpetuate poverty but also contribute to
                    other alarming impacts of climate change.
                  </p>
                  <p
                    style={{
                      fontSize:
                        window.innerWidth > 400
                          ? "1rem"
                          : window.innerWidth > 300
                          ? "0.9rem"
                          : "0.8rem",
                    }}
                  >
                    Our mission is to provide affordable and clean alternative
                    energy solutions in these underserved areas. We aim to
                    enhance the quality of life for all residents. We firmly
                    believe that access to energy is a fundamental human right,
                    and we are unwavering in our commitment to transforming this
                    belief into a reality for every individual while
                    safeguarding the health of our planet.
                  </p>
                  <p
                    style={{
                      fontSize:
                        window.innerWidth > 400
                          ? "1rem"
                          : window.innerWidth > 300
                          ? "0.9rem"
                          : "0.8rem",
                    }}
                  >
                    Our team works tirelessly to drive innovation, introduce
                    sustainable financing solutions, and offer you cutting-edge
                    technologies.
                  </p>
                  <p
                    style={{
                      fontSize:
                        window.innerWidth > 400
                          ? "1rem"
                          : window.innerWidth > 300
                          ? "0.9rem"
                          : "0.8rem",
                    }}
                  >
                    Join us on our journey towards a brighter, cleaner, and more
                    equitable future powered by sustainable energy solutions.
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "stretch",
                  justifyContent: "left",
                }}
              >
                <img
                  src={image22}
                  alt="Grass with two-pin electric socket"
                  style={{
                    width: "30%",
                    height: "100%",
                    marginBottom: window.innerWidth >= 600 ? "5%" : "10%",
                    borderRadius: "5px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "65%",
                    marginBottom: window.innerWidth >= 600 ? "5%" : "10%",
                    marginLeft: "5%",
                    paddingLeft: "1%",
                    paddingRight: "1%",
                  }}
                >
                  <Paragraph
                    style={{
                      fontWeight: "bold",
                      fontSize:
                        window.innerWidth > 400
                          ? "1.5rem"
                          : window.innerWidth > 300
                          ? "1.35rem"
                          : "1rem",
                      paddingBottom: "1%",
                    }}
                  >
                    Our Solutions
                  </Paragraph>
                  <p
                    style={{
                      fontSize:
                        window.innerWidth > 400
                          ? "1rem"
                          : window.innerWidth > 300
                          ? "0.9rem"
                          : "0.8rem",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>
                      1. Clean Energy Product Range (Solar):
                    </span>{" "}
                    Power-as-a-Service (PaaS)- Distributed captive power
                    management, Rent-to-Own (RTO)- Access clean energy solutions
                    affordably, Energy Audits & Warranties- Ensure efficiency
                    and reliability.
                  </p>
                  <p
                    style={{
                      fontSize:
                        window.innerWidth > 400
                          ? "1rem"
                          : window.innerWidth > 300
                          ? "0.9rem"
                          : "0.8rem",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>
                      2 Credit-Financed Renewable Energy Projects:
                    </span>{" "}
                    We leverage various sources of financing, primarily debt, to
                    develop renewable energy solutions for businesses and
                    households. Our focus is on unlocking access to everyone.
                  </p>
                  <p
                    style={{
                      fontSize:
                        window.innerWidth > 400
                          ? "1rem"
                          : window.innerWidth > 300
                          ? "0.9rem"
                          : "0.8rem",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>
                      3. Data & Carbon Trading:
                    </span>{" "}
                    Financial credibility APIs empowering other businesses,
                    Carbon trading contributing to a greener world.
                  </p>
                  <p
                    style={{
                      fontSize:
                        window.innerWidth > 400
                          ? "1rem"
                          : window.innerWidth > 300
                          ? "0.9rem"
                          : "0.8rem",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>
                      4. Diverse Clean Energy Sources:
                    </span>{" "}
                    Hydropower, Wind, geothermal, hydrogen, nuclear, and wave
                    energy. Energy Storage ensuring uninterrupted power supply,
                    Environmental Solutions - remediation and recycling,
                    Cutting-edge technology and a robust supply chain.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "fixed",
                top: 0,
                width: "100%",
              }}
            >
              <LoadingAnimation loading={true} />
            </div>
          )}
        </div>
      </Element>
    </>
  );
};

export default About;
