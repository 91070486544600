import React from "react";
import { useMediaQuery } from "react-responsive";

const PaymentTable = ({ payment }) => {
  const fieldMappings = {
    lease: { label: "Lease ID:" },
    paymentType: { label: "Payment Type:" },
    bank: { label: "Originating Bank:" },
    paymentID: { label: "Transaction Reference:" },
  };

  const isMobile = useMediaQuery({ maxWidth: 992 });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>Field</th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(fieldMappings).map(([key, { label }]) => (
          <tr key={key} style={{ fontSize: isMobile ? "10px" : "13px" }}>
            <td style={{ width: isMobile ? "78%" : "80%" }}>{label}</td>
            <td style={{ width: isMobile ? "22%" : "20%" }}>
              {key === "paymentType"
                ? capitalizeFirstLetter(payment[key] || "-")
                : payment[key] != null
                ? payment[key]
                : "-"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PaymentTable;
