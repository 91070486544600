import React, { useState, useEffect } from "react";
import { StyledCarbonoffset } from "./StyledCarbonOffset";

const CarbonOffsetAnimation = () => {
  const [viewBox, setViewBox] = useState("160 7 30 160");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let newViewBox;

      if (width > 1500) {
        newViewBox = "40 7 130 160";
      } else if (width > 1200) {
        newViewBox = "90 5 150 175";
      } else if (width > 992) {
        newViewBox = "20 -5 360 200";
      } else if (width > 768) {
        newViewBox = "130 5 80 215";
      } else if (width > 600) {
        newViewBox = "170 10 100 240";
      } else if (width > 490) {
        newViewBox = "210 10 110 300";
      } else if (width > 440) {
        newViewBox = "270 10 100 370";
      } else if (width > 370) {
        newViewBox = "320 10 90 410";
      } else if (width > 340) {
        newViewBox = "350 10 100 450";
      } else if (width > 280) {
        newViewBox = "390 0 110 475";
      } else {
        newViewBox = "460 -10 200 600";
      }

      setViewBox(newViewBox);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <StyledCarbonoffset>
      <svg
        width="290mm"
        height="160mm"
        version="1.1"
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <rect
            class="backdrop"
            x="10.721"
            y="9.8889"
            width="187.6"
            height="74.429"
            stroke-linejoin="bevel"
            stroke-width=".69318"
          />
          <text
            class="NO2"
            transform="scale(.98507 1.0152)"
            x="18.387589"
            y="62.360649"
            font-weight="bold"
            stroke-width=".87545"
          >
            <tspan
              x="18.387589"
              y="62.360649"
              font-weight="bold"
              stroke-width=".87545"
            >
              NITROUS-OXIDE (N
              <tspan baseline-shift="sub" font-weight="bold">
                2
              </tspan>
              O)
            </tspan>
          </text>
          <text
            class="CH4"
            transform="scale(.98507 1.0152)"
            x="18.733311"
            y="69.919952"
            font-weight="bold"
            stroke-width=".87545"
          >
            <tspan
              x="18.733311"
              y="69.919952"
              font-weight="bold"
              stroke-width=".87545"
            >
              METHANE (CH
              <tspan baseline-shift="sub" font-weight="bold">
                4
              </tspan>
              )
            </tspan>
          </text>
          <text
            class="SFC"
            transform="scale(1.048 .95416)"
            x="17.235804"
            y="25.914639"
            font-weight="bold"
            stroke-width=".87545"
          >
            <tspan
              x="17.235804"
              y="25.914639"
              font-weight="bold"
              stroke-width=".87545"
            >
              SULPHUR-HEXAFLUORIDE (SF
              <tspan baseline-shift="sub" font-weight="bold">
                6
              </tspan>
              )
            </tspan>
          </text>
          <text
            class="PFC"
            transform="scale(.98507 1.0152)"
            x="18.239422"
            y="47.538372"
            font-weight="bold"
            stroke-width=".87545"
          >
            <tspan
              x="18.239422"
              y="47.538372"
              font-weight="bold"
              stroke-width=".87545"
            >
              PERFLUOROCARBONS (PFC)
            </tspan>
          </text>
          <text
            class="carbonOffset1"
            transform="scale(.98507 1.0152)"
            x="18.674046"
            y="40.472958"
            font-weight="bold"
            stroke-width=".87545"
          >
            <tspan
              x="18.674046"
              y="40.472958"
              font-weight="bold"
              stroke-width=".87545"
            >
              CARBON OFFSETS
            </tspan>
          </text>
          <text
            class="carbonOffset2"
            transform="scale(.98507 1.0152)"
            x="18.674046"
            y="40.472958"
            font-weight="bold"
            stroke-width=".87545"
          >
            <tspan
              x="18.674046"
              y="40.472958"
              font-weight="bold"
              stroke-width=".87545"
            >
              CARBON OFFSETS
            </tspan>
          </text>
          <text
            class="HFC"
            transform="scale(.98507 1.0152)"
            x="18.387589"
            y="32.419765"
            font-weight="bold"
            stroke-width=".87545"
          >
            <tspan
              x="18.387589"
              y="32.419765"
              font-weight="bold"
              stroke-width=".87545"
            >
              HYDROFLUOROCARBONS (HFC)
            </tspan>
          </text>
          <text
            class="CO2"
            transform="scale(1.048 .95416)"
            x="17.522259"
            y="58.134331"
            font-weight="bold"
            stroke-width=".87545"
          >
            <tspan
              x="17.522259"
              y="58.134331"
              font-weight="bold"
              stroke-width=".87545"
            >
              CARBON-DIOXIDE (CO
              <tspan baseline-shift="sub" font-weight="bold">
                2
              </tspan>
              )
            </tspan>
          </text>
          <g
            class="cert1"
            transform="matrix(-.00033702 -.44823 .48682 .00070452 139.19 144.08)"
          >
            <rect x="133.12" y="49.255" width="44.798" height="61.681" />
            <path d="m140.7 63.259h30.467" />
            <path d="m140.71 71.963h30.467" />
            <path d="m140.71 80.668h30.467" />
            <path d="m146.38 98.116 3.8374 7.0934h1.3954l10.672-17.409-4.1598-0.04111-7.2097 15.357-2.8772-5.8225z" />
          </g>
          <g
            class="cert2"
            transform="matrix(.40434 -.4882 .51366 .40837 81.979 104.11)"
          >
            <rect x="133.03" y="50.7" width="44.886" height="60.236" />
            <path d="m140.7 63.259h30.467" />
            <path d="m140.71 71.963h30.467" />
            <path d="m140.71 80.668h30.467" />
            <path d="m146.38 98.116 3.8374 7.0934h1.3954l10.672-17.409-4.1598-0.04111-7.2097 15.357-2.8772-5.8225z" />
          </g>
          <g
            class="cert3"
            transform="matrix(.75263 -.29871 .28862 .77894 61.109 37.68)"
          >
            <rect x="133.03" y="50.7" width="44.886" height="60.236" />
            <path d="m140.7 63.259h30.467" />
            <path d="m140.71 71.963h30.467" />
            <path d="m140.71 80.668h30.467" />
            <path d="m146.38 98.116 3.8374 7.0934h1.3954l10.672-17.409-4.1598-0.04111-7.2097 15.357-2.8772-5.8225z" />
          </g>
          <g class="cert4" transform="translate(60.056 -26.668)">
            <rect x="133.03" y="50.7" width="44.886" height="60.236" />
            <path d="m140.7 63.259h30.467" />
            <path d="m140.71 71.963h30.467" />
            <path d="m140.71 80.668h30.467" />
            <path
              class="check1"
              d="m146.38 98.116 3.8374 7.0934h1.3954l10.672-17.409-4.1598-0.04111-7.2097 15.357-2.8772-5.8225z"
            />
            <path
              class="check2"
              d="m146.38 98.116 3.8374 7.0934h1.3954l10.672-17.409-4.1598-0.04111-7.2097 15.357-2.8772-5.8225z"
            />
          </g>
        </g>
      </svg>
    </StyledCarbonoffset>
  );
};

export default CarbonOffsetAnimation;
