export const FORM_STEPS = [
  {
    label: "Components",
    fields: [
      {
        name: "component",
        label: "Component",
        options: [
          { label: "Solar Panel", value: "Solar Panels" },
          { label: "Hybrid Inverter", value: "Hybrid Inverter" },
          { label: "Battery-based Inverter", value: "Battery Inverter" },
          { label: "Grid Tie Inverter", value: "Grid Tie Inverter" },
          { label: "MPPT Charge Controller", value: "MPPT Controller" },
          { label: "PWM Charge Controller", value: "PWM Controller" },
          { label: "Lithium Iron Phosphate Battery", value: "LiFePO4" },
          { label: "Lead-acid Tubular Battery", value: "Tubular Battery" },
          { label: "Solar Water Pump", value: "Solar Pump" },
        ],
      },
      {
        name: "specification",
        label:
          "Specification (e.g. 550W, 3.5kVA 48V, 12V 30A,  10kWh 48V, 220Ah 12V)",
      },
      {
        name: "quantity",
        label: "Quantity",
        type: "number",
      },
      {
        name: "buyersComment",
        label: "Preferred Brand and Other Considerations",
      },
    ],
  },
  {
    label: "Contact Info",
    fields: [
      {
        name: "contactPerson",
        label: "Name of Contact Person",
        required: true,
      },
      {
        name: "serviceEmail",
        label: "Email",
        required: true,
      },
      {
        name: "servicePhone",
        label: "Phone",
        type: "phone",
        required: true,
      },
      {
        name: "serviceAddress",
        label: "Business/Home Delivery Address",
        required: true,
      },
      {
        name: "serviceCity",
        label: "City/Town",
        required: true,
      },
      {
        name: "serviceState",
        label: "State/Region",
        required: true,
      },
      {
        name: "serviceCountry",
        label: "Country",
        required: true,
      },
      {
        name: "preferredContact",
        label: "Preferred Means of Contact",
        required: true,
        options: [
          { label: "Email", value: "email" },
          { label: "Phone", value: "phone" },
        ],
      },
    ],
  },
];
