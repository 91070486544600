import React, { useState, useEffect, useMemo } from "react";
import { Element } from "react-scroll";
import { auth } from "./utils/fireBaseConfig";
import {
  GlobalStyle,
  SectionContainer,
  ImageContainer,
  TextOverlay,
  SubTextOverlay,
  ContentContainer,
  RestHeading,
  Paragraph,
  ButtonContainer2,
  ParagraphContainer1,
  Button4,
  Highlight,
  HighlightContainer,
} from "./StyledComponents";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  RightIcon,
  NumberOneIcon,
  NumberTwoIcon,
  NumberThreeIcon,
  ArrowIcon,
  HomeIcon,
  BusinessIcon,
  PowerIcon,
  GuideIcon,
  GridIcon,
  CatalogIcon,
} from "./Icons";
import { Question, Answer } from "./StyledFAQ";

import LoadingAnimation from "./LoadingAnimation";
import image2 from "../assets/images/image2.svg";
import image3 from "../assets/images/image3.svg";
import image4 from "../assets/images/image4.svg";
import image5 from "../assets/images/image5.svg";
import image6 from "../assets/images/image6.svg";
import image7 from "../assets/images/image7.svg";
import image8 from "../assets/images/offgridpics.svg";
import image9 from "../assets/images/offgrid-config.svg";
import image10 from "../assets/images/solarpumppics.svg";
import image11 from "../assets/images/ongridpics.svg";
import image12 from "../assets/images/ongrid-config.svg";
import image13 from "../assets/images/background1.svg";
import image14 from "../assets/images/background2.svg";
import image15 from "../assets/images/compucalc.svg";
import image16 from "../assets/images/phonepuzzle.svg";
import image17 from "../assets/images/cardpos.svg";
import image18 from "../assets/images/minigrid.svg";
import image19 from "../assets/images/4thgenmodel.svg";
import image20 from "../assets/images/background3.svg";

const Solutions = ({ isHPBannerVisible }) => {
  const images = useMemo(
    () => [image2, image3, image4, image5, image6, image7],
    []
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

useEffect(() => {
  const preloadImages = async () => {
    try {
      const img = new Image();
      img.src = image2;
      img.onload = () => {
        setImagesLoaded(true);
      };
      img.onerror = (error) => {
        console.error(`Error loading image ${image2}:`, error);
      };
    } catch (error) {
      console.error("Error preloading images:", error);
    }
  };

  preloadImages();
}, []);


  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (sectionId) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === sectionId ? null : sectionId
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sectionId = queryParams.get("section");
    if (imagesLoaded && sectionId) {
      const sectionElement = document.getElementById(sectionId);

      if (sectionElement) {
        setTimeout(() => {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }, 0);
      } else {
        console.error("Section element not found");
      }
    }
  }, [imagesLoaded, location.search]);

  const handleBusinessApplyButton = () => {
    const isAuthenticated = auth.currentUser;
    if (!isAuthenticated || !isAuthenticated.emailVerified) {
      navigate(`/signup?type=business`);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    } else {
      navigate(`/business-form`);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    }
  };

  const handleHomeApplyButton = () => {
    const isAuthenticated = auth.currentUser;
    if (!isAuthenticated || !isAuthenticated.emailVerified) {
      navigate(`/signup?type=home`);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    } else {
      navigate(`/home-form`);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    }
  };

      const handlePowerasaServiceButton = () => {
        navigate(`/power-as-a-service`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
      };

    const handleGuidedPurchaseButton = () => {
      const isAuthenticated = auth.currentUser;
      if (!isAuthenticated || !isAuthenticated.emailVerified) {
        navigate(`/signup?type=guided`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
      } else {
        navigate(`/guided-purchase`);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 500);
      }
    };

    const handleBuyDirectButton = () => {
      navigate(`/direct-purchase`);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 500);
    };

  const handleMiniGridButton = () => {
    navigate(`/minigrid`);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  return (
    <>
      <GlobalStyle />
      <Element
        name="photo-gallery"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div
          style={{
            position: "absolute",
            paddingLeft: "3%",
            height: "10px",
            zIndex: 3,
            display: window.innerWidth <= 991 ? "block" : "none",
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            Home{" "}
          </Link>
          <RightIcon /> Solutions
        </div>
        <div
          style={{
            height: imagesLoaded ? "" : "1000vh",
            paddingTop: "0vw",
            paddingBottom: "0vw",
            backgroundImage: `url(${image13})`,
            backgroundSize: imagesLoaded ? "cover" : "contain",
            backgroundPosition: "right top",
          }}
        >
          {imagesLoaded ? (
            <ImageContainer>
              <img
                src={images[currentIndex]}
                alt="Solar panels & wind turbines."
                style={{
                  width: "100%",
                  height: "80%",
                  objectFit: "cover",
                  filter: "brightness(70%)",
                }}
              />
              <TextOverlay
                style={{
                  position: "absolute",
                  bottom: window.innerWidth > 450 ? "" : "75px",
                  paddingBottom: "0",
                  marginBottom: "0",
                }}
              >
                <p>The Kepler Solution.</p>
              </TextOverlay>
              <SubTextOverlay
                style={{
                  position: "absolute",
                  top: window.innerWidth > 450 ? "" : "30%",
                  paddingBottom: "0",
                  marginBottom: "0",
                  fontSize: window.innerWidth > 450 ? "" : "3vmax",
                }}
              >
                <p>Clean Power, Credit Access, Mini-grids in Africa!</p>
              </SubTextOverlay>
            </ImageContainer>
          ) : (
            <div
              style={{
                position: "fixed",
                top: 0,
                width: "100%",
              }}
            >
              <LoadingAnimation loading={true} />
            </div>
          )}
        </div>
      </Element>
      <Element name="solutions" id="solutions">
        <SectionContainer style={{ display: imagesLoaded ? "block" : "none" }}>
          <RestHeading
            style={{
              paddingTop: "4%",
              paddingBottom: "3%",
            }}
          >
            {" "}
            <HighlightContainer>
              <Highlight>Solutions</Highlight>
            </HighlightContainer>{" "}
            for all situations.
          </RestHeading>

          <ContentContainer
            style={{
              paddingTop: "0",
              marginBottom: "12vh",
            }}
          >
            <div
              id="off-grid"
              style={{
                width: "100%",
                height: "",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "#f3f5f5",
                borderRadius: "10px",
                padding: "1%",
                paddingLeft: "2%",
                paddingRight: "2%",
                paddingBottom: "2%",
                margin: "1%",
                border: "1px solid #b6c6c5",
              }}
            >
              <div
                style={{
                  width: window.innerWidth > 992 ? "80%" : "",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <RestHeading
                  style={{
                    textAlign: "left",
                    margin: "0",
                    fontSize: window.innerWidth > 992 ? "1.8rem" : "1.8rem",
                  }}
                >
                  Off-grid
                </RestHeading>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: window.innerWidth > 992 ? "row" : "column",
                  justifyContent:
                    window.innerWidth > 992 ? "space-between" : "center",
                  alignItems: "stretch",
                }}
              >
                <div>
                  {" "}
                  <ParagraphContainer1
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "",
                      height: window.innerWidth > 992 ? "" : "",
                      paddingLeft: window.innerWidth > 992 ? "5%" : "3%",
                      justifyContent: "flex-start",
                      lineHeight: "1.2",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#fff",
                      marginLeft: window.innerWidth > 992 ? "1%" : "",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "0",
                    }}
                  >
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        padding: "0",
                        fontSize: window.innerWidth > 992 ? "1.5rem" : "1.3rem",
                      }}
                    >
                      Off-grid Situations
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                        padding: "0",
                      }}
                    >
                      Remote Locations without access to utility power supply.
                      Rural to semi-urban.
                    </Paragraph>
                    <Question onClick={() => toggleSection("farms")}>
                      Farms
                      <ArrowIcon isOpen={openSection === "farms"} />
                    </Question>
                    <Answer isOpen={openSection === "farms"}>
                      Cash-crop, Food-crop, Dairy, Ranch, Poultry, Fishery,
                      Flower, Hay, Aquaculture, Apiary, Dry-season/Irrigation,
                      Organic, Green-House.
                    </Answer>
                    <Question onClick={() => toggleSection("factories")}>
                      Factories
                      <ArrowIcon isOpen={openSection === "factories"} />
                    </Question>
                    <Answer isOpen={openSection === "factories"}>
                      Processing, Manufacturing, Assembly, Recycling, Job shops.
                      Call-centers.
                    </Answer>
                    <Question onClick={() => toggleSection("warehouses")}>
                      Warehouses
                      <ArrowIcon isOpen={openSection === "warehouses"} />
                    </Question>
                    <Answer isOpen={openSection === "warehouses"}>
                      Cold-storage, Food-storage, Climate-controlled, Reverse
                      logistics, Chemical & Hazmat.
                    </Answer>
                    <Question onClick={() => toggleSection("others1")}>
                      Others
                      <ArrowIcon isOpen={openSection === "others1"} />
                    </Question>
                    <Answer isOpen={openSection === "others1"}>
                      Drilling Rigs and Mines. Schools and Places of Worship.
                      Administrative Buildings. Health Care Centers. Country
                      Homes. Resorts. Beach Houses.
                    </Answer>
                  </ParagraphContainer1>
                </div>

                <div>
                  {" "}
                  <ParagraphContainer1
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "",
                      paddingLeft: window.innerWidth > 992 ? "5%" : "3%",
                      justifyContent: "flex-start",
                      lineHeight: "1.2",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#fff",
                      marginLeft: window.innerWidth > 992 ? "1%" : "",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "2%",
                    }}
                  >
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1.5rem" : "1.3rem",
                      }}
                    >
                      Off-grid Solutions
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                        padding: "0",
                      }}
                    >
                      Renewable Energy Source + Backup Supply
                    </Paragraph>
                    <img
                      src={image9}
                      alt="Off-grid solutions configuration"
                      style={{
                        width: window.innerWidth > 992 ? "" : "100%",
                        height: window.innerWidth > 992 ? "" : "",
                        filter: "saturate(80%)",
                      }}
                    />
                    <Question onClick={() => toggleSection("REsource1")}>
                      Renewable energy source
                      <ArrowIcon isOpen={openSection === "REsource1"} />
                    </Question>
                    <Answer isOpen={openSection === "REsource1"}>
                      Photovoltaic panels are typical. Smaller wind turbines are
                      emerging.
                    </Answer>
                    <Question onClick={() => toggleSection("BUsupply1")}>
                      Back up power supply
                      <ArrowIcon isOpen={openSection === "BUsupply1"} />
                    </Question>
                    <Answer isOpen={openSection === "BUsupply1"}>
                      Options of energy storage like Li-ion batteries with
                      inverter and other components, or generator.
                    </Answer>
                    <Paragraph
                      style={{
                        padding: "0",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                      }}
                    >
                      Replaces continuous generator use with up to 50% in energy
                      costs savings.
                    </Paragraph>
                  </ParagraphContainer1>
                </div>
                <div
                  style={{
                    width: window.innerWidth > 992 ? "28vw" : "100%",
                    filter: "saturate(100%)",
                    display: "flex",
                  }}
                >
                  {" "}
                  <img
                    src={image8}
                    alt="Off-grid situations."
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "100%",
                      filter: "saturate(100%)",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#000",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "0",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              id="solar pump"
              style={{
                width: "100%",
                height: "",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "#f3f5f5",
                borderRadius: "10px",
                padding: "1%",
                paddingLeft: "2%",
                paddingRight: "2%",
                paddingBottom: "2%",
                marginTop: "3vh",
                marginLeft: "1%",
                marginRight: "1%",
                marginBottom: "1%",
                border: "1px solid #b6c6c5",
              }}
            >
              <div
                style={{
                  width: window.innerWidth > 992 ? "85%" : "",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <RestHeading
                  style={{
                    textAlign: "left",
                    paddingBottom: "1%",
                    margin: "0",
                    fontSize: window.innerWidth > 992 ? "1.8rem" : "1.8rem",
                  }}
                >
                  Solar Water Pump
                </RestHeading>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: window.innerWidth > 992 ? "row" : "column",
                  justifyContent:
                    window.innerWidth > 992 ? "space-between" : "center",
                  alignItems: "stretch",
                }}
              >
                <div>
                  {" "}
                  <ParagraphContainer1
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "",
                      height: window.innerWidth > 992 ? "" : "",
                      paddingLeft: window.innerWidth > 992 ? "5%" : "3%",
                      justifyContent: "flex-start",
                      lineHeight: "1.2",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#fff",
                      marginLeft: window.innerWidth > 992 ? "1%" : "",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "0",
                    }}
                  >
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        padding: "0",
                        fontSize: window.innerWidth > 992 ? "1.5rem" : "1.3rem",
                      }}
                    >
                      Solar Water Pump Situations
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                        padding: "0",
                      }}
                    >
                      Getting water from surface and underground sources.
                    </Paragraph>
                    <Question onClick={() => toggleSection("irrigation")}>
                      Irrigation agriculture.
                      <ArrowIcon isOpen={openSection === "irrigation"} />
                    </Question>
                    <Answer isOpen={openSection === "irrigation"}>
                      Dry Season Farming. Tomato. Green vegetables. Animal
                      husbandry.
                    </Answer>
                  </ParagraphContainer1>
                </div>

                <div>
                  {" "}
                  <ParagraphContainer1
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "",
                      paddingLeft: window.innerWidth > 992 ? "5%" : "3%",
                      justifyContent: "flex-start",
                      lineHeight: "1.2",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#fff",
                      marginLeft: window.innerWidth > 992 ? "1%" : "",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "2%",
                    }}
                  >
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1.5rem" : "1.3rem",
                      }}
                    >
                      Solar Water Pump Solutions
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                        padding: "0",
                      }}
                    >
                      Renewable Energy Source + Water Pump
                    </Paragraph>
                    <Question onClick={() => toggleSection("REsource2")}>
                      Renewable energy source
                      <ArrowIcon isOpen={openSection === "REsource2"} />
                    </Question>
                    <Answer isOpen={openSection === "REsource2"}>
                      Photovoltaic panels mated to an inverter (if AC water
                      pump).
                    </Answer>
                    <Question onClick={() => toggleSection("BUsupply2")}>
                      Water pump
                      <ArrowIcon isOpen={openSection === "BUsupply2"} />
                    </Question>
                    <Answer isOpen={openSection === "BUsupply2"}>
                      Ac or DC pump.
                    </Answer>
                    <Paragraph
                      style={{
                        padding: "0",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                      }}
                    >
                      Replaces generator use with up to 40% in energy costs
                      savings.
                    </Paragraph>
                  </ParagraphContainer1>
                </div>
                <div
                  style={{
                    width: window.innerWidth > 992 ? "28vw" : "100%",
                    filter: "saturate(100%)",
                    display: "flex",
                  }}
                >
                  {" "}
                  <img
                    src={image10}
                    alt="solar water pump pictures"
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "100%",
                      filter: "saturate(100%)",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#000",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "0",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              id="on-grid"
              style={{
                width: "100%",
                height: "",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "#f3f5f5",
                borderRadius: "10px",
                padding: "1%",
                paddingLeft: "2%",
                paddingRight: "2%",
                paddingBottom: "2%",
                marginTop: "3vh",
                marginLeft: "1%",
                marginRight: "1%",
                marginBottom: "1%",
                border: "1px solid #b6c6c5",
              }}
            >
              <div
                style={{
                  width: window.innerWidth > 992 ? "80%" : "",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <RestHeading
                  style={{
                    textAlign: "left",
                    paddingBottom: "1%",
                    margin: "0",
                    fontSize: window.innerWidth > 992 ? "1.8rem" : "1.8rem",
                  }}
                >
                  On-grid
                </RestHeading>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: window.innerWidth > 992 ? "row" : "column",
                  justifyContent:
                    window.innerWidth > 992 ? "space-between" : "center",
                  alignItems: "stretch",
                }}
              >
                <div>
                  {" "}
                  <ParagraphContainer1
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "",
                      height: window.innerWidth > 992 ? "" : "",
                      paddingLeft: window.innerWidth > 992 ? "5%" : "3%",
                      justifyContent: "flex-start",
                      lineHeight: "1.2",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#fff",
                      marginLeft: window.innerWidth > 992 ? "1%" : "",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "0",
                    }}
                  >
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        padding: "0",
                        fontSize: window.innerWidth > 992 ? "1.5rem" : "1.3rem",
                      }}
                    >
                      On-grid Situations
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                        padding: "0",
                      }}
                    >
                      Locations with inadequate, unreliable or expensive utility
                      power supply. Semi-urban to urban.
                    </Paragraph>
                    <Question onClick={() => toggleSection("buildings")}>
                      Buildings
                      <ArrowIcon isOpen={openSection === "buildings"} />
                    </Question>
                    <Answer isOpen={openSection === "buildings"}>
                      <p>Educational - Schools. Campuses. </p>{" "}
                      <p>Institutional - Hospitals. Pharmacies.</p>{" "}
                      <p>
                        Assembly - Places of Worship, Event Centers.
                        Restaurants. Clubs. Hotels. Sports Complexes. Gymnasia.
                        Stadia.
                      </p>
                      <p>
                        Business - Offices. Courts. Mercantile - Malls. Shops.
                        Supermarkets. Market Stalls. Abattoirs.
                      </p>
                    </Answer>
                    <Question onClick={() => toggleSection("residential")}>
                      Residential
                      <ArrowIcon isOpen={openSection === "residential"} />
                    </Question>
                    <Answer isOpen={openSection === "residential"}>
                      Gated Estates. Standalone Duplexes. Semi-detached
                      Duplexes. Terraces. Apartments. Condominiums, Bungalows.
                    </Answer>
                    <Question onClick={() => toggleSection("industrial")}>
                      Industrial
                      <ArrowIcon isOpen={openSection === "industrial"} />
                    </Question>
                    <Answer isOpen={openSection === "industrial"}>
                      Consumer Goods Factories. Metalwork Sheds. Carpentry
                      Sheds. Water Purification Plants. Food Processing Plants.
                      Printing Presses. Refineries.
                    </Answer>
                    <Question onClick={() => toggleSection("storage")}>
                      Storage
                      <ArrowIcon isOpen={openSection === "storage"} />
                    </Question>
                    <Answer isOpen={openSection === "storage"}>
                      Data Centers & Server Rooms. Cold Rooms. Diagnostic
                      Medical Labs. Warehouses.
                    </Answer>
                  </ParagraphContainer1>
                </div>

                <div>
                  {" "}
                  <ParagraphContainer1
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "",
                      paddingLeft: window.innerWidth > 992 ? "5%" : "3%",
                      justifyContent: "flex-start",
                      lineHeight: "1.2",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#fff",
                      marginLeft: window.innerWidth > 992 ? "1%" : "",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "2%",
                    }}
                  >
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1.5rem" : "1.3rem",
                      }}
                    >
                      On-grid Solutions
                    </Paragraph>
                    <Paragraph
                      style={{
                        fontWeight: "bold",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                        padding: "0",
                      }}
                    >
                      Renewable Energy Source + Grid Supply + Backup Supply
                    </Paragraph>
                    <img
                      src={image12}
                      alt="Solar panels & wind turbines."
                      style={{
                        width: window.innerWidth > 992 ? "" : "100%",
                        height: window.innerWidth > 992 ? "" : "",
                        filter: "saturate(80%)",
                      }}
                    />
                    <Question onClick={() => toggleSection("REsource3")}>
                      Renewable energy source
                      <ArrowIcon isOpen={openSection === "REsource3"} />
                    </Question>
                    <Answer isOpen={openSection === "REsource3"}>
                      Photovoltaic panels are typical. Smaller wind turbines are
                      emerging.
                    </Answer>
                    <Question onClick={() => toggleSection("Gridsupply")}>
                      Grid power supply
                      <ArrowIcon isOpen={openSection === "Gridsupply"} />
                    </Question>
                    <Answer isOpen={openSection === "Gridsupply"}>
                      230Vac/50Hz supply is typical in Africa.
                    </Answer>
                    <Question onClick={() => toggleSection("BUsupply2")}>
                      Back up power supply
                      <ArrowIcon isOpen={openSection === "BUsupply2"} />
                    </Question>
                    <Answer isOpen={openSection === "BUsupply2"}>
                      storage like Li-ion batteries with inverter and other
                      components. Generators are not needed except in extreme
                      load cases.
                    </Answer>

                    <Paragraph
                      style={{
                        padding: "0",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                      }}
                    >
                      Replaces continuous grid supply and generator use with up
                      to 30% in energy costs savings.
                    </Paragraph>
                    <Paragraph
                      style={{
                        padding: "0",
                        fontSize: window.innerWidth > 992 ? "1rem" : "1rem",
                      }}
                    >
                      Sell electricity back to the grid where net-metering
                      exists.
                    </Paragraph>
                  </ParagraphContainer1>
                </div>
                <div
                  style={{
                    width: window.innerWidth > 992 ? "28vw" : "100%",
                    filter: "saturate(100%)",
                    display: "flex",
                  }}
                >
                  {" "}
                  <img
                    src={image11}
                    alt="Solar panels & wind turbines."
                    style={{
                      width: window.innerWidth > 992 ? "28vw" : "100%",
                      filter: "saturate(100%)",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                      background: "#000",
                      borderRadius: "5px",
                      marginBottom: window.innerWidth > 992 ? "" : "0",
                    }}
                  />
                </div>
              </div>
            </div>
          </ContentContainer>
          <RestHeading
            id="three-ways"
            style={{
              paddingTop: "3vh",
            }}
          >
            {" "}
            <HighlightContainer>
              <Highlight>Three ways</Highlight>
            </HighlightContainer>{" "}
            we bring these solutions to you.
          </RestHeading>
          <ContentContainer
            style={{
              paddingTop: "0",
              backgroundImage: `url(${image14})`,
              backgroundSize: "cover",
              backgroundPosition: "top right",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "",
                borderRadius: "10px",
                paddingTop: "0",
                padding: "2%",
                margin: "1%",
              }}
            >
              <div
                style={{
                  width: window.innerWidth > 992 ? "80%" : "",
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: window.innerWidth > 992 ? "column" : "column",
                  justifyContent:
                    window.innerWidth > 992 ? "space-between" : "center",
                  alignItems: "flex-start",
                }}
              >
                <div
                  id="credit"
                  style={{
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <div>
                    <RestHeading
                      style={{
                        textAlign: "left",
                        paddingBottom: "2%",
                        paddingTop: window.innerWidth > 992 ? "7%" : "0%",
                        margin: "0",
                        fontSize: "1.8rem",
                      }}
                    >
                      <NumberOneIcon />
                      Credit-financing
                    </RestHeading>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: window.innerWidth > 992 ? "row" : "column",
                      justifyContent:
                        window.innerWidth > 992 ? "center" : "center",
                      alignItems: "center",
                      paddingBottom: window.innerWidth > 992 ? "1%" : "3%",
                      paddingTop: window.innerWidth > 992 ? "1%" : "3%",
                      padding: "1%",
                      background: "#d0e7e4",
                      width: "87vw",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        borderRadius: "10px",
                        margin: "2%",
                        position: "relative",
                        overflow: "hidden",
                        border: "5px solid #b4d8d4",
                        background: "#f1f8f7",
                        width: window.innerWidth > 992 ? "60vw" : "",
                      }}
                    >
                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize:
                            window.innerWidth > 992 ? "1.3rem" : "1.1rem",

                          color: "",
                          fontWeight: "500",
                        }}
                      >
                        Does your Business or Home need help in accessing
                        reliable and cost-effective electricity?
                      </RestHeading>

                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize:
                            window.innerWidth > 992 ? "1.3rem" : "1.1rem",
                          color: "",
                          fontWeight: "500",
                        }}
                      >
                        Experience a seamless process by financing your power
                        solutions through us. Comes with free energy audit.
                      </RestHeading>

                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize: window.innerWidth > 992 ? "1rem" : "0.8rem",
                          color: "",
                          fontWeight: "600",
                        }}
                      >
                        1. Provide basic application info <RightIcon />{" "}
                        Instantly see your savings.
                      </RestHeading>
                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize: window.innerWidth > 992 ? "1rem" : "0.8rem",
                          color: "",
                          fontWeight: "600",
                        }}
                      >
                        2. Approval & free energy audit <RightIcon /> Agree on
                        terms & enjoy power solutions.
                      </RestHeading>
                    </div>
                    <div
                      style={{
                        width: window.innerWidth > 992 ? "5vw" : "0",
                        height: window.innerWidth > 992 ? "" : "0",
                      }}
                    ></div>
                    <img
                      src={image15}
                      alt="compucalc"
                      style={{
                        width: window.innerWidth > 992 ? "25vw" : "60vw",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "",
                      flexDirection: window.innerWidth > 992 ? "row" : "column",
                      paddingBottom: window.innerWidth > 992 ? "5vh" : "7vh",
                    }}
                  >
                    <ButtonContainer2
                      style={{
                        padding: "0",
                        marginTop: "3%",
                        marginBottom: "3%",
                        marginRight: window.innerWidth > 992 ? "3%" : "",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button4
                        style={{
                          paddingRight: "6%",
                        }}
                        onClick={handleBusinessApplyButton}
                      >
                        <BusinessIcon />
                        Apply for Credit as a Business
                      </Button4>
                    </ButtonContainer2>
                    <ButtonContainer2
                      style={{
                        padding: "0",
                        marginLeft: window.innerWidth > 992 ? "3%" : "",
                        marginTop: "3%",
                        marginBottom: "3%",
                      }}
                    >
                      <Button4
                        style={{ paddingRight: "6%" }}
                        onClick={handleHomeApplyButton}
                      >
                        <HomeIcon />
                        Apply for Credit for Home use
                      </Button4>
                    </ButtonContainer2>
                  </div>
                </div>
                <div
                  id="PaaS"
                  style={{
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <div>
                    <RestHeading
                      style={{
                        textAlign: "left",
                        paddingBottom: "2%",
                        paddingTop: window.innerWidth > 992 ? "8%" : "6%",
                        margin: "0",
                        fontSize: "1.8rem",
                      }}
                    >
                      <NumberTwoIcon />
                      Power-as-a-Service (PaaS)
                    </RestHeading>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: window.innerWidth > 992 ? "row" : "column",
                      justifyContent:
                        window.innerWidth > 992 ? "center" : "center",
                      alignItems: "center",
                      paddingBottom: window.innerWidth > 992 ? "1%" : "3%",
                      paddingTop: window.innerWidth > 992 ? "1%" : "3%",
                      padding: "1%",
                      background: "#f1e7d8",
                      width: "87vw",
                    }}
                  >
                    <img
                      src={image16}
                      alt="phonepuzzle"
                      style={{
                        width: window.innerWidth > 992 ? "25vw" : "60vw",
                        filter: "saturate(100%)",
                        paddingTop: window.innerWidth > 992 ? "" : "4%",
                      }}
                    />
                    <div
                      style={{
                        width: window.innerWidth > 992 ? "5vw" : "0",
                        height: window.innerWidth > 992 ? "" : "0",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        borderRadius: "10px",
                        margin: "2%",
                        position: "relative",
                        overflow: "hidden",
                        border: "5px solid #f5d4c6",
                        background: "#f9f5ef",
                        width: window.innerWidth > 992 ? "60vw" : "",
                      }}
                    >
                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize:
                            window.innerWidth > 992 ? "1.3rem" : "1.1rem",

                          color: "",
                          fontWeight: "500",
                        }}
                      >
                        Does managing your Business, Estate or Gated Community
                        power supply seem like a puzzle?
                      </RestHeading>

                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize:
                            window.innerWidth > 992 ? "1.3rem" : "1.1rem",
                          color: "",
                          fontWeight: "500",
                        }}
                      >
                        We'll take-over your power supply for the most
                        affordable, efficient, reliable and sustainable energy
                        mix.
                      </RestHeading>

                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize:
                            window.innerWidth > 992 ? "1.3rem" : "1.1rem",
                          color: "",
                          fontWeight: "500",
                        }}
                      >
                        Comes with free energy audit. One step away.
                      </RestHeading>

                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize: window.innerWidth > 992 ? "1rem" : "0.8rem",
                          color: "",
                          fontWeight: "600",
                        }}
                      >
                        1. Apply by providing basic info <RightIcon />
                        Instantly see your savings <RightIcon />
                        Pre-approval <RightIcon />
                        Site assessment & energy audit <RightIcon /> Align on
                        terms of service & we deploy to your site.
                      </RestHeading>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "",
                      flexDirection: window.innerWidth > 992 ? "row" : "column",
                      paddingBottom: window.innerWidth > 992 ? "5vh" : "7vh",
                    }}
                  >
                    <ButtonContainer2
                      style={{
                        padding: "0",
                        marginTop: "3%",
                        marginBottom: "3%",
                        marginRight: window.innerWidth > 992 ? "3%" : "",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button4
                        style={{
                          paddingRight: "6%",
                        }}
                        onClick={handlePowerasaServiceButton}
                      >
                        <PowerIcon />
                        Apply for Power-as-a-Service
                      </Button4>
                    </ButtonContainer2>
                  </div>
                </div>{" "}
                <div
                  id="direct"
                  style={{
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <div>
                    <RestHeading
                      style={{
                        textAlign: "left",
                        paddingBottom: "2%",
                        paddingTop: window.innerWidth > 992 ? "8%" : "6%",
                        margin: "0",
                        fontSize: "1.8rem",
                      }}
                    >
                      <NumberThreeIcon />
                      Direct Purchase
                    </RestHeading>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: window.innerWidth > 992 ? "row" : "column",
                      justifyContent:
                        window.innerWidth > 992 ? "center" : "center",
                      alignItems: "center",
                      paddingBottom: window.innerWidth > 992 ? "1%" : "3%",
                      paddingTop: window.innerWidth > 992 ? "1%" : "3%",
                      padding: "1%",
                      background: "#f3ffd0",
                      width: "87vw",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        borderRadius: "10px",
                        margin: "2%",
                        position: "relative",
                        overflow: "hidden",
                        border: "5px solid #e7ffa0",
                        background: "#faffea",
                        width: window.innerWidth > 992 ? "60vw" : "",
                      }}
                    >
                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize:
                            window.innerWidth > 992 ? "1.3rem" : "1.1rem",

                          color: "",
                          fontWeight: "500",
                        }}
                      >
                        Don't want to wait while going through credit-financing
                        approval?
                      </RestHeading>

                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize:
                            window.innerWidth > 992 ? "1.3rem" : "1.1rem",
                          color: "",
                          fontWeight: "500",
                        }}
                      >
                        Pay upfront and we deliver to your location with the
                        option to install.
                      </RestHeading>
                      <RestHeading
                        style={{
                          textAlign: "left",
                          padding: "3%",
                          marginLeft: "3%",
                          marginTop: "0",
                          marginRight: "0",
                          marginBottom: "0",
                          fontSize:
                            window.innerWidth > 992 ? "1.3rem" : "1.1rem",
                          color: "",
                          fontWeight: "500",
                        }}
                      >
                        Entitled to a free energy audit if we install for you.
                      </RestHeading>
                    </div>
                    <div
                      style={{
                        width: window.innerWidth > 992 ? "7vw" : "0",
                        height: window.innerWidth > 992 ? "" : "0",
                      }}
                    ></div>
                    <img
                      src={image17}
                      alt="cardpos"
                      style={{
                        width: window.innerWidth > 992 ? "25vw" : "60vw",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "",
                      flexDirection: window.innerWidth > 992 ? "row" : "column",
                      paddingBottom: window.innerWidth > 992 ? "5vh" : "7vh",
                    }}
                  >
                    {" "}
                    <ButtonContainer2
                      style={{
                        padding: "0",
                        marginTop: "3%",
                        marginBottom: "3%",
                        marginRight: window.innerWidth > 992 ? "6%" : "",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button4
                        style={{
                          paddingRight: "6%",
                        }}
                        onClick={handleGuidedPurchaseButton}
                      >
                        <GuideIcon />
                        Guided Purchase
                      </Button4>
                    </ButtonContainer2>
                    <ButtonContainer2
                      style={{
                        padding: "0",
                        marginTop: "3%",
                        marginBottom: "3%",
                        marginRight: window.innerWidth > 992 ? "3%" : "",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button4
                        style={{
                          paddingRight: "6%",
                        }}
                        onClick={handleBuyDirectButton}
                      >
                        <CatalogIcon /> I Know My Needs
                      </Button4>
                    </ButtonContainer2>
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
          <RestHeading
            style={{
              paddingTop: "10%",
              paddingBottom: "3%",
            }}
          >
            {" "}
            <HighlightContainer>
              <Highlight id="mini-grids">
                4<sup>th</sup> generation
              </Highlight>
            </HighlightContainer>{" "}
            mini-grids development.
          </RestHeading>

          <ContentContainer
            style={{
              paddingTop: "0",
              marginBottom: "15vh",
            }}
          >
            <div
              style={{
                width: window.innerWidth > 992 ? "80%" : "",
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
              }}
            ></div>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent:
                  window.innerWidth > 992 ? "space-between" : "center",
                alignItems: "stretch",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)",
                background: "#f3f5f5",
                borderRadius: "5px",
                border: "1px solid #b6c6c5",
              }}
            >
              <div>
                {" "}
                <ParagraphContainer1
                  style={{
                    width: "",
                    height: window.innerWidth > 992 ? "" : "",
                    paddingLeft: window.innerWidth > 992 ? "5%" : "3%",
                    justifyContent: "flex-start",
                    lineHeight: "1.2",
                    marginTop: window.innerWidth > 992 ? "1%" : "1%",
                    marginBottom: window.innerWidth > 992 ? "" : "0",
                    paddingTop: window.innerWidth > 992 ? "3%" : "",
                  }}
                >
                  <div
                    style={{
                      width: window.innerWidth > 992 ? "100%" : "100%",
                      filter: "saturate(100%)",
                      display: "flex",
                      flexDirection: window.innerWidth > 992 ? "row" : "column",
                      justifyContent:
                        window.innerWidth > 992 ? "space-between" : "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <img
                      src={image18}
                      alt="Off-grid situations."
                      style={{
                        width: window.innerWidth > 992 ? "30vw" : "80vw",
                        filter: "saturate(100%)",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                        background: "#000",
                        borderRadius: "5px",
                        marginBottom: window.innerWidth > 992 ? "" : "0",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection:
                            window.innerWidth > 992 ? "row" : "column",
                        }}
                      >
                        <Paragraph
                          style={{
                            margin: "2%",
                            padding: "3%",
                            fontSize:
                              window.innerWidth > 992 ? "1.2rem" : "0.9rem",
                            width: window.innerWidth > 992 ? "25vw" : "",
                            border: "1px solid #b6c6c5",
                            borderRadius: "5px",
                            background: "#fff",
                          }}
                        >
                          We work with stakeholders to build isolated power
                          clusters that serve our communities and transform
                          local supply chains.
                        </Paragraph>
                        <Paragraph
                          style={{
                            margin: "2%",
                            padding: "3%",
                            fontSize:
                              window.innerWidth > 992 ? "1.2rem" : "0.9rem",
                            width: window.innerWidth > 992 ? "25vw" : "",
                            border: "1px solid #b6c6c5",
                            borderRadius: "5px",
                            background: "#fff",
                          }}
                        >
                          Generated electricity can be sold to nearby
                          communities or back to the grid where such framework
                          exist.
                        </Paragraph>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection:
                            window.innerWidth > 992 ? "row" : "column",
                        }}
                      >
                        <Paragraph
                          style={{
                            margin: "2%",
                            padding: "3%",
                            fontSize:
                              window.innerWidth > 992 ? "1.2rem" : "0.9rem",
                            width: window.innerWidth > 992 ? "25vw" : "",
                            border: "1px solid #b6c6c5",
                            borderRadius: "5px",
                            background: "#fff",
                          }}
                        >
                          When renewable energy is used to power mini-grids, it
                          is more reliable and typically cheaper than fossil
                          fuels.
                        </Paragraph>
                        <Paragraph
                          style={{
                            margin: "2%",
                            padding: "3%",
                            fontSize:
                              window.innerWidth > 992 ? "1.2rem" : "0.9rem",
                            width: window.innerWidth > 992 ? "25vw" : "",
                            border: "1px solid #b6c6c5",
                            borderRadius: "5px",
                            background: "#fff",
                          }}
                        >
                          Diesel-based isolated grids worldwide now lend
                          themselves to be retrofitted with renewable energy
                          technologies.
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                  <Paragraph
                    style={{
                      fontWeight: "500",
                      padding: "3%",
                      marginTop: "7%",
                      marginBottom: "1%",
                      fontSize: window.innerWidth > 992 ? "1.3rem" : "1rem",
                      width: window.innerWidth > 992 ? "" : "",
                      background: "#7a9795",
                      borderRadius: "5px",
                      color: "#fff",
                    }}
                  >
                    A sustainable model of developing mini-grids, supplying
                    power to communities and investing in enhanced value chains
                    by providing offtake/processing support.
                  </Paragraph>
                </ParagraphContainer1>
                <div
                  style={{
                    marginLeft: "0",
                    paddingLeft: "0",
                    width: "100%",
                    filter: "saturate(100%)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundImage: `url(${image20})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top right",
                  }}
                >
                  {" "}
                  <img
                    src={image19}
                    alt="4th mini-grid examples"
                    style={{
                      padding: "3%",
                      width: window.innerWidth > 992 ? "70%" : "100%",
                      filter: "saturate(100%)",
                      borderRadius: "12px",
                      marginBottom: window.innerWidth > 992 ? "" : "0",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "",
                      flexDirection: window.innerWidth > 992 ? "row" : "column",
                      paddingBottom: "7vh",
                    }}
                  >
                    <ButtonContainer2
                      style={{
                        padding: "0",
                        marginTop: window.innerWidth > 992 ? "5%" : "10%",
                        marginBottom: "2%",
                        marginRight: window.innerWidth > 992 ? "3%" : "",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button4
                        onClick={handleMiniGridButton}
                        style={{
                          paddingRight: "6%",
                        }}
                      >
                        <GridIcon />
                        Get started on mini-grids
                      </Button4>
                    </ButtonContainer2>
                  </div>
                </div>
              </div>
            </div>
          </ContentContainer>
        </SectionContainer>
      </Element>
    </>
  );
};

export default Solutions;
