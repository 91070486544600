import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "@mdi/react";
import { mdiWhatsapp } from "@mdi/js";

const WhatsAppButtonContainer = styled.div`
  position: relative;
  z-index: 1000;
`;

const RoundButton = styled.div`
  border-radius: 50%;
  display: flex;
  background-color: #25d366;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #128c7e;
    transform: scale(1.1);
  }
`;

const WhatsAppIcon = styled(Icon)`
  color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WhatsApp = () => {
  const [countryCode, setCountryCode] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");

  useEffect(() => {
    async function fetchUserCountry() {
      try {
        const response = await fetch("https://freeipapi.com/api/json");
        const data = await response.json();
        setCountryCode(data.countryCode);
      } catch (error) {
        console.error("Error fetching user's country:", error);
        setCountryCode("");
      }
    }

    fetchUserCountry();
  }, []);

  useEffect(() => {
    const whatsappNumbers = {
      EG: "+201128661553", // Egypt
      NG: "+2347048263658", // Nigeria
      // Add more countries and their WhatsApp numbers as needed
    };
    setWhatsappNumber(whatsappNumbers[countryCode] || "+2347048263658");
  }, [countryCode]);

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  };

  return (
    <WhatsAppButtonContainer>
      <RoundButton onClick={handleWhatsAppClick}>
        <WhatsAppIcon path={mdiWhatsapp} size={1.5} />
      </RoundButton>
    </WhatsAppButtonContainer>
  );
};

export default WhatsApp;
