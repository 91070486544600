import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const NotificationWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "90vw" : "75vw")};
  align-items: center;
  text-align: center;
  font-size: 16px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) =>
    props.isMobile ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 0.75)"};
  color: #e8ffa4;
  padding: 16px;
  border-radius: 4px;
  z-index: 8000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: ${(props) => (props.isHovered ? "#ccff33" : "#e8ffa4")};
  border: none;
  color: #1b2e10;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
`;

const CookieConsent = ({ onConsent }) => {
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem("cookieConsent");
    if (!hasConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleConsent = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
    onConsent();
  };

  return (
    isVisible && (
      <NotificationWrapper isMobile={isMobile}>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div>This site uses cookies to enhance your experience. &nbsp; </div>
          <div>
            By continuing, you agree to our&nbsp;
            <a
              href="/legal#cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#e8ffa4" }}
            >
              Cookie Policy
            </a>
            .
          </div>
        </div>
        <CloseButton
          onClick={handleConsent}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onTouchStart={() => setIsHovered(false)}
          onTouchEnd={() => setIsHovered(false)}
          isHovered={isHovered}
        >
          OK, Got it.
        </CloseButton>
      </NotificationWrapper>
    )
  );
};

export default CookieConsent;
