export const FORM_STEPS = [
  {
    label: "Business Information",
    fields: [
      {
        name: "businessName",
        label: "Business Name",
        required: true,
      },
      {
        name: "businessServiceAddress",
        label: "Business Address (applying for)",
        required: true,
      },
      {
        name: "businessCity",
        label: "City/Town",
        required: true,
      },
      {
        name: "businessState",
        label: "State/Region",
        required: true,
      },
      {
        name: "businessCountry",
        label: "Country",
        required: true,
      },

      {
        name: "businessEmail",
        label: "Business Email",
        required: true,
      },
      {
        name: "businessPhone",
        label: "Business Phone",
        type: "phone",
        required: true,
      },
      {
        name: "industryOfOperations",
        label: "Business Industry/Sector",
        required: true,
        options: [
          {
            label:
              "Aerospace & Defense - e.g. Aircraft, aerospace & defense equipment & parts manufacturing",
            value: "aeroDefense",
          },
          {
            label:
              "Automotive - e.g. automotive assembly & parts manufacturing",
            value: "auto",
          },
          {
            label:
              "Beverage, Food, & Tobacco e.g. food processing & packaging, brewery",
            value: "food",
          },
          {
            label: "Capital Equipment - e.g. heavy machinery manufacturing",
            value: "equipment",
          },
          {
            label: "Chemicals, Plastics & Rubber - e.g. chemicals production",
            value: "chemical",
          },
          { label: "Construction & Building", value: "construction" },
          {
            label:
              "Consumer Goods: Durable - e.g. electronics, furniture, stationery manufacturing",
            value: "durableGood",
          },
          {
            label:
              "Consumer Goods: Non-Durable - e.g. toiletries, clothing, personal care products manufacturing",
            value: "nonDurableGood",
          },
          {
            label:
              "Containers, Packaging, & Glass - e.g. paper, metal, glass containers manufacturing",
            value: "container",
          },
          {
            label: "Education e.g. schools, colleges & tertiary institutions",
            value: "education",
          },
          {
            label:
              "Energy: Electricity e.g. Non-utility electricity production & sales",
            value: "nonUtilityElectricity",
          },
          {
            label: "Energy: Oil & Gas e.g. oil-field operations & servicing",
            value: "oilGas",
          },
          {
            label:
              "Environmental Industries e.g. environmental services & waste management",
            value: "environment",
          },
          { label: "Finance: Asset Management", value: "assetManagement" },
          {
            label: "Finance: Banking & Credit Services",
            value: "banking",
          },
          {
            label: "Finance: Insurance",
            value: "insurance",
          },
          {
            label:
              "Healthcare & Pharmaceuticals e.g. hospitals, pharmacies, medical device & drugs manufacturers",
            value: "health",
          },
          {
            label:
              "High Tech Industries e.g. computer hardware & software, IT services, transaction processing",
            value: "hiTech",
          },
          {
            label:
              "Hotel, Gaming & Leisure e.g. restaurants, amusement parks, resorts, lodging, arcade galleries",
            value: "hospitality",
          },
          { label: "Media: Print, Broadcast, & Production", value: "media" },
          {
            label: "Metals & Mining e.g. coal, metal production & recycling",
            value: "mining",
          },
          {
            label:
              "Retail, Trading & Distribution e.g. Supermarkets, grocery stores, general goods s",
            value: "retail",
          },
          {
            label:
              "Services: Business e.g. consulting, legal services, HR & admin, security & protection ",
            value: "serviceBusiness",
          },
          {
            label:
              "Services: Consumer e.g. barbershop & hair salon, auto & equipment repairs, realtor, cleaning & laundry",
            value: "serviceConsumer",
          },
          {
            label:
              "Services: Not-for-Profit e.g. charities, NGOs, religious organisations",
            value: "serviceNGO",
          },
          {
            label:
              "Services: Public e.g. central, regional & local governments, agencies, military, policing &  traffic management",
            value: "serviceGovernment",
          },
          {
            label:
              "Telecommunications e.g. internet service providers, towers, satellite equipment & services, data centers",
            value: "telecom",
          },
          {
            label: "Transportation: Cargo e.g. trucking, shipping, air-freight",
            value: "transportCargo",
          },
          {
            label:
              "Transportation: Consumer e.g. airports, airlines, passenger railroad & commuter transportation",
            value: "transportConsumer",
          },
          {
            label:
              "Utilities: Electricity e.g. regulated Electricity network, transmission & distribution",
            value: "utilityElectricity",
          },
          {
            label:
              "Utilities: Water e.g. regulated & unregulated water utilities",
            value: "utilityWater",
          },
          {
            label: "Wholesale Distribution",
            value: "wholesale",
          },
        ],
      },

      {
        name: "businessStartDate",
        label: "Business Operations Start Date (mm/dd/yyyy)",
        type: "date",
        required: true,
      },
      {
        name: "incorporationID",
        label: "Company Registration Number/ID",
        type: "text",
        required: true,
      },
      {
        name: "incorporationCertificate",
        label: "Incorporation Certificate (upload)",
        type: "file",
        required: true,
      },
    ],
  },
  {
    label: "Energy Demand Estimation",
    fields: [
      {
        name: "applianceName",
        label: "Appliance Name",
      },
      {
        name: "powerRating",
        label: "Power Rating (Watts)",
        type: "number",
      },
      {
        name: "dailyUsageHours",
        label: "Daily Usage (Hours)",
        type: "number",
      },
      {
        name: "quantity",
        label: "Quantity",
        type: "number",
      },
    ],
  },
  {
    label: "Assets",
    fields: [
      {
        name: "numEmployee",
        label: "Number of Full-Time Employees (FTEs)",
        type: "number",
        required: true,
      },
      {
        name: "ownBuilding",
        label: "Business Premises",
        type: "radio",
        required: true,
        options: [
          { label: "Fully-owned", value: "fullOwn" },
          { label: "Partly-owned", value: "partOwn" },
          { label: "Renting", value: "rent" },
        ],
      },
      {
        name: "currency",
        label: "Business Reporting Currency",
        required: true,
        options: [
          { label: "AED - United Arab Emirates Dirham", value: "AED" },
          { label: "AOA - Angolan Kwanza", value: "AOA" },
          { label: "BHD - Bahraini Dinar", value: "BHD" },
          { label: "BIF - Burundian Franc", value: "BIF" },
          { label: "BWP - Botswanan Pula", value: "BWP" },
          { label: "CDF - Congolese Franc", value: "CDF" },
          { label: "CVE - Cape Verdean Escudo", value: "CVE" },
          { label: "DJF - Djiboutian Franc", value: "DJF" },
          { label: "DZD - Algerian Dinar", value: "DZD" },
          { label: "EGP - Egyptian Pound", value: "EGP" },
          { label: "ERN - Eritrean Nakfa", value: "ERN" },
          { label: "ETB - Ethiopian Birr", value: "ETB" },
          { label: "GHS - Ghanaian Cedi", value: "GHS" },
          { label: "GMD - Gambian Dalasi", value: "GMD" },
          { label: "GNF - Guinean Franc", value: "GNF" },
          { label: "ILS - Israeli New Sheqel", value: "ILS" },
          { label: "IQD - Iraqi Dinar", value: "IQD" },
          { label: "IRR - Iranian Rial", value: "IRR" },
          { label: "KES - Kenyan Shilling", value: "KES" },
          { label: "KWD - Kuwaiti Dinar", value: "KWD" },
          { label: "LBP - Lebanese Pound", value: "LBP" },
          { label: "LRD - Liberian Dollar", value: "LRD" },
          { label: "LSL - Lesotho Loti", value: "LSL" },
          { label: "LYD - Libyan Dinar", value: "LYD" },
          { label: "MAD - Moroccan Dirham", value: "MAD" },
          { label: "MGA - Malagasy Ariary", value: "MGA" },
          { label: "MRU - Mauritanian Ouguiya", value: "MRU" },
          { label: "MUR - Mauritian Rupee", value: "MUR" },
          { label: "MWK - Malawian Kwacha", value: "MWK" },
          { label: "MZN - Mozambican Metical", value: "MZN" },
          { label: "NAD - Namibian Dollar", value: "NAD" },
          { label: "NGN - Nigerian Naira", value: "NGN" },
          { label: "OMR - Omani Rial", value: "OMR" },
          { label: "QAR - Qatari Rial", value: "QAR" },
          { label: "RWF - Rwandan Franc", value: "RWF" },
          { label: "SAR - Saudi Riyal", value: "SAR" },
          { label: "SCR - Seychellois Rupee", value: "SCR" },
          { label: "SDG - Sudanese Pound", value: "SDG" },
          { label: "SHP - Saint Helena Pound", value: "SHP" },
          { label: "SLL - Sierra Leonean Leone", value: "SLL" },
          { label: "SOS - Somali Shilling", value: "SOS" },
          { label: "SSP - South Sudanese Pound", value: "SSP" },
          { label: "STN - São Tomé and Príncipe Dobra", value: "STN" },
          { label: "SYP - Syrian Pound", value: "SYP" },
          { label: "SZL - Swazi Lilangeni", value: "SZL" },
          { label: "TND - Tunisian Dinar", value: "TND" },
          { label: "TRY - Turkish Lira", value: "TRY" },
          { label: "TZS - Tanzanian Shilling", value: "TZS" },
          { label: "UGX - Ugandan Shilling", value: "UGX" },
          { label: "XAF - Central African CFA Franc", value: "XAF" },
          { label: "XOF - West African CFA Franc", value: "XOF" },
          { label: "YER - Yemeni Rial", value: "YER" },
          { label: "ZAR - South African Rand", value: "ZAR" },
          { label: "ZMW - Zambian Kwacha", value: "ZMW" },
          { label: "ZWL - Zimbabwean Dollar", value: "ZWL" },
          {
            label: "-------------------------------------",
            value: "",
            disabled: true,
          },
          { label: "CAD - Canadian Dollar", value: "CAD" },
          { label: "CNY - Chinese Yuan", value: "CNY" },
          { label: "EUR - Euro", value: "EUR" },
          { label: "GBP - British Pound Sterling", value: "GBP" },
          { label: "INR - Indian Rupee", value: "INR" },
          { label: "JPY - Japanese Yen", value: "JPY" },
          { label: "RUB - Russian Ruble", value: "RUB" },
          { label: "USD - United States Dollar", value: "USD" },
        ],
      },
      {
        name: "revenue",
        label: "Annual Revenue (Annualised if <12 months)",
        type: "number",
        required: true,
      },
      {
        name: "assets",
        label: "Assets (Cash, Inventory, Equipment, Building)",
        type: "number",
        required: true,
      },
      {
        name: "cashInventory",
        label: "Assets (Cash & Inventory only)",
        type: "number",
        required: true,
      },
      {
        name: "cash",
        label: "Assets (Cash only + Receivables if any)",
        type: "number",
        required: true,
      },
      {
        name: "assetLitigation",
        label: "Any Asset in Dispute/Litigation",
        type: "radio",
        required: true,
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        name: "assetLitigationValue",
        label: "Value of Assets in Dispute/Litigation",
        type: "number",
      },
    ],
  },
  {
    label: "Liabilities  & Equity",
    fields: [
      {
        name: "ownershipType",
        label: "Business Ownership Structure",
        required: true,
        options: [
          { label: "Sole Proprietorship", value: "soleProp" },
          { label: "General Partnership", value: "genPartner" },
          { label: "Limited Liability Company", value: "LLC" },
          { label: "Limited Liability Partnership", value: "ltdPartner" },
          { label: "Corporation (for-profit)", value: "profitCorp" },
          { label: "Nonprofit Corporation", value: "nonProfitCorp" },
          { label: "Cooperative", value: "coop" },
        ],
      },
      {
        name: "equityValue",
        label: "Market Value of Equity (if known)",
        type: "number",
        required: false,
      },
      {
        name: "expenses",
        label: "Annual Total Expenses (Annualised if <12months)",
        type: "number",
        required: true,
      },
      {
        name: "debtExpense",
        label: "Annual Debt Expenses (Annualised if <12months)",
        type: "number",
        required: true,
      },
      {
        name: "liabilities",
        label: "Liabilities (owed to Lenders/Suppliers/Salaries)",
        type: "number",
        required: true,
      },
      {
        name: "liabilityLitigation",
        label: "Any Liability in Dispute/Litigation",
        type: "radio",
        required: true,
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        name: "liabilityLitigationValue",
        label: "Value of Liabilities in Dispute/Litigation",
        type: "number",
      },
      {
        name: "letterOfCredit",
        label: "Recent Letter of Credit, if any (upload)",
        type: "file",
        required: false,
      },
      {
        name: "auditedBooks",
        label: "Latest Business Account Audit, if any (upload)",
        type: "file",
        required: false,
      },
    ],
  },
  {
    label: "Business Factors",
    fields: [
      {
        name: "dailyOperatingHours",
        label: "Business Operating Hours per Day",
        type: "number",
        required: true,
      },
      {
        name: "hourlyElectricitySupply",
        label: "Grid Electricity Supply, Hours per Day",
        type: "number",
        required: true,
      },
      {
        name: "operatingDaysPerWeek",
        label: "Number of Business Operating Days per Week",
        type: "number",
        required: true,
      },
      {
        name: "monthlyEnergyCost",
        label: "Power Costs per Month",
        type: "number",
        required: true,
      },
      {
        name: "monthlyDieselCost",
        label: "Monthly Diesel/Petrol Costs for Power",
        type: "number",
        required: true,
      },
      {
        name: "powerTopCost",
        label: "Is Power your biggest expense?",
        type: "radio",
        required: true,
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        name: "accountStatement1",
        label: "Business Bank Statement, 6 months+ (Upload)",
        required: true,
        type: "file",
      },
      {
        name: "accountStatement2",
        label: "Extra Bank Statement, 6 months+ (Upload)",
        required: false,
        type: "file",
      },
      {
        name: "taxCertificate",
        label: "Latest Tax Clearance Certificate, if any (Upload)",
        required: false,
        type: "file",
      },
    ],
  },
  {
    label: "Applicant Information",
    fields: [
      {
        name: "applicantName",
        label: "Name of Applicant (Person Applying for Business)",
        required: true,
      },
      {
        name: "applicantRelationship",
        label: "Applicant's Relationship with Business",
        required: true,
        options: [
          { label: "Owner - Proprietor/Founder", value: "owner" },
          {
            label: "Principal Officer - CEO/Managing Director/Chairman",
            value: "principalOfficer",
          },
          {
            label: "Executive Staff - CFO/CMO/CTO/Manager",
            value: "execStaff",
          },
          {
            label: "Management- Supervisor/Team-Lead",
            value: "1stLineMgmt",
          },
          {
            label: "Employee - Staff/Admin/Secretary",
            value: "regularStaff",
          },
          {
            label: "Legal Representative - Legal Counsel/Solicitor",
            value: "legalRep",
          },
          { label: "Business Consultant", value: "consultant" },
          { label: "Other", value: "other" },
        ],
      },
      {
        name: "applicantAddress",
        label: "Applicant Address",
        required: true,
      },
      {
        name: "applicantCity",
        label: "City/Town",
        required: true,
      },
      {
        name: "applicantState",
        label: "State/Region",
        required: true,
      },
      {
        name: "applicantCountry",
        label: "Country",
        required: true,
      },
      {
        name: "applicantEmail",
        label: "Applicant Email",
        required: true,
      },
      {
        name: "applicantPhone",
        label: "Applicant Phone",
        type: "phone",
        required: true,
      },
      {
        name: "applicantDOB",
        label: "Applicant Date of Birth (mm/dd/yyyy)",
        type: "date",
        required: true,
      },
      {
        name: "applicantGender",
        label: "Applicant Gender",
        type: "radio",
        required: true,
        options: [
          { label: "Female", value: "F" },
          { label: "Male", value: "M" },
          { label: "Other", value: "O" },
        ],
      },
      {
        name: "applicantIdentification",
        label: "Applicant's Passport Bio page or National ID Card",
        type: "file",
        required: true,
      },
    ],
  },
  {
    label: "Recommended System & Benefits",
    fields: [
      {
        name: "applicantComment",
        label: "Applicant Feedback and/or Clarification",
        required: true,
      },
    ],
  },
];
