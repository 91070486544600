import React from "react";
import { useMediaQuery } from "react-responsive";

const DeployedTable = ({ deployed }) => {
  const fieldMappings = {
    solarPanelsW: { label: "Solar Panels (Watt):", formatNumber: true },
    solarPanelsCount: { label: "Number of Panels:", formatNumber: true },
    solarPanelBrand: { label: "Panel Brand:", formatNumber: false },
    hybridInverterKVA: { label: "Hybrid Inverter (kVA):", formatNumber: true },
    hybridInverterCount: { label: "Number of Inverters:", formatNumber: true },
    hybridInverterBrand: { label: "Inverter Brand:", formatNumber: false },
    lFPBatteriesAH: { label: "LFP Battery (AH):", formatNumber: true },
    lFPBatteriesV: { label: "Battery Voltage:", formatNumber: true },
    lFPBatteriesCount: { label: "Number of Batteries:", formatNumber: true },
    lFPBatteriesBrand: { label: "Battery Brand:", formatNumber: false },
  };

  const isMobile = useMediaQuery({ maxWidth: 992 });

  const formatSelectedNumber = (number) => {
    if (number === null || number === undefined) return "-";
    if (Number.isInteger(number)) return number.toLocaleString("en-US");
    return Math.round(number).toLocaleString("en-US");
  };

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>Equipment</th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(deployed).map(([key, value]) => (
          <tr key={key} style={{ fontSize: isMobile ? "10px" : "13px" }}>
            <td style={{ width: isMobile ? "78%" : "80%" }}>
              {fieldMappings[key].label}
            </td>
            <td style={{ width: isMobile ? "22%" : "20%" }}>
              {fieldMappings[key].formatNumber
                ? formatSelectedNumber(value)
                : value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DeployedTable;
