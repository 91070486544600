import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axiosInstance from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import { GlobalStyle } from "./StyledComponents";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { StepBackIcon } from "./Icons";
import image27 from "../assets/images/background10.svg";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Input = styled.input`
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }
`;

const Unsubscribe = ({ isHPBannerVisible, user }) => {
  const query = useQuery();
  const [email, setEmail] = useState("");
  const [unsubscribeMarketing, setUnsubscribeMarketing] = useState(false);
  const [unsubscribeInsight, setUnsubscribeInsight] = useState(false);
  const [unsubscribeAll, setUnsubscribeAll] = useState(false);
  const [resubscribeAll, setResubscribeAll] = useState(false);
  const [message, setMessage] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const navigate = useNavigate();

  useEffect(() => {
    const emailFromQuery = query.get("email");
    if (emailFromQuery) {
      setEmail(emailFromQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (unsubscribeAll) {
      setUnsubscribeMarketing(true);
      setUnsubscribeInsight(true);
      setResubscribeAll(false);
    }
  }, [unsubscribeAll]);

  useEffect(() => {
    if (unsubscribeMarketing) {
      setResubscribeAll(false);
    }
    if (unsubscribeInsight) {
      setResubscribeAll(false);
    }
  }, [unsubscribeMarketing, unsubscribeInsight]);

  useEffect(() => {
    if (resubscribeAll) {
      setUnsubscribeMarketing(false);
      setUnsubscribeInsight(false);
      setUnsubscribeAll(false);
    }
  }, [resubscribeAll]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !unsubscribeMarketing &&
      !unsubscribeInsight &&
      !unsubscribeAll &&
      !resubscribeAll
    ) {
      setMessage("Please select an option");
      return;
    }

    let unsubscribeType = "none";
    if (resubscribeAll) {
      unsubscribeType = "none";
    } else if (unsubscribeAll) {
      unsubscribeType = "all";
    } else if (unsubscribeMarketing) {
      unsubscribeType = "marketing";
    } else if (unsubscribeInsight) {
      unsubscribeType = "insight";
    }

    try {
      const response = await axiosInstance.post("/api/email-unsubscribe", {
        email,
        type: unsubscribeType,
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.error || "An error occurred");
    }
  };

  const handleReturnToUserSettings = () => {
    navigate("/user-settings");
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  return (
    <>
      <GlobalStyle />
      <Element
        name="insights-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
          backgroundImage: `url(${image27})`,
          backgroundSize: "auto",
          backgroundPosition: "top",
        }}
      >
        <div
          style={{
            height: "80vh",
            width: isMobile ? "85vw" : "90vw",
            margin: isMobile ? "7.5vw" : "5vw",
            marginBottom: "0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
            Manage Email Preferences
          </h3>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "100%",
              maxWidth: "600px",
              padding: isMobile ? "4vw" : "2vw",
              borderRadius: "7px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                height: "10px",
                width: "100%",
                marginTop: isMobile ? "30px" : "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#e75139",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {message && <p>{message}</p>}
            </div>
            <div>
              <label>Email:</label>
              <Input
                type="email"
                value={email}
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{ width: "100%" }}
              />
            </div>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <label>Unsubscribe from:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={unsubscribeMarketing}
                    onChange={(e) => setUnsubscribeMarketing(e.target.checked)}
                    style={{ marginRight: "10px" }}
                  />
                  Marketing Communication
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={unsubscribeInsight}
                    onChange={(e) => setUnsubscribeInsight(e.target.checked)}
                    style={{ marginRight: "10px" }}
                  />
                  Insights, News, and Announcement
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={unsubscribeAll}
                    onChange={(e) => setUnsubscribeAll(e.target.checked)}
                    style={{ marginRight: "10px" }}
                  />
                  All Communication
                </label>
              </div>
            </div>
            <div
              style={{
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <label>Resubscribe to:</label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={resubscribeAll}
                    onChange={(e) => setResubscribeAll(e.target.checked)}
                    style={{ marginRight: "10px" }}
                  />
                  All Communication
                </label>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
                marginBottom: isMobile ? "30px" : "10px",
              }}
            >
              <button
                style={{
                  backgroundColor: "#e3e9e9",
                  width: "200px",
                  padding: "4px",
                  borderRadius: "5px",
                  boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
                  border: "1px solid #7a9795",
                }}
                type="submit"
              >
                Submit Preference
              </button>
            </div>
            {user && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
                onClick={handleReturnToUserSettings}
              >
                <StepBackIcon />
                Return to Account Settings
              </div>
            )}
          </form>
        </div>
      </Element>
    </>
  );
};

export default Unsubscribe;
