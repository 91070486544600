export const FORM_STEPS = [
  {
    label: "Home Information",
    fields: [
      {
        name: "homeServiceAddress",
        label: "Home Address (applying for)",
        required: true,
      },
      {
        name: "homeCity",
        label: "City/Town",
        required: true,
      },
      {
        name: "homeState",
        label: "State/Region",
        required: true,
      },
      {
        name: "homeCountry",
        label: "Country",
        required: true,
      },
      {
        name: "residencyStartDate",
        label: "Occupied Address Since (mm/dd/yyyy)",
        type: "date",
        required: true,
      },
      {
        name: "homeEstateName",
        label: "Estate Name (if home is in an estate)",
      },
      {
        name: "homeType",
        label: "Type of home",
        required: true,
        options: [
          {
            label:
              "Traditional Hut/Cottage - small, one  to a few rooms, made of mud or wooden materials and one story in height",
            value: "hut",
          },
          {
            label:
              "Bungalow -  standalone typically one-story brick-and-mortar house with several rooms",
            value: "bungalow",
          },
          {
            label:
              "Flat/Apartment/Condominium - self-contained unit in multistory block of houses",
            value: "flat",
          },
          {
            label:
              "Penthouse - single to multistory houses sharing multiple side walls",
            value: "penthouse",
          },
          {
            label:
              "Terrace/Townhouse - separate unit on the topmost floor of a multistory building",
            value: "terrace",
          },
          {
            label:
              "Semi-detached - multistory house with only one shared/common wall",
            value: "semiDetached",
          },
          {
            label:
              "Multiplex - standalone multi story house (duplex, triplex, mansion, etc.)",
            value: "multiplex",
          },
        ],
      },
      {
        name: "homeOccupants",
        label: "Number of Home Occupants",
        type: "number",
        required: true,
      },
      {
        name: "roofOrientation",
        label: "Flat or Slanted Roof?",
        required: true,
        type: "radio",
        options: [
          { label: "Flat", value: "flat" },
          { label: "Slanted", value: "slant" },
        ],
      },
      {
        name: "roofDamage",
        label: "Roof Leak or Damage?",
        required: true,
        type: "radio",
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        name: "roofAccessDependency",
        label: "Roof Requires Neighbors approval?",
        type: "radio",
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
    ],
  },
  {
    label: "Energy Demand Estimation",
    fields: [
      {
        name: "applianceName",
        label: "Appliance Name",
      },
      {
        name: "powerRating",
        label: "Power Rating (Watts)",
        type: "number",
      },
      {
        name: "dailyUsageHours",
        label: "Daily Usage (Hours)",
        type: "number",
      },
      {
        name: "quantity",
        label: "Quantity",
        type: "number",
      },
    ],
  },
  {
    label: "Applicant Information",
    fields: [
      {
        name: "applicantName",
        label: "Name of Applicant (Person Applying for Home)",
        required: true,
      },
      {
        name: "applicantAddress",
        label: "Applicant Address (if different from Home address)",
      },
      {
        name: "applicantCity",
        label: "City/Town",
      },
      {
        name: "applicantState",
        label: "State/Region",
      },
      {
        name: "applicantCountry",
        label: "Country",
      },
      {
        name: "applicantEmail",
        label: "Applicant Email",
        required: true,
      },
      {
        name: "applicantPhone",
        label: "Applicant Phone",
        type: "phone",
        required: true,
      },
      {
        name: "applicantDOB",
        label: "Applicant Date of Birth (mm/dd/yyyy)",
        type: "date",
        required: true,
      },
      {
        name: "applicantGender",
        label: "Applicant Gender",
        type: "radio",
        required: true,
        options: [
          { label: "Female", value: "F" },
          { label: "Male", value: "M" },
          { label: "Other", value: "O" },
        ],
      },
      {
        name: "maritalStatus",
        label: "Marital Status",
        type: "radio",
        required: true,
        options: [
          { label: "Married", value: "married" },
          { label: "Separated", value: "separated" },
          { label: "Single", value: "single" },
        ],
      },
      {
        name: "educationLevel",
        label: "Education Level",
        type: "radio",
        required: true,
        options: [
          { label: "Primary", value: "primary" },
          { label: "Secondary", value: "secondary" },
          { label: "Tertiary", value: "tertiary" },
        ],
      },
    ],
  },
  {
    label: "Employment & Income",
    fields: [
      {
        name: "employmentStatus",
        label: "Employment Status",
        required: true,
        type: "radio",
        options: [
          { label: "Employed", value: "employed" },
          { label: "Unemployed", value: "Unemployed" },
        ],
      },
      {
        name: "employmentStartDate",
        label: "Employment/Business Start Date (mm/dd/yyyy)",
        type: "date",
      },
      {
        name: "currency",
        label: "Income & Expenses Currency",
        required: true,
        options: [
          { label: "AED - United Arab Emirates Dirham", value: "AED" },
          { label: "AOA - Angolan Kwanza", value: "AOA" },
          { label: "BHD - Bahraini Dinar", value: "BHD" },
          { label: "BIF - Burundian Franc", value: "BIF" },
          { label: "BWP - Botswanan Pula", value: "BWP" },
          { label: "CDF - Congolese Franc", value: "CDF" },
          { label: "CVE - Cape Verdean Escudo", value: "CVE" },
          { label: "DJF - Djiboutian Franc", value: "DJF" },
          { label: "DZD - Algerian Dinar", value: "DZD" },
          { label: "EGP - Egyptian Pound", value: "EGP" },
          { label: "ERN - Eritrean Nakfa", value: "ERN" },
          { label: "ETB - Ethiopian Birr", value: "ETB" },
          { label: "GHS - Ghanaian Cedi", value: "GHS" },
          { label: "GMD - Gambian Dalasi", value: "GMD" },
          { label: "GNF - Guinean Franc", value: "GNF" },
          { label: "ILS - Israeli New Sheqel", value: "ILS" },
          { label: "IQD - Iraqi Dinar", value: "IQD" },
          { label: "IRR - Iranian Rial", value: "IRR" },
          { label: "KES - Kenyan Shilling", value: "KES" },
          { label: "KWD - Kuwaiti Dinar", value: "KWD" },
          { label: "LBP - Lebanese Pound", value: "LBP" },
          { label: "LRD - Liberian Dollar", value: "LRD" },
          { label: "LSL - Lesotho Loti", value: "LSL" },
          { label: "LYD - Libyan Dinar", value: "LYD" },
          { label: "MAD - Moroccan Dirham", value: "MAD" },
          { label: "MGA - Malagasy Ariary", value: "MGA" },
          { label: "MRU - Mauritanian Ouguiya", value: "MRU" },
          { label: "MUR - Mauritian Rupee", value: "MUR" },
          { label: "MWK - Malawian Kwacha", value: "MWK" },
          { label: "MZN - Mozambican Metical", value: "MZN" },
          { label: "NAD - Namibian Dollar", value: "NAD" },
          { label: "NGN - Nigerian Naira", value: "NGN" },
          { label: "OMR - Omani Rial", value: "OMR" },
          { label: "QAR - Qatari Rial", value: "QAR" },
          { label: "RWF - Rwandan Franc", value: "RWF" },
          { label: "SAR - Saudi Riyal", value: "SAR" },
          { label: "SCR - Seychellois Rupee", value: "SCR" },
          { label: "SDG - Sudanese Pound", value: "SDG" },
          { label: "SHP - Saint Helena Pound", value: "SHP" },
          { label: "SLL - Sierra Leonean Leone", value: "SLL" },
          { label: "SOS - Somali Shilling", value: "SOS" },
          { label: "SSP - South Sudanese Pound", value: "SSP" },
          { label: "STN - São Tomé and Príncipe Dobra", value: "STN" },
          { label: "SYP - Syrian Pound", value: "SYP" },
          { label: "SZL - Swazi Lilangeni", value: "SZL" },
          { label: "TND - Tunisian Dinar", value: "TND" },
          { label: "TRY - Turkish Lira", value: "TRY" },
          { label: "TZS - Tanzanian Shilling", value: "TZS" },
          { label: "UGX - Ugandan Shilling", value: "UGX" },
          { label: "XAF - Central African CFA Franc", value: "XAF" },
          { label: "XOF - West African CFA Franc", value: "XOF" },
          { label: "YER - Yemeni Rial", value: "YER" },
          { label: "ZAR - South African Rand", value: "ZAR" },
          { label: "ZMW - Zambian Kwacha", value: "ZMW" },
          { label: "ZWL - Zimbabwean Dollar", value: "ZWL" },
          {
            label: "-------------------------------------",
            value: "",
            disabled: true,
          },
          { label: "CAD - Canadian Dollar", value: "CAD" },
          { label: "CNY - Chinese Yuan", value: "CNY" },
          { label: "EUR - Euro", value: "EUR" },
          { label: "GBP - British Pound Sterling", value: "GBP" },
          { label: "INR - Indian Rupee", value: "INR" },
          { label: "JPY - Japanese Yen", value: "JPY" },
          { label: "RUB - Russian Ruble", value: "RUB" },
          { label: "USD - United States Dollar", value: "USD" },
        ],
      },
      {
        name: "monthlyIncome",
        label: "Monthly Income",
        type: "number",
      },
      {
        name: "savings",
        label: "Savings: Cash+Short-term (<6 months) Investments",
        type: "number",
        required: true,
      },
      {
        name: "homeOwnership",
        label: "Home Ownership:",
        type: "radio",
        required: true,
        options: [
          { label: "Own", value: "own" },
          { label: "Rent", value: "rent" },
        ],
      },

      {
        name: "applicantIdentification",
        label: "Applicant's Passport Bio page or National ID Card",
        type: "file",
        required: true,
      },
      {
        name: "employmentIncomeProof",
        label: "Proof of Employment/Income",
        type: "file",
      },
      {
        name: "homeOwnershipProof",
        label: "Proof of Home Ownership",
        type: "file",
      },
    ],
  },
  {
    label: "Expenses & Liabilities",
    fields: [
      {
        name: "monthlyExpenses",
        label: "Monthly Expenses",
        type: "number",
        required: true,
      },
      {
        name: "debt",
        label: "Total Debt",
        type: "number",
        required: true,
      },
      {
        name: "monthlyDebtExpense",
        label: "Monthly Debt Repayment",
        type: "number",
        required: true,
      },
      {
        name: "monthlyEnergyCost",
        label: "Power Costs per Month",
        type: "number",
        required: true,
      },
      {
        name: "monthlyDieselCost",
        label: "Monthly Diesel/Petrol Costs for Power",
        type: "number",
        required: true,
      },
      {
        name: "powerTopCost",
        label: "Is Power your biggest expense?",
        type: "radio",
        required: true,
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        name: "bankTaxID",
        label: "Bank Verification Number (or Tax ID if no BVN)",
        required: true,
      },
      {
        name: "accountStatement1",
        label: "Business Bank Statement, 6 months+ (Upload)",
        required: true,
        type: "file",
      },
      {
        name: "accountStatement2",
        label: "Extra Bank Statement, 6 months+ (Upload)",
        required: false,
        type: "file",
      },
    ],
  },
  {
    label: "Other Information",
    fields: [
      {
        name: "hourlyElectricitySupply",
        label: "Grid Electricity Supply, Hours per Day",
        type: "number",
        required: true,
      },
      {
        name: "preexistingInverter",
        label: "Currently use Inverter in Home?",
        required: true,
        type: "radio",
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        name: "preexistingSolar",
        label: "Current Solar Panels use in Home?",
        required: true,
        type: "radio",
        options: [
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
        ],
      },
      {
        name: "socialLink",
        label: "Socials Profile (LinkedIn/Facebook/IG/Twitter) Link",
      },
      {
        name: "clubCard",
        label: "Club, Frequent Flyer or Syndicate Card (upload)",
        type: "file",
      },
    ],
  },
    {
    label: "Recommended System & Benefits",
    fields: [
      {
        name: "applicantComment",
        label: "Applicant Feedback and/or Clarification",
        required: true,
      },
    ],
  },
];
