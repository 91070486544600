import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { Link, useNavigate } from "react-router-dom";
import { useForm, useFormState } from "react-hook-form";
import { Grid } from "react-loader-spinner";
import { isValid as isDateValid } from "date-fns";
import axiosInstance from "../utils/axiosInstance";
import styled from "styled-components";
import {
  GlobalStyle,
  RestHeading,
  HighlightContainer2,
  Highlight,
  Copyright2,
} from "./StyledComponents";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  NextIcon,
  PreviousIcon,
  FormIcon,
  OneIcon,
  TwoIcon,
  AddIcon,
  TrashCanIcon,
} from "./Icons";
import logo from "../assets/logo/kepler_logo.svg";
import image23 from "../assets/images/background8.svg";
import africa from "../assets/images/africa2.svg";
import { FORM_STEPS } from "./DirectPurchaseFormSteps";
import PowerSwitch from "./PowerswitchAnimation";

const StepList = styled.ul`
  position: relative;
  display: flex;
  list-style: none;
  padding: 0px;
  width: 100%;
  z-index: 3;
`;

const StepItem = styled.li`
  flex: 1;
  border-radius: 15px 15px 0 0;
  padding-top: 0.1rem;
  text-align: center;
  font-size: 15px;
  margin-bottom: 2vw;
  border: ${(props) =>
    props.isActive
      ? "solid 1px #1b2e10"
      : props.index < props.currentPage ||
        (props.index >= props.currentPage &&
          props.isValid &&
          props.navigationAllowed &&
          props.nextNavigationAllowed &&
          props.componentCount !== 0)
      ? "solid 1px #245e41"
      : "solid 1px transparent"};
  border-bottom: ${(props) =>
    props.isActive ? "solid 2px #1b2e10" : "1px solid #cdd8d8"};
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  color: ${(props) =>
    props.isActive
      ? "#fff"
      : props.index >= props.currentPage &&
        props.isValid &&
        props.navigationAllowed &&
        !props.nextNavigationAllowed
      ? "#245e41"
      : props.index < props.currentPage ||
        (props.index >= props.currentPage &&
          props.isValid &&
          props.navigationAllowed &&
          props.nextNavigationAllowed &&
          props.componentCount !== 0)
      ? "#ecf0f0"
      : "#7a9795"};
  background-color: ${(props) =>
    props.isActive
      ? "#1b2e10"
      : props.index >= props.currentPage &&
        props.isValid &&
        props.navigationAllowed &&
        !props.nextNavigationAllowed
      ? "#e8ffa4"
      : props.index < props.currentPage ||
        (props.index >= props.currentPage &&
          props.isValid &&
          props.navigationAllowed &&
          props.nextNavigationAllowed &&
          props.componentCount !== 0)
      ? "#245e41"
      : "#ecf0f0"};
  cursor: ${(props) =>
    props.isActive ||
    (props.index > props.currentPage && !props.navigationAllowed)
      ? "default"
      : "pointer"};
  line-height: 1;

  &:hover {
    color: ${(props) =>
      props.isActive
        ? "#fff"
        : (props.index >= props.currentPage &&
            props.isValid &&
            props.navigationAllowed) ||
          props.index < props.currentPage
        ? "#ecf0f0"
        : "#7a9795"};
    background-color: ${(props) =>
      props.isActive
        ? "#1b2e10"
        : (props.index >= props.currentPage &&
            props.isValid &&
            props.navigationAllowed) ||
          props.index < props.currentPage
        ? "#1b2e10"
        : "#ecf0f0"};
  }

  @media (max-width: 992px) {
    margin-bottom: 6vw;
    border-radius: 9px 9px 0 0;
  }
`;

const FormContainer = styled.div`
  width: 50vw;
  margin: 3vw;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
  margin-left: 0;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 3vw;
  padding-right: 3vw;
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  border: 1px solid #b6c6c5;
  background: #fff;

  @media (max-width: 992px) {
    flex-direction: column;
    width: 90vw;
    margin: 1vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-bottom: 5vh;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormContent = styled.div`
  position: relative;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  position: relative;
  padding-bottom: 20px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-35px" : "50%")};
  transform: translateY(-10%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    (!props.focused && props.value) ||
    (props.htmlFor === "component" &&
      !props.focused &&
      props.selectedComponent) ||
    (props.htmlFor === "quantity" &&
      !props.focused &&
      props.selectedQuantity !== "") ||
    (props.htmlFor === "specification" &&
      !props.focused &&
      props.selectedSpecification) ||
    (props.htmlFor === "buyersComment" && !props.focused && props.comment)
      ? "transparent"
      : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 0px;
  padding-top: 15px;
  margin-bottom: 30px;

  ${FormContent} div:focus-within & {
    top: -35px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};

    ${FormContent} div:focus-within & {
      font-size: 13px;
    }
  }
`;

const Label2 = styled.label`
  position: relative;
  padding-top: 5px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-35px" : "50%")};
  transform: translateY(-70%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused &&
    (props.value || props.defaultValue !== "" || props.isEmpty) &&
    props.value !== ""
      ? "transparent"
      : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 0px;
  height: 10px;

  ${FormContent} div:focus-within & {
    top: -35px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};
  }
  ${FormContent} div:focus-within & {
    font-size: 13px;
  }
`;

const Label3 = styled.label`
  position: relative;
  padding-top: 5px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-35px" : "50%")};
  transform: translateY(-70%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    (!props.focused &&
      (props.value || props.defaultValue !== "") &&
      props.value !== "") ||
    (!props.focused && props.dateIsValid)
      ? "transparent"
      : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 0px;
  height: 10px;

  ${FormContent} div:focus-within & {
    top: -35px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 992px) {
    left: 5px;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};

    ${FormContent} div:focus-within & {
      font-size: 13px;
    }
  }
`;

const Label4 = styled.label`
  position: relative;
  padding-top: 5px;
  left: 12px;
  top: ${(props) => (props.focused || props.value ? "-35px" : "50%")};
  transform: translateY(-70%);
  font-size: ${(props) => (props.focused || props.value ? "14px" : "16px")};
  font-weight: ${(props) => (props.focused || props.value ? "bold" : "")};
  color: ${(props) =>
    !props.focused && props.isPhoneValid ? "transparent" : "#7a9795"};
  pointer-events: none;
  transition: all 0.2s;
  width: 0px;
  height: 10px;

  ${FormContent} div:focus-within & {
    top: -35px;
    font-size: 14px;
    font-weight: bold;
    color: #245e41;
    transition: all 0.2s;
  }

  @media (max-width: 450px) {
    font-size: ${(props) => (props.focused || props.value ? "12px" : "14px")};
  }
  ${FormContent} div:focus-within & {
    font-size: 13px;
  }
`;

const RequiredIndicator = styled.span`
  color: ${(props) =>
    !props.focused && props.transparent ? "transparent" : "red"};
  margin-left: 1px;
  font-size: 14px;
`;

const Input = styled.input`
  position: absolute;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  background-color: transparent;
  white-space: pre-line;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  /* Style for autofill Input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const Input1 = styled.input`
  position: absolute;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  width: 98%;
  height: 40px;
  white-space: pre-line;
  background-color: transparent;
  color: ${(props) =>
    !props.focused && !props.dateIsValid ? "transparent" : "#1b2e10"};

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  /* Style for autofill Input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const Input2 = styled.input`
  position: absolute;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  width: 12vw;
  background-color: transparent;
  white-space: pre-line;
  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  /* Style for autofill Input */
  &:-webkit-autofill + label {
    top: -15px;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) =>
      !props.focused && props.value ? "transparent" : "#7a9795"};
  }

  @media (max-width: 992px) {
    width: 25vw;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const NextButton = styled.button`
  height: 40px;
  width: 120px;
  font-size: 16px;
  background-color: ${(props) =>
    props.isValid && props.navigationAllowed && !props.nextNavigationAllowed
      ? "#e8ffa4"
      : props.isValid && props.navigationAllowed && props.nextNavigationAllowed
      ? "#245e41"
      : "transparent"};
  color: ${(props) =>
    props.isValid && props.navigationAllowed && !props.nextNavigationAllowed
      ? "#1b2e10"
      : props.isValid && props.navigationAllowed && props.nextNavigationAllowed
      ? "#ecf0f0"
      : "#b6c6c5"};
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  @media (max-width: 992px) {
    font-size: 17px;
  }
`;

const Button = styled.button`
  height: 40px;
  width: 120px;
  font-size: 16px;
  background-color: #245e41;
  color: #ecf0f0;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  @media (max-width: 992px) {
    font-size: 17px;
  }
`;

const SubmitButton = styled.button`
  height: 40px;
  padding-left: 3vmax;
  padding-right: 3vmax;
  font-size: 18px;
  background-color: transparent;
  color: #fff;
  background-color: #1b2e10;
  border: none;
  border-radius: 5px;
  white-space: nowrap;

  &:hover {
    svg {
      color: #ccff33;
    }
  }

  &:active {
    color: #ccff33;
    background-color: #1b2e10;
  }
  @media (max-width: 992px) {
    font-size: 2vmax;
  }
`;

const CustomFileButton = styled.label`
  background-color: #ecf0f0;
  color: #7a9795;
  padding: 8px 5px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #b6c6c5;
    color: #1b2e10;
  }
`;

const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 10px;
  margin-bottom: 3rem;
`;

const Select = styled.select`
  width: 98%;
  padding: 8px 12px 5px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  color: #1b2e10;

  &:focus {
    outline: none;
    border-bottom-color: #245e41;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

const PurchaseForm = ({
  user,
  successNotification,
  warningNotification,
  errorNotification,
  infoNotification,
}) => {
  const methods = useForm({
    shouldUseNativeValidation: true,
  });
  const { register, handleSubmit, control, setValue, trigger } = methods;
  const { isDirty, isValid } = useFormState({ control });
  const [currentPage, setCurrentPage] = useState(0);
  const [fieldValues, setFieldValues] = useState({});
  const [dateValues, setDateValues] = useState({});
  const [isDateInputValid, setIsDateInputValid] = useState(false);
  const MAX_FILE_SIZE_BYTES = 16 * 1024 * 1024; // 16MB
  const [selectedComponent, setSelectedComponent] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedSpecification, setSelectedSpecification] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isEmpty, setIsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [userRegion, setUserRegion] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userCountryName, setUserCountryName] = useState("");
  const [serviceCity, setServiceCity] = useState("");
  const [serviceState, setServiceState] = useState("");
  const [serviceCountry, setServiceCountry] = useState("");
  const [userIpAddress, setUserIpAddress] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState({});
  const [isPhoneValid, setIsPhoneValid] = useState({});
  const [phoneCountryCodes, setPhoneCountryCodes] = useState({});
  const [componentsList, setComponentsList] = useState([]);
  const [componentCount, setComponentCount] = useState(0);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();
  const copyrightText =
    currentYear === 2023 ? `${currentYear}` : `${2023} - ${currentYear}`;

  const CountryFlagIcon = ({ country, width, height }) => {
    const flagMap = {
      Africa: africa,
    };

    return (
      <div>
        <img
          src={flagMap[country]}
          alt={`${country} Flag`}
          width={width}
          height={height}
        />
      </div>
    );
  };

  const iconMap = {
    0: <OneIcon />,
    1: <TwoIcon />,
  };

  const PHONE_INPUT_FIELDS = ["businessPhone", "applicantPhone"];

  useEffect(() => {
    async function fetchUserCountry() {
      try {
        const response = await fetch("https://freeipapi.com/api/json");
        const data = await response.json();
        setUserCountry(data.countryCode.toLowerCase());
        const phoneInputCountryCodes = {};
        for (const field of PHONE_INPUT_FIELDS) {
          phoneInputCountryCodes[field.name] = data.countryCode.toLowerCase();
        }
        setPhoneCountryCodes(phoneInputCountryCodes);
        setUserCity(data.cityName);
        setUserRegion(data.regionName);
        setUserCountryName(data.countryName);
        setUserIpAddress(data.ipAddress);
        if (userCity && !serviceCity) {
          handleInputChange("serviceCity", userCity, "text");
          setServiceCity(userCity);
        }
        if (userRegion && !serviceState) {
          handleInputChange("serviceState", userRegion, "text");
          setServiceState(userRegion);
        }
        if (userCountryName && !serviceCountry) {
          handleInputChange("serviceCountry", userCountryName, "text");
          setServiceCountry(userCountryName);
        }
      } catch (error) {
        setUserCountry("");
        setUserCity("");
        setUserRegion("");
        setUserCountryName("");
        setUserIpAddress("");
        setServiceCity("");
        setServiceState("");
        setServiceCountry("");
      }
    }

    fetchUserCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, userCountry, userCity, userCountryName, userRegion]);

  const handleInputChange = (fieldName, value, fieldType) => {
    setFieldValues((prevFieldValues) => ({
      ...prevFieldValues,
      [fieldName]: value,
    }));
    if (fieldType !== "number") {
      setValue(fieldName, value);
    }
    if (fieldName === "component") {
      setSelectedComponent(value);
    }
    if (fieldName === "quantity") {
      setSelectedQuantity(value);
    }
    if (fieldName === "specification") {
      setSelectedSpecification(value);
    }
    if (fieldName === "buyersComment") {
      setComment(value);
    }
    if (fieldName === "serviceCity") {
      setServiceCity(value);
    }
    if (fieldName === "serviceState") {
      setServiceState(value);
    }
    if (fieldName === "serviceCountry") {
      setServiceCountry(value);
    }

    trigger(fieldName);
  };

  const handleNextPage = () => {
    if (currentPage < FORM_STEPS.length - 1) {
      const isCurrentPageValid = isNavigationAllowed(currentPage + 1);

      if (isCurrentPageValid && isValid) {
        setCurrentPage(currentPage + 1);
      } else {
        warningNotification(`Complete required inputs to progress.`);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRemoveComponent = (index) => {
    const updatedComponentsList = [...componentsList];
    // eslint-disable-next-line no-unused-vars
    const removedComponent = updatedComponentsList.splice(index, 1)[0];
    setComponentsList(updatedComponentsList);
    setComponentCount(componentCount - 1);
  };

  const handlePhoneInputChange = (fieldName, phone, country) => {
    setPhoneNumbers((prevPhoneNumbers) => ({
      ...prevPhoneNumbers,
      [fieldName]: phone,
    }));
    setPhoneCountryCodes((prevPhoneCountryCodes) => ({
      ...prevPhoneCountryCodes,
      [fieldName]: country.countryCode,
    }));
    handleInputChange(fieldName, phone, "phone");
    const isValid = isValidPhoneNumber(
      phone,
      country.countryCode.toUpperCase()
    );
    setIsPhoneValid((prevIsPhoneValid) => ({
      ...prevIsPhoneValid,
      [fieldName]: isValid,
    }));
  };

  const isPhoneNumberValid = (fieldName) => {
    const phone = phoneNumbers[fieldName] || "";
    const country = phoneCountryCodes[fieldName] || userCountry;
    const isValid = isValidPhoneNumber(phone, country.toUpperCase());
    const field = FORM_STEPS.flatMap((step) => step.fields).find(
      (field) => field.name === fieldName
    );
    return !field || (!field.required && phone === "") || isValid;
  };

  const isNavigationAllowed = (targetStep) => {
    if (targetStep >= 1) {
      if (componentCount === 0) {
        return false;
      }
    }
    for (let i = 0; i < targetStep; i++) {
      const stepFields = FORM_STEPS[i].fields;
      if (
        !stepFields.every((field) => {
          if (field.required) {
            const isFieldValid =
              field.type === "dropdown"
                ? fieldValues[field.name] !== undefined
                : field.type === "date"
                ? isDateValid(fieldValues[field.name])
                : field.type === "phone"
                ? isPhoneNumberValid(field.name)
                : fieldValues[field.name] !== undefined &&
                  fieldValues[field.name].trim() !== "";
            return isFieldValid;
          }
          return true;
        })
      ) {
        return false;
      }
    }
    return true;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const formData = new FormData();

      Object.keys(data).forEach((fieldName) => {
        const fieldValue = data[fieldName];
        if (
          fieldValue !== null &&
          fieldValue !== undefined &&
          fieldValue !== "" &&
          fieldName !== "component" &&
          fieldName !== "specification" &&
          fieldName !== "quantity" &&
          fieldName !== "buyersComment"
        ) {
          const processedValue =
            typeof fieldValue === "string"
              ? fieldValue.replace(/,/g, "")
              : fieldValue;
          formData.append(fieldName, processedValue);
        }
      });

      Object.keys(phoneNumbers).forEach((fieldName) => {
        const phoneNumber = phoneNumbers[fieldName];
        const country = phoneCountryCodes[fieldName];

        if (phoneNumber && country) {
          formData.append(`${fieldName}_countryCode`, country);
        }
      });

      componentsList.forEach((component, index) => {
        formData.append(`component_${index}`, component.name);
        formData.append(`specification_${index}`, component.specification);
        formData.append(`quantity_${index}`, component.quantity);
        formData.append(`buyersComment_${index}`, component.buyersComment);
      });
      formData.append("ipAddress", userIpAddress);

      let totalFormDataSize = 0;

      for (const pair of formData.entries()) {
        // eslint-disable-next-line no-unused-vars
        const [name, value] = pair;
        if (value instanceof File) {
          totalFormDataSize += value.size;
        } else {
          totalFormDataSize += new Blob([value]).size;
        }
      }

      if (totalFormDataSize > MAX_FILE_SIZE_BYTES) {
        warningNotification(
          "The total size of your submission is too large. Please reduce the file sizes before submitting."
        );
        setLoading(false);
        return;
      }

      axiosInstance
        .post("/api/directOrder", formData, {
          headers: user && user.uid ? { "user-uid": user.uid } : {},
        })
        .then((response) => {
          successNotification(
            `Your purchase order information has been saved successfully. Someone will be in touch shortly. Thank you!`
          );
          setLoading(false);
          setTimeout(() => {
            navigate("/");
            setTimeout(() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }, 100);
          }, 0);
        })
        .catch((error) => {
          errorNotification(`Error submitting form. Please try again later.`);
          setLoading(false);
        });
    } catch (error) {
      errorNotification(
        `Error submitting form. User profile not found. Login and try again.`
      );
      setLoading(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      {loading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 6000,
            backgroundColor: "rgb(0,0,0,0.3)",
          }}
        >
          <Grid
            height="30%"
            width="30%"
            color="#245e41"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-loader"
            visible={true}
          />
        </div>
      )}
      <Element>
        <div
          style={{
            margin: "0",
            padding: "0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundImage: `url(${image23})`,
            backgroundSize: window.innerWidth > 992 ? "auto" : "cover",
            backgroundPosition: "bottom left",
          }}
        >
          <div
            id="top-demo"
            style={{
              margin: "0",
              padding: "0",
              width: "100%",
              display: "flex",
              flexDirection: window.innerWidth > 992 ? "row" : "column",
              justifyContent: "center",
              alignItems: window.innerWidth > 992 ? "stretch" : "center",
              minHeight: "100vh",
            }}
          >
            <div
              style={{
                margin: "0",
                paddingTop: "7%",
                paddingLeft: window.innerWidth > 992 ? "7%" : "1%",
                paddingRight: window.innerWidth > 992 ? "7%" : "1%",
                width: window.innerWidth > 992 ? "50vw" : "",
                minHeight: "50vw",
                filter: "opacity(90%)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  width: "100%",
                  paddingTop: "0.5%",
                  margin: "0",
                  marginBottom: "10%",
                  marginLeft: window.innerWidth > 992 ? "20%" : "0",
                  marginRight: window.innerWidth > 992 ? "0" : "40%",
                  display: "flex",
                  justifyContent: window.innerWidth > 992 ? "start" : "center",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/"
                  onClick={() => {
                    setTimeout(() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }, 100);
                  }}
                >
                  <img
                    src={logo}
                    alt="Kepler Logo"
                    style={{
                      width: "100px",
                    }}
                  />
                </Link>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <RestHeading
                  style={{
                    textAlign: window.innerWidth > 992 ? "left" : "center",
                    paddingTop: window.innerWidth > 992 ? "" : "10%",
                    paddingBottom: "4%",
                    margin: "0",
                    fontSize: window.innerWidth > 992 ? "2.5rem" : "2.3rem",
                    color: "#1b2e10",
                  }}
                >
                  <HighlightContainer2>
                    <Highlight>Direct Purchase</Highlight>
                  </HighlightContainer2>{" "}
                </RestHeading>
                <RestHeading
                  style={{
                    textAlign: window.innerWidth > 992 ? "left" : "center",
                    paddingBottom: "7%",
                    margin: "0",
                    fontSize: "1.5rem",
                    color: "#1b2e10",
                  }}
                >
                  Fast and pocket-friendly!
                </RestHeading>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <PowerSwitch />
                </div>
                <div
                  style={{
                    color: "#223614",
                    fontSize: window.innerWidth > 992 ? "13px" : "11px",
                    fontWeight: "500",
                    margin: "5px",
                    padding: "5px",
                  }}
                >
                  {" "}
                  <p>
                    Fields marked with a red asterisk (
                    <span style={{ color: "red" }}>*</span>) are required.
                    Ensure that the asterisks disappear before you can progress
                    to the next step.
                  </p>
                  <p>
                    Note that by filling out and submitting this form, you
                    attest that you are providing factually correct information
                    and are liable to be held responsible if found to have
                    knowingly provided wrong information. You also agree to our{" "}
                    <a
                      href="/legal#terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#14412d" }}
                    >
                      terms of service
                    </a>{" "}
                    and our{" "}
                    <a
                      href="/legal#privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#14412d" }}
                    >
                      privacy policy.
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <FormContainer>
              <form onSubmit={handleSubmit(onSubmit)}>
                <StepList>
                  {FORM_STEPS.map((step, index) => (
                    <StepItem
                      key={index}
                      isActive={index === currentPage}
                      onClick={() => {
                        if (index < currentPage) {
                          setCurrentPage(index);
                        } else if (index > currentPage) {
                          const allowNavigation = isNavigationAllowed(index);

                          if (allowNavigation & isValid) {
                            setCurrentPage(index);
                          } else {
                            warningNotification(
                              `Not allowed! Complete required inputs to progress.`
                            );
                          }
                        }
                      }}
                      index={index}
                      currentPage={currentPage}
                      isValid={isValid}
                      navigationAllowed={isNavigationAllowed(index)}
                      nextNavigationAllowed={isNavigationAllowed(index + 1)}
                      componentCount={componentCount}
                    >
                      {index + 1}
                    </StepItem>
                  ))}
                </StepList>

                <RestHeading
                  style={{
                    textAlign: "left",
                    paddingBottom: "10%",
                    margin: "0",
                    marginLeft: "1%",
                    fontSize: "1.5rem",
                  }}
                >
                  {iconMap[currentPage]} {FORM_STEPS[currentPage].label}
                </RestHeading>
                <FormContent>
                  <Form>
                    {FORM_STEPS[currentPage].fields.map((field) => (
                      <div key={field.name || field}>
                        {field.type === "dropdown" ? (
                          <select
                            name={
                              typeof field === "object" ? field.name : field
                            }
                            {...register(
                              typeof field === "object" ? field.name : field,
                              {
                                required: field.required,
                              }
                            )}
                            defaultValue={isDirty ? undefined : ""}
                          >
                            {/* Add dropdown options here */}
                            <option value="option1">Option 1</option>
                            <option value="option2">Option 2</option>
                          </select>
                        ) : field.type === "phone" ? (
                          <div>
                            <Label4
                              htmlFor={field.name}
                              value={phoneNumbers[field.name] || ""}
                              isPhoneValid={isPhoneValid[field.name]}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null &&
                                    isPhoneValid[field.name]
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label4>
                            <PhoneInputContainer>
                              <PhoneInput
                                style={{
                                  width: "250px !important",
                                }}
                                country={
                                  phoneCountryCodes[field.name] || userCountry
                                }
                                enableSearch={true}
                                value={phoneNumbers[field.name] || ""}
                                onChange={(phone, country) => {
                                  handlePhoneInputChange(
                                    field.name,
                                    phone,
                                    country
                                  );
                                }}
                              />
                            </PhoneInputContainer>
                          </div>
                        ) : field.name === "preferredContact" ? (
                          <div>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              style={{ display: "block", marginTop: "15px" }}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                position: "absolute",
                                left:
                                  window.innerWidth > 600 ? "250px" : "160px",
                                top:
                                  window.innerWidth > 600 ? "455px" : "455px",
                                gap: window.innerWidth > 600 ? "70px" : "0px",
                                fontSize:
                                  window.innerWidth > 600 ? "14px" : "12px",
                                alignItems: "start",
                              }}
                            >
                              {field.options.map((option, index) => (
                                <div
                                  key={index}
                                  style={{ marginRight: "20px" }}
                                >
                                  <span
                                    htmlFor={`${field.name}_${option.value}`}
                                    value={fieldValues[field.name]}
                                    style={{
                                      position: "relative",
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "50px",
                                      left: "53px",
                                      alignText: "left",
                                      marginBottom: "3px",
                                    }}
                                  >
                                    {option.label}
                                  </span>

                                  <Input1
                                    type="radio"
                                    id={`${field.name}_${option.value}`}
                                    name={field.name}
                                    value={option.value}
                                    {...register(field.name, {
                                      required: field.required,
                                    })}
                                    onChange={(e) => {
                                      handleInputChange(
                                        field.name,
                                        e.target.value,
                                        field.type
                                      );
                                    }}
                                    style={{
                                      width: "15px",
                                      backgroundColor: "#245e41",
                                      marginLeft: "55px",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : field.type === "date" ? (
                          <div key={field.name || field}>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              dateIsValid={isDateInputValid[field.name]}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null &&
                                    isDateInputValid[field.name]
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <Input1
                              type="date"
                              name={field.name}
                              onChange={(e) => {
                                const fieldName = field.name;
                                const date = e.target.value;
                                setDateValues((prevDateValues) => ({
                                  ...prevDateValues,
                                  [fieldName]: date,
                                }));

                                const parsedDate = new Date(date);
                                if (!isNaN(parsedDate)) {
                                  setIsDateInputValid((prevIsValid) => ({
                                    ...prevIsValid,
                                    [fieldName]: true,
                                  }));
                                  handleInputChange(
                                    fieldName,
                                    parsedDate,
                                    field.type
                                  );
                                } else {
                                  setIsDateInputValid((prevIsValid) => ({
                                    ...prevIsValid,
                                    [fieldName]: false,
                                  }));
                                }
                              }}
                              onBlur={(e) => {
                                const fieldName = field.name;
                                const date = e.target.value;
                                const parsedDate = new Date(date);
                                if (date === "" || isNaN(parsedDate)) {
                                  setIsDateInputValid((prevIsValid) => ({
                                    ...prevIsValid,
                                    [fieldName]: false,
                                  }));

                                  handleInputChange(fieldName, "", field.type);
                                } else if (!isNaN(parsedDate)) {
                                  setIsDateInputValid((prevIsValid) => ({
                                    ...prevIsValid,
                                    [fieldName]: true,
                                  }));
                                }
                              }}
                              value={dateValues[field.name] || ""}
                              dateIsValid={isDateInputValid[field.name]}
                              id={field.name}
                              {...(field.required ? { required: true } : {})}
                              max={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        ) : field.name === "serviceCity" ||
                          field.name === "serviceState" ||
                          field.name === "serviceCountry" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: window.innerWidth > 992 ? "40vw" : "85vw",
                            }}
                          >
                            {field.name === "serviceCity" && (
                              <div
                                style={{
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "18vw",
                                }}
                              >
                                <Label2
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userCity}
                                >
                                  {field.label}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label2>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userCity ||
                                        ""
                                  }
                                  defaultValue={userCity}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                            {field.name === "serviceState" && (
                              <div
                                style={{
                                  position: "relative",
                                  left:
                                    window.innerWidth > 992 ? "14.5vw" : "28vw",
                                  bottom: "27px",
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "23vw",
                                }}
                              >
                                <Label3
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userRegion}
                                >
                                  {field.label}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label3>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userRegion ||
                                        ""
                                  }
                                  defaultValue={userRegion}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                            {field.name === "serviceCountry" && (
                              <div
                                style={{
                                  position: "relative",
                                  left:
                                    window.innerWidth > 992 ? "29vw" : "57vw",
                                  bottom: "54px",
                                  width:
                                    window.innerWidth > 992 ? "15vw" : "20vw",
                                }}
                              >
                                <Label2
                                  htmlFor={field.name}
                                  value={fieldValues[field.name]}
                                  defaultValue={userCountryName}
                                >
                                  {field.label}
                                  {field.required && (
                                    <RequiredIndicator
                                      transparent={
                                        fieldValues[field.name] !== "" &&
                                        fieldValues[field.name] !== undefined &&
                                        fieldValues[field.name] !== null
                                      }
                                    >
                                      *
                                    </RequiredIndicator>
                                  )}
                                </Label2>
                                <Input2
                                  type="text"
                                  name={field.name}
                                  {...register(field.name, {
                                    required: field.required,
                                  })}
                                  value={
                                    fieldValues[field.name] === ""
                                      ? ""
                                      : fieldValues[field.name] ||
                                        userCountryName ||
                                        ""
                                  }
                                  defaultValue={userCountryName}
                                  onChange={(e) => {
                                    setValue(field.name, e.target.value);
                                    handleInputChange(
                                      field.name,
                                      e.target.value,
                                      field.type
                                    );
                                    setIsEmpty(e.target.value === "");
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : field.name === "component" ? (
                          <div>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              selectedComponent={selectedComponent}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <Select
                              name={field.name}
                              {...register(field.name, {
                                required: field.required,
                              })}
                              defaultValue={isDirty ? undefined : ""}
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                if (
                                  selectedValue &&
                                  !field.options.find(
                                    (option) => option.value === selectedValue
                                  )?.disabled
                                ) {
                                  handleInputChange(
                                    field.name,
                                    selectedValue,
                                    field.type
                                  );
                                }
                              }}
                            >
                              <option value="" disable hidden></option>
                              {field.options.map((option) => (
                                <option
                                  key={option.value}
                                  value={option.value}
                                  disabled={option.disabled}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                          </div>
                        ) : (
                          <>
                            <Label
                              htmlFor={field.name}
                              value={fieldValues[field.name]}
                              selectedQuantity={selectedQuantity}
                              selectedSpecification={selectedSpecification}
                              comment={comment}
                            >
                              {field.label}
                              {field.required && (
                                <RequiredIndicator
                                  transparent={
                                    fieldValues[field.name] !== "" &&
                                    fieldValues[field.name] !== undefined &&
                                    fieldValues[field.name] !== null
                                  }
                                >
                                  *
                                </RequiredIndicator>
                              )}
                            </Label>
                            <Input
                              type="text"
                              name={
                                typeof field === "object" ? field.name : field
                              }
                              {...register(
                                typeof field === "object" ? field.name : field,
                                {
                                  required: field.required,
                                }
                              )}
                              defaultValue={isDirty ? undefined : ""}
                              onKeyDown={(e) => {
                                const isDecimalField = field.type === "number";

                                if (isDecimalField) {
                                  if (
                                    (e.key === "." &&
                                      !e.target.value.includes(".")) ||
                                    /[0-9]/.test(e.key) ||
                                    e.key === "Backspace" ||
                                    e.key === "Delete"
                                  ) {
                                  } else {
                                    e.preventDefault();
                                  }
                                }
                              }}
                              onChange={(e) => {
                                const fieldName =
                                  typeof field === "object"
                                    ? field.name
                                    : field;
                                let value = e.target.value;

                                handleInputChange(fieldName, value, field.type);
                              }}
                              inputMode={
                                field.type === "number" ? "decimal" : "text"
                              }
                            />
                          </>
                        )}
                      </div>
                    ))}
                  </Form>{" "}
                </FormContent>
                <FormContent>
                  <Form>
                    {FORM_STEPS[currentPage].label === "Components" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "98%",
                          }}
                        >
                          <CustomFileButton
                            style={{
                              fontSize:
                                window.innerWidth > 992 ? "13px" : "12px",
                              marginBottom: "0rem",
                            }}
                            onClick={() => {
                              if (
                                selectedComponent &&
                                selectedQuantity &&
                                selectedSpecification
                              ) {
                                const newComponent = {
                                  name: selectedComponent,
                                  quantity: selectedQuantity,
                                  specification: selectedSpecification,
                                  buyersComment: comment,
                                };

                                setComponentsList([
                                  ...componentsList,
                                  newComponent,
                                ]);

                                setComponentCount(componentCount + 1);

                                setFieldValues({
                                  ...fieldValues,
                                  component: "",
                                  quantity: "",
                                  specification: "",
                                  buyersComment: "",
                                });
                                setSelectedComponent("");
                                setSelectedQuantity("");
                                setSelectedSpecification("");
                                setComment("");
                                setValue("component", "");
                                setValue("quantity", "");
                                setValue("specification", "");
                                setValue("buyersComment", "");
                              } else {
                                infoNotification(
                                  `Please select/enter complete information to complete your order`
                                );
                              }
                            }}
                          >
                            Add Component{" "}
                            <RequiredIndicator transparent={componentCount > 0}>
                              *
                            </RequiredIndicator>
                            <AddIcon />
                          </CustomFileButton>
                          {componentsList.length > 0 && (
                            <div
                              style={{
                                fontSize:
                                  window.innerWidth > 992 ? "13px" : "13px",
                                fontWeight: "bold",
                                padding: "5px 0px",
                                marginLeft: "7px",
                                color: "#245e41",
                                width: "70%",
                                lineHeight: "1.2",
                                borderTop: "1px solid",
                                borderBottom: "1px solid",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <div>
                              Your Order List
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            position: "relative",
                            fontSize: window.innerWidth > 992 ? "13px" : "12px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "start",
                            textAlign: "left",
                            minHeight: "370px",
                            marginTop: "2%",
                            width: "100%",
                            whiteSpace: "pre-line",
                            marginBottom: "5%",
                          }}
                        >
                          {componentsList.length > 0 && (
                            <table>
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "15.5vw"
                                          : "25vw",
                                    }}
                                  >
                                    Component
                                  </th>

                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "14.5vw"
                                          : "12.5vw",
                                      textAlign: "center",
                                    }}
                                  >
                                    Specification
                                  </th>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "6vw"
                                          : "11.5vw",
                                      textAlign: "center",
                                    }}
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992
                                          ? "14.5vw"
                                          : "11.5vw",
                                      textAlign: "center",
                                    }}
                                  >
                                    Comments
                                  </th>
                                  <th
                                    style={{
                                      width:
                                        window.innerWidth > 992 ? "4vw" : "4vw",
                                      textAlign: "center",
                                    }}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody>
                                {componentsList.map((component, index) => (
                                  <tr key={index}>
                                    <td>{component.name}</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1.5vw",
                                      }}
                                    >
                                      {component.specification}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "2.5vw",
                                      }}
                                    >
                                      {component.quantity}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        paddingRight: "1.5vw",
                                      }}
                                    >
                                      {component.buyersComment}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleRemoveComponent(index)
                                      }
                                    >
                                      <TrashCanIcon />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </>
                    )}
                  </Form>
                </FormContent>
                <div
                  className="button-container"
                  style={{
                    position: "relative",
                    width: window.innerWidth > 992 ? "100%" : "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                  }}
                >
                  {" "}
                  {currentPage === 0 && (
                    <button
                      type="button"
                      style={{
                        border: "none",
                        background: "transparent",
                      }}
                      disabled
                    ></button>
                  )}
                  {currentPage > 0 && (
                    <Button type="button" onClick={handlePreviousPage}>
                      <PreviousIcon />
                      Previous
                    </Button>
                  )}
                  {currentPage < FORM_STEPS.length - 1 && (
                    <NextButton
                      type="button"
                      onClick={handleNextPage}
                      isValid={isValid}
                      navigationAllowed={isNavigationAllowed(currentPage + 1)}
                      nextNavigationAllowed={isNavigationAllowed(
                        currentPage + 2
                      )}
                      disabled={
                        !isValid || !isNavigationAllowed(currentPage + 1)
                      }
                    >
                      Next
                      <NextIcon />
                    </NextButton>
                  )}
                  {currentPage === FORM_STEPS.length - 1 && (
                    <SubmitButton
                      type="submit"
                      disabled={
                        !isValid || !isNavigationAllowed(currentPage + 1)
                      }
                      className={
                        !isValid || !isNavigationAllowed(currentPage + 1)
                          ? "disabled"
                          : ""
                      }
                    >
                      <FormIcon />
                      Submit
                    </SubmitButton>
                  )}
                </div>
              </form>
            </FormContainer>
          </div>
          <div>
            <Copyright2>
              &copy; {copyrightText} All rights reserved &nbsp;|&nbsp; Built in{" "}
              <CountryFlagIcon country="Africa" width="27px" height="14px" />
              with 💚 by Kepler Solutions
            </Copyright2>
          </div>
        </div>
      </Element>
    </>
  );
};

export default PurchaseForm;
