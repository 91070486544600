import React from "react";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { RightIcon } from "./Icons";
import { GlobalStyle } from "./StyledComponents";
import ComingSoon from "./ComingSoon";

const Jobs = ({ isHPBannerVisible }) => {
  return (
    <>
      <GlobalStyle />
      <Element
        name="jobs-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div
          style={{
            position: "relative",
            paddingLeft: "3%",
            height: "10px",
            zIndex: 3,
            display: window.innerWidth <= 991 ? "block" : "none",
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            Home{" "}
          </Link>
          <RightIcon /> Jobs
        </div>
        <div
          style={{
            position: "relative",
            height: "100vh",
          }}
        >
          <ComingSoon />
        </div>
      </Element>
    </>
  );
};

export default Jobs;
