import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Element } from "react-scroll";
import { Link, useNavigate } from "react-router-dom";
import { GlobalStyle } from "./StyledComponents";
import logo from "../assets/logo/kepler_logo.svg";

const animStar = keyframes`
  0% {
    transform: translateY(0px);
}
  100% {
    transform: translateY(-2000px);
}
`;

const PageContainer = styled.a`
  display: block;
  text-align: center;
  text-decoration: none;
  background-color: #0e1808;
  height: 100vh;
`;

const TopHeader = styled.header`
  /* Add your top header styles here */
`;

const DustParticleContainer = styled.div`
  /* Add your dust particle container styles here */
`;

const StarSec = styled.div`
  content: " ";
  position: absolute;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
    234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4,
    633px 601px #ff5722, 350px 630px #ffeb3b, 1164px 782px #00bcd4,
    76px 690px #3f51b5, 1825px 701px #cddc39, 1646px 578px #ffeb3b,
    544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
    168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0,
    104px 1690px #8bc34a, 1167px 1338px #e91e63, 345px 1652px #009688,
    1682px 1196px #f44336, 1995px 494px #8bc34a, 428px 798px #ff5722,
    340px 1623px #f44336, 605px 349px #9c27b0, 1339px 1344px #673ab7,
    1102px 1745px #3f51b5, 1592px 1676px #2196f3, 419px 1024px #ff9800,
    630px 1033px #4caf50, 1995px 1644px #00bcd4, 1092px 712px #9c27b0,
    1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
    19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3,
    67px 712px #ff5722, 89px 1406px #f44336, 275px 321px #009688,
    592px 630px #e91e63, 1012px 1690px #9c27b0, 1749px 23px #673ab7,
    94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
    1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4,
    1202px 46px #ff5722, 890px 569px #ff5722, 1613px 813px #2196f3,
    223px 252px #ff9800, 983px 1093px #f44336, 726px 1029px #ffc107,
    1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
    212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff,
    1768px 516px #f44336, 849px 391px #ff9800, 228px 1824px #fff,
    1119px 1680px #ffc107, 812px 1480px #3f51b5, 1438px 1585px #cddc39,
    137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
    857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50,
    1655px 911px #00bcd4, 1102px 1216px #ff5722, 1807px 1044px #fff,
    660px 435px #03a9f4, 299px 678px #4caf50, 1193px 115px #ff9800,
    918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
    108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722,
    1004px 391px #673ab7, 1529px 516px #f44336, 1206px 845px #cddc39,
    347px 583px #009688, 1102px 1332px #f44336, 709px 1756px #00bcd4,
    1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
    320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722,
    1546px 692px #f44336;
  animation: ${animStar} 150s linear infinite;
`;

const StarThird = styled.div`
  content: " ";
  position: absolute;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
    234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4,
    633px 601px #ff5722, 350px 630px #ffeb3b, 1164px 782px #00bcd4,
    76px 690px #3f51b5, 1825px 701px #cddc39, 1646px 578px #ffeb3b,
    544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
    168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0,
    104px 1690px #8bc34a, 1167px 1338px #e91e63, 345px 1652px #009688,
    1682px 1196px #f44336, 1995px 494px #8bc34a, 428px 798px #ff5722,
    340px 1623px #f44336, 605px 349px #9c27b0, 1339px 1344px #673ab7,
    1102px 1745px #3f51b5, 1592px 1676px #2196f3, 419px 1024px #ff9800,
    630px 1033px #4caf50, 1995px 1644px #00bcd4, 1092px 712px #9c27b0,
    1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
    19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3,
    67px 712px #ff5722, 89px 1406px #f44336, 275px 321px #009688,
    592px 630px #e91e63, 1012px 1690px #9c27b0, 1749px 23px #673ab7,
    94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
    1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4,
    1202px 46px #ff5722, 890px 569px #ff5722, 1613px 813px #2196f3,
    223px 252px #ff9800, 983px 1093px #f44336, 726px 1029px #ffc107,
    1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
    212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff,
    1768px 516px #f44336, 849px 391px #ff9800, 228px 1824px #fff,
    1119px 1680px #ffc107, 812px 1480px #3f51b5, 1438px 1585px #cddc39,
    137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
    857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50,
    1655px 911px #00bcd4, 1102px 1216px #ff5722, 1807px 1044px #fff,
    660px 435px #03a9f4, 299px 678px #4caf50, 1193px 115px #ff9800,
    918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
    108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722,
    1004px 391px #673ab7, 1529px 516px #f44336, 1206px 845px #cddc39,
    347px 583px #009688, 1102px 1332px #f44336, 709px 1756px #00bcd4,
    1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
    320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722,
    1546px 692px #f44336;
  animation: ${animStar} 10s linear infinite;
`;

const StarFourth = styled.div`
  content: " ";
  position: absolute;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
    234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4,
    633px 601px #ff5722, 350px 630px #ffeb3b, 1164px 782px #00bcd4,
    76px 690px #3f51b5, 1825px 701px #cddc39, 1646px 578px #ffeb3b,
    544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
    168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0,
    104px 1690px #8bc34a, 1167px 1338px #e91e63, 345px 1652px #009688,
    1682px 1196px #f44336, 1995px 494px #8bc34a, 428px 798px #ff5722,
    340px 1623px #f44336, 605px 349px #9c27b0, 1339px 1344px #673ab7,
    1102px 1745px #3f51b5, 1592px 1676px #2196f3, 419px 1024px #ff9800,
    630px 1033px #4caf50, 1995px 1644px #00bcd4, 1092px 712px #9c27b0,
    1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
    19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3,
    67px 712px #ff5722, 89px 1406px #f44336, 275px 321px #009688,
    592px 630px #e91e63, 1012px 1690px #9c27b0, 1749px 23px #673ab7,
    94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
    1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4,
    1202px 46px #ff5722, 890px 569px #ff5722, 1613px 813px #2196f3,
    223px 252px #ff9800, 983px 1093px #f44336, 726px 1029px #ffc107,
    1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
    212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff,
    1768px 516px #f44336, 849px 391px #ff9800, 228px 1824px #fff,
    1119px 1680px #ffc107, 812px 1480px #3f51b5, 1438px 1585px #cddc39,
    137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
    857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50,
    1655px 911px #00bcd4, 1102px 1216px #ff5722, 1807px 1044px #fff,
    660px 435px #03a9f4, 299px 678px #4caf50, 1193px 115px #ff9800,
    918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
    108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722,
    1004px 391px #673ab7, 1529px 516px #f44336, 1206px 845px #cddc39,
    347px 583px #009688, 1102px 1332px #f44336, 709px 1756px #00bcd4,
    1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
    320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722,
    1546px 692px #f44336;
  animation: ${animStar} 50s linear infinite;
`;

const StarFifth = styled.div`
  content: " ";
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 571px 173px #00bcd4, 1732px 143px #00bcd4, 1745px 454px #ff5722,
    234px 784px #00bcd4, 1793px 1123px #ff9800, 1076px 504px #03a9f4,
    633px 601px #ff5722, 350px 630px #ffeb3b, 1164px 782px #00bcd4,
    76px 690px #3f51b5, 1825px 701px #cddc39, 1646px 578px #ffeb3b,
    544px 293px #2196f3, 445px 1061px #673ab7, 928px 47px #00bcd4,
    168px 1410px #8bc34a, 777px 782px #9c27b0, 1235px 1941px #9c27b0,
    104px 1690px #8bc34a, 1167px 1338px #e91e63, 345px 1652px #009688,
    1682px 1196px #f44336, 1995px 494px #8bc34a, 428px 798px #ff5722,
    340px 1623px #f44336, 605px 349px #9c27b0, 1339px 1344px #673ab7,
    1102px 1745px #3f51b5, 1592px 1676px #2196f3, 419px 1024px #ff9800,
    630px 1033px #4caf50, 1995px 1644px #00bcd4, 1092px 712px #9c27b0,
    1355px 606px #f44336, 622px 1881px #cddc39, 1481px 621px #9e9e9e,
    19px 1348px #8bc34a, 864px 1780px #e91e63, 442px 1136px #2196f3,
    67px 712px #ff5722, 89px 1406px #f44336, 275px 321px #009688,
    592px 630px #e91e63, 1012px 1690px #9c27b0, 1749px 23px #673ab7,
    94px 1542px #ffeb3b, 1201px 1657px #3f51b5, 1505px 692px #2196f3,
    1799px 601px #03a9f4, 656px 811px #00bcd4, 701px 597px #00bcd4,
    1202px 46px #ff5722, 890px 569px #ff5722, 1613px 813px #2196f3,
    223px 252px #ff9800, 983px 1093px #f44336, 726px 1029px #ffc107,
    1764px 778px #cddc39, 622px 1643px #f44336, 174px 1559px #673ab7,
    212px 517px #00bcd4, 340px 505px #fff, 1700px 39px #fff,
    1768px 516px #f44336, 849px 391px #ff9800, 228px 1824px #fff,
    1119px 1680px #ffc107, 812px 1480px #3f51b5, 1438px 1585px #cddc39,
    137px 1397px #fff, 1080px 456px #673ab7, 1208px 1437px #03a9f4,
    857px 281px #f44336, 1254px 1306px #cddc39, 987px 990px #4caf50,
    1655px 911px #00bcd4, 1102px 1216px #ff5722, 1807px 1044px #fff,
    660px 435px #03a9f4, 299px 678px #4caf50, 1193px 115px #ff9800,
    918px 290px #cddc39, 1447px 1422px #ffeb3b, 91px 1273px #9c27b0,
    108px 223px #ffeb3b, 146px 754px #00bcd4, 461px 1446px #ff5722,
    1004px 391px #673ab7, 1529px 516px #f44336, 1206px 845px #cddc39,
    347px 583px #009688, 1102px 1332px #f44336, 709px 1756px #00bcd4,
    1972px 248px #fff, 1669px 1344px #ff5722, 1132px 406px #f44336,
    320px 1076px #cddc39, 126px 943px #ffeb3b, 263px 604px #ff5722,
    1546px 692px #f44336;
  animation: ${animStar} 80s linear infinite;
`;

const move = keyframes`
 0% {
    transform: rotate(40deg);
  }
  50% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(40deg);
  }
`;

const LampWrap = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
`;

const Lamp = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  margin: 0px auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: center top;
  animation-timing-function: cubic-bezier(0.6, 0, 0.38, 1);
  animation: ${move} 5.1s infinite;

  @media (max-width: 640px) {
    zoom: 0.8;
  }

  @media (max-width: 450px) {
    zoom: 0.7;
  }

  @media (max-width: 350px) {
    zoom: 0.6;
  }

  @media (max-width: 300px) {
    zoom: 0.5;
  }
`;

const Cable = styled.div`
  width: 8px;
  height: 108px;
  background-image: linear-gradient(rgb(32 148 218 / 70%), rgb(193 65 25)),
    linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
`;

const Cover = styled.div`
  width: 200px;
  height: 80px;
  background: #abe900;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: relative;
  z-index: 200;
`;

const InCover = styled.div`
  width: 100%;
  max-width: 200px;
  height: 20px;
  border-radius: 100%;
  background: #ccff3f;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  bottom: -9px;
  z-index: 100;
`;

const Bulb = styled.div`
  width: 50px;
  height: 50px;
  background-color: #ccff3a;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -20px;
  margin: 0px auto;
  -webkit-box-shadow: 0 0 15px 7px rgba(204, 255, 51, 0.8),
    0 0 40px 25px rgba(204, 255, 51, 0.5),
    -75px 0 30px 15px rgba(204, 255, 51, 0.2);
  box-shadow: 0 0 25px 7px rgb(210 255 75 / 80%),
    0 0 64px 47px rgba(204, 255, 51, 0.5),
    0px 0 30px 15px rgba(204, 255, 51, 0.2);
`;

const Light = styled.div`
  width: 200px;
  height: 0px;
  border-bottom: 2000px solid rgb(212 255 82 / 24%);
  border-left: 45px solid transparent;
  border-right: 45px solid transparent;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 130px;
  margin: 0px auto;
  z-index: 1;
  border-radius: 90px 90px 0px 0px;
`;

const ErrorSection = styled.section`
  height: 100vh;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  overflow: hidden;
`;

const ErrorContent = styled.div`
  position: absolute;
  top: 70%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media (max-width: 640px) {
    top: 60%;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #0e1808;
`;

const MessageTitle = styled.h1`
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 4.6rem;
  padding-bottom: 40px;
  max-width: 960px;
  margin: 0 auto;

  @media (max-width: 1400px) {
    font-size: 3.6rem;
  }

  @media (max-width: 640px) {
    font-size: 1.6rem;
  }
`;

const MessageText = styled.p`
  line-height: 42px;
  font-size: 18px;
  padding: 0 60px;
  max-width: 680px;
  margin: auto;
  color: #fff;
  line-height: 1.5;
`;

const ErrorNav = styled.div`
  max-width: 600px;
  margin: 40px auto 0;
  text-align: center;
`;

const NotFound = ({ setShouldShowNavbar, setShouldShowFooter }) => {
  setShouldShowNavbar(false);
  setShouldShowFooter(false);
  const navigate = useNavigate();
  const MESSAGE_TIMER = 15;
  const [countdown, setCountdown] = useState(MESSAGE_TIMER);

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    if (countdown <= 0) {
      clearInterval(redirectTimer);
      navigate("/");
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    }
    return () => {
      clearInterval(redirectTimer);
    };
  }, [countdown, navigate]);

  return (
    <>
      <GlobalStyle />
      <Element name="error-top">
        <PageContainer>
          <TopHeader />
          <DustParticleContainer>
            <StarSec />
            <StarThird />
            <StarFourth />
            <StarFifth />
          </DustParticleContainer>
          <LampWrap>
            <Lamp>
              <Cable />
              <Cover>
                <InCover>
                  <Bulb />
                </InCover>
              </Cover>
              <Light />
            </Lamp>
          </LampWrap>
          <ErrorSection>
            <ErrorContent>
              <ErrorMessage>
                <MessageTitle>404 | Page Not Found</MessageTitle>
                <MessageText>
                  We're sorry, the page you were looking for may either be from
                  a broken link or doesn't exist.
                </MessageText>
                <MessageText>
                  You will be redirected to our home page in {countdown}{" "}
                  second(s).
                </MessageText>
              </ErrorMessage>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  margin: "0",
                  marginTop: "5vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/"
                  onClick={() => {
                    setTimeout(() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }, 100);
                  }}
                >
                  <img
                    src={logo}
                    alt="Kepler Logo"
                    style={{
                      width: "100px",
                    }}
                  />
                </Link>
              </div>
              <ErrorNav></ErrorNav>
            </ErrorContent>
          </ErrorSection>
        </PageContainer>
      </Element>
    </>
  );
};

export default NotFound;
