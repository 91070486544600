import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { RightIcon } from "./Icons";
import {
  GlobalStyle,
  RestHeading,
  Highlight,
  HighlightContainer,
  Paragraph,
} from "./StyledComponents";

const Legal = ({ isHPBannerVisible }) => {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const yOffset = -300; // Adjust this value as needed
        const yCoordinate =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: yCoordinate, behavior: "smooth" });
      }
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      <Element
        name="jobs-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div
          style={{
            position: "relative",
            paddingLeft: "3%",
            height: "10px",
            zIndex: 3,
            display: window.innerWidth <= 991 ? "block" : "none",
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            Home{" "}
          </Link>
          <RightIcon /> Legal
        </div>
        <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
          <RestHeading
            style={{
              paddingTop: "4%",
              paddingBottom: "3%",
            }}
          >
            <HighlightContainer>
              <Highlight> The Kepler Word</Highlight>
            </HighlightContainer>
          </RestHeading>

          <section id="privacy-policy">
            <Paragraph
              style={{
                fontWeight: "bold",
                fontSize:
                  window.innerWidth > 400
                    ? "1.5rem"
                    : window.innerWidth > 300
                    ? "1.35rem"
                    : "1rem",
                paddingBottom: "1%",
              }}
            >
              Privacy Policy
            </Paragraph>
            <p>
              At Kepler Clean Energy and Sustainability Solutions Ltd. (also known as Kepler Solutions), we are committed to protecting your privacy.
              This Privacy Policy explains how we collect, use, and safeguard
              your information when you use our services.
            </p>
            <h5>Information We Collect</h5>
            <p>
              We may collect personal identification information such as your
              name, email address, and other details to provide better services.
            </p>
            <h5>How We Use Your Information</h5>
            <p>
              Your information is used to improve our services, provide customer
              support, and communicate updates and offers.
            </p>
            <h5>Data Protection</h5>
            <p>
              We implement security measures to protect your personal data
              against unauthorized access, alteration, or destruction.
            </p>
            <p>For more details, please read our full Privacy Policy.</p>
          </section>

          <section id="terms-of-service">
            <Paragraph
              style={{
                fontWeight: "bold",
                fontSize:
                  window.innerWidth > 400
                    ? "1.5rem"
                    : window.innerWidth > 300
                    ? "1.35rem"
                    : "1rem",
                paddingBottom: "1%",
              }}
            >
              Terms of Service
            </Paragraph>
            <p>
              By using Kepler Solutions, you agree to abide by our terms and
              conditions outlined in this section.
            </p>
            <h5>Acceptance of Terms</h5>
            <p>
              By accessing our services, you agree to comply with these terms.
              If you do not agree, please refrain from using our services.
            </p>
            <h5>Modifications</h5>
            <p>
              We reserve the right to modify these terms at any time. Your
              continued use of our services constitutes acceptance of the new
              terms.
            </p>
            <h5>Usage Restrictions</h5>
            <p>
              You agree not to misuse our services or engage in any unlawful
              activities.
            </p>
            <p>For the full terms, please read our Terms of Service.</p>
          </section>

          <section id="cookie-policy">
            <Paragraph
              style={{
                fontWeight: "bold",
                fontSize:
                  window.innerWidth > 400
                    ? "1.5rem"
                    : window.innerWidth > 300
                    ? "1.35rem"
                    : "1rem",
                paddingBottom: "1%",
              }}
            >
              Cookie Policy
            </Paragraph>
            <p>
              Kepler Solutions uses cookies to enhance your experience. This
              Cookie Policy explains what cookies are and how we use them.
            </p>
            <h5>What Are Cookies?</h5>
            <p>
              Cookies are small text files stored on your device when you visit
              a website. They help us remember your preferences and improve your
              experience.
            </p>
            <h5>How We Use Cookies</h5>
            <p>
              We use cookies to provide security as you visit our site, and
              improve our services for your benefit.
            </p>
            <p>For more details, please read our full Cookie Policy.</p>
          </section>

          <section>
            <p>
              If you have any questions or concerns about our legal policies,
              please contact us at:{" "}
              <span
                href="mailto:legal@keplersolutionshq.com"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  cursor: "pointer",
                  transition: "color 0.2s",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontSize:
                    window.innerWidth > 389
                      ? ""
                      : window.innerWidth > 350
                      ? "13.5px"
                      : "12.5px",
                }}
                onMouseEnter={(e) => (e.target.style.color = "#e75139")}
                onMouseLeave={(e) => (e.target.style.color = "inherit")}
              >
                legal@keplersolutions.com
              </span>
            </p>
          </section>
        </div>
      </Element>
    </>
  );
};

export default Legal;
