import React from "react";
import { useMediaQuery } from "react-responsive";

const AuditTable = ({ audit }) => {
  const fieldMappings = {
    solarPanelsKW: { label: "Solar Panels (kW):", formatNumber: true },
    hybridInverterKVA: { label: "Hybrid Inverter (kVA):", formatNumber: true },
    lFP48VBatteriesAH: { label: "48V LFP Battery (AH):", formatNumber: true },
    percentSavings: { label: "Savings (%):", formatNumber: true },
    autoCurrencyCode: { label: "Currency:", formatNumber: false },
    monthlySavings: { label: "Monthly Savings:", formatNumber: true },
    nonSolarCost: { label: "Non-solar Cost",formatNumber: true },
    solarCost: { label: "Solar Cost", formatNumber: true },
    abatedEmissionsMTCO2: { label: "Avoided CO2 (Tons):", formatNumber: false },
  };

  const isMobile = useMediaQuery({ maxWidth: 992 });

  const formatSelectedNumber = (number) => {
    if (number == null || number === undefined) return "-";
    if (Number.isInteger(number)) return number.toLocaleString("en-US");
    return Math.round(number).toLocaleString("en-US");
  };

  const sortedEntries = Object.entries(fieldMappings).map(
    ([key, { label, formatNumber }]) => ({
      key,
      label,
      value: audit[key] != null ? audit[key] : "-",
      formatNumber,
    })
  );

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>Recommended</th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {sortedEntries.map(({ key, label, value, formatNumber }) => (
          <tr key={key} style={{ fontSize: isMobile ? "10px" : "13px" }}>
            <td style={{ width: isMobile ? "78%" : "80%" }}>{label}</td>
            <td style={{ width: isMobile ? "22%" : "20%" }}>
              {formatNumber ? formatSelectedNumber(value) : value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AuditTable;
