import React from "react";

const AuditOrder = ({ order, isMobile }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatSelectedNumber = (number) => {
    if (number === null || number === undefined) return "-";
    if (Number.isInteger(number)) return number.toLocaleString("en-US");
    return Math.ceil(number).toLocaleString("en-US");
  };

  const formatMoneyNumber = (number) => {
    if (number === null || number === undefined) return "-";
    return Number(number.toFixed(2)).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  let fieldsToDisplay = [];
  let mapping = {};

  if (order.type === "guided") {
    if (order.audit) {
      mapping = {
        solarPanelsKW: { label: "Solar Panels (kW):", formatNumber: true },
        hybridInverterKVA: {
          label: "Hybrid Inverter (kVA):",
          formatNumber: true,
        },
        lFP48VBatteriesAH: {
          label: "48V LFP Battery (AH):",
          formatNumber: true,
        },
        percentSavings: { label: "Savings (%):", formatNumber: true },
        autoCurrencyCode: { label: "Currency:", formatNumber: false },
        monthlySavings: { label: "Monthly Savings:", formatNumber: true },
        nonSolarCost: { label: "Non-solar Cost:", formatNumber: true },
        solarCost: { label: "Solar Cost:", formatNumber: true },
        abatedEmissionsMTCO2: {
          label: "Avoided CO2 (Tons):",
          formatNumber: false,
        },
      };

      fieldsToDisplay = Object.entries(order.audit).map(([key, value]) => ({
        label: mapping[key] ? mapping[key].label : key,
        value,
        formatNumber: mapping[key] ? mapping[key].formatNumber : false,
      }));
    } else if (order.deployed) {
      mapping = {
        solarPanelsW: { label: "Solar Panels (Watt):", formatNumber: true },
        solarPanelsCount: { label: "Number of Panels:", formatNumber: true },
        solarPanelBrand: { label: "Panel Brand:", formatNumber: false },
        hybridInverterKVA: {
          label: "Hybrid Inverter (kVA):",
          formatNumber: true,
        },
        hybridInverterCount: {
          label: "Number of Inverters:",
          formatNumber: true,
        },
        hybridInverterBrand: { label: "Inverter Brand:", formatNumber: false },
        lFPBatteriesAH: { label: "LFP Battery (AH):", formatNumber: true },
        lFPBatteriesV: { label: "Battery Voltage:", formatNumber: true },
        lFPBatteriesCount: {
          label: "Number of Batteries:",
          formatNumber: true,
        },
        lFPBatteriesBrand: { label: "Battery Brand:", formatNumber: false },
      };

      fieldsToDisplay = Object.entries(order.deployed).map(([key, value]) => ({
        label: mapping[key] ? mapping[key].label : key,
        value,
        formatNumber: mapping[key] ? mapping[key].formatNumber : false,
      }));
    }
  }

  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: isMobile ? "78%" : "80%" }}>
            {order.audit ? `Field` : `Equipment`}
          </th>
          <th style={{ width: isMobile ? "22%" : "20%" }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {fieldsToDisplay.map((field, index) => (
          <tr key={index} style={{ fontSize: isMobile ? "10px" : "13px" }}>
            <td style={{ width: isMobile ? "78%" : "80%" }}>{field.label}</td>
            <td style={{ width: isMobile ? "22%" : "20%" }}>
              {field.formatNumber &&
              (field.label === "Monthly Savings:" ||
                field.label === "Non-solar Cost:" ||
                field.label === "Solar Cost:")
                ? formatMoneyNumber(field.value)
                : field.formatNumber
                ? formatSelectedNumber(field.value)
                : field.value}
            </td>
          </tr>
        ))}
        {order.paymentCurrency && (
          <tr style={{ fontSize: isMobile ? "10px" : "13px" }}>
            <td style={{ width: isMobile ? "78%" : "80%" }}>
              Payment Currency
            </td>
            <td style={{ width: isMobile ? "22%" : "20%" }}>
              {order.paymentCurrency}
            </td>
          </tr>
        )}
        {order.amountPaid && (
          <tr style={{ fontSize: isMobile ? "10px" : "13px" }}>
            <td style={{ width: isMobile ? "78%" : "80%" }}>Amount Paid</td>
            <td style={{ width: isMobile ? "22%" : "20%" }}>
              {formatMoneyNumber(order.amountPaid)}
            </td>
          </tr>
        )}
        {order.paymentDate && (
          <tr style={{ fontSize: isMobile ? "10px" : "13px" }}>
            <td style={{ width: isMobile ? "78%" : "80%" }}>Payment Date</td>
            <td style={{ width: isMobile ? "22%" : "20%" }}>
              {formatDate(order.paymentDate)}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default AuditOrder;
