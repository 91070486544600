import styled, { createGlobalStyle, css, keyframes } from "styled-components";
import { Nav } from "react-bootstrap";

export const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
    background: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #c5cad3;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #223614;
  }
`;

// Navbar
export const BannerContainer = styled.div`
  display: ${(props) => (props.isHPBannerVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.isHPBannerVisible ? "3rem" : "0")};
  width: 100vw;
  top: 0px;
  background-color: #E8EBF0; #c5cad3; #e8ffa4; #f5d4c6;
  color: #4305fa;
  line-height: 1.2;
  text-align: center;
  z-index: 999;
  position: absolute;
  padding-left: 2%;
  padding-right: 2%;
  cursor: pointer;

  @media (max-width: 992px) {
    font-size: 15px;
  }
`;

export const HoverLink = styled(Nav.Link)`
  &&& {
    color: #223614;
    margin: ${({ isNavExpanded }) => (isNavExpanded ? "2vmax" : "2.3vmax")};
    transition: font-size 0.1s, text-shadow 0.1s;
    font-size: ${({ isNavExpanded }) => (isNavExpanded ? "1.5rem" : "1.3rem")};
    text-align: center;
    display: block;
    height: ${({ isNavExpanded }) => (isNavExpanded ? "1.9em" : "")};
    white-space: nowrap;
  }

  &:hover {
    color: #1b2e10;
    text-shadow: 0 0 1px #1b2e10;
  }

  @media (max-width: 992px) {
    font-size: ${({ isNavExpanded }) => (isNavExpanded ? "" : "")};
  }
`;

export const HoverLinkButton = styled(Nav.Link)`
  &&& {
    color: #ffffff;
    background-color: #223614 !important;
    height: 40px;
    padding-left: 2vmax;
    padding-right: 2vmax;
    transition: font-size 0.05s;
    max-font-size: 2vmax;
    text-align: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    white-space: nowrap;

    @media (max-width: 992px) {
      margin-top: ${({ isNavExpanded }) => (isNavExpanded ? "3.5vmax" : "")};
      margin-bottom: ${({ isNavExpanded }) => (isNavExpanded ? "10vmax" : "")};
      width: ${({ isNavExpanded }) => (isNavExpanded ? "70%" : "")};
      font-size: ${({ isNavExpanded }) => (isNavExpanded ? "1.3rem" : "2vmax")};
      height: ${({ isNavExpanded }) => (isNavExpanded ? "50px" : "40px")};
    }
  }

  &:hover {
    background-color: #1b2e10 !important;
    svg {
      color: #ccff33;
    }
  }

  svg {
    font-size: 2.5vmax;
    margin-left: 0.5vmax;
  }
`;

export const UserHoverLinkButton = styled(Nav.Link)`
  &&& {
    color: #223614;
    background-color: #e3e9e9;
    height: 40px;
    padding-left: 1vmax;
    padding-right: 1vmax;
    transition: font-size 0.05s;
    max-font-size: 2vmax;
    text-align: center;
    border: 1px solid #9cb2b0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    white-space: nowrap;

    @media (max-width: 992px) {
      margin-top: ${({ isNavExpanded }) => (isNavExpanded ? "3.5vmax" : "")};
      margin-bottom: ${({ isNavExpanded }) => (isNavExpanded ? "10vmax" : "")};
      width: ${({ isNavExpanded }) => (isNavExpanded ? "70%" : "")};
      font-size: ${({ isNavExpanded }) => (isNavExpanded ? "1.3rem" : "2vmax")};
      height: ${({ isNavExpanded }) => (isNavExpanded ? "50px" : "40px")};
    }
  }

  &:hover {
    color: #1b2e10;
    text-shadow: 0 0 1px #1b2e10;
    background-color: #e8ffa4 !important;
    border: 1px solid #7a9795 !important;
    transition: 0.3s ease-in-out;
  }

  svg {
    font-size: 2.5vmax;
    margin-left: 0.5vmax;
  }
`;

export const MobileHoverLinkButton = styled(Nav.Link)`
  &&& {
    color: #223614;
    background-color: #e3e9e9;
    height: 40px;
    padding-left: 1vmax;
    padding-right: 1vmax;
    transition: font-size 0.05s;
    max-font-size: 2vmax;
    text-align: center;
    border: 1px solid #7a9795;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    white-space: nowrap;

    @media (max-width: 992px) {
      margin-top: ${({ isNavExpanded }) => (isNavExpanded ? "3.5vmax" : "")};
      margin-bottom: ${({ isNavExpanded }) => (isNavExpanded ? "10vmax" : "")};
      width: ${({ isNavExpanded }) => (isNavExpanded ? "70%" : "")};
      font-size: ${({ isNavExpanded }) => (isNavExpanded ? "1.3rem" : "2vmax")};
      height: ${({ isNavExpanded }) => (isNavExpanded ? "50px" : "40px")};
    }
  }

  &:hover {
    color: #1b2e10;
    text-shadow: 0 0 1px #1b2e10;
    background-color: #e8ffa4 !important;
    transition: 0.3s ease-in-out;
  }

  svg {
    font-size: 2.5vmax;
    margin-left: 0.5vmax;
  }
`;



export const WrapperMenu = styled.div`
  opacity: 1 !important;
  margin: 2%;
  margin-right: 5%;
  width: 38px;
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;

  &.open {
    transform: rotate(-45deg);
  }
`;

export const LineMenu = styled.div`
  background-color: #223614 !important;
  border-radius: 5px;
  width: 100%;
  height: 4px;

  &.half {
    width: 50%;
  }

  &.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
  }

  ${WrapperMenu}.open &.start {
    transform: rotate(-90deg) translateX(3px);
  }

  &.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
  }

  ${WrapperMenu}.open &.end {
    transform: rotate(-90deg) translateX(-3px);
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: calc(100% + 0.5px);
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  height: automatic;
  width: 100%;
`;

export const DropdownMenuItem = styled.div`
  padding: 5px 15px;
  color: #223614;
  background-color: #e3e9e9;
  margin: 0px;
  cursor: pointer;
  height: 35px;
  font-size: 15px;

  &:hover {
    color: #1b2e10;
    text-shadow: 0 0 1px #1b2e10;
    background-color: #e8ffa4 !important;
  }

  &:first-child {
    border-bottom: 1px solid #ccc;
  }
`;

// Landing Section
export const SectionContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  margin: auto;
  padding: 90;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 5vw;
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
  height: auto;
  margin: auto;
  gap: 0rem;

  /* Animation */
  /*opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? 0 : "20px")});*/
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;

  @media (min-width: 992px) {
    flex-direction: column; /* Changed to column */
    align-items: flex-start; /* Changed to flex-start */
    justify-content: flex-start; /* Changed to flex-start */

    #welcome-element {
      order: ${({ mobileOrder }) => (mobileOrder ? -1 : 0)};
    }

    #rest-of-section-one {
      width: ${({ mobileOrder }) => (mobileOrder ? "100%" : "auto")};
    }
  }

  #subsection-one {
    /* Added */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  #subsection-two {
    /* Added */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 2vmax;
  padding: 4vmax;
  height: fit-content;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2vmax;
  padding: 2vmax;
  height: fit-content;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
  }

  margin: 3vmax;
  margin-top: 0vmax;
  margin-bottom: 1vmax;
  background-color: transparent;
`;

export const ButtonContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2vmax;
  padding: 1vmax;
  padding-left: 0;
  height: fit-content;
  margin: 3vmax;
  margin-left: 0;
  margin-bottom: 1vmax;
  background-color: transparent;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1vmax;
    margin: 3vmax;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex: 1 1 0;
  height: 120px;
  font-size: 19px;
  text-align: left;
  background-color: #223614;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding-top: 0.5vmax;
  padding-bottom: 0.5vmax;
  gap: 0.7rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 992px) {
    font-size: 2vmax;
    width: 100%;
  }

  &:hover:not(:focus):not(:active) {
    color: #fff;
    svg {
      color: #ccff33;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: #1b2e10;
      color: #fff;
      box-shadow: 0px 5px 5px rgba(204, 255, 51, 1);
      svg {
        color: #ccff33;
      }
    `}

  svg {
    font-size: 30px;
    margin: 0.5vmax;
    min-width: 40px;
  }
`;

export const Button4 = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0 0 calc(% - 10px);
  padding-left: 1vmax;
  padding-right: 1vmax;
  white-space: nowrap;
  height: 50px;
  font-size: 16px;
  background-color: #223614;
  color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 992px) {
    font-size: 2vmax;
    width: 100%;
    height: 60px;
  }

  &:hover {
    svg {
      color: #ccff33;
    }
  }

  &:active {
    color: #ccff33;
    background-color: #1b2e10;
  }

  svg {
    margin: 0.5vmax;
  }
`;


export const ContinueButton = styled.button`
  width: 13vmax;
  height: 40px;
  padding-left: 1vmax;
  padding-right: 1vmax;
  font-size: 20px;
  background-color: transparent;
  color: #d6dce5;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  pointer-events: none;
  @media (max-width: 992px) {
    font-size: 2vmax;
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: #1b2e10;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
      pointer-events: auto;
      svg {
        color: #ccff33;
      }
    `}

  svg {
    margin-left: 0.5vmax;
  }
`;

export const GetStartedButton = styled.button`
  position: relative;
  max-width: 20vmax;
  height: 50px;
  padding-left: 1vmax;
  padding-right: 1vmax;
  font-size: 16px;
  background-color: #223614;
  color: #fff;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 992px) {
    font-size: 2vmax;
  }

  &:hover {
    svg {
      color: #ccff33;
    }
  }

  &:active {
    color: #ccff33;
    background-color: #1b2e10;
  }

  svg {
    margin: 0.5vmax;
  }
`;

export const LearnMoreButton = styled.button`
  position: relative;
  max-width: 20vmax;
  height: 50px;
  padding-left: 1vmax;
  padding-right: 1vmax;
  font-size: 16px;
  background-color: #223614;
  color: #fff;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 992px) {
    font-size: 2vmax;
  }

  &:hover {
    svg {
      color: #ccff33;
    }
  }

  &:active {
    color: #ccff33;
    background-color: #1b2e10;
  }

  svg {
    margin: 0.5vmax;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  flex: 1.2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  height: 600px;

  @media (max-width: 992px) {
    max-height: 80vmin;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transparency: 20%;
  filter: brightness(70%);
`;

export const TextOverlay = styled.div`
  position: absolute;
  top: 250px;
  left: 2%;
  transform: translate(0, -50%);
  color: #ccff33;
  margin: auto;
  font-size: 56px;
  font-weight: bold;
  text-align: left;
  margin-right: 0;
  margin-left: 0;
  line-height: 1.2;
  white-space: nowrap;
  @media (max-width: 992px) {
    font-size: 4.4vmax;
    top: 32%;
  }
`;

export const StyledPeriod = styled.span`
  color: #e75139;
  vertical-align: middle;
`;

export const SubTextOverlay = styled.div`
  position: absolute;
  top: 310px;
  left: 2%;
  transform: translate(0, -50%);
  color: #fff;
  margin: auto;
  font-size: 45px;
  font-weight: bold;
  text-align: left;
  margin-right: 0;
  margin-left: 0;
  line-height: 0.9;
  @media (max-width: 992px) {
    font-size: 4vmax;
    top: 50%;
  }
`;

export const AnimationImage = styled.img`
  max-width: 90%;
  height: auto;
  object-fit: contain;
  background-color: #d6dce5;
  margin: 2vmax;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
`;

export const WelcomeAnimationImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 70vh;
  margin-left: 3vmax;
  animation: translateAnimation 10s infinite linear;
  animation-delay: 10s;

  @keyframes translateAnimation {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
`;

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px;
  text-align: left;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const Welcome = styled.h1`
  color: #223614;
  margin: auto;
  font-size: 65px;
  line-height: 1.2;
  font-weight: bold;
  text-align: left;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 1vmax;
  @media (max-width: 992px) {
    font-size: 5vmax;
  }
`;

export const SubWelcome = styled.h1`
  color: #e75139;
  font-size: 50px;
  white-space: nowrap;
  font-weight: bold;
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  margin: auto;
  height: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 992px) {
    font-size: 4.4vmax;
  }

  ${({ fadeTransition }) =>
    fadeTransition &&
    css`
      opacity: 0;
      transition: opacity 0.5s ease-out;
    `}
`;

export const Heading = styled.h1`
  text-align: left;
  font-size: 23px;
  margin: auto;
  margin-left: 0;
  margin-top: 2vmin;
  margin-bottom: 2vmin;
  color: #223614;
  @media (max-width: 992px) {
    font-size: 2.4vmax;
    margin: auto;
  }
`;

export const RestHeading = styled.h1`
  color: #223614;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  padding: 0;
  margin-left: 10%;
  margin-right: 10%;
  height: auto;
  margin-top: auto;
  margin-bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 992px) {
    font-size: 4.4vmax;
    margin-left: 5%;
    margin-right: 5%;
  }

  ${({ fadeTransition }) =>
    fadeTransition &&
    css`
      opacity: 0;
      transition: opacity 0.5s ease-out;
    `}
`;

const animateTop = keyframes`
  25% {
    width: 100%;
    opacity: 1;
  }

  30%, 100% {
    opacity: 0;
  }
`;

const animateBottom = keyframes`
  0%, 50% {
    opacity: 0;
    width: 0;
  }

  75% {
    opacity: 1;
    width: 100%;
  }

  76%, 100% {
    opacity: 0;
  }
`;

export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 20px;
  padding: 1vmax;
  width: 100%;
  height: 100%;
  background-color: ;#223614;
  border-radius: 10px;
  box-shadow: ;0px 2px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: left;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
  }

position: relative;

  /* Pseudo-elements acting as the moving lines */
  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 100vmax;
  }

  &::before {
    top: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: linear-gradient(90deg, transparent 50%, rgba(231, 81, 57, 0.2), rgb(231, 81, 57, 0.5));
    animation: ${animateTop} 3.5s ease-in-out infinite;
  }

  &::after {
    right: 0;
    bottom: 0;
    height: 5px;
    background: linear-gradient(90deg, rgb(204, 255, 51), rgba(204, 255, 51, 0.7), transparent 50%);
    animation: ${animateBottom} 3.5s ease-in-out infinite;
  }
`;

export const ParagraphContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 20px;
  padding: 1vmax;
  width: 100%;
  height: 100%;
  background-color: ;#223614;
  border-radius: 10px;
  box-shadow: ;0px 2px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: left;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
  }

`;

export const Paragraph = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  font-size: 18px;
  margin: 0;
  color: #223614;

  @media (max-width: 992px) {
    font-size: 2.1vmax;
  }

  svg {
    margin: 1vmax;
    margin-top: 0.5vmax;
    flex-shrink: 0;
  }
`;

export const BoldSpan = styled.span`
  font-weight: bold;
  height: 0;
`;

export const HighlightContainer1 = styled.span`
  position: relative;
  display: inline;

  &:before {
    content: "";
    display: block;
    height: 14%;
    width: 100%;
    position: absolute;
    background: #ccff33;
    opacity: 1;
    z-index: 1;
    transform: rotate(2deg);
    top: 70px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;

    @media (max-width: 992px) {
      top: 5.3vmax;
      padding: 9px 2px 2px 9px;
    }
  }
`;

export const HighlightContainer = styled.span`
  position: relative;
  display: inline;

  &:before {
    content: "";
    display: block;
    height: 8%;
    width: 100%;
    position: absolute;
    background: #ccff33;
    opacity: 1;
    z-index: 1;
    transform: rotate(2deg);
    top: 42px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;

    @media (max-width: 992px) {
      top: 5.05vmax;
      padding: 8px 1px 1px 8px;
    }
  }
`;

export const HighlightContainer2 = styled.span`
  position: relative;
  display: inline;

  &:before {
    content: "";
    display: block;
    height: 8%;
    width: 100%;
    position: absolute;
    background: #ccff33;
    opacity: 1;
    z-index: 1;
    transform: rotate(2deg);
    top: 45px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 7px 3px 3px 7px;

    @media (max-width: 992px) {
      padding: 8px 1px 1px 8px;
    }
  }
`;

export const HighlightContainer3 = styled.span`
  position: relative;
  display: inline;

  &:before {
    content: "";
    display: block;
    height: 8%;
    width: 100%;
    position: absolute;
    background: #81bc03;
    opacity: 1;
    z-index: 1;
    transform: rotate(1.5deg);
    top: 42px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 8px 3px 3px 8px;

    @media (max-width: 992px) {
      top: 5.15vmax;
      padding: 8px 1px 1px 8px;
    }
  }
`;

export const Highlight = styled.span`
  position: relative;
  z-index: 1;
`;

//Footer
export const FooterContainer = styled.footer`
  padding-top: 6.5vmax;
  padding-left: 2vmax;
  padding-right: 2vmax;
  padding-bottom: 1vmax;
  background-color: #1b2e10;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 47vh;
  position: relative;
  z-index: 4000;
  width: 100%;

  @media (max-width: 750px) {
    min-height: 100vh;
  }
  @media (max-width: 400px) {
  }
`;

export const Section = styled.div`
  width: 15vw;
  margin-bottom: 3vh;

  @media (max-width: 750px) {
    flex-basis: 75%;
    width: 8.5rem;
  }

  @media (max-width: 350px) {
    flex-basis: 50%;
    width: 8rem;
  }

  @media (max-width: 290px) {
    flex-basis: 50%;
    width: 7rem;
  }
`;

export const SectionHeading = styled.h4`
  margin-bottom: 0.6rem;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: #ccff33;
  }

  @media (max-width: 350px) {
    font-size: 80%;
  }
`;

export const SectionHeadingCalm = styled.h4`
  margin-bottom: 0.6rem;
  font-size: 1.1rem;
  font-weight: bold;

  @media (max-width: 350px) {
    font-size: 80%;
  }
`;

export const FooterInfo = styled.div`
  margin-bottom: 2.1rem;
  font-size: 0.9rem;
  cursor: pointer;

  p {
    margin: 0.6rem 0;
    &:hover {
      color: #ccff33;
    }
  }

  @media (max-width: 750px) {
    p {
      margin: 1rem 0;
    }
  }

  @media (max-width: 350px) {
    font-size: 80%;
  }
`;
export const FooterInfo1 = styled.div`
  margin-bottom: 2.1rem;
  font-size: 0.9rem;

  p {
    margin: 0.6rem 0;
  }

  @media (max-width: 750px) {
    p {
      margin: 1rem 0;
    }
  }

  @media (max-width: 350px) {
    font-size: 60%;
  }
`;

export const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 1.2rem;
  padding-bottom: 0.5rem;
  position: relative;
  height: 10%;

  a {
    margin: 0 0.7rem;
    color: #fff;
    font-size: 1.25rem;
    transition: color 0.3s;

    &:hover {
      color: #ccff33;
    }
  }
`;

export const Copyright = styled.p`
  padding-top: 1.2rem;
  padding-bottom: 0.6rem;
  padding-left: auto;
  padding-right: auto;
  margin: 0;
  width: 100%;
  font-size: 0.7rem;
  text-align: center;
  border-top: 0.1px solid #3f4753;
  color: #e8ebf0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 750px) {
    padding-top: 1rem;
    padding-bottom: 0.6rem;
  }

  @media (max-width: 390px) {
    font-size: 0.65rem;
  }

  @media (max-width: 365px) {
    font-size: 0.5rem;
  }

  @media (max-width: 300px) {
    font-size: 0.45rem;
  }
`;

export const Copyright1 = styled.p`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: auto;
  padding-right: auto;
  margin: 0;
  width: 100%;
  font-size: 0.7rem;
  text-align: center;
  color: #7a9795;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 750px) {
    padding-bottom: 0.6rem;
  }

  @media (max-width: 390px) {
    font-size: 0.65rem;
  }

  @media (max-width: 365px) {
    font-size: 0.5rem;
  }

  @media (max-width: 300px) {
    font-size: 0.45rem;
  }
`;

export const Copyright2 = styled.p`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: auto;
  padding-right: auto;
  margin: 0;
  width: 100%;
  font-size: 0.7rem;
  text-align: center;
  color: #1b2e10;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 750px) {
    padding-bottom: 0.6rem;
  }

  @media (max-width: 390px) {
    font-size: 0.65rem;
  }

  @media (max-width: 365px) {
    font-size: 0.5rem;
  }

  @media (max-width: 300px) {
    font-size: 0.45rem;
  }
`;
