import React, { useEffect, useState, useRef } from "react";
import {
  StyledSvg,
  AnimationTextOverlay1,
  AnimationTextOverlay2,
  AnimationTextOverlay3,
  AnimationTextOverlay4,
  ReloadButton,
} from "./StyledAnimations";
import { AsteriskIcon, ReloadIcon } from "./Icons";

const ElectricLineAnimation = () => {
  const [viewBox, setViewBox] = useState("-20 -10 290 190");
  const [restartAnimations, setRestartAnimations] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const svgRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      let newViewBox;

      if (width > 1200) {
        newViewBox = "-20 -10 290 190";
      } else if (width > 992) {
        newViewBox = "-20 -10 290 190";
      } else if (width > 768) {
        newViewBox = "-10 -10 270 190";
      } else if (width > 490) {
        newViewBox = "60 -60 220 230";
      } else {
        newViewBox = "65 30 220 5";
      }

      setViewBox(newViewBox);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const startInterval = () => {
    const id = setInterval(() => {
      setRestartAnimations(true);
      setTimeout(() => {
        setRestartAnimations(false);
      }, 500);
    }, 360000);
    setIntervalId(id);
  };

  useEffect(() => {
    startInterval();

    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  const handleRestartAnimations = () => {
    clearInterval(intervalId);
    setRestartAnimations(true);
    setTimeout(() => {
      setRestartAnimations(false);
      const svgElement = svgRef.current;
      const screenHeight = window.innerHeight;
      const scrollOffsetFactor = 0.07;
      const scrollOffset = screenHeight * scrollOffsetFactor;
      const targetScrollTop =
        window.pageYOffset +
        svgElement.getBoundingClientRect().top -
        scrollOffset;
      window.scrollTo({ top: targetScrollTop, behavior: "smooth" });
      startInterval();
    }, 500);
  };

  return (
    <div
      id="animation text"
      style={{
        padding: "auto",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      key={restartAnimations ? "restart" : "default"}
    >
      <StyledSvg ref={svgRef}>
        {
          <svg
            fill="none"
            version="1.1"
            viewBox={viewBox}
            id="svg358"
            xmlns="//www.w3.org/2000/svg"
          >
            <g fill="none" id="g304">
              <g stroke="#ccff33" strokeLinejoin="round" id="Ag302">
                <g
                  id="g244"
                  className="Adirect"
                  stroke="#ccff33"
                  strokeWidth="2"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="1"
                  strokeDasharray="30,150"
                  strokeDashoffset="0"
                >
                  <path
                    d="m 138.05,130.46 h 21.276 l 12.145,-15.566 h 50.149 l 3.0621,5.2311"
                    id="path232"
                  />
                  <path
                    d="m 138.02,134.55 23.092,0.006 12.326,-15.566 46.208,0.0638 8.9312,15.438 1.4673,0.0638"
                    id="path234"
                  />
                  <path
                    d="m 138.5,138.74 h 23.892 l 11.694,-15.205 43.793,-0.0826 9.5632,15.063 2.485,0.0856"
                    id="path236"
                  />
                  <path
                    d="m 138.64,143.19 24.614,0.0451 11.379,-15.566 42.167,0.0529 10.014,15.333 2.8029,-0.004"
                    id="path238"
                  />
                  <path
                    d="m 122.15817,165.8224 -0.019,-5.774 4.89083,-1.80441 v -5.774"
                    id="path240"
                    fillRule
                  />
                  <path
                    d="m 138.64,147.43 h 26.283 l 10.973,-15.566 h 38.829 l 10.646,15.608 4.427,0.0902"
                    id="path242"
                  />
                </g>
                <g
                  id="g262"
                  className="Aservice"
                  stroke="#ccff33"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="1"
                  strokeDasharray="30,150"
                  strokeDashoffset="0"
                >
                  <path
                    d="m 176.69108,104.14422 v -8.2933 l 12.51664,-6.851473 c 0.01,-1.476807 -0.0127,-0.746681 -0.0127,-4.312447"
                    id="path246"
                    fillRule
                  />
                  <path
                    d="m 98.364,117.6594 1.135227,-3.21905 6.172303,-2.3899 0.0292,-3.94355"
                    id="path248"
                    fillRule
                  />
                  <path d="M 169.542,62.431 H 186.28" id="path250" />
                  <path
                    d="m 111.8143,84.616 8.5455,0.04339 L 124.53399,70.69 137.49,70.7453"
                    id="path252"
                    fillRule
                  />
                  <path
                    d="m 111.36,98.157 h 28.561 l 9.5541,-22.884 36.884,-0.02385"
                    id="path254"
                  />
                  <path
                    d="m 111.58,93.449 25.26,0.13275 9.4464,-22.446 39.849,6e-5"
                    id="path256"
                  />
                  <path
                    d="m 111.58,88.89 22.07,-0.12243 9.7016,-22.063 42.784,-0.12753"
                    id="path258"
                  />
                  <path
                    d="m111.95 103.25 31.831-0.0898 10.394-23.372 31.97-6e-3"
                    id="path260"
                  />
                </g>
                <g
                  id="g284"
                  className="Acredit"
                  stroke="#ccff33"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="1"
                  strokeDasharray="30,150"
                  strokeDashoffset="2000"
                >
                  <path
                    className="Adelaycr2"
                    d="m 184.2039,16.9716 0.0434,4.366991 7.29557,1.477939 0.0105,3.847367"
                    id="path264"
                    fillRule
                  />
                  <path
                    d="m 90.496,19.331 v -4.3236 l 4.8223,-4.9057 2.4838,0.01438"
                    id="path266"
                  />
                  <path
                    d="m 93.461,19.407 v -2.2555 l 3.7441,-3.4734"
                    id="path268"
                  />
                  <path
                    className="Adelaycr2"
                    d="m 175.59499,47.725895 11.055,0.0451"
                    id="path270"
                  />
                  <path
                    d="m 100.15,35.883 h 8.2933 l 4.2742,11.228 h 3.9552 l 0.82933,1.2759 2.1052,4e-6"
                    id="path272"
                  />
                  <path
                    d="m 100.48,29.004 h 31.793 l 11.193,11.401 42.85,-3.1e-5"
                    id="path274"
                  />
                  <path
                    d="m 100.35,32.565 29.419,-0.09022 10.935,11.201 45.788,0.1089"
                    id="path276"
                  />
                  <path
                    d="m 100.22,25.357 h 34.718 l 10.287,10.973 h 40.977"
                    id="path278"
                  />
                  <path
                    className="Adelaycr1"
                    d="m 120.32904,21.71964 17.25424,-0.0034 10.11488,11.197219 38.75188,0.0188"
                    id="path280"
                    fillRule
                  />
                  <path
                    d="m 100.24,39.66 h 2.2555 l 1.1277,4.9169 2.481,-0.04511 0.67664,-1.1728 h 1.5788"
                    id="path282"
                  />
                </g>
                <g
                  id="g300"
                  className="Afinal1"
                  stroke="#ccff33"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="1"
                  strokeDasharray="30,150"
                  strokeDashoffset="2000"
                >
                  <path
                    className="Adelaycr3"
                    d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                    id="path286"
                  />
                  <path
                    className="Adelaycr3"
                    d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                    id="path288"
                  />
                  <path d="M 212.929,79.347 H 229.56" id="path290" />
                  <path d="M 212.924,74.585 H 229.69" id="path292" />
                  <path d="M 213.249,62.024 H 229.88" id="path294" />
                  <path d="M 213.429,65.748 H 230.06" id="path296" />
                  <path d="M 213.289,69.596 H 229.92" id="path298" />
                </g>
                <g
                  id="g300"
                  className="Afinal2"
                  stroke="#ccff33"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="1"
                  strokeDasharray="30,150"
                  strokeDashoffset="2000"
                >
                  <path
                    className="Adelaysv"
                    d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                    id="path286"
                  />
                  <path
                    className="Adelaysv"
                    d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                    id="path288"
                  />
                  <path d="M 212.929,79.347 H 229.56" id="path290" />
                  <path d="M 212.924,74.585 H 229.69" id="path292" />
                  <path d="M 213.249,62.024 H 229.88" id="path294" />
                  <path d="M 213.429,65.748 H 230.06" id="path296" />
                  <path d="M 213.289,69.596 H 229.92" id="path298" />
                </g>
              </g>
              <g
                id="g300"
                className="Afinal3"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Adelaydr"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Adelaydr"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
              <g
                id="g2586"
                className="Ainternal"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path d="m 203.06,53.39 v 4.3383" id="path2576" />
                <path d="m 207.37,53.459 v 4.3383" id="path2578" />
                <path d="m 192.28,53.461 v 4.3383" id="path2580" />
                <path d="m 199.52,53.47 v 4.3383" id="path2582" />
                <path d="m 195.9,53.529 v 4.3383" id="path2584" />
              </g>
            </g>
            <g stroke="#ccff33" strokeLinejoin="round" id="Bg302">
              <g
                id="g244"
                className="Bdirect"
                fill="none"
                stroke="#ccff33"
                strokeWidth="2"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 138.05,130.46 h 21.276 l 12.145,-15.566 h 50.149 l 3.0621,5.2311"
                  id="path232"
                />
                <path
                  d="m 138.02,134.55 23.092,0.006 12.326,-15.566 46.208,0.0638 8.9312,15.438 1.4673,0.0638"
                  id="path234"
                />
                <path
                  d="m 138.5,138.74 h 23.892 l 11.694,-15.205 43.793,-0.0826 9.5632,15.063 2.485,0.0856"
                  id="path236"
                />
                <path
                  d="m 138.64,143.19 24.614,0.0451 11.379,-15.566 42.167,0.0529 10.014,15.333 2.8029,-0.004"
                  id="path238"
                />
                <path
                  d="m 122.15817,165.8224 -0.019,-5.774 4.89083,-1.80441 v -5.774"
                  id="path240"
                  fillRule
                />
                <path
                  d="m 138.64,147.43 h 26.283 l 10.973,-15.566 h 38.829 l 10.646,15.608 4.427,0.0902"
                  id="path242"
                />
              </g>
              <g
                id="g262"
                className="Bservice"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 176.69108,104.14422 v -8.2933 l 12.51664,-6.851473 c 0.01,-1.476807 -0.0127,-0.746681 -0.0127,-4.312447"
                  id="path246"
                  fillRule
                />
                <path
                  d="m 98.364,117.6594 1.135227,-3.21905 6.172303,-2.3899 0.0292,-3.94355"
                  id="path248"
                  fillRule
                />
                <path d="M 169.542,62.431 H 186.28" id="path250" />
                <path
                  d="m 111.8143,84.616 8.5455,0.04339 L 124.53399,70.69 137.49,70.7453"
                  id="path252"
                  fillRule
                />
                <path
                  d="m 111.36,98.157 h 28.561 l 9.5541,-22.884 36.884,-0.02385"
                  id="path254"
                />
                <path
                  d="m 111.58,93.449 25.26,0.13275 9.4464,-22.446 39.849,6e-5"
                  id="path256"
                />
                <path
                  d="m 111.58,88.89 22.07,-0.12243 9.7016,-22.063 42.784,-0.12753"
                  id="path258"
                />
                <path
                  d="m111.95 103.25 31.831-0.0898 10.394-23.372 31.97-6e-3"
                  id="path260"
                />
              </g>
              <g
                id="g284"
                className="Bcredit"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Bdelaycr2"
                  d="m 184.2039,16.9716 0.0434,4.366991 7.29557,1.477939 0.0105,3.847367"
                  id="path264"
                  fillRule
                />
                <path
                  d="m 90.496,19.331 v -4.3236 l 4.8223,-4.9057 2.4838,0.01438"
                  id="path266"
                />
                <path
                  d="m 93.461,19.407 v -2.2555 l 3.7441,-3.4734"
                  id="path268"
                />
                <path
                  className="Bdelaycr2"
                  d="m 175.59499,47.725895 11.055,0.0451"
                  id="path270"
                />
                <path
                  d="m 100.15,35.883 h 8.2933 l 4.2742,11.228 h 3.9552 l 0.82933,1.2759 2.1052,4e-6"
                  id="path272"
                />
                <path
                  d="m 100.48,29.004 h 31.793 l 11.193,11.401 42.85,-3.1e-5"
                  id="path274"
                />
                <path
                  d="m 100.35,32.565 29.419,-0.09022 10.935,11.201 45.788,0.1089"
                  id="path276"
                />
                <path
                  d="m 100.22,25.357 h 34.718 l 10.287,10.973 h 40.977"
                  id="path278"
                />
                <path
                  className="Bdelaycr1"
                  d="m 120.32904,21.71964 17.25424,-0.0034 10.11488,11.197219 38.75188,0.0188"
                  id="path280"
                  fillRule
                />
                <path
                  d="m 100.24,39.66 h 2.2555 l 1.1277,4.9169 2.481,-0.04511 0.67664,-1.1728 h 1.5788"
                  id="path282"
                />
              </g>
              <g
                id="g300"
                className="Bfinal1"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Bdelaycr3"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Bdelaycr3"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
              <g
                id="g300"
                className="Bfinal2"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Bdelaysv"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Bdelaysv"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
            </g>
            <g
              id="g300"
              className="Bfinal3"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="2000"
            >
              <path
                className="Bdelaydr"
                d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                id="path286"
              />
              <path
                className="Bdelaydr"
                d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                id="path288"
              />
              <path d="M 212.929,79.347 H 229.56" id="path290" />
              <path d="M 212.924,74.585 H 229.69" id="path292" />
              <path d="M 213.249,62.024 H 229.88" id="path294" />
              <path d="M 213.429,65.748 H 230.06" id="path296" />
              <path d="M 213.289,69.596 H 229.92" id="path298" />
            </g>
            <g
              id="g2586"
              className="Binternal"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="0"
            >
              <path d="m 203.06,53.39 v 4.3383" id="path2576" />
              <path d="m 207.37,53.459 v 4.3383" id="path2578" />
              <path d="m 192.28,53.461 v 4.3383" id="path2580" />
              <path d="m 199.52,53.47 v 4.3383" id="path2582" />
              <path d="m 195.9,53.529 v 4.3383" id="path2584" />
            </g>
            <g stroke="#ccff33" strokeLinejoin="round" id="Cg302">
              <g
                id="g244"
                className="Cdirect"
                fill="none"
                stroke="#ccff33"
                strokeWidth="2"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 138.05,130.46 h 21.276 l 12.145,-15.566 h 50.149 l 3.0621,5.2311"
                  id="path232"
                />
                <path
                  d="m 138.02,134.55 23.092,0.006 12.326,-15.566 46.208,0.0638 8.9312,15.438 1.4673,0.0638"
                  id="path234"
                />
                <path
                  d="m 138.5,138.74 h 23.892 l 11.694,-15.205 43.793,-0.0826 9.5632,15.063 2.485,0.0856"
                  id="path236"
                />
                <path
                  d="m 138.64,143.19 24.614,0.0451 11.379,-15.566 42.167,0.0529 10.014,15.333 2.8029,-0.004"
                  id="path238"
                />
                <path
                  d="m 122.15817,165.8224 -0.019,-5.774 4.89083,-1.80441 v -5.774"
                  id="path240"
                  fillRule
                />
                <path
                  d="m 138.64,147.43 h 26.283 l 10.973,-15.566 h 38.829 l 10.646,15.608 4.427,0.0902"
                  id="path242"
                />
              </g>
              <g
                id="g262"
                className="Cservice"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 176.69108,104.14422 v -8.2933 l 12.51664,-6.851473 c 0.01,-1.476807 -0.0127,-0.746681 -0.0127,-4.312447"
                  id="path246"
                  fillRule
                />
                <path
                  d="m 98.364,117.6594 1.135227,-3.21905 6.172303,-2.3899 0.0292,-3.94355"
                  id="path248"
                  fillRule
                />
                <path d="M 169.542,62.431 H 186.28" id="path250" />
                <path
                  d="m 111.8143,84.616 8.5455,0.04339 L 124.53399,70.69 137.49,70.7453"
                  id="path252"
                  fillRule
                />
                <path
                  d="m 111.36,98.157 h 28.561 l 9.5541,-22.884 36.884,-0.02385"
                  id="path254"
                />
                <path
                  d="m 111.58,93.449 25.26,0.13275 9.4464,-22.446 39.849,6e-5"
                  id="path256"
                />
                <path
                  d="m 111.58,88.89 22.07,-0.12243 9.7016,-22.063 42.784,-0.12753"
                  id="path258"
                />
                <path
                  d="m111.95 103.25 31.831-0.0898 10.394-23.372 31.97-6e-3"
                  id="path260"
                />
              </g>
              <g
                id="g284"
                className="Ccredit"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Cdelaycr2"
                  d="m 184.2039,16.9716 0.0434,4.366991 7.29557,1.477939 0.0105,3.847367"
                  id="path264"
                  fillRule
                />
                <path
                  d="m 90.496,19.331 v -4.3236 l 4.8223,-4.9057 2.4838,0.01438"
                  id="path266"
                />
                <path
                  d="m 93.461,19.407 v -2.2555 l 3.7441,-3.4734"
                  id="path268"
                />
                <path
                  className="Cdelaycr2"
                  d="m 175.59499,47.725895 11.055,0.0451"
                  id="path270"
                />
                <path
                  d="m 100.15,35.883 h 8.2933 l 4.2742,11.228 h 3.9552 l 0.82933,1.2759 2.1052,4e-6"
                  id="path272"
                />
                <path
                  d="m 100.48,29.004 h 31.793 l 11.193,11.401 42.85,-3.1e-5"
                  id="path274"
                />
                <path
                  d="m 100.35,32.565 29.419,-0.09022 10.935,11.201 45.788,0.1089"
                  id="path276"
                />
                <path
                  d="m 100.22,25.357 h 34.718 l 10.287,10.973 h 40.977"
                  id="path278"
                />
                <path
                  className="Cdelaycr1"
                  d="m 120.32904,21.71964 17.25424,-0.0034 10.11488,11.197219 38.75188,0.0188"
                  id="path280"
                  fillRule
                />
                <path
                  d="m 100.24,39.66 h 2.2555 l 1.1277,4.9169 2.481,-0.04511 0.67664,-1.1728 h 1.5788"
                  id="path282"
                />
              </g>
              <g
                id="g300"
                className="Cfinal1"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Cdelaycr3"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Cdelaycr3"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
              <g
                id="g300"
                className="Cfinal2"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Cdelaysv"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Cdelaysv"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
            </g>
            <g
              id="g300"
              className="Cfinal3"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="2000"
            >
              <path
                className="Cdelaydr"
                d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                id="path286"
              />
              <path
                className="Cdelaydr"
                d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                id="path288"
              />
              <path d="M 212.929,79.347 H 229.56" id="path290" />
              <path d="M 212.924,74.585 H 229.69" id="path292" />
              <path d="M 213.249,62.024 H 229.88" id="path294" />
              <path d="M 213.429,65.748 H 230.06" id="path296" />
              <path d="M 213.289,69.596 H 229.92" id="path298" />
            </g>
            <g
              id="g2586"
              className="Cinternal"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="0"
            >
              <path d="m 203.06,53.39 v 4.3383" id="path2576" />
              <path d="m 207.37,53.459 v 4.3383" id="path2578" />
              <path d="m 192.28,53.461 v 4.3383" id="path2580" />
              <path d="m 199.52,53.47 v 4.3383" id="path2582" />
              <path d="m 195.9,53.529 v 4.3383" id="path2584" />
            </g>

            <g stroke="#ccff33" strokeLinejoin="round" id="Dg302">
              <g
                id="g244"
                className="Ddirect"
                fill="none"
                stroke="#ccff33"
                strokeWidth="2"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 138.05,130.46 h 21.276 l 12.145,-15.566 h 50.149 l 3.0621,5.2311"
                  id="path232"
                />
                <path
                  d="m 138.02,134.55 23.092,0.006 12.326,-15.566 46.208,0.0638 8.9312,15.438 1.4673,0.0638"
                  id="path234"
                />
                <path
                  d="m 138.5,138.74 h 23.892 l 11.694,-15.205 43.793,-0.0826 9.5632,15.063 2.485,0.0856"
                  id="path236"
                />
                <path
                  d="m 138.64,143.19 24.614,0.0451 11.379,-15.566 42.167,0.0529 10.014,15.333 2.8029,-0.004"
                  id="path238"
                />
                <path
                  d="m 122.15817,165.8224 -0.019,-5.774 4.89083,-1.80441 v -5.774"
                  id="path240"
                  fillRule
                />
                <path
                  d="m 138.64,147.43 h 26.283 l 10.973,-15.566 h 38.829 l 10.646,15.608 4.427,0.0902"
                  id="path242"
                />
              </g>
              <g
                id="g262"
                className="Dservice"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 176.69108,104.14422 v -8.2933 l 12.51664,-6.851473 c 0.01,-1.476807 -0.0127,-0.746681 -0.0127,-4.312447"
                  id="path246"
                  fillRule
                />
                <path
                  d="m 98.364,117.6594 1.135227,-3.21905 6.172303,-2.3899 0.0292,-3.94355"
                  id="path248"
                  fillRule
                />
                <path d="M 169.542,62.431 H 186.28" id="path250" />
                <path
                  d="m 111.8143,84.616 8.5455,0.04339 L 124.53399,70.69 137.49,70.7453"
                  id="path252"
                  fillRule
                />
                <path
                  d="m 111.36,98.157 h 28.561 l 9.5541,-22.884 36.884,-0.02385"
                  id="path254"
                />
                <path
                  d="m 111.58,93.449 25.26,0.13275 9.4464,-22.446 39.849,6e-5"
                  id="path256"
                />
                <path
                  d="m 111.58,88.89 22.07,-0.12243 9.7016,-22.063 42.784,-0.12753"
                  id="path258"
                />
                <path
                  d="m111.95 103.25 31.831-0.0898 10.394-23.372 31.97-6e-3"
                  id="path260"
                />
              </g>
              <g
                id="g284"
                className="Dcredit"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Ddelaycr2"
                  d="m 184.2039,16.9716 0.0434,4.366991 7.29557,1.477939 0.0105,3.847367"
                  id="path264"
                  fillRule
                />
                <path
                  d="m 90.496,19.331 v -4.3236 l 4.8223,-4.9057 2.4838,0.01438"
                  id="path266"
                />
                <path
                  d="m 93.461,19.407 v -2.2555 l 3.7441,-3.4734"
                  id="path268"
                />
                <path
                  className="Ddelaycr2"
                  d="m 175.59499,47.725895 11.055,0.0451"
                  id="path270"
                />
                <path
                  d="m 100.15,35.883 h 8.2933 l 4.2742,11.228 h 3.9552 l 0.82933,1.2759 2.1052,4e-6"
                  id="path272"
                />
                <path
                  d="m 100.48,29.004 h 31.793 l 11.193,11.401 42.85,-3.1e-5"
                  id="path274"
                />
                <path
                  d="m 100.35,32.565 29.419,-0.09022 10.935,11.201 45.788,0.1089"
                  id="path276"
                />
                <path
                  d="m 100.22,25.357 h 34.718 l 10.287,10.973 h 40.977"
                  id="path278"
                />
                <path
                  className="Ddelaycr1"
                  d="m 120.32904,21.71964 17.25424,-0.0034 10.11488,11.197219 38.75188,0.0188"
                  id="path280"
                  fillRule
                />
                <path
                  d="m 100.24,39.66 h 2.2555 l 1.1277,4.9169 2.481,-0.04511 0.67664,-1.1728 h 1.5788"
                  id="path282"
                />
              </g>
              <g
                id="g300"
                className="Dfinal1"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Ddelaycr3"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Ddelaycr3"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
              <g
                id="g300"
                className="Dfinal2"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Ddelaysv"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Ddelaysv"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
            </g>
            <g
              id="g300"
              className="Dfinal3"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="2000"
            >
              <path
                className="Ddelaydr"
                d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                id="path286"
              />
              <path
                className="Ddelaydr"
                d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                id="path288"
              />
              <path d="M 212.929,79.347 H 229.56" id="path290" />
              <path d="M 212.924,74.585 H 229.69" id="path292" />
              <path d="M 213.249,62.024 H 229.88" id="path294" />
              <path d="M 213.429,65.748 H 230.06" id="path296" />
              <path d="M 213.289,69.596 H 229.92" id="path298" />
            </g>
            <g
              id="g2586"
              className="Dinternal"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="0"
            >
              <path d="m 203.06,53.39 v 4.3383" id="path2576" />
              <path d="m 207.37,53.459 v 4.3383" id="path2578" />
              <path d="m 192.28,53.461 v 4.3383" id="path2580" />
              <path d="m 199.52,53.47 v 4.3383" id="path2582" />
              <path d="m 195.9,53.529 v 4.3383" id="path2584" />
            </g>

            <g stroke="#ccff33" strokeLinejoin="round" id="Eg302">
              <g
                id="g244"
                className="Edirect"
                fill="none"
                stroke="#ccff33"
                strokeWidth="2"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 138.05,130.46 h 21.276 l 12.145,-15.566 h 50.149 l 3.0621,5.2311"
                  id="path232"
                />
                <path
                  d="m 138.02,134.55 23.092,0.006 12.326,-15.566 46.208,0.0638 8.9312,15.438 1.4673,0.0638"
                  id="path234"
                />
                <path
                  d="m 138.5,138.74 h 23.892 l 11.694,-15.205 43.793,-0.0826 9.5632,15.063 2.485,0.0856"
                  id="path236"
                />
                <path
                  d="m 138.64,143.19 24.614,0.0451 11.379,-15.566 42.167,0.0529 10.014,15.333 2.8029,-0.004"
                  id="path238"
                />
                <path
                  d="m 122.15817,165.8224 -0.019,-5.774 4.89083,-1.80441 v -5.774"
                  id="path240"
                  fillRule
                />
                <path
                  d="m 138.64,147.43 h 26.283 l 10.973,-15.566 h 38.829 l 10.646,15.608 4.427,0.0902"
                  id="path242"
                />
              </g>
              <g
                id="g262"
                className="Eservice"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 176.69108,104.14422 v -8.2933 l 12.51664,-6.851473 c 0.01,-1.476807 -0.0127,-0.746681 -0.0127,-4.312447"
                  id="path246"
                  fillRule
                />
                <path
                  d="m 98.364,117.6594 1.135227,-3.21905 6.172303,-2.3899 0.0292,-3.94355"
                  id="path248"
                  fillRule
                />
                <path d="M 169.542,62.431 H 186.28" id="path250" />
                <path
                  d="m 111.8143,84.616 8.5455,0.04339 L 124.53399,70.69 137.49,70.7453"
                  id="path252"
                  fillRule
                />
                <path
                  d="m 111.36,98.157 h 28.561 l 9.5541,-22.884 36.884,-0.02385"
                  id="path254"
                />
                <path
                  d="m 111.58,93.449 25.26,0.13275 9.4464,-22.446 39.849,6e-5"
                  id="path256"
                />
                <path
                  d="m 111.58,88.89 22.07,-0.12243 9.7016,-22.063 42.784,-0.12753"
                  id="path258"
                />
                <path
                  d="m111.95 103.25 31.831-0.0898 10.394-23.372 31.97-6e-3"
                  id="path260"
                />
              </g>
              <g
                id="g284"
                className="Ecredit"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Edelaycr2"
                  d="m 184.2039,16.9716 0.0434,4.366991 7.29557,1.477939 0.0105,3.847367"
                  id="path264"
                  fillRule
                />
                <path
                  d="m 90.496,19.331 v -4.3236 l 4.8223,-4.9057 2.4838,0.01438"
                  id="path266"
                />
                <path
                  d="m 93.461,19.407 v -2.2555 l 3.7441,-3.4734"
                  id="path268"
                />
                <path
                  className="Edelaycr2"
                  d="m 175.59499,47.725895 11.055,0.0451"
                  id="path270"
                />
                <path
                  d="m 100.15,35.883 h 8.2933 l 4.2742,11.228 h 3.9552 l 0.82933,1.2759 2.1052,4e-6"
                  id="path272"
                />
                <path
                  d="m 100.48,29.004 h 31.793 l 11.193,11.401 42.85,-3.1e-5"
                  id="path274"
                />
                <path
                  d="m 100.35,32.565 29.419,-0.09022 10.935,11.201 45.788,0.1089"
                  id="path276"
                />
                <path
                  d="m 100.22,25.357 h 34.718 l 10.287,10.973 h 40.977"
                  id="path278"
                />
                <path
                  className="Edelaycr1"
                  d="m 120.32904,21.71964 17.25424,-0.0034 10.11488,11.197219 38.75188,0.0188"
                  id="path280"
                  fillRule
                />
                <path
                  d="m 100.24,39.66 h 2.2555 l 1.1277,4.9169 2.481,-0.04511 0.67664,-1.1728 h 1.5788"
                  id="path282"
                />
              </g>
              <g
                id="g300"
                className="Efinal1"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Edelaycr3"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Edelaycr3"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
              <g
                id="g300"
                className="Efinal2"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Edelaysv"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Edelaysv"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
            </g>
            <g
              id="g300"
              className="Efinal3"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="2000"
            >
              <path
                className="Edelaydr"
                d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                id="path286"
              />
              <path
                className="Edelaydr"
                d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                id="path288"
              />
              <path d="M 212.929,79.347 H 229.56" id="path290" />
              <path d="M 212.924,74.585 H 229.69" id="path292" />
              <path d="M 213.249,62.024 H 229.88" id="path294" />
              <path d="M 213.429,65.748 H 230.06" id="path296" />
              <path d="M 213.289,69.596 H 229.92" id="path298" />
            </g>
            <g
              id="g2586"
              className="Einternal"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="0"
            >
              <path d="m 203.06,53.39 v 4.3383" id="path2576" />
              <path d="m 207.37,53.459 v 4.3383" id="path2578" />
              <path d="m 192.28,53.461 v 4.3383" id="path2580" />
              <path d="m 199.52,53.47 v 4.3383" id="path2582" />
              <path d="m 195.9,53.529 v 4.3383" id="path2584" />
            </g>
            <g stroke="#ccff33" strokeLinejoin="round" id="Fg302">
              <g
                id="g244"
                className="Fdirect"
                fill="none"
                stroke="#ccff33"
                strokeWidth="2"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 138.05,130.46 h 21.276 l 12.145,-15.566 h 50.149 l 3.0621,5.2311"
                  id="path232"
                />
                <path
                  d="m 138.02,134.55 23.092,0.006 12.326,-15.566 46.208,0.0638 8.9312,15.438 1.4673,0.0638"
                  id="path234"
                />
                <path
                  d="m 138.5,138.74 h 23.892 l 11.694,-15.205 43.793,-0.0826 9.5632,15.063 2.485,0.0856"
                  id="path236"
                />
                <path
                  d="m 138.64,143.19 24.614,0.0451 11.379,-15.566 42.167,0.0529 10.014,15.333 2.8029,-0.004"
                  id="path238"
                />
                <path
                  d="m 122.15817,165.8224 -0.019,-5.774 4.89083,-1.80441 v -5.774"
                  id="path240"
                  fillRule
                />
                <path
                  d="m 138.64,147.43 h 26.283 l 10.973,-15.566 h 38.829 l 10.646,15.608 4.427,0.0902"
                  id="path242"
                />
              </g>
              <g
                id="g262"
                className="Fservice"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 176.69108,104.14422 v -8.2933 l 12.51664,-6.851473 c 0.01,-1.476807 -0.0127,-0.746681 -0.0127,-4.312447"
                  id="path246"
                  fillRule
                />
                <path
                  d="m 98.364,117.6594 1.135227,-3.21905 6.172303,-2.3899 0.0292,-3.94355"
                  id="path248"
                  fillRule
                />
                <path d="M 169.542,62.431 H 186.28" id="path250" />
                <path
                  d="m 111.8143,84.616 8.5455,0.04339 L 124.53399,70.69 137.49,70.7453"
                  id="path252"
                  fillRule
                />
                <path
                  d="m 111.36,98.157 h 28.561 l 9.5541,-22.884 36.884,-0.02385"
                  id="path254"
                />
                <path
                  d="m 111.58,93.449 25.26,0.13275 9.4464,-22.446 39.849,6e-5"
                  id="path256"
                />
                <path
                  d="m 111.58,88.89 22.07,-0.12243 9.7016,-22.063 42.784,-0.12753"
                  id="path258"
                />
                <path
                  d="m111.95 103.25 31.831-0.0898 10.394-23.372 31.97-6e-3"
                  id="path260"
                />
              </g>
              <g
                id="g284"
                className="Fcredit"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Fdelaycr2"
                  d="m 184.2039,16.9716 0.0434,4.366991 7.29557,1.477939 0.0105,3.847367"
                  id="path264"
                  fillRule
                />
                <path
                  d="m 90.496,19.331 v -4.3236 l 4.8223,-4.9057 2.4838,0.01438"
                  id="path266"
                />
                <path
                  d="m 93.461,19.407 v -2.2555 l 3.7441,-3.4734"
                  id="path268"
                />
                <path
                  className="Fdelaycr2"
                  d="m 175.59499,47.725895 11.055,0.0451"
                  id="path270"
                />
                <path
                  d="m 100.15,35.883 h 8.2933 l 4.2742,11.228 h 3.9552 l 0.82933,1.2759 2.1052,4e-6"
                  id="path272"
                />
                <path
                  d="m 100.48,29.004 h 31.793 l 11.193,11.401 42.85,-3.1e-5"
                  id="path274"
                />
                <path
                  d="m 100.35,32.565 29.419,-0.09022 10.935,11.201 45.788,0.1089"
                  id="path276"
                />
                <path
                  d="m 100.22,25.357 h 34.718 l 10.287,10.973 h 40.977"
                  id="path278"
                />
                <path
                  className="Fdelaycr1"
                  d="m 120.32904,21.71964 17.25424,-0.0034 10.11488,11.197219 38.75188,0.0188"
                  id="path280"
                  fillRule
                />
                <path
                  d="m 100.24,39.66 h 2.2555 l 1.1277,4.9169 2.481,-0.04511 0.67664,-1.1728 h 1.5788"
                  id="path282"
                />
              </g>
              <g
                id="g300"
                className="Ffinal1"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Fdelaycr3"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Fdelaycr3"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
              <g
                id="g300"
                className="Ffinal2"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Fdelaysv"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Fdelaysv"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
            </g>
            <g
              id="g300"
              className="Ffinal3"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="2000"
            >
              <path
                className="Fdelaydr"
                d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                id="path286"
              />
              <path
                className="Fdelaydr"
                d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                id="path288"
              />
              <path d="M 212.929,79.347 H 229.56" id="path290" />
              <path d="M 212.924,74.585 H 229.69" id="path292" />
              <path d="M 213.249,62.024 H 229.88" id="path294" />
              <path d="M 213.429,65.748 H 230.06" id="path296" />
              <path d="M 213.289,69.596 H 229.92" id="path298" />
            </g>
            <g
              id="g2586"
              className="Finternal"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="0"
            >
              <path d="m 203.06,53.39 v 4.3383" id="path2576" />
              <path d="m 207.37,53.459 v 4.3383" id="path2578" />
              <path d="m 192.28,53.461 v 4.3383" id="path2580" />
              <path d="m 199.52,53.47 v 4.3383" id="path2582" />
              <path d="m 195.9,53.529 v 4.3383" id="path2584" />
            </g>
            <g stroke="#ccff33" strokeLinejoin="round" id="Gg302">
              <g
                id="g244"
                className="Gdirect"
                fill="none"
                stroke="#ccff33"
                strokeWidth="2"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 138.05,130.46 h 21.276 l 12.145,-15.566 h 50.149 l 3.0621,5.2311"
                  id="path232"
                />
                <path
                  d="m 138.02,134.55 23.092,0.006 12.326,-15.566 46.208,0.0638 8.9312,15.438 1.4673,0.0638"
                  id="path234"
                />
                <path
                  d="m 138.5,138.74 h 23.892 l 11.694,-15.205 43.793,-0.0826 9.5632,15.063 2.485,0.0856"
                  id="path236"
                />
                <path
                  d="m 138.64,143.19 24.614,0.0451 11.379,-15.566 42.167,0.0529 10.014,15.333 2.8029,-0.004"
                  id="path238"
                />
                <path
                  d="m 122.15817,165.8224 -0.019,-5.774 4.89083,-1.80441 v -5.774"
                  id="path240"
                  fillRule
                />
                <path
                  d="m 138.64,147.43 h 26.283 l 10.973,-15.566 h 38.829 l 10.646,15.608 4.427,0.0902"
                  id="path242"
                />
              </g>
              <g
                id="g262"
                className="Gservice"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <path
                  d="m 176.69108,104.14422 v -8.2933 l 12.51664,-6.851473 c 0.01,-1.476807 -0.0127,-0.746681 -0.0127,-4.312447"
                  id="path246"
                  fillRule
                />
                <path
                  d="m 98.364,117.6594 1.135227,-3.21905 6.172303,-2.3899 0.0292,-3.94355"
                  id="path248"
                  fillRule
                />
                <path d="M 169.542,62.431 H 186.28" id="path250" />
                <path
                  d="m 111.8143,84.616 8.5455,0.04339 L 124.53399,70.69 137.49,70.7453"
                  id="path252"
                  fillRule
                />
                <path
                  d="m 111.36,98.157 h 28.561 l 9.5541,-22.884 36.884,-0.02385"
                  id="path254"
                />
                <path
                  d="m 111.58,93.449 25.26,0.13275 9.4464,-22.446 39.849,6e-5"
                  id="path256"
                />
                <path
                  d="m 111.58,88.89 22.07,-0.12243 9.7016,-22.063 42.784,-0.12753"
                  id="path258"
                />
                <path
                  d="m111.95 103.25 31.831-0.0898 10.394-23.372 31.97-6e-3"
                  id="path260"
                />
              </g>
              <g
                id="g284"
                className="Gcredit"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Gdelaycr2"
                  d="m 184.2039,16.9716 0.0434,4.366991 7.29557,1.477939 0.0105,3.847367"
                  id="path264"
                  fillRule
                />
                <path
                  d="m 90.496,19.331 v -4.3236 l 4.8223,-4.9057 2.4838,0.01438"
                  id="path266"
                />
                <path
                  d="m 93.461,19.407 v -2.2555 l 3.7441,-3.4734"
                  id="path268"
                />
                <path
                  className="Fdelaycr2"
                  d="m 175.59499,47.725895 11.055,0.0451"
                  id="path270"
                />
                <path
                  d="m 100.15,35.883 h 8.2933 l 4.2742,11.228 h 3.9552 l 0.82933,1.2759 2.1052,4e-6"
                  id="path272"
                />
                <path
                  d="m 100.48,29.004 h 31.793 l 11.193,11.401 42.85,-3.1e-5"
                  id="path274"
                />
                <path
                  d="m 100.35,32.565 29.419,-0.09022 10.935,11.201 45.788,0.1089"
                  id="path276"
                />
                <path
                  d="m 100.22,25.357 h 34.718 l 10.287,10.973 h 40.977"
                  id="path278"
                />
                <path
                  className="Gdelaycr1"
                  d="m 120.32904,21.71964 17.25424,-0.0034 10.11488,11.197219 38.75188,0.0188"
                  id="path280"
                  fillRule
                />
                <path
                  d="m 100.24,39.66 h 2.2555 l 1.1277,4.9169 2.481,-0.04511 0.67664,-1.1728 h 1.5788"
                  id="path282"
                />
              </g>
              <g
                id="g300"
                className="Gfinal1"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Gdelaycr3"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Gdelaycr3"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
              <g
                id="g300"
                className="Gfinal2"
                stroke="#ccff33"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="2000"
              >
                <path
                  className="Gdelaysv"
                  d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                  id="path286"
                />
                <path
                  className="Gdelaysv"
                  d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                  id="path288"
                />
                <path d="M 212.929,79.347 H 229.56" id="path290" />
                <path d="M 212.924,74.585 H 229.69" id="path292" />
                <path d="M 213.249,62.024 H 229.88" id="path294" />
                <path d="M 213.429,65.748 H 230.06" id="path296" />
                <path d="M 213.289,69.596 H 229.92" id="path298" />
              </g>
            </g>
            <g
              id="g300"
              className="Gfinal3"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="2000"
            >
              <path
                className="Gdelaydr"
                d="m 256.18,99.645 h 5.0623 l 2.5311,4.9169 5.5685,-0.0451 1.5187,-1.1728 h 3.5436"
                id="path286"
              />
              <path
                className="Gdelaydr"
                d="m 257.13,40.974 h 4.3236 l 4.9058,4.8223 -0.0144,2.4838"
                id="path288"
              />
              <path d="M 212.929,79.347 H 229.56" id="path290" />
              <path d="M 212.924,74.585 H 229.69" id="path292" />
              <path d="M 213.249,62.024 H 229.88" id="path294" />
              <path d="M 213.429,65.748 H 230.06" id="path296" />
              <path d="M 213.289,69.596 H 229.92" id="path298" />
            </g>
            <g
              id="g2586"
              className="Ginternal"
              stroke="#ccff33"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeOpacity="1"
              strokeDasharray="30,150"
              strokeDashoffset="0"
            >
              <path d="m 203.06,53.39 v 4.3383" id="path2576" />
              <path d="m 207.37,53.459 v 4.3383" id="path2578" />
              <path d="m 192.28,53.461 v 4.3383" id="path2580" />
              <path d="m 199.52,53.47 v 4.3383" id="path2582" />
              <path d="m 195.9,53.529 v 4.3383" id="path2584" />
            </g>
            <g strokeWidth="0.5">
              <g stroke="#ccff33" strokeLinejoin="round">
                <path d="m87.178 19.14v-4.3383" />
                <path d="m83.454 19.221v-4.3383" />
                <path d="m79.606 19.149v-4.3383" />
                <path d="m93.507 50.909v-4.3383" />
                <path d="m90.101 112 0.03068-4.0316" />
                <path d="m101.58 112.2v-4.3383" />
                <path d="m97.855 112.19v-4.3383" />
                <path d="m94.007 112.12v-4.3383" />
                <path d="m87.143 46.646v4.3383" />
                <path d="m90.541 46.611v4.3383" />
                <path d="m79.583 46.592v4.3383" />
                <path d="m83.425 46.66v4.3383" />
                <path d="m200.01 84.581v4.3383" />
                <path d="m97.306 80.997v-4.3383" />
                <path d="m101.16 81.019v-4.3383" />
                <path d="m105.73 81.019v-4.3383" />
                <path d="m93.581 81.078v-4.3383" />
                <path d="m89.733 81.007v-4.3383" />
                <path d="m114.85 156.41v-4.3384" />
                <path d="m131.62 156.34v-4.3383" />
                <path d="m122.61 156.42v-4.3383" />
                <path d="m118.76 156.35v-4.3383" />
                <path d="m122.5 124.5v-4.3383" />
                <path d="m126.35 124.53v-4.3383" />
                <path d="m131.12 124.53v-4.3383" />
                <path d="m118.78 124.58v-4.3383" />
                <path d="m114.93 124.51v-4.3383" />
                <path d="m230.04 103.34h-4.3383" />
                <path d="m230.06 99.487h-4.3383" />
                <path d="m230.06 94.725h-4.3383" />
                <path d="m230.12 107.06h-4.3383" />
                <path d="m230.05 110.91h-4.3383" />
                <path d="m230 83.197h-4.3383" />
                <path d="m230.08 86.922h-4.3383" />
                <path d="m230.01 90.77h-4.3383" />
                <path d="m260.57 103.47h-4.3383" />
                <path d="m260.59 94.862h-4.3384" />
                <path d="m260.65 107.2h-4.3383" />
                <path d="m260.58 111.05h-4.3383" />
                <path d="m260.53 83.334h-4.3383" />
                <path d="m260.55 79.484h-4.3383" />
                <path d="m260.55 74.722h-4.3383" />
                <path d="m260.61 87.059h-4.3383" />
                <path d="m260.54 90.907h-4.3383" />
                <path d="m230.13 58.173h-4.3383" />
                <path d="m230.13 53.411h-4.3383" />
                <path d="m260.63 62.161h-4.3383" />
                <path d="m260.66 58.31h-4.3384" />
                <path d="m260.66 53.548h-4.3384" />
                <path d="m260.72 65.885h-4.3383" />
                <path d="m260.64 69.733h-4.3383" />
                <path d="m230.15 44.933h-4.3383" />
                <path d="m230.17 41.083h-4.3383" />
                <path d="m230.17 36.32h-4.3383" />
                <path d="m230.23 48.657h-4.3383" />
                <path d="m230.26 31.332h-4.3383" />
                <path d="m260.64 40.994h-4.3383" />
                <path d="m260.66 37.144h-4.3384" />
                <path d="m260.66 32.381h-4.3384" />
                <path d="m260.72 44.719h-4.3383" />
                <path d="m260.64 48.567h-4.3383" />
                <path d="m261.31 144.07h-4.3383" />
                <path d="m261.34 140.22h-4.3383" />
                <path d="m261.34 135.45h-4.3384" />
                <path d="m261.39 147.79h-4.3383" />
                <path d="m261.28 123.93h-4.3383" />
                <path d="m261.3 120.08h-4.3384" />
                <path d="m261.3 115.31h-4.3384" />
                <path d="m261.36 127.65h-4.3383" />
                <path d="m261.29 131.5h-4.3383" />
                <path d="m242.78 162.2v-4.3383" />
                <path d="m246.63 162.22v-4.3383" />
                <path d="m251.39 162.22v-4.3383" />
                <path d="m239.05 162.28v-4.3383" />
                <path d="m235.21 162.21v-4.3383" />
                <path d="m243 22.613v-4.3383" />
                <path d="m246.85 22.634v-4.3383" />
                <path d="m251.62 22.634v-4.3383" />
                <path d="m239.28 22.694v-4.3383" />
                <path d="m235.43 22.622v-4.3383" />
                <path d="m203.73 89.166v-4.3383" />
                <path d="m208.49 89.166v-4.3383" />
                <path d="m196.16 89.225v-4.3383" />
                <path d="m192.31 89.154v-4.3383" />
                <path d="m199.43 26.726v-3.9478" />
                <path d="m203.32 26.662-0.04339-3.861" />
                <path d="m208.04 26.662v-3.861" />
                <path d="m195.79 26.851-0.08678-3.9912" />
              </g>
              <g
                id="262"
                className="circles"
                stroke="#d6dce5"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity="1"
                strokeDasharray="30,150"
                strokeDashoffset="0"
              >
                <ellipse
                  transform="rotate(-90)"
                  cx="-103.39"
                  cy="275.53"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse cx="184.18" cy="16.667" rx="1.3879" ry="1.356" />
                <ellipse cx="120.97" cy="48.424" rx="1.3879" ry="1.356" />
                <ellipse
                  transform="rotate(-90)"
                  cx="-70.495"
                  cy="138.71"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-62.522"
                  cy="168.17"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-47.59"
                  cy="174.26"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-118.83"
                  cy="98.058"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-10.172"
                  cy="99.232"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-21.743"
                  cy="118.91"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-105.19"
                  cy="176.79"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-167.26"
                  cy="122.07"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-121.12"
                  cy="225.72"
                  rx="1.3879"
                  ry="1.356"
                />
                <ellipse
                  transform="rotate(-90)"
                  cx="-49.337"
                  cy="266.3"
                  rx="1.3879"
                  ry="1.356"
                />
              </g>
              <g>
                <g
                  id="230"
                  className="rectangles"
                  stroke="#d6dce5"
                  strokeWidth="0.8"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeOpacity="1"
                  strokeDasharray="0"
                  strokeDashoffset="10"
                >
                  <path
                    id="path218"
                    x="73.701866"
                    y="19.382929"
                    width="26.583883"
                    height="27.022976"
                    d="m75.554 19.383h22.88a1.8521 1.8521 45 0 1 1.8521 1.8521v23.319a1.8521 1.8521 135 0 1-1.8521 1.8521h-22.88a1.8521 1.8521 45 0 1-1.8521-1.8521v-23.319a1.8521 1.8521 135 0 1 1.8521-1.8521z"
                  />
                  <path
                    id="path220"
                    x="85.06031"
                    y="81.03772"
                    width="26.583883"
                    height="27.022976"
                    d="m86.912 81.038h22.88a1.8521 1.8521 45 0 1 1.8521 1.8521v23.319a1.8521 1.8521 135 0 1-1.8521 1.8521h-22.88a1.8521 1.8521 45 0 1-1.8521-1.8521v-23.319a1.8521 1.8521 135 0 1 1.8521-1.8521z"
                  />
                  <path
                    id="path222"
                    x="230.03937"
                    y="22.645262"
                    width="26.617746"
                    height="135.23961"
                    d="m231.89 22.645h22.914a1.8521 1.8521 45 0 1 1.8521 1.8521v131.54a1.8521 1.8521 135 0 1-1.8521 1.8521h-22.914a1.8521 1.8521 45 0 1-1.8521-1.8521v-131.54a1.8521 1.8521 135 0 1 1.8521-1.8521z"
                  />
                  <path
                    id="path224"
                    x="108.8889"
                    y="124.31343"
                    width="29.413046"
                    height="28.080542"
                    d="m110.74 124.31h25.709a1.8521 1.8521 45 0 1 1.8521 1.8521v24.376a1.8521 1.8521 135 0 1-1.8521 1.8521h-25.709a1.8521 1.8521 45 0 1-1.8521-1.8521v-24.376a1.8521 1.8521 135 0 1 1.8521-1.8521z"
                  />
                  <path
                    id="path226"
                    x="186.52127"
                    y="26.691357"
                    width="26.583883"
                    height="27.022976"
                    d="m188.37 26.691h22.88a1.8521 1.8521 45 0 1 1.8521 1.8521v23.319a1.8521 1.8521 135 0 1-1.8521 1.8521h-22.88a1.8521 1.8521 45 0 1-1.8521-1.8521v-23.319a1.8521 1.8521 135 0 1 1.8521-1.8521z"
                  />
                  <path
                    id="path228"
                    x="186.50911"
                    y="57.761295"
                    width="26.583883"
                    height="27.022976"
                    d="m188.36 57.761h22.88a1.8521 1.8521 45 0 1 1.8521 1.8521v23.319a1.8521 1.8521 135 0 1-1.8521 1.8521h-22.88a1.8521 1.8521 45 0 1-1.8521-1.8521v-23.319a1.8521 1.8521 135 0 1 1.8521-1.8521z"
                  />
                </g>
                <g stroke="#ccff33" strokeLinejoin="round">
                  <g strokeWidth="0.5">
                    <path d="m138.05 130.46h21.276l12.145-15.566h50.149l3.0621 5.2311" />
                    <path d="m138.02 134.55 23.092 6e-3 12.326-15.566 46.208 0.0638 8.9312 15.438 1.4673 0.0638" />
                    <path d="m138.5 138.74h23.892l11.694-15.205 43.793-0.0826 9.5632 15.063 2.485 0.0856" />
                    <path d="m138.64 143.19 24.614 0.0451 11.379-15.566 42.167 0.0529 10.014 15.333 2.8029-4e-3" />
                    <path d="m122.16 165.82-0.019-5.774 4.8908-1.8044v-5.774" />
                    <path d="m138.64 147.43h26.283l10.973-15.566h38.829l10.646 15.608 4.427 0.0902" />
                  </g>
                  <g>
                    <g strokeWidth="0.5">
                      <path d="m176.69 104.14v-8.2933l12.517-6.8515c0.01-1.4768-0.0127-0.74668-0.0127-4.3124" />
                      <path d="m98.364 117.66 1.1352-3.219 6.1723-2.3899 0.0292-3.9436" />
                      <path d="m169.54 62.431h16.738" />
                      <path d="m111.81 84.616 8.5455 0.04339 4.1742-13.969 12.956 0.0553" />
                    </g>
                    <path
                      d="m111.36 98.157h28.561l9.5541-22.884 36.884-0.02385"
                      strokeWidth="0.5"
                    />
                    <path
                      d="m111.58 93.449 25.26 0.13275 9.4464-22.446 39.849 6e-5"
                      strokeWidth="0.5"
                    />
                    <path
                      d="m111.58 88.89 22.07-0.12243 9.7016-22.063 42.784-0.12753"
                      strokeWidth="0.5"
                    />
                    <path
                      d="m186.14 79.782-33.653 3e-3 -9.9603 23.459-30.581 6e-3"
                      strokeWidth="0.5"
                    />
                  </g>
                  <g strokeWidth="0.5">
                    <path d="m184.2 16.972 0.0434 4.367 7.2956 1.4779 0.0105 3.8474" />
                    <path d="m90.496 19.331v-4.3236l4.8223-4.9057 2.4838 0.01438" />
                    <path d="m93.461 19.407v-2.2555l3.7441-3.4734" />
                    <path d="m175.59 47.726 11.055 0.0451" />
                    <path d="m100.15 35.883h8.2933l4.2742 11.228h3.9552l0.82933 1.2759 2.1052 4e-6" />
                    <path d="m100.48 29.004h31.793l11.193 11.401 42.85-3.1e-5" />
                    <path d="m100.35 32.565 29.419-0.09022 10.935 11.201 45.788 0.1089" />
                    <path d="m100.22 25.357h34.718l10.287 10.973h40.977" />
                    <path d="m120.33 21.72 17.254-0.0034 10.115 11.197 38.752 0.0188" />
                    <path d="m100.24 39.66h2.2555l1.1277 4.9169 2.481-0.04511 0.67664-1.1728h1.5788" />
                  </g>
                  <g>
                    <g strokeWidth="0.5">
                      <path d="m256.18 99.645h5.0623l2.5311 4.9169 5.5685-0.0451 1.5187-1.1728h3.5436" />
                      <path d="m257.13 40.974h4.3236l4.9058 4.8223-0.0144 2.4838" />
                      <path d="m212.93 79.347h16.631" />
                    </g>
                    <path d="m212.92 74.585h16.766" strokeWidth="0.5" />
                    <g strokeWidth="0.5">
                      <path d="m213.25 62.024h16.631" />
                      <path d="m213.43 65.748h16.631" />
                      <path d="m213.29 69.596h16.631" />
                    </g>
                    <g>
                      <path d="m203.06 53.559v4.1696" strokeWidth="0.5" />
                      <g strokeWidth="0.5">
                        <path d="m207.37 53.459v4.3383" />
                        <path d="m192.28 53.461v4.3383" />
                        <path d="m199.52 53.47v4.3383" />
                        <path d="m195.9 53.529v4.3383" fill="#000" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="text"></g>

            <g
              id="g1854"
              fill="#000000"
              fontFamily="'Hind Siliguri'"
              fontWeight="bold"
              strokeWidth=".52917"
            >
              <text
                className="applycr"
                id="text1176"
                x="74.660919"
                y="37.690918"
                fontSize="9.1722px"
                xmlSpace="preserve"
              >
                <tspan
                  id="tspan1174"
                  x="74.660919"
                  y="37.690918"
                  fontFamily="'Hind Siliguri'"
                  fontSize="9.1722px"
                  fontWeight="bold"
                  strokeWidth=".52917"
                >
                  Apply
                </tspan>
              </text>
              <text
                className="youcr1"
                id="text1176-2"
                x="74.97805"
                y="30.186569"
                fontSize="7.0556px"
                xmlSpace="preserve"
              >
                <tspan id="tspan1255" x="74.97805" y="30.186569">
                  You
                </tspan>
              </text>
            </g>
            <g
              fill="#000000"
              fontFamily="'Hind Siliguri'"
              fontWeight="bold"
              strokeWidth=".52917"
            >
              <text
                className="applysv"
                id="text1176-3"
                x="86.115959"
                y="98.00367"
                fontSize="9.1722px"
                xmlSpace="preserve"
              >
                <tspan
                  id="tspan1174-5"
                  x="86.115959"
                  y="98.00367"
                  fontFamily="'Hind Siliguri'"
                  fontSize="9.1722px"
                  fontWeight="bold"
                  strokeWidth=".52917"
                >
                  Apply
                </tspan>
              </text>
              <text
                className="yousv1"
                id="text1176-2-8"
                x="86.43309"
                y="90.499321"
                fontSize="7.0556px"
                xmlSpace="preserve"
              >
                <tspan id="tspan1255-7" x="86.43309" y="90.499321">
                  You
                </tspan>
              </text>
              <g
                fill="#000000"
                fontFamily="'Hind Siliguri'"
                fontWeight="bold"
                strokeWidth=".52917"
              >
                <text
                  className="buydr"
                  id="text1176-3-1"
                  x="111.64323"
                  y="142.67639"
                  fontSize="9.1722px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1934">Buy</tspan>
                </text>
                <text
                  className="youdr1"
                  id="text1176-2-8-3"
                  x="111.96036"
                  y="135.17204"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1255-7-2" x="111.96036" y="135.17204">
                    You
                  </tspan>
                </text>
              </g>
              <g
                id="g1863"
                fill="#000000"
                fontFamily="'Hind Siliguri'"
                fontWeight="bold"
                strokeWidth=".52917"
              >
                <text
                  className="prequalcr"
                  id="text1176-0"
                  x="186.46861"
                  y="35.049309"
                  fontSize="8.4667px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1333" x="186.46861" y="35.049309" dy="3.45">
                    Pre-
                  </tspan>
                  <tspan id="tspan1335" x="186.46861" y="45.632645">
                    qualify
                  </tspan>
                </text>
                <text
                  className="wecr1"
                  id="text1176-2-4"
                  x="202.41519"
                  y="33.043823"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1364" x="202.41519" y="33.043823">
                    We
                  </tspan>
                </text>
                <text
                  className="youcr2"
                  id="text1176-2-4-4"
                  x="193.48215"
                  y="52.913132"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1255-4-1" x="193.48215" y="52.913132">
                    You
                  </tspan>
                </text>
              </g>
              <g
                id="g1874"
                fill="#000000"
                fontFamily="'Hind Siliguri'"
                fontWeight="bold"
                strokeWidth=".52917"
              >
                <text
                  className="supinstcr"
                  id="text1176-0-2"
                  x="243.65161"
                  y="32.956696"
                  fontSize="8.4667px"
                  textAlign="center"
                  textAnchor="middle"
                  xmlSpace="preserve"
                >
                  <tspan
                    id="tspan1488"
                    x="243.65161"
                    y="32.956696"
                    dy="5.8000002"
                  >
                    Supply
                  </tspan>
                  <tspan id="tspan1490" x="243.65161" y="43.540031" dy="3.54">
                    &amp;
                  </tspan>
                  <tspan
                    id="tspan1492"
                    x="243.65161"
                    y="54.123371"
                    dy="-0.92000002"
                  >
                    Install
                  </tspan>
                  <tspan
                    id="tspan1494"
                    x="243.65161"
                    y="64.706711"
                    dy="-4.3099999"
                  >
                    for
                  </tspan>
                </text>
                <text
                  className="wecr3"
                  id="text1176-2-4-1"
                  x="237.94011"
                  y="30.358002"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1364-74" x="237.94011" y="30.358002">
                    We
                  </tspan>
                </text>
                <text
                  className="youcr4"
                  id="text1176-2-4-4-4"
                  x="236.39301"
                  y="66.77507"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1255-4-1-9" x="236.39301" y="66.77507">
                    You
                  </tspan>
                </text>
              </g>
              <g
                id="g1697"
                fill="#000000"
                fontFamily="'Hind Siliguri'"
                fontWeight="bold"
                strokeWidth=".52917"
              >
                <text
                  className="auditcr"
                  id="text1176-0-1"
                  x="185.90213"
                  y="66.706688"
                  fontSize="8.4667px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1665" x="185.90213" y="66.706688" dy="3.02">
                    Energy
                  </tspan>
                  <tspan id="tspan1667" x="185.90213" y="77.290024">
                    Audit
                  </tspan>
                </text>
                <text
                  className="wecr2"
                  id="text1176-2-4-5"
                  x="187.18526"
                  y="63.719391"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1364-7" x="187.18526" y="63.719391">
                    We
                  </tspan>
                </text>
                <text
                  className="youcr3"
                  id="text1176-2-4-4-6"
                  x="198.95955"
                  y="83.304985"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1255-4-1-7" x="198.95955" y="83.304985">
                    You
                  </tspan>
                </text>
              </g>
              <g
                id="g1697-9"
                transform="translate(-.15423 .18807)"
                fill="#000000"
                fontFamily="'Hind Siliguri'"
                fontWeight="bold"
                strokeWidth=".52917"
              >
                <text
                  className="auditsv"
                  id="text1176-0-1-4"
                  x="185.90213"
                  y="66.706688"
                  fontSize="8.4667px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1665-7" x="185.90213" y="66.706688" dy="3.02">
                    Energy
                  </tspan>
                  <tspan id="tspan1667-2" x="185.90213" y="77.290024">
                    Audit
                  </tspan>
                </text>
                <text
                  className="wesv1"
                  id="text1176-2-4-5-2"
                  x="187.18526"
                  y="63.719391"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1364-7-6" x="187.18526" y="63.719391">
                    We
                  </tspan>
                </text>
                <text
                  className="yousv2"
                  id="text1176-2-4-4-6-1"
                  x="198.95955"
                  y="83.304985"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1255-4-1-7-4" x="198.95955" y="83.304985">
                    You
                  </tspan>
                </text>
              </g>
              <g
                id="g1895"
                fill="#000000"
                fontFamily="'Hind Siliguri'"
                fontWeight="bold"
                strokeWidth=".52917"
              >
                <text
                  className="supinstdr"
                  id="text1176-0-2-3"
                  x="243.34697"
                  y="121.73966"
                  fontSize="8.4667px"
                  textAlign="center"
                  textAnchor="middle"
                  xmlSpace="preserve"
                >
                  <tspan
                    id="tspan1488-6"
                    x="243.34697"
                    y="121.73966"
                    dy="5.8000002"
                  >
                    Supply
                  </tspan>
                  <tspan id="tspan1490-6" x="243.34697" y="132.323" dy="3.54">
                    &amp;
                  </tspan>
                  <tspan
                    id="tspan1492-3"
                    x="243.34697"
                    y="142.90633"
                    dy="-0.92000002"
                  >
                    Install
                  </tspan>
                  <tspan
                    id="tspan1494-5"
                    x="243.34697"
                    y="153.48967"
                    dy="-4.3099999"
                  >
                    for
                  </tspan>
                </text>
                <text
                  className="wedr"
                  id="text1176-2-4-1-5"
                  x="237.63547"
                  y="119.14097"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1364-74-2" x="237.63547" y="119.14097">
                    We
                  </tspan>
                </text>
                <text
                  className="youdr2"
                  id="text1176-2-4-4-4-9"
                  x="236.08836"
                  y="155.55803"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1255-4-1-9-9" x="236.08836" y="155.55803">
                    You
                  </tspan>
                </text>
              </g>
              <g
                id="g1884"
                fill="#000000"
                fontFamily="'Hind Siliguri'"
                fontWeight="bold"
                strokeWidth=".52917"
              >
                <text
                  className="managesv"
                  id="text1176-0-2-3-2"
                  x="243.72948"
                  y="81.137375"
                  fontSize="8.4667px"
                  textAlign="center"
                  textAnchor="middle"
                  xmlSpace="preserve"
                >
                  <tspan
                    id="tspan1669"
                    x="243.72948"
                    y="81.137375"
                    dy="2.8299999"
                  >
                    Manage
                  </tspan>
                  <tspan id="tspan1671" x="243.72948" y="91.720711">
                    Power
                  </tspan>
                  <tspan id="tspan1673" x="243.72948" y="102.30405" dy="-3.49">
                    for
                  </tspan>
                </text>
                <text
                  className="wesv2"
                  id="text1176-2-4-1-5-4"
                  x="238.01797"
                  y="78.538666"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1364-74-2-3" x="238.01797" y="78.538666">
                    We
                  </tspan>
                </text>
                <text
                  className="yousv3"
                  id="text1176-2-4-4-4-9-9"
                  x="237.00003"
                  y="104.37263"
                  fontSize="7.0556px"
                  xmlSpace="preserve"
                >
                  <tspan id="tspan1255-4-1-9-9-3" x="237.00003" y="104.37263">
                    You
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
        }
      </StyledSvg>
      <ReloadButton onClick={handleRestartAnimations}>
        <ReloadIcon /> Restart
      </ReloadButton>

      <AnimationTextOverlay1>
        <p>How it works...</p>
      </AnimationTextOverlay1>
      <AnimationTextOverlay2>
        <p>
          <AsteriskIcon />
          Need credit financing?
        </p>
      </AnimationTextOverlay2>
      <AnimationTextOverlay3>
        <p>
          <AsteriskIcon />
          Need your power supply
        </p>
        <p>managed more efficiently</p>
        <p>and reliably?</p>
      </AnimationTextOverlay3>
      <AnimationTextOverlay4>
        <p>
          <AsteriskIcon />
          Want to pay upfront?
        </p>
      </AnimationTextOverlay4>
    </div>
  );
};

export default ElectricLineAnimation;
