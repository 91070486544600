import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  --color-invert: #ffffff;
  --width: 150px;
  --height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--width);
  height: var(--height);
`;

const Button = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: radial-gradient(
      circle closest-side,
      var(--color-invert),
      transparent
    );
    filter: blur(20px);
    opacity: 0;
    transition: opacity 1s ease, transform 1s ease;
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
  }
`;

const rotateCircle = keyframes`
  0% {
    transform: rotate(-58deg);
  }
  100% {
    transform: rotate(302deg);
  }
`;

const lineAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(10px);
  }
  40% {
    transform: translateY(-25px);
  }
  60% {
    transform: translateY(-25px);
  }
  85% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const clickAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

const PowerOnOffStyles = `
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  fill: none;
  stroke: var(--color-invert);
  stroke-width: 8px;
  stroke-linecap: round;
  stroke-linejoin: round;
  .line {
    opacity: 0.2;
  }
  .circle {
    opacity: 0.2;
    transform: rotate(-58deg);
    transform-origin: center 80px;
    stroke-dasharray: 220;
    stroke-dashoffset: 40;
  }
`;

const PowerOn = styled.svg`
  ${PowerOnOffStyles}
  filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.8));
  .line {
    opacity: 0;
    transition: opacity 0.3s ease 1s;
  }
  .circle {
    opacity: 1;
    stroke-dashoffset: 220;
    transition: transform 0s ease, stroke-dashoffset 1s ease 0s;
  }
`;

const PowerOff = styled.svg`
  ${PowerOnOffStyles}
  .line {
    transition: opacity 0.05s ease-in 0.55s;
  }
  .circle {
    transform: rotate(302deg);
    stroke-dashoffset: 40;
    transition: transform 0.4s ease 0.2s, stroke-dashoffset 0.4s ease 0.2s;
  }
`;

const Input = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  cursor: pointer;
  opacity: 0;

  &:checked {
    + ${Button} {
      &:after {
        opacity: 0.15;
        transform: scale(2) perspective(1px) translateZ(0);
        backface-visibility: hidden;
        transition: opacity 0.5s ease, transform 0.5s ease;
      }

      ${PowerOn},
      ${PowerOff} {
        animation: ${clickAnimation} 0.3s ease-in forwards;

        .line {
          opacity: 1;
          transition: opacity 0.05s ease-in 0.55s;
          animation: ${lineAnimation} 0.8s ease-in forwards;
        }
        .circle {
          transform: rotate(302deg);
          stroke-dashoffset: 40;
          transition: transform 0.4s ease 0.2s, stroke-dashoffset 0.4s ease 0.2s;
        }
      }
    }
    ${PowerOn} {
      animation: ${rotateCircle} 2s linear forwards;
    }
  }
`;

const PowerSwitch = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleInputChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const toggleSwitch = () => {
      setIsChecked((prev) => !prev);
    };

    const startCycle = () => {
      toggleSwitch();
      setTimeout(() => {
        toggleSwitch();
        setTimeout(startCycle, 5000);
      }, 60000);
    };

    const initialDelay = setTimeout(() => {
      startCycle();
    }, 5000);

    return () => {
      clearTimeout(initialDelay);
    };
  }, []);

  return (
    <Container>
      <Input type="checkbox" checked={isChecked} onChange={handleInputChange} />
      <Button>
        <PowerOn>
          <line x1="75" y1="34" x2="75" y2="58" className="line" />
          <circle cx="75" cy="80" r="35" className="circle" />
        </PowerOn>
        <PowerOff>
          <line x1="75" y1="34" x2="75" y2="58" className="line" />
          <circle cx="75" cy="80" r="35" className="circle" />
        </PowerOff>
      </Button>
    </Container>
  );
};

export default PowerSwitch;
