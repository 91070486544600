import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { FaRssSquare } from "react-icons/fa";
import { RightIcon, InfoIcon } from "../Icons";
import axiosInstance from "../../utils/axiosInstance";
import {
  GlobalStyle,
  RestHeading,
  Highlight,
  HighlightContainer3,
} from "../StyledComponents";
import insightheader from "../../assets/images/insightheader.svg";
import noinsights from "../../assets/images/noinsights.svg";
import { useInView } from "react-intersection-observer";
import { Grid } from "react-loader-spinner";

const Insights = ({ isHPBannerVisible }) => {
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const isSmallMobile = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get("/api/insights");
        const insightList = response.data;
        setInsights(insightList);
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    fetchInsights();
  }, []);

  const groupInsightsByYear = () => {
    const groupedInsights = {};
    insights.forEach((insight) => {
      const year = new Date(
        insight.dateOfPublication._seconds * 1000
      ).getFullYear();
      if (!groupedInsights[year]) {
        groupedInsights[year] = [];
      }
      groupedInsights[year].push(insight);
    });
    return groupedInsights;
  };

  const renderInsightsByYear = () => {
    const groupedInsights = groupInsightsByYear();

    const years = Object.keys(groupedInsights).sort((a, b) => b - a);

    return years.map((year) => {
      const insightsForYear = groupedInsights[year];

      const gridContainerStyles = {
        display: "grid",
        gridTemplateColumns:
          insightsForYear.length > 1 && !isSmallMobile ? "1fr 1fr" : "1fr",
        gap: "40px",
        marginTop: "10px",
        maxWidth: "1200px",
        margin: "0 auto",
      };

      return (
        <div key={year}>
          <h4 style={{ marginTop: "20px", color: "#9cb2b0" }}>{year}</h4>
          <div style={gridContainerStyles}>
            {insightsForYear
              .sort((a, b) => {
                const dateA = new Date(a.dateOfPublication._seconds * 1000);
                const dateB = new Date(b.dateOfPublication._seconds * 1000);
                return dateB - dateA;
              })
              .map((insight, index) => (
                <Link
                  key={insight.id}
                  to={`/insights/${insight.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <InsightCard
                    insight={insight}
                    index={index}
                    isMobile={isMobile}
                  />
                </Link>
              ))}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <GlobalStyle />
      <Element
        name="insights-top"
        style={{
          paddingTop: isHPBannerVisible ? "10rem" : "7rem",
        }}
      >
        <div
          style={{
            position: "relative",
            paddingLeft: "3%",
            height: "10px",
            zIndex: 3,
            display: window.innerWidth <= 991 ? "block" : "none",
          }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            Home{" "}
          </Link>
          <RightIcon /> Insights
        </div>
        <div
          style={{
            marginTop: isMobile ? "20px" : 0,
            width: "100vw",
            height: isMobile ? "200px" : "450px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${insightheader})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100vw",
              height: "100%",
              filter: "brightness(55%)",
            }}
          />
          <div
            style={{
              position: "absolute",
              top:
                window.innerWidth <= 600
                  ? "-100px"
                  : window.innerWidth <= 991
                  ? "-80px"
                  : "-280px",
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RestHeading
              style={{
                paddingTop: "4%",
                paddingBottom: "3%",
                color: "white",
              }}
            >
              <HighlightContainer3>
                <Highlight> The Kepler Insights </Highlight>
              </HighlightContainer3>
            </RestHeading>
          </div>
        </div>
        {insights.length > 0 && (
          <a
            href="https://www.keplersolutionshq.com/api/rss/insights"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginLeft: isMobile ? "85%" : "93%",
              textDecoration: "none",
              color: "#7a9795",
              height: "auto",
            }}
          >
            <FaRssSquare /> RSS
          </a>
        )}
        <div
          style={{
            margin: "auto",
            marginTop: "",
            marginBottom: "10vh",
            padding: "20px",
            maxWidth: "1200px",
            backgroundImage:
              !loading & (insights.length === 0) ? `url(${noinsights})` : "",
            backgroundSize: !loading & (insights.length === 0) ? "cover" : "",
          }}
        >
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "10vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 6000,
                backgroundColor: "rgb(0,0,0,0)",
                backdropFilter: "blur(1px)",
                marginBottom: "50vw",
              }}
            >
              <Grid
                height="30%"
                width="30%"
                color="#245e41"
                ariaLabel="grid-loading"
                radius="12.5"
                wrapperStyle={{}}
                wrapperClass="grid-loader"
                visible={true}
              />
            </div>
          ) : insights.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "90vw",
                width: "95vw",
                color: "#245e41",
                fontWeight: "600",
              }}
            >
              <div style={{ whiteSpace: "nowrap" }}>
                <InfoIcon />
                Check back for insights
              </div>
            </div>
          ) : (
            renderInsightsByYear()
          )}
        </div>
      </Element>
    </>
  );
};

const InsightCard = ({ insight, index, isMobile }) => {
  const { ref, inView } = useInView({
    threshold: 0.9,
    rootMargin: "-25% 0px",
  });

  return (
    <div
      ref={ref}
      id={`insight-${insight.id}`}
      className={`insight-card ${isMobile ? "mobile" : ""} ${
        inView ? "in-view" : ""
      }`}
      style={{
        position: "relative",
        height: "200px",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        maxWidth: "100%",
      }}
    >
      <div
        className="cover-photo"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${insight.headerPhoto})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div
        className="insight-details"
        style={{
          position: "relative",
          zIndex: 1,
          padding: "10px",
          height: "100%",
        }}
      >
        <h4
          style={{
            marginTop: "8px",
            color: "#cff",
            WebkitTextStroke: "0.3px #cf3",
            display: "flex",
            alignItems: "top",
            height: "30%",
          }}
        >
          {insight.title}
        </h4>
        <p
          style={{
            height: "40%",
            color: "white",
            display: "flex",
            alignItems: "top",
            marginBottom: "10px",
          }}
        >
          {insight.header}
        </p>
        <p
          style={{
            height: "15%",
            color: "#cff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          <span>
            {new Date(
              insight.dateOfPublication._seconds * 1000
            ).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </span>
          <span>by {insight.author}</span>
        </p>
      </div>
    </div>
  );
};

export default Insights;
