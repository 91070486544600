const PaymentInfo = {
  Nigeria: {
    countryCode: "NG",
    lease: {
      bankName: "Kuda Microfinance Bank (Kuda MFB)",
      accountNumber: "3001816546",
      reference: "Proforma or Agreement Number",
    },
    purchaseOrder: {
      bankName: "Kuda Microfinance Bank (Kuda MFB)",
      accountNumber: "3001816553",
      reference: "Proforma or Agreement Number",
    },
  },/*
  USA: {
    countryCode: "US",
    lease: {
      bankName: "Bank of America",
      routingNumber: "121000358",
      accountNumber: "1234567890",
      reference: "Lease Agreement Number",
    },
    purchaseOrder: {
      bankName: "Chase Bank",
      routingNumber: "021000021",
      accountNumber: "0987654321",
      reference: "Purchase Order Number",
    },
  },
  UK: {
    countryCode: "GB",
    lease: {
      bankName: "Barclays Bank",
      sortCode: "20-32-53",
      accountNumber: "12345678",
      reference: "Lease Reference Number",
    },
    purchaseOrder: {
      bankName: "Lloyds Bank",
      sortCode: "30-98-74",
      accountNumber: "87654321",
      reference: "Purchase Order Reference Number",
    },
  },
  Canada: {
    countryCode: "CA",
    lease: {
      bankName: "Royal Bank of Canada",
      transitNumber: "12345",
      institutionNumber: "678",
      accountNumber: "9876543210",
      reference: "Lease Reference",
    },
    purchaseOrder: {
      bankName: "Toronto-Dominion Bank",
      transitNumber: "54321",
      institutionNumber: "876",
      accountNumber: "0123456789",
      reference: "Purchase Order Reference",
    },
  },
  Ghana: {
    countryCode: "GH",
    lease: {
      bankName: "Standard Chartered Bank Ghana",
      accountNumber: "1234567890",
      reference: "Lease Reference",
    },
    purchaseOrder: {
      bankName: "Ghana Commercial Bank",
      accountNumber: "0987654321",
      reference: "Purchase Order Reference",
    },
  },
  South_Africa: {
    countryCode: "ZA",
    lease: {
      bankName: "Standard Bank",
      accountNumber: "1234567890",
      reference: "Lease Reference",
    },
    purchaseOrder: {
      bankName: "First National Bank",
      accountNumber: "0987654321",
      reference: "Purchase Order Reference",
    },
  },
  Kenya: {
    countryCode: "KE",
    lease: {
      bankName: "Equity Bank",
      accountNumber: "1234567890",
      reference: "Lease Reference",
    },
    purchaseOrder: {
      bankName: "Kenya Commercial Bank",
      accountNumber: "0987654321",
      reference: "Purchase Order Reference",
    },
  },*/
};

export default PaymentInfo;